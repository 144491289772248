import { useCallback } from 'react';

import { AttributeSettingProps } from '../types';

import type { Length } from './SimpleLengthSettings';
import { parseLength, SimpleLengthSettings } from './SimpleLengthSettings';

export const FontSizeSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  const { activeNodePos } = activeNodeResult;

  const onChange = useCallback(
    (length: Length) => {
      editor.chain().selectTextBlock().setFontSize(`${length.value}${length.unit}`).focus().run();
    },
    [editor]
  );

  return (
    <SimpleLengthSettings
      key={`${activeNodePos}-button-text`}
      title="Font Size"
      defaultValue={parseLength(editor.getAttributes('textStyle').fontSize || '')}
      onChange={onChange}
    />
  );
};
