import { Trash } from '@phosphor-icons/react';
import { TNavbarMenuElement } from '@shared/dream-components';

import SectionRenderer from '@/routes/website/_components/DreamEditor/AttributesPanel/helpers/SectionRenderer';
import { Button } from '@/routes/website/_components/UI/Button';

import { useNavbarContext } from '../../../NavbarContext';
import { getParent } from '../../../utils';
import { WidthHeightSettings } from '../general/WidthHeightSettings';

import { AlignmentSettings } from './AlignmentSettings';
import { RowNumberSettings } from './RowNumberSettings';

export function NavMenuLayoutSettings() {
  const { content, selectedContent, onDeleteNode, onUpdateNodeAttributes } = useNavbarContext();

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== 'navbar_menu' && selectedContent.type !== 'navbar_menu_list') return null;

  const rootMenuContent =
    selectedContent?.type === 'navbar_menu_list' && content ? getParent(content, selectedContent) : selectedContent;

  return (
    <SectionRenderer
      title="Navigation Bar"
      actions={
        selectedContent.type === 'navbar_menu_list' ? (
          <Button
            size="sm"
            variant="secondary"
            iconClassName="text-wb-secondary"
            Icon={Trash}
            onClick={() => {
              if (!content) return;
              const parent = getParent(content, selectedContent) as TNavbarMenuElement;
              const originalRowLength = parent?.content?.length || 0;
              onDeleteNode(selectedContent.attrs?.id || '');

              if (parent?.type === 'navbar_menu') {
                onUpdateNodeAttributes(parent.attrs?.id || '', { rows: Math.max(originalRowLength - 1, 0) });
              }
            }}
          />
        ) : undefined
      }
    >
      <AlignmentSettings />
      {rootMenuContent && (
        <WidthHeightSettings selectedContent={rootMenuContent} attribute="width" defaultFixedValue={700} />
      )}
      {selectedContent.type === 'navbar_menu_list' && (
        <WidthHeightSettings selectedContent={selectedContent} attribute="height" defaultFixedValue={68} />
      )}
      <RowNumberSettings />
    </SectionRenderer>
  );
}
