import { useCallback, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { NodeViewWrapper, NodeViewWrapperProps } from '@tiptap/react';
import { AxiosError } from 'axios';
import axiosRetry from 'axios-retry';
import { v4 as uuid } from 'uuid';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';

import { useCurrentUser } from '../../../../../context/current-user-context';
import api from '../../../../../services/swarm';
import { AiChangeImageStylePanel } from '../../../components/panels/AiChangeImageStyle';
import { ImageStyleType } from '../../../components/panels/AiChangeImageStyle/types';
import { Button } from '../../../components/ui/Button';
import { ConsumptionProgress } from '../../../components/ui/ConsumptionProgress';
import { HintWrapper } from '../../../components/ui/HintWrapper';
import { Icon } from '../../../components/ui/Icon';
import { Loader } from '../../../components/ui/Loader';
import { Panel, PanelHeadline } from '../../../components/ui/Panel';
import { Textarea } from '../../../components/ui/Textarea';
import { API } from '../../../lib/api';
import { colors } from '../../../lib/colors';
import { usePublicationContext } from '../../../lib/context/PublicationContext';
import { useConsumption } from '../../../lib/hooks/useConsumption';
import { AiWriterView } from '../../AiWriter/views';
import { replaceImageWithUpload } from '../../ImageBlock/ImageBlockPlugin';

import { Styled } from './AiImageView.styled';

interface Data {
  text: string;
  imageStyle?: ImageStyleType;
}

export const AiImageView = ({ editor, node, getPos, deleteNode }: NodeViewWrapperProps) => {
  const { publicationId } = usePublicationContext();
  const { currentUser } = useCurrentUser();
  const [data, setData] = useState<Data>({
    text: '',
    // text: 'A photo of an beautiful house inside of a forest and full of trees and plants.',
    imageStyle: undefined,
  });
  const [previewImage, setPreviewImage] = useState<string | undefined>(undefined);
  const currentPublicationId = useCurrentPublicationId();
  const { consumption, setConsumption, isConsumptionExceeded, showConsumption } = useConsumption(currentPublicationId);
  const [isFetching, setIsFetching] = useState(false);
  const [isInserting, setIsInserting] = useState(false);
  const textareaId = useMemo(() => uuid(), []);

  const { authorId, authorName, usesCollaboration } = node.attrs;
  const isSameAuthor = currentUser?.id === authorId;

  const generateImage = useCallback(async () => {
    if (!data.text) {
      toast.error('Please enter a description for the image');

      return;
    }

    setIsFetching(true);

    const payload = {
      publication_id: currentPublicationId,
      text: data.text,
      style: data.imageStyle,
    };

    try {
      axiosRetry(api, {
        retryCondition: (errPayload: AxiosError) => errPayload?.response?.status === 429,
        retryDelay: (retryCount) => 2 ** retryCount * 1000 + Math.random() * 1000,
        retries: 3,
      });

      const response = await api.post('/editor/ai/image/prompt', payload);
      const { data: json } = response;

      const url = json.response;

      if (!url.length) {
        return;
      }

      setPreviewImage(url);
      setConsumption({
        consumedRequests: json.requests_consumed,
        monthlyAvailableRequests: json.requests_allowed,
      });
      setIsFetching(false);
    } catch (errPayload: any) {
      const errorMessage = errPayload?.response?.data?.error;
      const message = errorMessage !== 'An error occurred' ? `An error has occured: ${errorMessage}` : errorMessage;

      setIsFetching(false);
      toast.error(message);
    }
  }, [data, currentPublicationId, setConsumption]);

  const insert = useCallback(async () => {
    if (!previewImage?.length) {
      return;
    }

    const { view } = editor;
    const from = getPos();

    setIsInserting(true);

    API.uploadPublicationAssetFromUrl({
      publicationId,
      url: previewImage,
    })
      .then((res) => {
        editor.chain().setNodeSelection(from).deleteSelection().run();

        replaceImageWithUpload(res, view, view.state.selection.$from);
      })
      .finally(() => setIsInserting(false));
  }, [editor, previewImage, publicationId, getPos]);

  const discard = useCallback(() => {
    deleteNode();
  }, [deleteNode]);

  if (usesCollaboration && !isSameAuthor) {
    return (
      <HintWrapper
        icon="AI"
        title={`${authorName || 'Someone'} added the AI Image element to generate magical content`}
      />
    );
  }

  return (
    <NodeViewWrapper data-drag-handle>
      <div {...{ inert: editor.isEditable ? undefined : '' }}>
        <Panel $width="100%">
          <Styled.Container>
            {isFetching && <Loader label="Generating cool new stuff" />}
            {previewImage && (
              <>
                <PanelHeadline>Preview</PanelHeadline>
                <Styled.PreviewContainer style={{ backgroundImage: `url(${previewImage})` }} />
              </>
            )}
            <Styled.Header>
              {!isConsumptionExceeded && (
                <PanelHeadline as="label" htmlFor={textareaId}>
                  Prompt
                </PanelHeadline>
              )}
              {showConsumption && (
                <Styled.ConsumptionWrapper>
                  <ConsumptionProgress
                    $isInline
                    used={consumption.consumedRequests || 0}
                    available={consumption.monthlyAvailableRequests || 0}
                    label="AI requests"
                  />
                </Styled.ConsumptionWrapper>
              )}
            </Styled.Header>
            {!isConsumptionExceeded && (
              <Textarea
                id={textareaId}
                value={data.text}
                onChange={(e) => setData((prevData) => ({ ...prevData, text: e.target.value }))}
                placeholder={`Describe the image that you want me to generate. \r\nFor example: "A beehive full of friendly bees and honey."`}
                required
              />
            )}
            <Styled.PromptFooter>
              {isConsumptionExceeded ? (
                <Styled.ConsumptionHint style={{ width: '100%' }}>
                  You have reached your monthly requests limit.
                </Styled.ConsumptionHint>
              ) : (
                <Styled.HorizontalButtonWrapper>
                  <AiChangeImageStylePanel
                    callback={(imageStyle: ImageStyleType) => {
                      setData((prevData) => ({ ...prevData, imageStyle }));
                    }}
                    defaultValue={data.imageStyle}
                    tippyOptions={{ placement: 'bottom-start' }}
                  />
                </Styled.HorizontalButtonWrapper>
              )}
              <Styled.HorizontalButtonWrapper isConsumptionExceeded={isConsumptionExceeded}>
                {previewImage && (
                  <Button $variant="quaternary" $size="small" $leftSlot={<Icon name="Trash" />} onClick={discard}>
                    Discard
                  </Button>
                )}
                {previewImage && (
                  <Button
                    $variant="quaternary"
                    $size="small"
                    onClick={insert}
                    $leftSlot={
                      isInserting ? <LoadingSpinner size="sm" className="mr-2 text-white" /> : <Icon name="Check" />
                    }
                  >
                    Insert
                  </Button>
                )}
                {!isConsumptionExceeded && (
                  <Button
                    $variant="quaternary"
                    $size="small"
                    $leftSlot={
                      previewImage ? (
                        <Icon name="AIRephrase" color={colors.pink[5]} />
                      ) : (
                        <Icon name="AI" color={colors.pink[5]} />
                      )
                    }
                    $muted
                    $active
                    onClick={generateImage}
                  >
                    {previewImage ? 'Regenerate' : 'Generate image'}
                  </Button>
                )}
                {isConsumptionExceeded && (
                  <Button
                    $variant="quaternary"
                    $size="small"
                    $leftSlot={<Icon name="Mail" color={colors.pink[5]} />}
                    $muted
                    $active
                    as="a"
                    href="/?new_support_ticket=true"
                    target="_blank"
                    style={{ whiteSpace: 'nowrap', marginLeft: 'auto' }}
                  >
                    Contact support
                  </Button>
                )}
              </Styled.HorizontalButtonWrapper>
            </Styled.PromptFooter>
          </Styled.Container>
        </Panel>
      </div>
    </NodeViewWrapper>
  );
};

export default AiWriterView;
