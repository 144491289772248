import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

import { Switch } from '@/components/Form';
import LoadingBox from '@/components/LoadingBox';
import { EmptyCard, ItemHeader, ItemHeaders, Items, ItemsBody } from '@/components/ResourceList';
import { AlignType } from '@/components/ResourceList/types';
import { Typography } from '@/components/Typography';
import UpgradeIntent from '@/components/UpgradeIntent/TieredUpgradeIntent';
import useLocalStorage from '@/hooks/useLocalStorage';
import usePublicationSettings from '@/hooks/usePublications/usePublicationSettings';
import { type OrderBy, useTeamMembers } from '@/hooks/useTeamMembers';
import { Organization } from '@/interfaces/organization';
import { Button } from '@/ui/Button';
import { LoadMoreButton } from '@/ui/LoadMoreButton';
import { ResourceListFilters, useResourceListFilters } from '@/ui/ResourceListFilters';
import { ResourceListSearchText } from '@/ui/ResourceListSearchText';

import BodyContainer from '../../_components/BodyContainer';
import CardHeader from '../../_components/CardHeader';

import useFetchTeamCount from './_hooks/useFetchTeamCount';
import InviteModal from './invite/InviteModal';
import MemberListItem from './MemberListItem';
import NewMember from './new';

interface Props {
  organization: Organization;
  publicationId: string;
}

const List = ({ organization, publicationId }: Props) => {
  const [newInviteModalOpen, setNewInviteModalOpen] = useState<boolean>(false);
  const [newInvitePanelOpen, setNewInvitePanelOpen] = useState<boolean>(false);
  const { data: publicationSettings } = usePublicationSettings(publicationId);

  const [hideInvites, setHideInvites] = useLocalStorage(true, 'workspaceSettingsTeamInvitesVisibility');

  const { data: settings } = usePublicationSettings(publicationId);
  const canInviteMembers = !!settings?.max_team_members && (settings.max_team_members > 1);

  const { search, setSearch, direction, orderBy, handleClickOrderButton } = useResourceListFilters<string, string>(
    '',
    'user'
  );

  const { data: teamCountData, isSuccess } = useFetchTeamCount(organization.id);
  const { team_count: teamCount, team_max: teamMax } = teamCountData || {team_count: 0, team_max: 0};
  const canAddMembers = isSuccess && teamCount < teamMax;

  const {
    data: teamMembersData,
    isLoading,
    isError,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    refetch,
  } = useTeamMembers({ organizationId: organization.id, search, orderBy: orderBy as OrderBy, direction, hideInvites });
  const teamMembers = teamMembersData?.pages.flatMap((page) => page.team_members).filter(Boolean) || [];
  const noResults = false;
  const totalCount = teamMembersData?.pages[0]?.pagination?.total;
  const showingCount = teamMembers?.length || 0;

  const queryClient = useQueryClient();
  const invalidateTeamMembers = () => {
    queryClient.invalidateQueries([organization.id, 'team_members', search, orderBy]);
  };

  useEffect(() => {
    refetch();
  }, [orderBy, search, direction, refetch]);

  return (
    <>
      { canAddMembers ? (
        <>
          <InviteModal
            isOpen={newInviteModalOpen}
            onClose={() => setNewInviteModalOpen(false)}
            organization={organization}
            contributorEnabled={!!publicationSettings?.contributor_role}
          />
          <NewMember
            title="Invite New User"
            isOpen={newInvitePanelOpen}
            onClose={() => {
              setNewInvitePanelOpen(false);
              invalidateTeamMembers();
            }}
            organization={organization}
          />
        </>
      ) : (
        <UpgradeIntent
          isOpen={newInviteModalOpen || newInvitePanelOpen}
          onClose={() => {
            setNewInviteModalOpen(false);
            setNewInvitePanelOpen(false);
          }}
        />
      )}


      <BodyContainer>
        <CardHeader
          title="Workspace Team"
          description={
            <Typography token="font-normal/text/sm" colorWeight="500">
              View and manage your team members user roles, permissions, and access levels across your publications and within your workspace.{' '}
              <a href="https://youtu.be/CHOOLGiTUdg"
                target="_blank"
                rel="noreferrer"
                className="text-primary-600 underline"
              >
                Learn More
              </a>
            </Typography>
          }
        />
        <div className="flex flex-col w-full gap-y-2 sm:justify-end sm:flex-row sm:gap-x-2">
          <Switch
            prefixLabelText="Hide Invites"
            size="small"
            checked={hideInvites}
            onChange={(_, value) => setHideInvites(value)}
            name="workspace-teams-hide-invites-toggle"
            className="w-[200px]"
          />
          <ResourceListFilters search={search} setSearch={setSearch} searchPlaceholder="Search users..." />
          <Button size="xs" onClick={() => (
            publicationSettings?.user_invites_v2 ? setNewInviteModalOpen(true) : setNewInvitePanelOpen(true)
          )}>
            Invite New User
          </Button>
        </div>
        <div className="flex flex-col gap-y-2">
          <ResourceListSearchText showingCount={showingCount} totalCount={totalCount || 0} />
          {noResults ? (
            <EmptyCard title="No Team Members" />
          ) : (
            <LoadingBox isLoading={isLoading} isError={isError}>
              <div className="overflow-x-auto">
                <Items>
                  <ItemHeaders>
                    <ItemHeader
                      align={AlignType.LEFT}
                      onClick={handleClickOrderButton}
                      orderBy="user"
                      isSorting={orderBy === 'user'}
                      currentDirection={direction}
                    >
                      User
                    </ItemHeader>
                    <ItemHeader
                      align={AlignType.LEFT}
                      onClick={handleClickOrderButton}
                      orderBy="permission"
                      isSorting={orderBy === 'permission'}
                      currentDirection={direction}
                    >
                      Permission
                    </ItemHeader>
                    <ItemHeader
                      align={AlignType.LEFT}
                      onClick={handleClickOrderButton}
                      orderBy="role"
                      isSorting={orderBy === 'role'}
                      currentDirection={direction}
                    >
                      Role
                    </ItemHeader>
                    <ItemHeader
                      align={AlignType.LEFT}
                      onClick={handleClickOrderButton}
                      orderBy="publication"
                      isSorting={orderBy === 'publication'}
                      currentDirection={direction}
                    >
                      Publication
                    </ItemHeader>
                    <ItemHeader align={AlignType.LEFT}>&nbsp;</ItemHeader>
                  </ItemHeaders>
                  <ItemsBody>
                    {teamMembers &&
                      teamMembers.map((member) => (
                        <MemberListItem
                          onCancelInvite={invalidateTeamMembers}
                          onResendInvite={invalidateTeamMembers}
                          onDeleteInvite={invalidateTeamMembers}
                          onUpdate={invalidateTeamMembers}
                          key={member.id}
                          member={member}
                          canChangeInvite={canInviteMembers || false}
                        />
                      ))}
                  </ItemsBody>
                </Items>
              </div>
            </LoadingBox>
          )}
          {hasNextPage && (
            <div className="flex justify-start mt-8 w-fit">
              <LoadMoreButton fetchNextPage={fetchNextPage} isFetchingNextPage={isFetchingNextPage} />
            </div>
          )}
        </div>
      </BodyContainer>
    </>
  );
};

export default List;
