import { createContext, Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useSettings } from '@/context/settings-context';
import { useTiers } from '@/hooks/useTiers';
import { Tier, TierStatus } from '@/interfaces/tier';

export type ErrorObject = {
  [key: string]: string | null;
};

export type ContextType = {
  tierName: string;
  setTierName: Dispatch<SetStateAction<string>>;
  previewText?: string;
  setPreviewText: Dispatch<SetStateAction<string | undefined>>;
  currency: string;
  setCurrency: Dispatch<SetStateAction<string>>;
  monthlyEnabled: boolean;
  setMonthlyEnabled: Dispatch<SetStateAction<boolean>>;
  monthlyIntervalDisplay: string;
  setMonthlyIntervalDisplay: Dispatch<SetStateAction<string>>;
  monthlyCents: number;
  setMonthlyCents: Dispatch<SetStateAction<number>>;
  monthlyCta: string;
  setMonthlyCta: Dispatch<SetStateAction<string>>;
  monthlyBenefits: string[];
  setMonthlyBenefits: Dispatch<SetStateAction<string[]>>;
  monthlyPriceId?: string;
  annualEnabled: boolean;
  setAnnualEnabled: Dispatch<SetStateAction<boolean>>;
  annualIntervalDisplay: string;
  setAnnualIntervalDisplay: Dispatch<SetStateAction<string>>;
  annualCents: number;
  setAnnualCents: Dispatch<SetStateAction<number>>;
  annualCta: string;
  setAnnualCta: Dispatch<SetStateAction<string>>;
  annualBenefits: string[];
  setAnnualBenefits: Dispatch<SetStateAction<string[]>>;
  annualPriceId?: string;
  oneTimeEnabled: boolean;
  setOneTimeEnabled: Dispatch<SetStateAction<boolean>>;
  oneTimeIntervalDisplay: string;
  setOneTimeIntervalDisplay: Dispatch<SetStateAction<string>>;
  oneTimeCents: number;
  setOneTimeCents: Dispatch<SetStateAction<number>>;
  oneTimeCta: string;
  setOneTimeCta: Dispatch<SetStateAction<string>>;
  oneTimeBenefits: string[];
  setOneTimeBenefits: Dispatch<SetStateAction<string[]>>;
  oneTimePriceId?: string;
  donationEnabled: boolean;
  setDonationEnabled: Dispatch<SetStateAction<boolean>>;
  donationIntervalDisplay: string;
  setDonationIntervalDisplay: Dispatch<SetStateAction<string>>;
  donationCta: string;
  setDonationCta: Dispatch<SetStateAction<string>>;
  donationBenefits: string[];
  setDonationBenefits: Dispatch<SetStateAction<string[]>>;
  donationPriceId?: string;
  redirectUrl: string;
  setRedirectUrl: Dispatch<SetStateAction<string>>;
  canAddActiveTier: boolean;
  isDefaultTier: boolean;
  isActiveTier: boolean;
  isDirty: boolean;
  isSaving: boolean;
  setIsSaving: (value: boolean) => void;
  errors: ErrorObject;
  setErrors: Dispatch<SetStateAction<ErrorObject>>;
};

const TierConfigurationContext = createContext<ContextType | undefined>(undefined);
TierConfigurationContext.displayName = 'TierConfigurationContext';

const TierConfigurationProvider = ({ tier, children }: { tier?: Tier; children: React.ReactNode }) => {
  const monthlyPrice = useMemo(() => tier?.prices.find((p) => p.interval === 'month'), [tier]);
  const annualPrice = useMemo(() => tier?.prices.find((p) => p.interval === 'year'), [tier]);
  const oneTimePrice = useMemo(() => tier?.prices.find((p) => p.interval === 'one_time'), [tier]);
  const donationPrice = useMemo(() => tier?.prices.find((p) => p.interval === 'donation'), [tier]);

  const { settings } = useSettings();
  const [currentPublicationId] = useCurrentPublicationState();
  const { data: tiers } = useTiers(currentPublicationId);

  const activeTiersCount = useMemo(() => tiers?.filter((t) => t.status === TierStatus.ACTIVE).length || 0, [tiers]);
  const canAddActiveTier = settings ? activeTiersCount < settings.max_premium_tiers : false;
  const isDefaultTier = tier?.is_default || false;
  const isActiveTier = monthlyPrice?.enabled || annualPrice?.enabled || false;

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [tierName, setTierName] = useState<string>(tier?.name || '');
  const [previewText, setPreviewText] = useState<string | undefined>(tier?.description);
  const [currency, setCurrency] = useState<string>(monthlyPrice?.currency || 'usd');
  const [monthlyEnabled, setMonthlyEnabled] = useState<boolean>(!!monthlyPrice?.enabled);
  const [monthlyIntervalDisplay, setMonthlyIntervalDisplay] = useState<string>(monthlyPrice?.interval_display || '');
  const [monthlyCents, setMonthlyCents] = useState<number>(monthlyPrice?.amount_cents || 0);
  const [monthlyCta, setMonthlyCta] = useState<string>(monthlyPrice?.cta || '');
  const [monthlyBenefits, setMonthlyBenefits] = useState<string[]>(monthlyPrice?.features || []);
  const [annualEnabled, setAnnualEnabled] = useState<boolean>(!!annualPrice?.enabled);
  const [annualIntervalDisplay, setAnnualIntervalDisplay] = useState<string>(annualPrice?.interval_display || '');
  const [annualCents, setAnnualCents] = useState<number>(annualPrice?.amount_cents || 0);
  const [annualCta, setAnnualCta] = useState<string>(annualPrice?.cta || '');
  const [annualBenefits, setAnnualBenefits] = useState<string[]>(annualPrice?.features || []);
  const [oneTimeEnabled, setOneTimeEnabled] = useState<boolean>(!!oneTimePrice?.enabled);
  const [oneTimeIntervalDisplay, setOneTimeIntervalDisplay] = useState<string>(oneTimePrice?.interval_display || '');
  const [oneTimeCents, setOneTimeCents] = useState<number>(oneTimePrice?.amount_cents || 0);
  const [oneTimeCta, setOneTimeCta] = useState<string>(oneTimePrice?.cta || '');
  const [oneTimeBenefits, setOneTimeBenefits] = useState<string[]>(oneTimePrice?.features || []);
  const [donationEnabled, setDonationEnabled] = useState<boolean>(!!donationPrice?.enabled);
  const [donationIntervalDisplay, setDonationIntervalDisplay] = useState<string>(donationPrice?.interval_display || '');
  const [donationCta, setDonationCta] = useState<string>(donationPrice?.cta || '');
  const [donationBenefits, setDonationBenefits] = useState<string[]>(donationPrice?.features || []);
  const [redirectUrl, setRedirectUrl] = useState<string>(tier?.redirect_url || '');
  const [errors, setErrors] = useState<ErrorObject>({});

  const isDirty = useMemo(() => {
    if (isSaving) {
      return false;
    }

    return (
      tierName !== tier?.name ||
      previewText !== tier?.description ||
      currency !== monthlyPrice?.currency ||
      monthlyEnabled !== !!monthlyPrice?.enabled ||
      monthlyIntervalDisplay !== monthlyPrice?.interval_display ||
      monthlyCents !== monthlyPrice?.amount_cents ||
      monthlyCta !== monthlyPrice?.cta ||
      monthlyBenefits !== monthlyPrice?.features ||
      annualEnabled !== !!annualPrice?.enabled ||
      annualIntervalDisplay !== annualPrice?.interval_display ||
      annualCents !== annualPrice?.amount_cents ||
      annualCta !== annualPrice?.cta ||
      annualBenefits !== annualPrice?.features ||
      oneTimeEnabled !== !!oneTimePrice?.enabled ||
      oneTimeIntervalDisplay !== oneTimePrice?.interval_display ||
      oneTimeCents !== oneTimePrice?.amount_cents ||
      oneTimeCta !== oneTimePrice?.cta ||
      oneTimeBenefits !== oneTimePrice?.features ||
      donationEnabled !== !!donationPrice?.enabled ||
      donationIntervalDisplay !== donationPrice?.interval_display ||
      donationCta !== donationPrice?.cta ||
      donationBenefits !== donationPrice?.features ||
      redirectUrl !== tier?.redirect_url
    );
  }, [
    annualBenefits,
    annualCents,
    annualCta,
    annualEnabled,
    annualIntervalDisplay,
    annualPrice?.amount_cents,
    annualPrice?.cta,
    annualPrice?.enabled,
    annualPrice?.features,
    annualPrice?.interval_display,
    currency,
    donationBenefits,
    donationCta,
    donationEnabled,
    donationIntervalDisplay,
    donationPrice?.cta,
    donationPrice?.enabled,
    donationPrice?.features,
    donationPrice?.interval_display,
    isSaving,
    monthlyBenefits,
    monthlyCents,
    monthlyCta,
    monthlyEnabled,
    monthlyIntervalDisplay,
    monthlyPrice?.amount_cents,
    monthlyPrice?.cta,
    monthlyPrice?.currency,
    monthlyPrice?.enabled,
    monthlyPrice?.features,
    monthlyPrice?.interval_display,
    oneTimeBenefits,
    oneTimeCents,
    oneTimeCta,
    oneTimeEnabled,
    oneTimeIntervalDisplay,
    oneTimePrice?.amount_cents,
    oneTimePrice?.cta,
    oneTimePrice?.enabled,
    oneTimePrice?.features,
    oneTimePrice?.interval_display,
    previewText,
    redirectUrl,
    tier?.description,
    tier?.name,
    tier?.redirect_url,
    tierName,
  ]);

  const context: ContextType = useMemo(
    () => ({
      tierName,
      setTierName,
      previewText,
      setPreviewText,
      currency,
      setCurrency,
      monthlyEnabled,
      setMonthlyEnabled,
      monthlyIntervalDisplay,
      setMonthlyIntervalDisplay,
      monthlyCents,
      setMonthlyCents,
      monthlyCta,
      setMonthlyCta,
      monthlyBenefits,
      setMonthlyBenefits,
      annualEnabled,
      setAnnualEnabled,
      annualIntervalDisplay,
      setAnnualIntervalDisplay,
      annualCents,
      setAnnualCents,
      annualCta,
      setAnnualCta,
      annualBenefits,
      setAnnualBenefits,
      oneTimeEnabled,
      setOneTimeEnabled,
      oneTimeIntervalDisplay,
      setOneTimeIntervalDisplay,
      oneTimeCents,
      setOneTimeCents,
      oneTimeCta,
      setOneTimeCta,
      oneTimeBenefits,
      setOneTimeBenefits,
      donationEnabled,
      setDonationEnabled,
      donationIntervalDisplay,
      setDonationIntervalDisplay,
      donationCta,
      setDonationCta,
      donationBenefits,
      setDonationBenefits,
      redirectUrl,
      setRedirectUrl,
      canAddActiveTier,
      isDefaultTier,
      isActiveTier,
      monthlyPriceId: monthlyPrice?.id,
      annualPriceId: annualPrice?.id,
      oneTimePriceId: oneTimePrice?.id,
      isDirty,
      isSaving,
      setIsSaving,
      errors,
      setErrors,
    }),
    [
      tierName,
      previewText,
      currency,
      monthlyEnabled,
      monthlyIntervalDisplay,
      monthlyCents,
      monthlyCta,
      monthlyBenefits,
      annualEnabled,
      annualIntervalDisplay,
      annualCents,
      annualCta,
      annualBenefits,
      oneTimeEnabled,
      oneTimeIntervalDisplay,
      oneTimeCents,
      oneTimeCta,
      oneTimeBenefits,
      donationEnabled,
      donationIntervalDisplay,
      donationCta,
      donationBenefits,
      redirectUrl,
      canAddActiveTier,
      isDefaultTier,
      isActiveTier,
      monthlyPrice?.id,
      annualPrice?.id,
      oneTimePrice?.id,
      isDirty,
      isSaving,
      setIsSaving,
      errors,
      setErrors,
    ]
  );

  return <TierConfigurationContext.Provider value={context}>{children}</TierConfigurationContext.Provider>;
};

const useTierConfigurationContext = () => {
  const context = useContext(TierConfigurationContext);
  if (context === undefined) {
    throw new Error(`useTierConfigurationContext must be used within a TierConfigurationProvider`);
  }
  return context;
};

export { TierConfigurationContext, TierConfigurationProvider, useTierConfigurationContext };
