import { useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import Fuse from 'fuse.js';

import { Label, TypeaheadSelect } from '@/components/Form';
import { Dropdown } from '@/ui/Dropdown';
import { weekdays as weekdayOptions } from '@/values/weekdays';

const timeOfDayHoursOptions = Array.from(Array(24).keys()).map((hour) => {
  const hourString = hour.toString().padStart(2, '0');

  return { label: `${hourString}`, value: hourString };
});

const timeOfDayMinutesOptions = Array.from(Array(60).keys()).map((minute) => {
  const minuteString = minute.toString().padStart(2, '0');

  return { label: `${minuteString}`, value: minuteString };
});

const initialTimeZoneType = (timeZone: string, publicationTimeZone: string) => {
  if (timeZone === publicationTimeZone) {
    return 'publication';
  }

  if (timeZone === '') {
    return 'subscriber';
  }

  return 'specific';
};

interface Props {
  publicationTimeZone: string;
  timezones: string[];
  weekdays: string[];
  onWeekDaysChange: (weekdays: string[]) => void;
  timeOfDay: string;
  onTimeOfDayChange: (value: string) => void;
  timeZone: string;
  onTimeZoneChange: (value: string) => void;
  onChange?: (value: boolean) => void;
}

const WaitUntilParamsForm = ({
  publicationTimeZone,
  timezones,
  weekdays,
  onWeekDaysChange,
  timeOfDay,
  onTimeOfDayChange,
  timeZone,
  onTimeZoneChange,
  onChange,
}: Props) => {
  const [weekdaysType, setWeekdaysType] = useState<'any' | 'specific'>(weekdays.length > 0 ? 'specific' : 'any');
  const [timeZoneType, setTimeZoneType] = useState<'publication' | 'subscriber' | 'specific'>(
    initialTimeZoneType(timeZone, publicationTimeZone)
  );
  const [timeOfDayHours, setTimeOfDayHours] = useState(Math.floor(parseInt(timeOfDay, 10) / 60));
  const [timeOfDayMinutes, setTimeOfDayMinutes] = useState(parseInt(timeOfDay, 10) % 60);

  const fuse = useMemo(() => new Fuse(timezones, { threshold: 0.4 }), [timezones]);

  const handleWeekdaysTypeChange = (name: string, value: string) => {
    onWeekDaysChange([]);
    setWeekdaysType(value as 'any' | 'specific');
  };

  const handleWeekdayClick = (value: string) => {
    const newWeekdays = [...weekdays];
    const valueIndex = weekdays.indexOf(value);

    if (valueIndex > -1) {
      newWeekdays.splice(valueIndex, 1);
    } else {
      newWeekdays.push(value);
    }

    onWeekDaysChange(newWeekdays.sort());
  };

  const renderWeekdays = () => (
    <div className="flex shadow-sm w-fit rounded-lg ml-px gap-x-1 p-0.5 border border-gray-300">
      {weekdayOptions.map((option) => {
        const active = weekdays.includes(option.value);

        return (
          <button
            type="button"
            key={option.value}
            className={cx(
              'w-9 text-center box-content text-sm px-2 py-1.5 rounded-md text-gray-600 hover:bg-gray-200 focus:z-10',
              active && 'bg-gray-100 text-gray-800 font-semibold'
            )}
            onClick={() => {
              handleWeekdayClick(option.value);
            }}
          >
            {option.label}
          </button>
        );
      })}
    </div>
  );

  const handleTimeOfDayChange = (hours: number, minutes: number) => {
    setTimeOfDayHours(hours);
    setTimeOfDayMinutes(minutes);
    onTimeOfDayChange((hours * 60 + minutes).toString());
  };

  const handleTimezoneTypeChange = (name: string, value: string) => {
    setTimeZoneType(value as 'publication' | 'subscriber' | 'specific');
    let newTimeZone = '';

    if (value === 'publication') {
      newTimeZone = publicationTimeZone || 'Eastern Time (US & Canada)';
    }

    return onTimeZoneChange(newTimeZone);
  };

  const fetchTimezones = async (inputValue?: string) => {
    if (!inputValue || inputValue === '') {
      return timezones.map((timezone) => ({ label: timezone, value: timezone }));
    }

    return fuse.search(inputValue || '').map((option) => ({
      label: option.item,
      value: option.item,
    }));
  };

  useEffect(() => {
    let newCanSave = true;

    if (weekdaysType === 'specific' && weekdays.length === 0) {
      newCanSave = false;
    }

    if (timeZoneType === 'specific' && timeZone === '') {
      newCanSave = false;
    }

    onChange?.(newCanSave);
  }, [weekdaysType, weekdays, timeZoneType, timeZone, onChange]);

  useEffect(() => {
    fuse.setCollection(timezones);
  }, [fuse, timezones]);

  return (
    <div className="space-y-6">
      <div className="space-y-2">
        <Label value="Day of week" htmlFor="weekdaysType" />
        <Dropdown
          name="weekdaysType"
          value={weekdaysType}
          onSelect={handleWeekdaysTypeChange}
          options={[
            { label: 'Any day of the week', value: 'any' },
            { label: 'Specific day(s)', value: 'specific' },
          ]}
        />

        {weekdaysType === 'specific' && renderWeekdays()}
      </div>

      <div className="flex flex-col space-y-2">
        <Label value="Time of day" htmlFor="timeOfDayHours" />
        <div className="flex gap-x-2">
          <Dropdown
            name="timeOfDayHours"
            value={timeOfDayHours.toString().padStart(2, '0')}
            onSelect={(name, value) => handleTimeOfDayChange(parseInt(value, 10), timeOfDayMinutes)}
            options={timeOfDayHoursOptions}
            className="w-28"
          />

          <Dropdown
            name="timeOfDayMinutes"
            labelText=""
            value={timeOfDayMinutes.toString().padStart(2, '0')}
            onSelect={(name, value) => handleTimeOfDayChange(timeOfDayHours, parseInt(value, 10))}
            options={timeOfDayMinutesOptions}
            className="w-28"
          />
        </div>
      </div>

      <div className="space-y-2">
        <Label value="Timezone" htmlFor="timeZoneType" />
        <Dropdown
          name="timeZoneType"
          value={timeZoneType}
          onSelect={handleTimezoneTypeChange}
          options={[
            { label: `Default timezone (${publicationTimeZone})`, value: 'publication' },
            { label: 'Timezone of the subscriber', value: 'subscriber' },
            { label: 'Specific timezone', value: 'specific' },
          ]}
        />

        {timeZoneType === 'specific' && (
          <TypeaheadSelect
            name="timeZone"
            placeholderText="Select a timezone"
            value={timeZone}
            onSelect={(name, value) => onTimeZoneChange(value)}
            onClear={() => onTimeZoneChange('')}
            loadOptions={fetchTimezones}
          />
        )}
      </div>
    </div>
  );
};

export default WaitUntilParamsForm;
