import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { CustomDomain } from '@/interfaces/custom_domain';

import api from '../../services/swarm';

import { UpdateCustomDomainErrorResponse } from './types';

interface UpdateCustomDomainSuccessResponse extends CustomDomain {}

interface UpdateCustomDomainPayload {
  id: string;
  formData: FormData;
}

interface Props {
  onSuccess?: () => void;
}

const useUpdateCustomDomain = ({ onSuccess }: Props) => {
  const queryClient = useQueryClient();

  return useMutation<UpdateCustomDomainSuccessResponse, UpdateCustomDomainErrorResponse, UpdateCustomDomainPayload>(
    ({ id, formData }: { id: string; formData: FormData }) =>
      api.patch(`/custom_domains/${id}`, formData).then((res) => res.data),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(['custom_domains', data.id]);
        onSuccess?.();
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message || 'Something went wrong, please try again.');
      },
    }
  );
};

export default useUpdateCustomDomain;
