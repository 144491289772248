import { GearSix, House } from '@phosphor-icons/react';

import { WebsiteProvider } from '@/context/website-context';
import useSitePackages from '@/hooks/useSitePackages/useSitePackages';

import { ContentTree } from '../_components/ContentTree';
import { ContentTreeProvider } from '../_components/ContentTree/context';
import { Layout } from '../_components/Layout';
import { Main } from '../_components/Main';
import { Breadcrumbs } from '../_components/Main/Breadcrumbs';
import { NavMenuItem } from '../_components/SideNav/NavMenuItem';
import { NavSection } from '../_components/SideNav/NavSection';
import { NavSectionTitle } from '../_components/SideNav/NavSectionTitle';
import { Text } from '../_components/UI/Text';
import useSetPages from '../_hooks/useSetPages';

import { DisplayCard } from './_components/DisplayCard';
import { TopBar } from './_components/TopBar';

const WebsiteProjectsPage = () => {
  const { pageRoutes, defaultRoutes } = useSetPages();
  const { data: packages, isLoading } = useSitePackages();
  const projects = packages?.pages.flatMap((pkg) => pkg.site_packages) || [];
  const hasNoProjects = projects.length === 0 && !isLoading;

  return (
    <Layout
      logoDropdownProps={{
        actionText: 'Back to Main App',
        backPath: '/',
      }}
      sidenavChildren={
        <>
          <NavSection>
            <NavSectionTitle title="Main" />
            <NavMenuItem Icon={House} title="Home" to="/website_builder_v2" />
            <NavMenuItem Icon={GearSix} title="Site Settings" to="/website_builder_v2/settings" />
          </NavSection>
          <NavSection>
            <NavSectionTitle title="Pages" />
            {pageRoutes && (
              <ContentTreeProvider isAllowDnD isShowOptions isShowAddPage pageLinkPrefix="/website_builder_v2/page">
                <ContentTree route={pageRoutes} parentPath={[]} slug="" />
              </ContentTreeProvider>
            )}
          </NavSection>
          <NavSection>
            <NavSectionTitle title="Default Pages" />
            {defaultRoutes && (
              <ContentTreeProvider
                dndDisabledErrorMessage="You can't re-arrange default pages"
                isAllowDnD={false}
                isShowOptions
                isShowAddPage
                pageLinkPrefix="/website_builder_v2/page"
              >
                <ContentTree showHome={false} route={defaultRoutes} parentPath={[]} slug="" />
              </ContentTreeProvider>
            )}
          </NavSection>
        </>
      }
    >
      <Main>
        <Breadcrumbs />
        <TopBar />
        {hasNoProjects ? (
          <div className="flex items-center justify-center h-full py-20">
            <Text size="xl" weight="semibold" variant="secondary" as="h4">
              No projects found
            </Text>
          </div>
        ) : (
          <div className="grid grid-cols-[repeat(auto-fill,_minmax(250px,_1fr))] gap-6">
            {projects?.map((project) => {
              return (
                <DisplayCard
                  key={project.id}
                  siteTemplateId={project.id}
                  redirectTo={`/website_builder_v2/projects/${project.id}`}
                  name={project.name}
                />
              );
            })}
          </div>
        )}
      </Main>
    </Layout>
  );
};

export default () => (
  <WebsiteProvider>
    <WebsiteProjectsPage />
  </WebsiteProvider>
);
