import { useInfiniteQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { Pagination } from '@/interfaces/general';
import { SitePackage } from '@/interfaces/site_package';

import api from '../../services/swarm';

interface ApiResponse {
  site_packages: SitePackage[];
  pagination: Pagination;
}

export default function useSitePackages() {
  const [currentPublicationId] = useCurrentPublicationState();

  return useInfiniteQuery<ApiResponse>(
    ['publications', currentPublicationId, 'site_packages'],
    ({ pageParam = 1 }) =>
      api
        .get(`/site_packages`, {
          params: {
            publication_id: currentPublicationId,
            page: pageParam,
          },
        })
        .then((res) => res.data),
    {
      getNextPageParam: (lastPage) => {
        const { page, total_pages: totalPages } = lastPage?.pagination || {};

        if (page < totalPages) return page + 1;

        return false;
      },
    }
  );
}
