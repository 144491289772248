import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

import { Dropdown } from '@/ui/Dropdown';

import { Input, Switch } from '../../../../components/Form';
import ColorField from '../../../../components/Form/ColorField';
import { useWebTemplate } from '../../../../hooks';
import { useUpdateLandingPage } from '../../../../hooks/useLandingPages';
import { FONT_SIZE_OPTIONS } from '../../../../interfaces/font';
import { LandingPage } from '../../../../interfaces/landing_page';
import FeaturedPosts from '../../../Settings/Pages/Website/Advanced/FeaturedPosts';
import ThemeEditorDropdown from '../../../ThemeEditor/components/ThemeEditorDropdown';
import ThemeFormDrawer from '../../../ThemeEditor/components/ThemeFormDrawer';
import { Divider, FormContainer, FormInput, FormSection } from '../../../ThemeEditor/helpers';
import { usePageContext } from '../../components/PageContext';

const FeaturedPostsSection = () => {
  const queryClient = useQueryClient();
  const webTemplateRequest = useWebTemplate();
  const {
    pageData: landingPage,
    colorPalette,
    setIsSaving,
    handleSaved: onSave,
    fontOptions,
  } = usePageContext<LandingPage>();

  // Featured Posts States
  const [sectionEnabled, setSectionEnabled] = useState(landingPage?.featured_posts_section?.enabled || false);
  const [bgColor, setBgColor] = useState(landingPage?.featured_posts_section?.bg_color || '#000000');
  const [overlineColor, setOverlineColor] = useState(landingPage?.featured_posts_section?.overline_color || '#000000');
  const [overline, setOverline] = useState(landingPage?.featured_posts_section?.overline || '');
  const [overlineFontFamily, setOverlineFontFamily] = useState(
    landingPage?.featured_posts_section?.overline_font_family || ''
  );
  const [overlineFontSize, setOverlineFontSize] = useState(
    landingPage?.featured_posts_section?.overline_font_size || 'md'
  );
  const [headlineColor, setHeadlineColor] = useState(landingPage?.featured_posts_section?.headline_color || '#000000');
  const [headline, setHeadline] = useState(landingPage?.featured_posts_section?.headline || '');
  const [headlineFontFamily, setHeadlineFontFamily] = useState(
    landingPage?.featured_posts_section?.headline_font_family || ''
  );
  const [headlineFontSize, setHeadlineFontSize] = useState(landingPage?.main_section?.headline_font_size || '5xl');
  const [textAlign, setTextAlign] = useState(landingPage?.featured_posts_section?.text_align || 'left');
  const [readMoreCTA, setReadMoreCTA] = useState(landingPage?.featured_posts_section?.read_more_cta || '');
  const [readMoreCTAFontFamily, setReadMoreCTAFontFamily] = useState(
    landingPage?.featured_posts_section?.read_more_cta_font_family || ''
  );
  const [readMoreCTAFontSize, setReadMoreCTAFontSize] = useState(
    landingPage?.featured_posts_section?.read_more_cta_font_size || 'xl'
  );
  const [enableAllPostImages, setEnableAllPostImages] = useState(
    landingPage?.featured_posts_section?.enable_all_post_images || false
  );

  useEffect(() => {
    setSectionEnabled(landingPage?.featured_posts_section?.enabled || false);
    setBgColor(landingPage?.featured_posts_section?.bg_color || '#000000');
    setOverlineColor(landingPage?.featured_posts_section?.overline_color || '#000000');
    setOverline(landingPage?.featured_posts_section?.overline || '');
    setOverlineFontFamily(landingPage?.featured_posts_section?.overline_font_family || '');
    setOverlineFontSize(landingPage?.featured_posts_section?.overline_font_size || 'md');
    setHeadlineColor(landingPage?.featured_posts_section?.headline_color || '#000000');
    setHeadline(landingPage?.featured_posts_section?.headline || '');
    setHeadlineFontFamily(landingPage?.featured_posts_section?.headline_font_family || '');
    setHeadlineFontSize(landingPage?.featured_posts_section?.headline_font_size || '5xl');
    setTextAlign(landingPage?.featured_posts_section?.text_align || 'left');
    setReadMoreCTA(landingPage?.featured_posts_section?.read_more_cta || '');
    setReadMoreCTAFontFamily(landingPage?.featured_posts_section?.read_more_cta_font_family || '');
    setReadMoreCTAFontSize(landingPage?.featured_posts_section?.read_more_cta_font_size || 'xl');
    setEnableAllPostImages(landingPage?.featured_posts_section?.enable_all_post_images || false);
  }, [landingPage]);

  const updateLandingPage = useUpdateLandingPage({
    pageId: landingPage?.id || '',
    onSuccess: () => {
      queryClient.invalidateQueries(['landing_page', '/subscribe', 'draft']);
      onSave();
    },
  });

  const handleSaveFeaturedPostsSection = (payload: any) => {
    setIsSaving(true);
    updateLandingPage.mutate({ landing_page: { featured_posts_section: JSON.stringify(payload) } });
  };

  return (
    <ThemeFormDrawer active={false} title="Featured Posts Section" onClickHandler={() => {}}>
      <FormContainer>
        <FormSection title="General">
          <FormInput title="Show Section">
            <Switch
              variant="primary"
              name="enabled"
              checked={sectionEnabled}
              onChange={(_name: string, updatedValue: boolean) => {
                setSectionEnabled(updatedValue);
                handleSaveFeaturedPostsSection({
                  ...landingPage?.featured_posts_section,
                  enabled: updatedValue,
                });
              }}
            />
          </FormInput>
          <FormInput title="Background Color">
            <ColorField
              placement="bottom"
              color={bgColor}
              colorPalette={colorPalette}
              onColorChange={(color: any) => setBgColor(color?.hexString)}
              onBlur={() => {
                handleSaveFeaturedPostsSection({
                  ...landingPage?.featured_posts_section,
                  bg_color: bgColor,
                });
              }}
            />
          </FormInput>
          <FormInput title="Featured Posts">
            <div className="pt-2">
              <FeaturedPosts
                webTemplateRequest={webTemplateRequest}
                autoSave
                onSuccess={() => {
                  queryClient.invalidateQueries(['landing_page', landingPage?.id, 'featured_posts']);
                  onSave();
                }}
              />
            </div>
          </FormInput>
          <FormInput title="Enable All Post Images">
            <Switch
              variant="primary"
              name="enabled"
              checked={enableAllPostImages}
              onChange={(_name: string, updatedValue: boolean) => {
                setEnableAllPostImages(updatedValue);
                handleSaveFeaturedPostsSection({
                  ...landingPage?.featured_posts_section,
                  enable_all_post_images: updatedValue,
                });
              }}
            />
            <p className="mt-2 text-xs text-gray-500">
              By default we only show the image for the first post. By toggling this option on we will show the images
              for all featured posts.
            </p>
          </FormInput>
        </FormSection>
        <Divider />
        <FormSection title="Alignment">
          <Dropdown
            name="text_align"
            value={textAlign}
            onSelect={(_name: string, value: string) => {
              setTextAlign(value);
              handleSaveFeaturedPostsSection({
                ...landingPage?.featured_posts_section,
                text_align: value,
              });
            }}
            options={[
              {
                label: 'Left',
                value: 'left',
              },
              {
                label: 'Center',
                value: 'center',
              },
              {
                label: 'Right',
                value: 'right',
              },
            ]}
          />
        </FormSection>
        <Divider />
        <FormSection title="Overline">
          <FormInput title="Color">
            <ColorField
              placement="bottom"
              color={overlineColor}
              colorPalette={colorPalette}
              onColorChange={(color: any) => setOverlineColor(color?.hexString)}
              onBlur={() => {
                handleSaveFeaturedPostsSection({
                  ...landingPage?.featured_posts_section,
                  overline_color: overlineColor,
                });
              }}
            />
          </FormInput>
          <FormInput title="Text">
            <Input
              name="overline"
              value={overline}
              maxLength={150}
              onChange={(e) => setOverline(e.target.value)}
              onBlur={() => {
                handleSaveFeaturedPostsSection({
                  ...landingPage?.featured_posts_section,
                  overline,
                });
              }}
            />
          </FormInput>
          <FormInput title="Font family">
            <ThemeEditorDropdown
              currentValue={overlineFontFamily}
              onUpdate={(val: string) =>
                handleSaveFeaturedPostsSection({
                  ...landingPage?.featured_posts_section,
                  overline_font_family: val,
                })
              }
              options={fontOptions}
            />
          </FormInput>
          <FormInput title="Font size">
            <ThemeEditorDropdown
              currentValue={overlineFontSize}
              onUpdate={(val: string) =>
                handleSaveFeaturedPostsSection({
                  ...landingPage?.featured_posts_section,
                  overline_font_size: val,
                })
              }
              options={FONT_SIZE_OPTIONS}
            />
          </FormInput>
        </FormSection>
        <Divider />
        <FormSection title="Headline">
          <FormInput title="Color">
            <ColorField
              placement="bottom"
              color={headlineColor}
              colorPalette={colorPalette}
              onColorChange={(color: any) => setHeadlineColor(color?.hexString)}
              onBlur={() => {
                handleSaveFeaturedPostsSection({
                  ...landingPage?.featured_posts_section,
                  headline_color: headlineColor,
                });
              }}
            />
          </FormInput>
          <FormInput title="Text">
            <Input
              name="headline"
              value={headline}
              maxLength={150}
              onChange={(e) => setHeadline(e.target.value)}
              onBlur={() => {
                handleSaveFeaturedPostsSection({
                  ...landingPage?.featured_posts_section,
                  headline,
                });
              }}
            />
          </FormInput>
          <FormInput title="Font family">
            <ThemeEditorDropdown
              currentValue={headlineFontFamily}
              onUpdate={(val: string) =>
                handleSaveFeaturedPostsSection({
                  ...landingPage?.featured_posts_section,
                  headline_font_family: val,
                })
              }
              options={fontOptions}
            />
          </FormInput>
          <FormInput title="Font size">
            <ThemeEditorDropdown
              currentValue={headlineFontSize}
              onUpdate={(val: string) =>
                handleSaveFeaturedPostsSection({
                  ...landingPage?.featured_posts_section,
                  headline_font_size: val,
                })
              }
              options={FONT_SIZE_OPTIONS}
            />
          </FormInput>
        </FormSection>
        <Divider />
        <FormSection title="Redirect Link">
          <FormInput title="Text">
            <Input
              name="read_more_cta"
              value={readMoreCTA}
              onChange={(e) => setReadMoreCTA(e.target.value)}
              onBlur={() => {
                handleSaveFeaturedPostsSection({
                  ...landingPage?.featured_posts_section,
                  read_more_cta: readMoreCTA,
                });
              }}
            />
          </FormInput>
          <FormInput title="Font family">
            <ThemeEditorDropdown
              currentValue={readMoreCTAFontFamily}
              onUpdate={(val: string) =>
                handleSaveFeaturedPostsSection({
                  ...landingPage?.featured_posts_section,
                  read_more_cta_font_family: val,
                })
              }
              options={fontOptions}
            />
          </FormInput>
          <FormInput title="Font size">
            <ThemeEditorDropdown
              currentValue={readMoreCTAFontSize}
              onUpdate={(val: string) =>
                handleSaveFeaturedPostsSection({
                  ...landingPage?.featured_posts_section,
                  read_more_cta_font_size: val,
                })
              }
              options={FONT_SIZE_OPTIONS}
            />
          </FormInput>
        </FormSection>
      </FormContainer>
    </ThemeFormDrawer>
  );
};

export default FeaturedPostsSection;
