import { useCallback } from 'react';
import { Minus, Plus } from '@phosphor-icons/react';

import { Text } from '@/routes/website/_components/UI/Text';
import { cn } from '@/utils/cn';

import { SimpleInputWrapper } from '../../../UI/Input';
import { AttributeSettingProps } from '../types';

type GridColumnSettingProps = AttributeSettingProps & {
  title: string;
};

const parseColumns = (columns: string) => {
  try {
    return parseInt(columns, 10);
  } catch (error) {
    return 3;
  }
};

export function GridColumnSetting({ editor, activeNodeResult, title }: GridColumnSettingProps) {
  const { activeNodeAttributes, activeNodePos } = activeNodeResult;
  const columns = activeNodeAttributes?.columns || '3';

  const isRemoveable = parseColumns(columns) > 1;
  const isAddable = parseColumns(columns) < 6;

  const onAddColumn = useCallback(() => {
    try {
      if (!isAddable) return;
      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, 'columns', `${parseColumns(columns) + 1}`);
        return true;
      });
    } catch (error) {
      console.error(error);
    }
  }, [columns, editor, isAddable, activeNodePos]);

  const onRemoveColumn = useCallback(() => {
    try {
      if (!isRemoveable) return;
      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, 'columns', `${parseColumns(columns) - 1}`);
        return true;
      });
    } catch (error) {
      console.error(error);
    }
  }, [columns, editor, isRemoveable, activeNodePos]);

  return (
    <div className="flex items-center justify-stretch gap-2 select-none min-w-0">
      <Text className="w-[80px] shrink-0" variant="secondary" size="2xs" weight="medium">
        {title}
      </Text>
      <SimpleInputWrapper className="h-[32px] cursor-default py-1">
        <div
          className={cn('hover:bg-wb-tertiary rounded-full p-1 text-wb-secondary', {
            'cursor-pointer': isRemoveable,
            'opacity-20': !isRemoveable,
          })}
          onClick={onRemoveColumn}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              onRemoveColumn();
            }
          }}
          role="none"
        >
          <Minus weight="bold" size={12} />
        </div>
        <Text size="2xs" weight="medium">
          {columns}
        </Text>
        <div
          className={cn('hover:bg-wb-tertiary rounded-full p-1 text-wb-secondary', {
            'cursor-pointer': isAddable,
            'opacity-20': !isAddable,
          })}
          onClick={onAddColumn}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              onAddColumn();
            }
          }}
          role="none"
        >
          <Plus weight="bold" size={12} />
        </div>
      </SimpleInputWrapper>
    </div>
  );
}
