import DnsRecordDisplay from '@/components/_domain/CustomDomain/DnsRecordDisplay';
import { Typography, TypographyStack } from '@/components/Typography';
import { CustomDomain, CustomDomainTypes } from '@/interfaces/custom_domain';

import { customDomainRecords, isDomainVerified } from '../../utils';

interface Props {
  customDomain: CustomDomain;
}

const BrandedLink = ({ customDomain }: Props) => {
  const { domain } = customDomain;
  const records = customDomainRecords(customDomain, CustomDomainTypes.BRANDED_LINK);
  const verified = isDomainVerified(customDomain, CustomDomainTypes.BRANDED_LINK);

  return (
    <div className="space-y-4">
      <TypographyStack gap="4">
        <Typography token="font-bold/text/sm" colorWeight="500" as="p">
          This view is in Work in Progress!!!
        </Typography>
        {verified ? (
          <Typography token="font-normal/text/sm" colorWeight="500" as="p">
            These DNS records has been verified and your {domain} branded link is live. Please make sure to keep these
            records in place to ensure your branded links continue to work. For more information, visit the{' '}
            <Typography token="font-medium/text/sm" colorWeight="600" color="secondary" className="cursor-pointer">
              <a
                href="https://www.beehiiv.com/support/article/14492990172823-How-to-add-and-configure-custom-domains"
                target="_blank"
                rel="noopener noreferrer"
              >
                beehiiv knowledge base
              </a>
            </Typography>{' '}
            or check your DNS provider&quot;s documentation.
          </Typography>
        ) : (
          <>
            <Typography token="font-normal/text/sm" colorWeight="500" as="p">
              Go to your DNS provider and create the following DNS records. Once done, click “Verify Setup” below to
              verify your configuration. We additionally will check on your behalf over the next 24 hours.
            </Typography>

            <Typography token="font-normal/text/sm" colorWeight="500" as="p">
              These records will ensure that your email links are branded with your custom domain.
            </Typography>
          </>
        )}
      </TypographyStack>

      <div className="space-y-4">
        {records.map((record) => (
          <DnsRecordDisplay key={record.name} record={record} />
        ))}
      </div>
    </div>
  );
};

export default BrandedLink;
