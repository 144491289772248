import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import moment from 'moment-mini';

import LoadingBox from '@/components/LoadingBox';
import { Typography, TypographyRow } from '@/components/Typography';
import BodyContainer from '@/routes/settings/_components/BodyContainer';
import CardHeader from '@/routes/settings/_components/CardHeader';
import { Button } from '@/ui/Button';
import { Card } from '@/ui/Card';

import { useBillingSummary, usePlanPrice } from '../../_hooks';

import CancelDowngradeModal from './CancelDowngradeModal';
import PlanCard from './PlanCard';

const BEEHIIV_PRICING_URL = 'https://www.beehiiv.com/pricing';

const CurrentPlan: React.FC<{ organizationId: string; publicationId: string }> = ({
  organizationId,
  publicationId,
}) => {
  const { data: billingSummary } = useBillingSummary({ organizationId, publicationId, includeLaunch: true });
  const { next_invoice: nextInvoice } = billingSummary || {};

  const {
    data: planPrice,
    isLoading: isLoadingCurrent,
    isError: isErrorCurrent,
  } = usePlanPrice({ organizationId, publicationId });

  const {
    data: upcomingPlanPrice,
    isLoading: isLoadingUpcoming,
    isError: isErrorUpcoming,
  } = usePlanPrice({ organizationId, publicationId, upcoming: true });

  const showUpcoming = upcomingPlanPrice && upcomingPlanPrice.id !== planPrice?.id;

  const pricingUrl = `${BEEHIIV_PRICING_URL}?subscriberCount=${planPrice?.active_subscriptions}`;

  return (
    <Card>
      <BodyContainer>
        <CardHeader
          title="Your Current Plan"
          cta={
            <Button
              type="button"
              size="xs"
              variant="flush"
              iconRight
              Icon={ArrowTopRightOnSquareIcon}
              onClick={() => window.open(pricingUrl, '_blank')}
            >
              Compare Plans
            </Button>
          }
        />
        <LoadingBox
          isLoading={isLoadingCurrent || isLoadingUpcoming}
          isError={isErrorCurrent || isErrorUpcoming}
          backgroundClassName="bg-transparent"
          height={300}
        >
          <div className="space-y-6">
            {planPrice && <PlanCard planPrice={planPrice} />}
            {showUpcoming && (
              <div className="space-y-2">
                <TypographyRow className="justify-between">
                  <div>
                    <Typography as="p" token="font-medium/text/base">
                      Upcoming Plan
                    </Typography>
                    {nextInvoice?.timestamp && (
                      <Typography token="font-medium/text/sm" colorWeight="500">
                        (starting {moment(nextInvoice.timestamp).format('LL')})
                      </Typography>
                    )}
                  </div>
                  {planPrice && showUpcoming && (
                    <CancelDowngradeModal
                      organizationId={organizationId}
                      publicationId={publicationId}
                      currentPlanPrice={planPrice}
                      upcomingPlanPrice={upcomingPlanPrice}
                    />
                  )}
                </TypographyRow>
                <PlanCard planPrice={upcomingPlanPrice} />
              </div>
            )}
          </div>
        </LoadingBox>
      </BodyContainer>
    </Card>
  );
};

export default CurrentPlan;
