import { useEffect, useState } from 'react';
import { CaretDown } from '@phosphor-icons/react';
import { NavbarSerializableNode } from '@shared/dream-components';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/routes/website/_components/UI/DropdownMenu';
import { SimpleInput, SimpleInputWrapper } from '@/routes/website/_components/UI/Input';
import { Text } from '@/routes/website/_components/UI/Text';

import { useNavbarContext } from '../../../NavbarContext';

const parseCssValue = (value: string) => {
  if (!value) return 0;
  if (value === 'auto') {
    return 0;
  }
  const numericValue = parseFloat(value.replace(/[^\d.-]/g, ''));
  return Number.isNaN(numericValue) ? 0 : numericValue;
};
export const WidthHeightSettings = ({
  selectedContent,
  attribute,
  defaultFixedValue = 700,
}: {
  selectedContent: NavbarSerializableNode;
  attribute: 'width' | 'height';
  defaultFixedValue?: number;
}) => {
  const { onUpdateNodeAttributes } = useNavbarContext();

  const [value, setValue] = useState<string | number>(0);
  const [dimensionType, setdimensionType] = useState<'fixed' | 'fill' | 'fit'>('fill');

  const contentValue =
    selectedContent?.attrs && attribute in selectedContent.attrs
      ? selectedContent.attrs[attribute as keyof typeof selectedContent.attrs]
      : '100%';

  useEffect(() => {
    setdimensionType(() => {
      if (contentValue === '100%') {
        return 'fill';
      }
      if (contentValue === 'auto') {
        return 'fit';
      }
      return 'fixed';
    });
    setValue(parseCssValue(contentValue as string));
  }, [contentValue]);

  if (!selectedContent || !selectedContent.attrs?.id) return null;

  const options: Record<string, string> = {
    fixed: 'Fixed',
    fill: 'Fill',
    fit: 'Fit',
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setValue(0);
      return;
    }
    setValue(parseInt(e.target.value, 10));
  };

  const onUpdateInput = (type: 'fit' | 'fill' | 'fixed', fixedValue: string | number) => {
    if (!selectedContent.attrs?.id) return;
    if (type === 'fit') {
      onUpdateNodeAttributes(selectedContent.attrs.id, { [attribute]: 'auto' });
    } else if (type === 'fill') {
      onUpdateNodeAttributes(selectedContent.attrs.id, { [attribute]: '100%' });
    } else {
      onUpdateNodeAttributes(selectedContent.attrs.id, { [attribute]: `${fixedValue}px` });
    }
  };

  const handleDimensionChange = (type: 'fill' | 'fixed' | 'fit') => {
    if (!selectedContent.attrs?.id) return;
    onUpdateInput(type, defaultFixedValue);
  };

  return (
    <div className="flex items-center justify-stretch gap-2">
      <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
        {attribute === 'width' ? 'Width' : 'Height'}
      </Text>

      <div className="grow flex gap-2 min-w-0 w-[120px]">
        <SimpleInputWrapper>
          {dimensionType === 'fill' && <SimpleInput type="text" value="100%" disabled />}
          {dimensionType === 'fit' && <SimpleInput type="text" value="auto" disabled />}
          {dimensionType === 'fixed' && (
            <SimpleInput
              type="number"
              value={value}
              onChange={handleChange}
              onBlur={() => onUpdateInput('fixed', value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onUpdateInput('fixed', value);
                }
              }}
            />
          )}
        </SimpleInputWrapper>
        <DropdownMenu>
          <DropdownMenuTrigger asChild className="cursor-pointer">
            <div className="grow w-full bg-wb-secondary rounded-lg shadow-sm justify-between flex items-center gap-2 p-2">
              <div className="flex items-center gap-1">
                <Text size="2xs" weight="medium">
                  {options[dimensionType]}
                </Text>
              </div>
              <CaretDown className="text-wb-secondary w-3 h-3" weight="bold" />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-full min-w-[8rem] cursor-pointer" align="end">
            {Object.keys(options).map((optionKey) => (
              <DropdownMenuItem
                key={optionKey}
                onSelect={() => {
                  handleDimensionChange(optionKey as 'fixed' | 'fill' | 'fit');
                }}
              >
                {options[optionKey]}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
};
