import React, { useCallback, useState } from 'react';
import { ArrowRight } from '@phosphor-icons/react';
import { Editor, Range } from '@tiptap/core';

import { useSite } from '@/hooks/useSite';
import { useInfiniteScrollSiteTemplates } from '@/hooks/useSiteTemplates';
import { SiteTemplate } from '@/interfaces/site_template';

import { cn } from '../../../../../_utils/cn';
import { Button } from '../../../../UI/Button';
import { Checkbox } from '../../../../UI/Checkbox';
import { Dialog, DialogContent, DialogFooter, DialogTrigger } from '../../../../UI/Dialog';
import { Label } from '../../../../UI/Label';
import { Text } from '../../../../UI/Text';

interface Props {
  editor: Editor;
  actionButton: React.ReactNode;
  insertPos: number | Range | null;
  multipleSelectionsAllowed?: boolean;
}

const AddSectionModal = ({ actionButton, editor, insertPos, multipleSelectionsAllowed = true }: Props) => {
  const { data: site } = useSite();
  const categories = site?.template_categories?.section;

  const defaultSection = categories?.[0]?.value;
  const [selectedSection, setSelectedSection] = useState<string | undefined>(defaultSection);
  const [selectedTemplates, setSelectedTemplates] = useState<SiteTemplate[]>([]);
  const [canFetchTemplates, setCanFetchTemplates] = useState(false);

  const selectedSectionsCount = selectedTemplates.length;
  const isDisabled = selectedSectionsCount === 0;
  const selectedSectionsCountText = selectedSectionsCount <= 1 ? '' : `(${selectedSectionsCount}) `;
  const selectedSectionsCountCTAText = selectedSectionsCount <= 1 ? 'section' : 'sections';

  const { data } = useInfiniteScrollSiteTemplates({ enabled: canFetchTemplates });
  const siteTemplates = data?.pages.flatMap((page) => page.site_templates) || [];

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
    setCanFetchTemplates(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCanFetchTemplates(false);
  };

  const handleInsertSection = useCallback(() => {
    const combinedContent = selectedTemplates?.map((template: SiteTemplate) => template.content) || [];

    if (typeof insertPos === 'number') {
      editor.chain().focus(insertPos).insertContent(combinedContent).run();
    } else if (insertPos) {
      editor.chain().insertContentAt(insertPos, combinedContent).run();
    }
  }, [editor, insertPos, selectedTemplates]);

  const handleSelection = (template: SiteTemplate, isSelected: boolean) => {
    if (isSelected) {
      if (multipleSelectionsAllowed) {
        setSelectedTemplates((prev) => [...prev, template]);
      } else {
        setSelectedTemplates([template]);
      }
    } else if (multipleSelectionsAllowed) {
      setSelectedTemplates((prev) => prev.filter((t) => t.id !== template.id));
    } else {
      setSelectedTemplates([]);
    }
  };

  return (
    <Dialog
      open={isModalOpen}
      onOpenChange={(open) => {
        if (open) {
          handleOpenModal();
        } else {
          handleCloseModal();
        }
      }}
    >
      <DialogTrigger asChild>{actionButton}</DialogTrigger>
      <DialogContent className="w-[800px] max-w-[95vw] p-0 overflow-hidden">
        <div className="flex flex-col p-4 h-[60vh]">
          <Text size="md" weight="semibold" className="mb-4">
            Add New Sections
          </Text>

          <div className="flex h-full">
            <div className="w-[200px] border-wb-hr pr-4 gap-2 flex flex-col">
              <Text weight="medium" variant="secondary" size="2xs">
                Sections
              </Text>
              <ul>
                {categories?.map(({ value, label }) => {
                  const isSelected = selectedSection === value;

                  return (
                    <li key={value}>
                      <Button
                        variant="ghost"
                        className={cn('w-full justify-start', isSelected ? 'bg-wb-secondary' : '')}
                        onClick={() => setSelectedSection(value)}
                      >
                        <Text weight="medium" variant="primary" size="2xs" as="span">
                          {label}
                        </Text>
                      </Button>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="flex flex-col w-full gap-4 overflow-y-auto no-scrollbar pb-40">
              <Text size="md" weight="semibold">
                Templates
              </Text>
              <div className="grid grid-cols-2 gap-8">
                {siteTemplates?.map((template) => {
                  const isSelected = selectedTemplates?.find((t: SiteTemplate) => t.id === template.id);

                  return (
                    <Label
                      htmlFor={template.id}
                      className={cn(
                        'flex flex-col cursor-pointer hover:shadow-md border shadow p-0 rounded-md h-56 focus:outline-none relative',
                        isSelected ? 'border-wb-accent border-2' : 'border-wb-primary'
                      )}
                    >
                      <div className="h-full w-full overflow-hidden">
                        <img
                          src={template.image_preview_url}
                          alt={template.name}
                          className="w-full h-full object-contain object-center"
                        />
                      </div>

                      <Checkbox
                        id={template.id}
                        className={cn('absolute -top-2 -right-2', isSelected ? 'opacity-100' : 'opacity-0')}
                        checked={Boolean(isSelected)}
                        onCheckedChange={() => handleSelection(template, !isSelected)}
                      />

                      <Text
                        weight="semibold"
                        variant="primary"
                        size="sm"
                        as="span"
                        className="absolute -bottom-6 left-1/2 transform -translate-x-1/2"
                      >
                        {template.name}
                      </Text>
                    </Label>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <DialogFooter>
          <div className="flex justify-end items-center border-t w-full p-4 bg-white absolute bottom-0 left-0">
            <div className="flex gap-2">
              <Button variant="outlined" onClick={handleCloseModal}>
                Cancel
              </Button>
              <Button variant="primary" onClick={handleInsertSection} isDisabled={isDisabled} RightIcon={ArrowRight}>
                Add {selectedSectionsCountCTAText} {selectedSectionsCountText}
              </Button>
            </div>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default AddSectionModal;
