import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { survey } from '../../dummyData/survey';
import { DEFAULT_BORDER_COLOR, DEFAULT_PRIMARY_COLOR, DEFAULT_TEXT_ON_PRIMARY_COLOR } from '../constants';

import { SurveyView } from './views/SurveyView';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    survey: {
      setSurvey: () => ReturnType;
    };
  }
}

export const Survey = Node.create<{}, {}>({
  name: 'survey',

  group: 'block',

  draggable: true,

  selectable: true,

  addAttributes() {
    return {
      gap: {
        default: '16px',
        parseHTML: (element) => element.getAttribute('data-gap') || '0px',
        renderHTML: (attributes) => ({ 'data-gap': attributes.gap }),
      },
      shadow: {
        default: 'none',
        parseHTML: (element) => element.getAttribute('data-shadow'),
        renderHTML: (attributes) => ({ 'data-shadow': attributes.shadow }),
      },
      textAlign: {
        default: 'left',
        parseHTML: (element) => element.getAttribute('data-alignment'),
        renderHTML: (attributes) => ({ 'data-alignment': attributes.alignment }),
      },
      height: {
        default: 'auto',
        parseHTML: (element) => element.getAttribute('data-height'),
        renderHTML: (attributes) => ({ 'data-height': attributes.height }),
      },
      width: {
        default: '700px',
        parseHTML: (element) => element.getAttribute('data-width'),
        renderHTML: (attributes) => ({ 'data-width': attributes.width }),
      },
      background: {
        default: '#ffffff',
        parseHTML: (element) => element.getAttribute('data-background-color'),
        renderHTML: (attributes) => ({ 'data-background-color': attributes.backgroundColor }),
      },
      borderWidth: {
        default: '1px',
        parseHTML: (element) => element.getAttribute('data-border-width'),
        renderHTML: (attributes) => ({ 'data-border-width': attributes.borderWidth }),
      },
      borderColor: {
        default: DEFAULT_BORDER_COLOR,
        parseHTML: (element) => element.getAttribute('data-border-color'),
        renderHTML: (attributes) => ({ 'data-border-color': attributes.borderColor }),
      },
      borderStyle: {
        default: 'none',
        parseHTML: (element) => element.getAttribute('data-border-style'),
        renderHTML: (attributes) => ({ 'data-border-style': attributes.borderStyle }),
      },
      borderRadius: {
        default: '8px',
        parseHTML: (element) => element.getAttribute('data-border-radius'),
        renderHTML: (attributes) => ({ 'data-border-radius': attributes.borderRadius }),
      },
      inputBorderWidth: {
        default: '1px',
        parseHTML: (element) => element.getAttribute('data-input-border-width'),
        renderHTML: (attributes) => ({ 'data-input-border-width': attributes.inputBorderWidth }),
      },
      inputBorderColor: {
        default: DEFAULT_BORDER_COLOR,
        parseHTML: (element) => element.getAttribute('data-input-border-color'),
        renderHTML: (attributes) => ({ 'data-input-border-color': attributes.inputBorderColor }),
      },
      inputBorderStyle: {
        default: 'solid',
        parseHTML: (element) => element.getAttribute('data-input-border-style'),
        renderHTML: (attributes) => ({ 'data-input-border-style': attributes.inputBorderStyle }),
      },
      inputBorderRadius: {
        default: '8px',
        parseHTML: (element) => element.getAttribute('data-input-border-radius'),
        renderHTML: (attributes) => ({ 'data-input-border-radius': attributes.inputBorderRadius }),
      },
      inputBackgroundColor: {
        default: '#ffffff',
        parseHTML: (element) => element.getAttribute('data-input-background-color'),
        renderHTML: (attributes) => ({ 'data-input-background-color': attributes.inputBackgroundColor }),
      },
      inputTextColor: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-input-text-color'),
        renderHTML: (attributes) => ({ 'data-input-text-color': attributes.inputTextColor }),
      },
      inputPlaceholderColor: {
        default: '#6b7280',
        parseHTML: (element) => element.getAttribute('data-input-placeholder-color'),
        renderHTML: (attributes) => ({ 'data-input-placeholder-color': attributes.inputPlaceholderColor }),
      },
      labelColor: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-label-color'),
        renderHTML: (attributes) => ({ 'data-label-color': attributes.labelColor }),
      },
      helperTextColor: {
        default: '#6b7280',
        parseHTML: (element) => element.getAttribute('data-helper-text-color'),
        renderHTML: (attributes) => ({ 'data-helper-text-color': attributes.helperTextColor }),
      },
      buttonColor: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-button-color'),
        renderHTML: (attributes) => ({ 'data-button-color': attributes.buttonColor }),
      },
      requiredColor: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-required-color'),
        renderHTML: (attributes) => ({ 'data-required-color': attributes.requiredColor }),
      },
      buttonTextColor: {
        default: DEFAULT_TEXT_ON_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-button-text-color'),
        renderHTML: (attributes) => ({ 'data-button-text-color': attributes.buttonTextColor }),
      },
      padding: {
        default: '0px',
        parseHTML: (element) => element.getAttribute('data-padding'),
        renderHTML: (attributes) => ({ 'data-padding': attributes.padding }),
      },
      data: {
        default: survey,
        parseHTML: (element) => JSON.parse(element.getAttribute('data-data') || '{}'),
        renderHTML: (attributes) => ({ 'data-data': JSON.stringify(attributes.data) }),
      },
    };
  },

  addCommands() {
    return {
      setSurvey:
        () =>
        ({ chain }) =>
          chain()
            .focus()
            .insertContent({
              type: this.name,
              attrs: {
                height: 100,
              },
            })
            .run(),
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', { ...HTMLAttributes, 'data-type': this.name }];
  },

  addNodeView() {
    return ReactNodeViewRenderer(SurveyView);
  },
});

export default Survey;
