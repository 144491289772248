import cx from 'classnames';

const Stepper = ({ step = 1, totalSteps = 1 }) => {
  if (totalSteps <= 1) return null;

  return (
    <div className="flex space-x-2 items-center justify-center pt-6 mx-auto">
      {Array.from({ length: totalSteps }).map((_, i) => (
        <div className={cx('rounded-full w-1.5 h-1.5', i >= step ? 'bg-surface-300' : 'bg-surface-600')} />
      ))}
    </div>
  );
};

export default Stepper;
