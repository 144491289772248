import { useCallback, useMemo } from 'react';
import { CaretRight } from '@phosphor-icons/react';

import { Popover, PopoverContent, PopoverTrigger } from '../../../../UI/Popover';
import { Text } from '../../../../UI/Text';
import { AttributeSettingProps } from '../../types';

type ButtonStyle = {
  label: string;
  key: string;
  attributes: {
    border_radius: string;
  };
  isActive: (activeNodeAttributes: Record<string, string>) => boolean;
};

const buttonStyles: ButtonStyle[] = [
  {
    label: 'Box',
    key: 'box',
    attributes: {
      border_radius: '0px',
    },
    isActive: (activeNodeAttributes: Record<string, string>) => {
      return activeNodeAttributes.border_radius === '0px';
    },
  },
  {
    label: 'Rounded',
    key: 'rounded',
    attributes: {
      border_radius: '8px',
    },
    isActive: (activeNodeAttributes: Record<string, string>) => {
      return activeNodeAttributes.border_radius === '8px';
    },
  },
  {
    label: 'Capsule',
    key: 'capsule',
    attributes: {
      border_radius: '9999px',
    },
    isActive: (activeNodeAttributes: Record<string, string>) => {
      return activeNodeAttributes.border_radius === '9999px';
    },
  },
];

const ButtonStyleOption = ({
  style,
  isActive,
  onClick,
}: {
  style: ButtonStyle;
  isActive: boolean;
  onClick: () => void;
}) => {
  const { label, attributes } = style;

  return (
    <div
      className={`flex p-2 border rounded-lg gap-3 items-center ${
        isActive ? 'bg-wb-button-accent-soft border-wb-accent-soft' : 'bg-wb-secondary border-transparent'
      }`}
      onClick={onClick}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          onClick();
        }
      }}
    >
      <div className="rounded-md bg-wb-surface h-[60px] p-3 flex items-center justify-center">
        <div className="bg-wb-primary w-[70px] h-[27px] shadow" style={{ borderRadius: attributes.border_radius }} />
      </div>
      <Text weight="medium">{label}</Text>
    </div>
  );
};

export const ButtonStyleSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  const { activeNodeAttributes, activeNodePos } = activeNodeResult;

  const handleUpdateButtonStyle = useCallback(
    (style: ButtonStyle) => {
      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, 'border_radius', style.attributes.border_radius);
        return true;
      });
    },
    [editor, activeNodePos]
  );

  const activeStyle = useMemo(() => {
    return buttonStyles.filter((v) => v.isActive(activeNodeAttributes))[0]?.label;
  }, [activeNodeAttributes]);

  return (
    <Popover>
      <div className="flex items-center justify-stretch gap-2 select-none">
        <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
          Style
        </Text>
        <PopoverTrigger asChild>
          <div className="grow bg-wb-secondary rounded-lg shadow-sm cursor-pointer">
            <div className="w-full justify-between flex items-center gap-2 py-2 px-3">
              <div className="flex items-center gap-1">
                <Text size="2xs" weight="medium" className="">
                  {activeStyle || 'Custom'}
                </Text>
              </div>
              <CaretRight className="text-wb-secondary" weight="bold" />
            </div>
          </div>
        </PopoverTrigger>
      </div>
      <PopoverContent className="w-[255px] p-0" align="center" side="left" sideOffset={110}>
        <div className="bg-wb-primary w-[255px] max-h-[500px] overflow-y-auto p-3 rounded-lg shadow-xl flex flex-col gap-2">
          <div className="flex items-center justify-between gap-2">
            <Text size="sm" weight="semibold">
              Button Styles
            </Text>
          </div>
          {buttonStyles.map((style) => {
            return (
              <ButtonStyleOption
                key={style.key}
                style={style}
                isActive={style.isActive(activeNodeAttributes)}
                onClick={() => handleUpdateButtonStyle(style)}
              />
            );
          })}
        </div>
      </PopoverContent>
    </Popover>
  );
};
