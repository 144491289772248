import SectionRenderer from '../../helpers/SectionRenderer';
import { RenderCondition } from '../../RenderCondition';
import { AttributeSettingProps } from '../../types';
import { BackgroundSettings } from '../BackgroundSettings';
import { GapSettings } from '../GapSettings';
import { SizingSettings } from '../SizingSettings';

import { SimpleColumnCountInput } from './SimpleColumnCountInput';

export function ColumnsSettings({ editor, activeNodeResult }: AttributeSettingProps) {
  return (
    <RenderCondition editor={editor} allowedNodeTypes={['columns']}>
      <SectionRenderer title="Background">
        <BackgroundSettings editor={editor} activeNodeResult={activeNodeResult} />
      </SectionRenderer>
      <SectionRenderer title="Layout">
        <SizingSettings editor={editor} />
        <SimpleColumnCountInput editor={editor} activeNodeResult={activeNodeResult} title="Columns" />
        <GapSettings editor={editor} activeNodeResult={activeNodeResult} property="gap" title="Gap" />
      </SectionRenderer>
    </RenderCondition>
  );
}
