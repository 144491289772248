import { CustomDomain, CustomDomainTypes, DnsRecord } from '@/interfaces/custom_domain';

import { DnsRecordsModalStep } from './components/constants';

export const getDomainForType = (type: CustomDomainTypes, steps: DnsRecordsModalStep[]): CustomDomain | null => {
  switch (type) {
    case CustomDomainTypes.EMAIL:
      return steps.find((step) => step.type === CustomDomainTypes.EMAIL)?.customDomain || null;
    case CustomDomainTypes.BRANDED_LINK:
      return steps.find((step) => step.type === CustomDomainTypes.BRANDED_LINK)?.customDomain || null;
    case CustomDomainTypes.WEB:
      return steps.find((step) => step.type === CustomDomainTypes.WEB)?.customDomain || null;
    case CustomDomainTypes.REDIRECT:
      return steps.find((step) => step.type === CustomDomainTypes.REDIRECT)?.customDomain || null;
    default:
      return null;
  }
};

export const isDomainVerified = (customDomain: CustomDomain | undefined | null, type: CustomDomainTypes): boolean => {
  if (!customDomain) {
    return false;
  }

  switch (type) {
    case 'email':
      return customDomain.email_enabled && !customDomain.email_verification_pending;
    case 'branded_link':
      return (
        customDomain.branded_link_added &&
        !customDomain.branded_link_pending &&
        !customDomain.branded_link_cloudflare_pending
      );
    case 'web':
      return customDomain.web_enabled && !customDomain.web_verification_pending;
    case 'redirect':
      return customDomain.web_redirect_enabled && !customDomain.web_redirect_verification_pending;
    default:
      return false;
  }
};

export const customDomainRecords = (customDomain: CustomDomain | null, type: CustomDomainTypes): DnsRecord[] => {
  if (!customDomain) {
    return [];
  }

  switch (type) {
    case 'email':
      return customDomain.email_authenticated_domains;
    case 'branded_link':
      if (customDomain.branded_link_pending && !customDomain.branded_link_cloudflare_pending) {
        return customDomain.authenticated_branded_links;
      }
      return [customDomain.cloudflare_verified_authenticated_branded_link];

    case 'web':
      return customDomain.web_authenticated_domains;
    case 'redirect':
      return customDomain.web_authenticated_domains;
    default:
      return [];
  }
};
