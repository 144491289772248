import { FC } from 'react';
import { AccordionElement } from '@shared/dream-components';
import { NodeViewProps } from '@tiptap/core';
import { NodeViewContent, useReactNodeView } from '@tiptap/react';

import { getDataAttributes } from '../../utils';

export const AccordionView: FC<NodeViewProps> = (props) => {
  const { onDragStart } = useReactNodeView();
  const { node, HTMLAttributes } = props;

  return (
    <AccordionElement
      element={{
        type: 'accordion',
        attrs: {
          id: node.attrs.id,
          ...node.attrs,
        },
        content: [
          {
            type: 'accordionTitle',
            content: [
              {
                type: 'paragraph',
                content: [],
              },
            ],
          },
          {
            type: 'accordionContent',
            content: [],
          },
        ],
      }}
      attributes={{
        ...getDataAttributes(HTMLAttributes),
        'data-node-view-wrapper': '',
      }}
      onDragStart={onDragStart}
    >
      <NodeViewContent data-node-view-display-contents />
    </AccordionElement>
  );
};
