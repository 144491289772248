import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { GearSix, House } from '@phosphor-icons/react';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { WebsiteProvider } from '@/context/website-context';
import useSitePackage from '@/hooks/useSitePackages/useSitePackage';

import { ContentTree } from '../../_components/ContentTree';
import { ContentTreeProvider } from '../../_components/ContentTree/context';
import { Layout } from '../../_components/Layout';
import { Main } from '../../_components/Main';
import { Breadcrumbs } from '../../_components/Main/Breadcrumbs';
import { NavMenuItem } from '../../_components/SideNav/NavMenuItem';
import { NavSection } from '../../_components/SideNav/NavSection';
import { NavSectionTitle } from '../../_components/SideNav/NavSectionTitle';
import { Text } from '../../_components/UI/Text';
import useSetPages from '../../_hooks/useSetPages';
import { DisplayCard } from '../_components/DisplayCard';

import { TemplatesTopBar } from './_components/TemplatesTopBar';

const WebsiteProjectPage = () => {
  const { projectId } = useParams();
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();
  const { data: project, isLoading } = useSitePackage({ sitePackageId: projectId || '' });
  const templates = project?.templates;
  const hasNoTemplates = templates?.length === 0 && !isLoading;

  const onDeleteTemplateSuccess = () => {
    queryClient.invalidateQueries(['publications', currentPublicationId, 'site_packages', projectId]);
  };

  const { pageRoutes, defaultRoutes } = useSetPages();

  return (
    <Layout
      logoDropdownProps={{
        actionText: 'Back to Main App',
        backPath: '/',
      }}
      sidenavChildren={
        <>
          <NavSection>
            <NavSectionTitle title="Main" />
            <NavMenuItem Icon={House} title="Home" to="/website_builder_v2" />
            <NavMenuItem Icon={GearSix} title="Site Settings" to="/website_builder_v2/settings" />
          </NavSection>
          <NavSection>
            <NavSectionTitle title="Pages" />
            {pageRoutes && (
              <ContentTreeProvider isAllowDnD isShowOptions isShowAddPage pageLinkPrefix="/website_builder_v2/page">
                <ContentTree route={pageRoutes} parentPath={[]} slug="" />
              </ContentTreeProvider>
            )}
          </NavSection>
          <NavSection>
            <NavSectionTitle title="Default Pages" />
            {defaultRoutes && (
              <ContentTreeProvider
                dndDisabledErrorMessage="You can't re-arrange default pages"
                isAllowDnD={false}
                isShowOptions
                isShowAddPage
                pageLinkPrefix="/website_builder_v2/page"
              >
                <ContentTree showHome={false} route={defaultRoutes} parentPath={[]} slug="" />
              </ContentTreeProvider>
            )}
          </NavSection>
        </>
      }
    >
      <Main>
        <Breadcrumbs />
        <TemplatesTopBar project={project} />
        {hasNoTemplates ? (
          <div className="flex items-center justify-center h-full py-20">
            <Text size="xl" weight="semibold" variant="secondary" as="h4">
              No templates found
            </Text>
          </div>
        ) : (
          <div className="grid grid-cols-[repeat(auto-fill,_minmax(250px,_1fr))] gap-6">
            {templates?.map((template) => {
              return (
                <DisplayCard
                  key={template.id}
                  siteTemplateId={template.id}
                  onDeleteSuccess={onDeleteTemplateSuccess}
                  redirectTo={`/website_builder_v2/projects/${projectId}/templates/${template.id}`}
                  name={template.name}
                />
              );
            })}
          </div>
        )}
      </Main>
    </Layout>
  );
};

export default () => (
  <WebsiteProvider>
    <WebsiteProjectPage />
  </WebsiteProvider>
);
