import { NodeSelection, Plugin, PluginKey, TextSelection } from '@tiptap/pm/state';

import { modKey } from '../../utils';

export const NodeSelectorPluginKey = new PluginKey('nodeSelectorPlugin');

export const getNodeSelectorPlugin = () => {
  return new Plugin({
    key: NodeSelectorPluginKey,

    props: {
      handleDOMEvents: {
        mousedown(view, event) {
          if (event[modKey]) {
            return false;
          }

          const posDetails = view.posAtCoords({ left: event.clientX, top: event.clientY });

          if (!posDetails) {
            return false;
          }

          const { inside, pos } = posDetails;

          if (inside === -1) {
            return false;
          }

          const { selection } = view.state;

          if (selection instanceof NodeSelection && inside === selection.from) {
            return false;
          }

          if (selection instanceof TextSelection && selection.$from.sameParent(view.state.doc.resolve(pos))) {
            return false;
          }

          view.dispatch(view.state.tr.setSelection(new NodeSelection(view.state.doc.resolve(inside))));

          event.preventDefault();

          return true;
        },
      },
    },
  });
};
