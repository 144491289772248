import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Badge from '@/components/Badge';
import EllipsisDropdown from '@/components/EllipsisDropdown';
import { Input } from '@/components/Form';
import usePublicationSettings from '@/hooks/usePublications/usePublicationSettings';
import { CustomDomain, CustomDomainTypes } from '@/interfaces/custom_domain';
import { EllipsisDropdownOption } from '@/interfaces/general';
import appendSettingsPublicationId from '@/utils/appendSettingsPublicationId';

import useVerificationModals from '../hooks/useVerificationModals';
import { isDomainVerified } from '../utils';

import DisconnectDomainForm from './DisconnectDomainForm';
import StatusBadge from './StatusBadge';

interface Props {
  publicationId: string;
  customDomain: CustomDomain;
  customRedirectDomain?: CustomDomain;
  disabled: boolean;
}

const WebCustom: React.FC<Props> = ({ publicationId, customDomain, customRedirectDomain, disabled }) => {
  const navigate = useNavigate();
  const { handleOpenDnsRecordsModal } = useVerificationModals();
  const { data: settings } = usePublicationSettings(publicationId);

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const type = CustomDomainTypes.WEB;
  const { domain, entri_verification_pending: entriVerificationPending } = customDomain;
  const isVerified = isDomainVerified(customDomain, CustomDomainTypes.WEB);

  const canAddRedirectDomain = settings?.apex_domains && !customRedirectDomain;

  const dropdownOptions = useMemo(() => {
    const options: EllipsisDropdownOption[] = [];

    // TODO: [BEE-7083] Allow viewing Entri records when verified
    if (!entriVerificationPending) {
      options.push(
        {
          label: isVerified ? 'View DNS Records' : 'Verify',
          onClick: () => handleOpenDnsRecordsModal(type),
        });
    };

    if (canAddRedirectDomain) {
      options.push({
        label: 'Configure Redirect',
        onClick: () => navigate(appendSettingsPublicationId('/settings/publication/domain/web/new', publicationId)),
      });
    }

    return [
      ...options,
      {
        label: 'Disconnect Domain',
        onClick: () => setDeleteModalOpen(true),
        isDestructive: true,
      },
    ];
  }, [entriVerificationPending, canAddRedirectDomain, isVerified, handleOpenDnsRecordsModal, type, navigate, publicationId]);

  return (
    <>
      <DisconnectDomainForm
        customDomain={customDomain}
        type={type}
        isOpen={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
      />

      <div className="p-4 bg-gray-50 border border-surface-200 rounded-lg">
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-start space-x-2">
            <Badge size="sm">Web</Badge>
            <StatusBadge domain={customDomain} type={type} />
          </div>
          {!disabled && <EllipsisDropdown options={dropdownOptions} />}
        </div>

        <Input name="domain" labelText="Domain" value={domain} className="flex-grow" readOnly />
      </div>
    </>
  );
};

export default WebCustom;
