import { CaretDown, CaretRight, Image, MagnifyingGlass } from '@phosphor-icons/react';
import { NavbarItemType } from '@shared/dream-components';

import { Popover, PopoverContent, PopoverTrigger } from '@/routes/website/_components/UI/Popover';
import { Text } from '@/routes/website/_components/UI/Text';

import { navbarDropdownContent } from '../../../defaultContent';
import { useNavbarContext } from '../../../NavbarContext';
import { formatCamelCase } from '../../../utils';
import { OptionsWithPreview } from '../../ui/OptionsWithPreview';

export const TypeSettings = () => {
  const { selectedContent, onUpdateNodeAttributes, onUpdateNodeContent } = useNavbarContext();

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== 'navbar_item') return null;

  const contentValue = selectedContent.attrs?.type || 'link';

  const handleTypeChange = (type: NavbarItemType) => {
    if (!selectedContent.attrs?.id) return;
    const additionalAttr = type === 'logo' ? { width: 'auto', height: '36px' } : {};
    onUpdateNodeAttributes(selectedContent.attrs?.id, { type, ...additionalAttr });
    if (type === 'dropdown') {
      onUpdateNodeContent(selectedContent.attrs?.id, [navbarDropdownContent()]);
    } else {
      onUpdateNodeContent(selectedContent.attrs?.id, []);
    }
  };

  return (
    <Popover>
      <div className="flex items-center justify-stretch gap-2">
        <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
          Type
        </Text>

        <PopoverTrigger asChild>
          <div className="grow bg-wb-secondary rounded-lg shadow-sm cursor-pointer w-[120px]">
            <div className="w-full justify-between flex items-center gap-2 p-2 ">
              <div className="flex items-center gap-1">
                <Text size="2xs" weight="medium">
                  {formatCamelCase(contentValue)}
                </Text>
              </div>
              <CaretRight className="text-wb-secondary" weight="bold" />
            </div>
          </div>
        </PopoverTrigger>
      </div>
      <PopoverContent className="w-[255px] p-0" align="center" side="left" sideOffset={110}>
        <div className="max-h-[500px] overflow-y-auto p-3 flex flex-col gap-6">
          <Text size="sm" weight="semibold">
            Type
          </Text>
          <div className="flex flex-col gap-2">
            {(['link', 'button', 'dropdown', 'search', 'logo'] as NavbarItemType[]).map((type) => (
              <OptionsWithPreview
                key={type}
                label={formatCamelCase(type)}
                selected={type === contentValue}
                onClick={() => handleTypeChange(type)}
              >
                {/* preview */}
                <div className="flex items-center justify-center bg-wb-primary rounded-md p-2 w-full gap-1 shadow-wb-md">
                  {type === 'link' && (
                    <Text size="sm" weight="medium" className="text-[8px]">
                      Products
                    </Text>
                  )}
                  {type === 'button' && (
                    <div className="flex items-center px-2 py-1 bg-wb-accent rounded-full">
                      <Text size="sm" weight="medium" variant="on-accent" className="text-[8px]">
                        Products
                      </Text>
                    </div>
                  )}
                  {type === 'search' && <MagnifyingGlass size={16} weight="bold" className="text-wb-secondary" />}
                  {type === 'logo' && <Image size={16} weight="bold" className="text-wb-secondary" />}
                  {type === 'dropdown' && (
                    <div className="flex items-center gap-1">
                      <Text size="sm" weight="medium" className="text-[8px]">
                        Products
                      </Text>
                      <CaretDown size={8} weight="bold" className="text-wb-primary" />
                    </div>
                  )}
                </div>
              </OptionsWithPreview>
            ))}
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};
