import { SimpleSelect } from '@/components/Form';
import { Typography } from '@/components/Typography';
import { PlanPrice } from '@/interfaces/plan_price';
import { capitalize } from '@/utils';

interface Props {
  currentPlanPrice: PlanPrice;
  value: PlanPrice['interval'];
  onSelect: (val: PlanPrice['interval']) => void;
}

const IntervalSelect: React.FC<Props> = ({ currentPlanPrice, value, onSelect }) => {
  return (
    <>
      <Typography as="div">
        You are currently billed {currentPlanPrice.interval}ly for your {capitalize(currentPlanPrice.plan_name)} plan.
        If you would like to change your billing cadence, select an option below.
      </Typography>
      <SimpleSelect
        className="my-6"
        name="desired_plan_price"
        value={value}
        onSelect={(_, val) => onSelect(val)}
        options={[
          {
            label: 'Monthly',
            value: 'month',
          },
          {
            label: 'Yearly',
            value: 'year',
          },
        ]}
      />
    </>
  );
};

export default IntervalSelect;
