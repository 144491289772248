import { File, Question } from '@phosphor-icons/react';

import { Text } from '@/routes/website/_components/UI/Text';
import { Tooltip } from '@/routes/website/_components/UI/Tooltip';
import { cn } from '@/utils/cn';

import { useNavbarContext } from '../../../NavbarContext';

export const LinkToSettings = () => {
  const { selectedContent } = useNavbarContext();

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== 'navbar_item') return null;

  // TODO: implement links & internal links
  // const contentValue = selectedContent?.attrs && 'href' in selectedContent.attrs && selectedContent.attrs.href || '';

  const disabled = selectedContent?.attrs && 'action' in selectedContent.attrs && !!selectedContent.attrs.action;

  return (
    <div className="flex items-center justify-stretch gap-2">
      <div className="flex items-center gap-1 w-[80px] min-w-[80px]">
        <Text variant="secondary" size="2xs" weight="medium">
          Link to
        </Text>
        {disabled && (
          <Tooltip center="If an action is set, the link will be disabled">
            <Question size={16} weight="bold" className="text-wb-secondary" />
          </Tooltip>
        )}
      </div>
      <div className={cn('grow bg-wb-secondary rounded-lg shadow-sm', disabled && 'opacity-50 pointer-events-none')}>
        <div className="w-full justify-between flex items-center gap-2 p-2">
          <div className="flex items-center gap-1">
            <File size={16} weight="bold" className="text-wb-secondary" />
            <Text size="2xs" weight="medium">
              Home
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};
