/* eslint-disable jsx-a11y/media-has-caption */

import { FC } from 'react';
import { ParagraphElement } from '@shared/dream-components';
import { NodeViewContent, NodeViewProps, useReactNodeView } from '@tiptap/react';

import { getDataAttributes } from '../../utils';

export const ParagraphView: FC<NodeViewProps> = ({ HTMLAttributes, node }) => {
  const { onDragStart } = useReactNodeView();

  return (
    <ParagraphElement
      element={{
        type: 'paragraph',
        attrs: {
          id: node.attrs.id,
          ...node.attrs,
        },
      }}
      attributes={{
        'data-node-view-wrapper': '',
        ...getDataAttributes(HTMLAttributes),
      }}
      onDragStart={onDragStart}
    >
      <NodeViewContent as="span" />
    </ParagraphElement>
  );
};
