import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import Frame, { useFrame } from 'react-frame-component';
import { SpinnerGap } from '@phosphor-icons/react';
import { getUsedFonts, NavbarSerializableNode } from '@shared/dream-components';
import { StyleSheetManager } from 'styled-components';

import { useWebsiteContext } from '@/context/website-context';
import { ATTRIBUTES_PANEL_ID } from '@/routes/website/_components/DreamEditor/constants';
import { cn } from '@/utils/cn';

import { AttributesPanel } from './AttributesPanel/AttributesPanel';
import { NavbarEmptyState } from './NavbarComponents/NavbarEmptyState';
import { DragHandle } from './DragHandle';
import { NavbarProvider, useNavbarContext } from './NavbarContext';
import { SelectionBox } from './SelectionBox';
import { NavbarSerializer } from './serializer';

interface IframeWrapperProps {
  children: ReactNode;
  width: number;
  height: number;
  contentWidth: number;
  iframeProps?: React.IframeHTMLAttributes<HTMLIFrameElement>;
}

const StyleManager = ({ children }: { children: React.ReactNode }) => {
  const { document } = useFrame();
  return (
    <StyleSheetManager target={document?.head}>
      <>{children}</>
    </StyleSheetManager>
  );
};

function generateTextSkeleton(length: number) {
  return Array.from({ length }).map(() => (
    <div
      key={`skeleton-${Math.random().toString(36).substr(2, 9)}`}
      className="flex flex-col w-full p-12 justify-center items-center gap-8"
    >
      <div className="h-8 w-[30%] bg-gray-900/5 rounded-full" />
      <div className="h-4 w-[70%] bg-gray-900/5 rounded-full" />
      <div className="h-4 w-[50%] bg-gray-900/5 rounded-full" />
    </div>
  ));
}

const IframeWrapper = React.forwardRef<HTMLIFrameElement, IframeWrapperProps>(
  ({ children, width, height, iframeProps, contentWidth }, ref) => {
    const { content: draftContent } = useNavbarContext();
    const { previewSiteVersion } = useWebsiteContext();
    const [initialContent, setInitialContent] = useState('');
    const [links, setLinks] = useState<Array<{ rel: string; href: string; tagName: string }>>([]);

    useEffect(() => {
      fetch('/canvas/index.html')
        .then((d) => d.text())
        .then(setInitialContent);
    }, []);

    const content = (previewSiteVersion ? previewSiteVersion?.navbar : draftContent) as NavbarSerializableNode;

    useEffect(() => {
      const getContentFontLinks = async () => {
        const renderableFonts = await getUsedFonts(content);
        const uniqueFonts = Array.from(new Set(renderableFonts));
        setLinks(
          uniqueFonts.map((font) => ({
            rel: 'stylesheet',
            href: font,
            tagName: 'link',
          }))
        );
      };

      getContentFontLinks();
    }, [content]);

    if (!initialContent)
      return (
        <div className="w-full min-h-[400px] flex flex-col justify-center items-center p-16 bg-white">
          <SpinnerGap size={24} className="animate-spin text-wb-accent" />
        </div>
      );

    const scale = width / contentWidth;

    return (
      <Frame
        ref={ref}
        initialContent={initialContent}
        {...iframeProps}
        head={
          <>
            <style>
              {`
                .ProseMirror {
                  outline: none !important;
                }
                .ProseMirror:focus {
                  outline: none !important;
                  box-shadow: none !important;
                }
              `}
            </style>

            {links.map((link) => (
              <link key={link.href} rel={link.rel} href={link.href} />
            ))}
          </>
        }
        title="Page Editor Content"
        mountTarget="body"
        style={{
          transform: `scale(${scale})`,
          transformOrigin: 'top left',
          width: contentWidth,
          height: height / scale,
          border: 'none',
          outline: 'none',
          ...iframeProps?.style,
        }}
      >
        <StyleManager>{children}</StyleManager>
      </Frame>
    );
  }
);

// Custom hook for detecting mobile viewport
const useIsMobile = (iframeRef: React.RefObject<HTMLIFrameElement>) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const currentIframe = iframeRef.current;

    const checkMobile = () => {
      setIsMobile((currentIframe?.contentWindow?.innerWidth || 1024) < 768); // Adjust breakpoint as needed
    };

    checkMobile();
    currentIframe?.contentWindow?.addEventListener('resize', checkMobile);
    return () => currentIframe?.contentWindow?.removeEventListener('resize', checkMobile);
  }, [iframeRef]);

  return isMobile;
};

const NavbarContent = ({ portalRef }: { portalRef: React.RefObject<HTMLDivElement> }) => {
  const { content } = useNavbarContext();
  if (!content || !('content' in content) || content.content?.length === 0) return <NavbarEmptyState />;
  return <NavbarSerializer node={content} portalRef={portalRef} />;
};

const NavbarContentPreview = ({
  iframeRef,
  portalRef,
}: {
  iframeRef: React.RefObject<HTMLIFrameElement>;
  portalRef: React.RefObject<HTMLDivElement>;
}) => {
  const { content } = useNavbarContext();
  const isMobile = useIsMobile(iframeRef);
  if (!content || !('content' in content) || content.content?.length === 0) return <NavbarEmptyState />;
  return <NavbarSerializer node={content} portalRef={portalRef} screen={isMobile ? 'mobile' : 'desktop'} />;
};

export const NavbarEditor = ({
  editorContainerRef,
  editorRect,
  contentWidth = 1024,
  isPreview = false,
}: {
  editorContainerRef: React.RefObject<HTMLDivElement>;
  editorRect: Partial<DOMRect>;
  contentWidth: number;
  isPreview?: boolean;
}) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const portalRef = useRef<HTMLDivElement>(null);
  const attributesPanelEl = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    attributesPanelEl.current = document.getElementById(ATTRIBUTES_PANEL_ID) as HTMLDivElement;
  }, []);

  return (
    <NavbarProvider iframeRef={iframeRef} editorContainerRef={editorContainerRef} contentWidth={contentWidth}>
      <IframeWrapper
        width={editorRect.width || 0}
        height={editorRect.height || 0}
        ref={iframeRef}
        contentWidth={contentWidth}
      >
        <div
          className="grow relative overflow-y-auto min-h-0 h-screen max-h-[1000px] w-full flex flex-col justify-start items-center cursor-auto"
          id="scrollable-container"
          style={{
            backgroundColor: 'white',
          }}
        >
          <div className={cn('dream-navbar-editor w-full justify-center', isPreview ? 'hidden' : 'flex')}>
            <NavbarContent portalRef={portalRef} />
            <div ref={portalRef} id="portal-ref" className="z-50" />
          </div>
          <div className={cn('w-full justify-center', isPreview ? 'flex' : 'hidden')}>
            <NavbarContentPreview iframeRef={iframeRef} portalRef={portalRef} />
          </div>
          <div className="flex flex-col w-full flex-1">{generateTextSkeleton(10)}</div>
        </div>
      </IframeWrapper>
      <SelectionBox />
      <DragHandle />

      <div>{attributesPanelEl.current && createPortal(<AttributesPanel />, attributesPanelEl.current)}</div>
    </NavbarProvider>
  );
};
