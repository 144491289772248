import React, { useCallback } from 'react';
import { Cards, CaretRight } from '@phosphor-icons/react';

import { Text } from '../../../../UI/Text';
import { AttributeSettingProps } from '../../types';

type Props = AttributeSettingProps & {};

export const PostsSelectionSettings = ({ editor, activeNodeResult }: Props) => {
  const { activeNodeAttributes, activeNodePos } = activeNodeResult;

  const handleUpdate = useCallback(() => {
    if (!activeNodeAttributes) return;

    editor.commands.command(({ tr }) => {
      tr.setNodeAttribute(activeNodePos, 'insertedFromSidebar', 'true');
      return true;
    });
  }, [editor, activeNodeAttributes, activeNodePos]);

  return (
    <div className="flex items-center justify-stretch gap-2">
      <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
        Select
      </Text>

      <button type="button" className="grow bg-wb-secondary rounded-lg shadow-sm cursor-pointer" onClick={handleUpdate}>
        <div className="w-full justify-between flex items-center gap-2 p-2 ">
          <div className="flex items-center gap-1">
            <Cards className="text-wb-secondary" weight="bold" />
            <Text
              size="2xs"
              weight="medium"
              className="whitespace-nowrap overflow-hidden overflow-ellipsis pr-2 max-w-[80px]"
            >
              Select posts
            </Text>
          </div>

          <CaretRight className="text-wb-secondary" weight="bold" />
        </div>
      </button>
    </div>
  );
};
