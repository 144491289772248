import React from "react";

import { Switch } from "@/components/Form";
import LoadingBox from "@/components/LoadingBox";
import { useOrganization, useUpdateOrganization } from "@/hooks";
import { PlanPrice } from "@/interfaces/plan_price";
import { PLAN } from "@/utils/plans";

interface Props {
  organizationId: string;
  publicationId: string;
  currentPlanPrice?: PlanPrice;
  upcomingPlanPrice?: PlanPrice;
}

const AutoUpgradeToggle: React.FC<Props> = ({
  organizationId,
  publicationId,
  currentPlanPrice,
  upcomingPlanPrice,
}) => {
  const {data, isLoading, isError} = useOrganization(organizationId, publicationId);
  const organizationMutation = useUpdateOrganization(organizationId, publicationId);

  const isPendingDowngrade = currentPlanPrice?.id !== upcomingPlanPrice?.id;

  if (!data?.organization?.auto_upgrade_enabled || !currentPlanPrice || !upcomingPlanPrice || currentPlanPrice.plan_name === PLAN.LAUNCH || currentPlanPrice.plan_name === PLAN.CUSTOM) {
    return null;
  }

  // Optimistic UI for changing the switch state while it's loading
  const autoUpgrade = organizationMutation.isLoading
    ? organizationMutation.variables.auto_upgrade_plan_tier
    : !!data?.organization?.auto_upgrade_plan_tier;

  return (
    <LoadingBox isLoading={isLoading} isError={isError} height={100} backgroundClassName="bg-transparent">
      <Switch
        name="auto_upgrade_tiers"
        checked={isPendingDowngrade ? false : autoUpgrade}
        onChange={() => organizationMutation.mutate({auto_upgrade_plan_tier: !autoUpgrade})}
        labelText="Auto-upgrade my plan tier when I reach my subscriber limit"
        helperText="You will automatically be charged a prorated amount for the next tier of your current plan when you surpass your current subscriber limit. By opting out, sends to subscribers may be delayed if plan limits are exceeded."
        disabled={isPendingDowngrade}
        disabledText="You cannot be auto-upgraded to the next tier while there is a pending plan change."
      />
    </LoadingBox>
  );
}

export default AutoUpgradeToggle;