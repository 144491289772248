import React, { useEffect, useState } from 'react';

import { Input, Textarea } from '@/components/Form';
import SlideOver from '@/components/SlideOver';
import { PublicationSubscriberTagParams } from '@/interfaces/subscriber_tag';
import { Button } from '@/ui/Button';
import { Dropdown } from '@/ui/Dropdown';

interface FormProps {
  description: string;
  subscriberTag?: PublicationSubscriberTagParams | null;
  onSuccess: () => void;
  bodyId: string;
  isOpen: boolean;
  onClose: () => void;
  headerText: string;
  handleSubmit: (data: any) => void;
}

const Form: React.FunctionComponent<FormProps> = ({
  description,
  subscriberTag,
  onSuccess,
  bodyId,
  isOpen,
  onClose,
  headerText,
  handleSubmit,
}) => {
  const colorChoices = [
    { label: 'Default', value: 'gray', colorClassName: 'gray' },
    { label: 'Purple', value: 'purple', colorClassName: 'purple' },
    { label: 'Pink', value: 'pink', colorClassName: 'pink' },
    { label: 'Red', value: 'red', colorClassName: 'red' },
    { label: 'Blue', value: 'blue', colorClassName: 'blue' },
    { label: 'Yellow', value: 'yellow', colorClassName: 'yellow' },
    { label: 'Green', value: 'green', colorClassName: 'green' },
  ];

  const [name, setName] = useState(subscriberTag?.name || '');
  const [color, setColor] = useState(subscriberTag?.color || '');
  const [subscriberEmails, setSubscriberEmails] = useState(subscriberTag?.subscriber_emails || '');

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (subscriberTag) {
      setName(subscriberTag.name || '');
      setColor(subscriberTag.color || '');
      setSubscriberEmails(subscriberTag.subscriber_emails || '');
    }
  }, [subscriberTag]);

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    const subscriberTagParams: PublicationSubscriberTagParams = {
      name,
      color,
      subscriber_emails: subscriberEmails,
    };

    try {
      await handleSubmit(subscriberTagParams);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      onSuccess();
    }
  };

  const handleClose = () => {
    setName('');
    setColor('');
    setSubscriberEmails('');
    onClose();
  };

  const form = () => {
    return (
      <section>
        <p className="text-xs text-gray-600 mb-4">{description}</p>
        <form onSubmit={onSubmit}>
          <div>
            <Input
              name="name"
              className="mb-6"
              labelText="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Paid Subscriber"
              required
            />
            <Dropdown
              name="color"
              labelText="Color"
              onSelect={(fieldName: string, value: string) => setColor(value)}
              value={color}
              options={colorChoices}
              helperText="Choose a color for your tag."
              required
            />
            <Textarea
              name="subscriber_emails"
              value={subscriberEmails}
              onChange={(e) => setSubscriberEmails(e.target.value)}
              labelText="Subscriber Emails"
              placeholderText="email1@beehiiv.com, email2@beehiiv.com, email3@beehiiv.com, etc."
              className="mt-6"
              helperText="Add subscribers you want to add this tag to and separate emails with a comma."
            />
          </div>
        </form>
      </section>
    );
  };

  const loadingHeader = () => {
    return (
      <header className="flex justify-between items-center">
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900 ml-0">Loading...</h3>
        </div>
      </header>
    );
  };

  const actions = () => {
    return (
      <>
        <Button type="button" variant="primary-inverse" onClick={handleClose} className="mr-2">
          Cancel
        </Button>
        <Button type="submit" variant="primary" loading={loading} onClick={onSubmit}>
          Save
        </Button>
      </>
    );
  };

  return (
    <SlideOver
      bodyId={bodyId}
      isOpen={isOpen}
      onClose={() => {
        handleClose();
      }}
      headerText={headerText}
      actionsChildren={subscriberTag ? actions() : ''}
    >
      {subscriberTag ? form() : loadingHeader()}
    </SlideOver>
  );
};

export default Form;
