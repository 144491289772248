import { Fragment, useMemo, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { SparklesIcon } from '@heroicons/react/20/solid';

import { ImageSelect, Input, Textarea } from '@/components/Form';
import { SortableList, SortableListItem, useSortableList } from '@/components/SortableList';
import TabNavigation from '@/components/TabNavigation';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useTiers } from '@/hooks/useTiers';
import { PaywallStyle } from '@/interfaces/paywall';
import { PremiumOffer } from '@/interfaces/premium_offer';
import { Tier } from '@/interfaces/tier';
import ColorInput from '@/routes/website_builder/_components/Form/ColorInput';
import SelectInput from '@/routes/website_builder/_components/Form/SelectInput';
import SwitchInput from '@/routes/website_builder/_components/Form/SwitchInput';
import { Button } from '@/ui/Button';
import { Dropdown } from '@/ui/Dropdown';

import usePremiumOffers from '../../_hooks/usePremiumOffers';

interface FormPanelProps {
  name: string;
  setName: (name: string) => void;
  subheader: string;
  setSubheader: (subheader: string) => void;
  title: string;
  setTitle: (title: string) => void;
  description: string;
  setDescription: (description: string) => void;
  cta: string;
  style: PaywallStyle;
  setStyle: (style: PaywallStyle) => void;
  setCta: (cta: string) => void;
  features: string[];
  setFeatures: (features: string[]) => void;
  offerId: string;
  setOfferId: (offerId: string) => void;
  onSubmit: () => void;
  isSaving: boolean;
  isEditing: boolean;
  imageUrl: string | File;

  setImageUrl: (imageUrl: string | File) => void;
}

type FeatureItem = {
  id: string;
  value: string;
};

const FormPanel = ({
  name,
  setName,
  title,
  setTitle,
  description,
  setDescription,
  cta,
  setCta,
  subheader,
  setSubheader,
  features,
  setFeatures,
  offerId,
  setOfferId,
  onSubmit,
  isSaving,
  isEditing,
  imageUrl,
  setImageUrl,
  style,
  setStyle,
}: FormPanelProps) => {
  const [newFeature, setNewFeature] = useState('');
  const [tab, setTab] = useState<'content' | 'styles'>('content');
  const ctaText = isEditing ? 'Save' : 'Create';
  const savingText = isEditing ? 'Saving...' : 'Creating...';
  const buttonText = isSaving ? savingText : ctaText;

  const { data } = usePremiumOffers();

  const offerOptions = useMemo(() => {
    const offers = data?.pages[0]?.premium_offers || [];
    return [
      { label: 'None', value: '' },
      ...offers.map((offer: PremiumOffer) => ({
        label: offer.name,
        value: offer.id,
      })),
    ];
  }, [data]);

  const [publicationId] = useCurrentPublicationState();
  const tiersQuery = useTiers(publicationId);
  const tiers = tiersQuery.data || [];

  const { sortableList, setSortableList, reStringifyList } = useSortableList<FeatureItem>({
    list: features,
    addIdToListOfStrings: true,
    zeroBasedIndex: false,
  });

  const tabs = useMemo(
    () => [
      {
        name: 'content',
        label: 'Content',
        selected: tab === 'content',
        onSelect: () => setTab('content'),
      },
      {
        name: 'polls',
        label: 'Styles',
        selected: tab === 'styles',
        onSelect: () => setTab('styles'),
      },
    ],
    [tab]
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit();
  };

  const handleSmartFill = (tier: Tier) => {
    setName(`${tier.name} Paywall`);
    setTitle(`Subscribe to ${tier.name} to read the rest.`);
    setDescription(
      `Become a paying subscriber of ${tier.name} to get access to this post and other subscriber-only content.`
    );
    setCta('Upgrade');
    setSubheader('A subscription gets you');
    setFeatures(tier.prices?.[0].features || []);
  };

  return (
    <div className="h-full relative bg-white sm:rounded-r-lg rounded-b-lg">
      <form
        className="space-y-4 h-full no-scrollbar overflow-y-scroll pb-32 sm:rounded-r-lg rounded-b-lg"
        onSubmit={handleSubmit}
      >
        <div className="p-4 border-b border-gray-200 flex flex-col gap-2">
          <Popover className="relative">
            <Popover.Button className="flex items-center gap-2 text-xs py-1 px-2 rounded-md hover:bg-gray-100 cursor-pointer">
              <SparklesIcon className="w-3 h-3" /> Smart fill from Paid Subscription Tier
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute top-8 left-0 z-10 shadow-lg rounded-md bg-white p-2 w-full max-w-xs border border-gray-200">
                {({ close }) => (
                  <>
                    {tiers.map((tier) => (
                      <div key={tier.id}>
                        <Button
                          type="button"
                          variant="flush"
                          size="xs"
                          className="w-full justify-start !text-left"
                          onClick={() => {
                            handleSmartFill(tier);
                            close();
                          }}
                        >
                          <span className="text-left w-full">{tier.name}</span>
                        </Button>
                      </div>
                    ))}
                  </>
                )}
              </Popover.Panel>
            </Transition>
          </Popover>
          <Input
            name="name"
            labelText="Name"
            type="text"
            value={name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
            helperText="This won't be shown on the paywall"
            required
          />
        </div>

        <div className="px-4">
          <TabNavigation tabs={tabs} variant="tertiary" className="w-fit mb-0" />
        </div>

        {tab === 'content' && (
          <div className="p-4 border-gray-200 flex flex-col gap-4">
            <ImageSelect
              name="image_url"
              labelText="Image"
              onFileClear={() => setImageUrl('')}
              onFileSelect={(payload) => setImageUrl(payload)}
              file={imageUrl}
              type="landscape"
              fullWidth
              placeholderText="browse to upload"
              dimensionSuggestion="use a square aspect ratio for best results"
              publicationId=""
            />
            <Input
              name="title"
              labelText="Title"
              placeholder={`Subscribe to ${name} to read the rest.`}
              type="text"
              value={title}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTitle(e.target.value)}
              required
            />
            <Textarea
              name="description"
              labelText="Description"
              placeholderText={`Become a paying subscriber of ${name} to get access to this post and other subscriber-only content.`}
              value={description}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setDescription(e.target.value)}
              required
            />
            <Input
              name="cta"
              labelText="Button"
              placeholder="Upgrade"
              type="text"
              value={cta}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCta(e.target.value)}
              required
            />
            <Input
              name="subheader"
              labelText="Benefits Header"
              helperText="This will be shown above the list of benefits you add"
              placeholder="A subscription gets you"
              type="text"
              value={subheader}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSubheader(e.target.value)}
            />
            <div className="flex flex-col gap-2">
              <div className="flex gap-2 items-end">
                <Input
                  name="add_feature"
                  className="w-full"
                  labelText="Benefits"
                  type="text"
                  value={newFeature}
                  placeholder="Add a benefit"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewFeature(e.target.value)}
                  onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      if (newFeature.trim() !== '') {
                        setFeatures([...features, newFeature]);
                        setNewFeature('');
                      }
                    }
                  }}
                />
                <Button
                  type="button"
                  variant="primary-inverse"
                  className="h-10"
                  disabled={!newFeature.trim()}
                  onClick={() => {
                    if (newFeature.trim() !== '') {
                      setFeatures([...features, newFeature]);
                      setNewFeature('');
                    }
                  }}
                >
                  Add
                </Button>
              </div>
              <SortableList
                listItems={sortableList}
                onItemsSorted={(sortedList: any[]) => {
                  setSortableList(sortedList);
                  const reStringifiedList = reStringifyList(sortedList);
                  setFeatures(reStringifiedList);
                }}
              >
                {(list) => {
                  return list.map((item: FeatureItem) => {
                    return (
                      <SortableListItem
                        key={item.id}
                        listItem={item}
                        onRemoveItem={(featureItem: FeatureItem) => {
                          const newList = list.filter((feature: FeatureItem) => feature.id !== featureItem.id);
                          setSortableList(newList);
                          const reStringifiedList = reStringifyList(newList);
                          setFeatures(reStringifiedList);
                        }}
                        text={item.value}
                      />
                    );
                  });
                }}
              </SortableList>
            </div>

            <Dropdown
              name="offer_id"
              labelText="Offer"
              value={offerId}
              onSelect={(_, value) => setOfferId(value)}
              options={offerOptions}
              helperText="If selected, this offer will be applied when redirected to the upgrade page through this paywall."
            />
          </div>
        )}
        {tab === 'styles' && (
          <div className="p-4 border-gray-200 flex flex-col gap-4">
            <SwitchInput
              key="show_image"
              label="Show Image"
              name="show_image"
              enabled={style.show_image}
              onSave={(value: boolean) => {
                setStyle({ ...style, show_image: value });
              }}
            />
            <ColorInput
              label="Background Color"
              resettable={{
                label: 'Factory Default',
                resetValue: '#F9FAFB',
                check: false,
              }}
              color={style.background_color}
              onSave={(value: string) => setStyle({ ...style, background_color: value })}
              defaultColors={[]}
            />
            <ColorInput
              label="Text Color"
              resettable={{
                label: 'Factory Default',
                resetValue: '#F9FAFB',
                check: false,
              }}
              color={style.text_color}
              onSave={(value: string) => setStyle({ ...style, text_color: value })}
              defaultColors={[]}
            />
            <ColorInput
              label="Button Background Color"
              resettable={{
                label: 'Factory Default',
                resetValue: '#F9FAFB',
                check: false,
              }}
              color={style.button_background_color}
              onSave={(value: string) => setStyle({ ...style, button_background_color: value })}
              defaultColors={[]}
            />
            <ColorInput
              label="Button Text Color"
              resettable={{
                label: 'Factory Default',
                resetValue: '#F9FAFB',
                check: false,
              }}
              color={style.button_text_color}
              onSave={(value: string) => setStyle({ ...style, button_text_color: value })}
              defaultColors={[]}
            />
            <SelectInput
              label="Shadow"
              value={style.container_shadow}
              tooltip={{
                id: 'shadow',
                text: 'Only applied on the web view.',
              }}
              options={[
                {
                  label: 'None',
                  value: '0 0 #00000000',
                },
                {
                  label: 'Sm',
                  value: '0 1px 2px 0 #0000000D',
                },
                {
                  label: 'Md',
                  value: '0 1px 3px 0 #0000001A, 0 1px 2px -1px #0000001A',
                },
                {
                  label: 'Lg',
                  value: '0 10px 15px -3px #0000001A, 0 4px 6px -4px #0000001A',
                },
                {
                  label: 'Xl',
                  value: '0 20px 25px -5px #0000001A, 0 8px 10px -6px #0000001A',
                },
                {
                  label: '2xl',
                  value: '0 25px 50px -12px #00000040',
                },
              ]}
              onSave={(value: string) => setStyle({ ...style, container_shadow: value })}
            />
          </div>
        )}
        <div className="flex justify-end absolute bottom-0 right-0 p-4 bg-white border-t rounded-b-lg sm:rounded-br-lg sm:rounded-bl-none border-gray-200 w-full overflow-hidden">
          <Button type="submit" loading={isSaving}>
            {buttonText}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default FormPanel;
