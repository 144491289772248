import { Editor, Extension } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { EditorView } from '@tiptap/pm/view';

export interface HoverOptions {
  /**
   * The class name that should be added to the focused node.
   * @default 'has-focus'
   * @example 'is-focused'
   */
  className: string;

  /**
   * The mode by which the focused node is determined.
   * - All: All nodes are marked as focused.
   * - Deepest: Only the deepest node is marked as focused.
   * - Shallowest: Only the shallowest node is marked as focused.
   *
   * @default 'all'
   * @example 'deepest'
   * @example 'shallowest'
   */
  mode: 'all' | 'deepest' | 'shallowest';
}

type HoverStorage = {
  hoverTimeout: NodeJS.Timeout | null;
  rect: DOMRect | null;
  node: Element | null;
  pos: number | null;
};

const handleDropFromInsertPanel = (editor: Editor, view: EditorView, event: DragEvent) => {
  const jsonString = event.dataTransfer?.getData('text/plain');

  try {
    const json = JSON.parse(jsonString || '');

    const pos = view.posAtCoords({
      left: event.clientX,
      top: event.clientY,
    })?.pos;

    if (typeof pos === 'number' && pos >= 0 && json.type === 'block' && json.block) {
      editor.chain().insertContentAt(pos, json.block).focus().run();

      return true;
    }
  } catch {
    // no op
  }

  return false;
};

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    hover: {
      setHoveredNode: (options: { node: Element; pos: number }) => ReturnType;
    };
  }
}

/**
 * This extension allows you to add a class to the focused node.
 * @see https://www.tiptap.dev/api/extensions/focus
 */
export const Hover = Extension.create<HoverOptions, HoverStorage>({
  name: 'hover',

  addOptions() {
    return {
      className: 'dream-hovering',
      mode: 'all',
    };
  },

  addStorage() {
    return {
      hoverTimeout: null,
      rect: null,
      node: null,
      pos: null,
    };
  },

  addCommands() {
    return {
      setHoveredNode:
        ({ node, pos }) =>
        () => {
          this.storage.node = node;
          this.storage.rect = node.getBoundingClientRect();
          this.storage.pos = pos;
          return true;
        },
    };
  },

  addProseMirrorPlugins() {
    const { editor } = this;

    return [
      new Plugin({
        key: new PluginKey('hover'),
        props: {
          handleDrop(view, event) {
            return handleDropFromInsertPanel(editor, view, event);
          },
          handleDOMEvents: {
            mouseover: (view, event) => {
              const posDetails = view.posAtCoords({
                left: event.clientX,
                top: event.clientY,
              });

              if (!posDetails) return;

              const { inside } = posDetails;

              if (inside < 0) return;

              const nodeDom = (view.nodeDOM(inside) as Element).firstElementChild;

              if (nodeDom) {
                editor.commands.setHoveredNode({
                  node: nodeDom,
                  pos: inside,
                });
              }
            },
          },
        },
      }),
    ];
  },
});
