import React, { createContext, PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { NavbarSerializableNode } from '@shared/dream-components'

import { useWebsiteContext } from "@/context/website-context";
import { useBeforeNavigate } from '@/hooks/useBeforeNavigate';
import { useUpdateSiteVersion } from "@/hooks/useSiteVersion";
import useCreateSiteVersion from "@/hooks/useSiteVersion/useCreateSiteVersion";

export type NavbarDataContextType = {
  content: NavbarSerializableNode | undefined;
  onUpdateContent: (content: NavbarSerializableNode) => void;
  changesMade: boolean;
  onSave: () => void;
  isSaveLoading: boolean;
  onSaveToVersionHistory: (isDraft: boolean, versionName: string) => Promise<void>;
  isSaveVersionLoading: boolean;
};
export const NavbarDataContext = createContext<NavbarDataContextType | undefined>(undefined);

export const NavbarDataProvider = ({ children }: PropsWithChildren) => {
  const { site, previewSiteVersion } = useWebsiteContext();
  const [content, setContent] = useState<NavbarSerializableNode | undefined>(undefined);
  const [changesMade, setChangesMade] = useState(false);

  const {
    mutate: updateSiteVersion,
    mutateAsync: updateSiteVersionAsync,
    isLoading: isSaveLoading,
  } = useUpdateSiteVersion({
    id: site?.draft_site_version?.id || '',
  });

  const { isLoading: isSaveVersionLoading, mutateAsync: createSiteVersionAsync } = useCreateSiteVersion({});

  useEffect(() => {
    if (site?.draft_site_version) {
      setContent(site.draft_site_version.navbar as NavbarSerializableNode);
    }
  }, [site?.draft_site_version]);

  const onUpdateContent = useCallback((c: NavbarSerializableNode) => {
    setContent(c);
    setChangesMade(true);
  }, []);

  const onSave = useCallback(() => {
    if (previewSiteVersion) return; // don't save if previewing other versions
    if (!changesMade) return; // don't save if no changes
    updateSiteVersion({ navbar: content });
    setChangesMade(false);
  }, [updateSiteVersion, content, changesMade, previewSiteVersion]);


  const onSaveToVersionHistory = useCallback(
    async (autoSave: boolean = false, versionName?: string): Promise<void> => {
      if (previewSiteVersion) return; // don't save if previewing other versions

      await updateSiteVersionAsync({ navbar: content });
      setChangesMade(false);
      await createSiteVersionAsync({
        version_name: versionName,
        version_type: autoSave ? 'autosave' : 'usersave',
      });
    },
    [updateSiteVersionAsync, createSiteVersionAsync, content, previewSiteVersion]
  );

  // Auto save every 10 seconds
  useEffect(() => {
    const autoSaveInterval = setInterval(() => {
      if (changesMade) {
        onSave();
      }
    }, 10000); // 10 seconds

    return () => {
      clearInterval(autoSaveInterval);
    };
  }, [changesMade, onSave]);

  // Auto save Version History every 10 minutes
  useEffect(() => {
    const autoSaveInterval = setInterval(() => {
      onSaveToVersionHistory(true);
    }, 60000); // 10 minutes

    return () => {
      clearInterval(autoSaveInterval);
    };
  }, [changesMade, onSaveToVersionHistory]);

  // Save on CMD + S
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 's') {
        event.preventDefault();
        onSave();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onSave]);

  // Save on navigate away & prompt on reload or leaving page
  useBeforeNavigate(onSave, !!changesMade);

  const contextValue = useMemo(() => ({
    content,
    onUpdateContent,
    changesMade,
    onSave,
    isSaveLoading,
    onSaveToVersionHistory,
    isSaveVersionLoading,
  }), [content, onUpdateContent, changesMade, onSave, isSaveLoading, onSaveToVersionHistory, isSaveVersionLoading]);

  return (
    <NavbarDataContext.Provider value={contextValue}>
      {children}
    </NavbarDataContext.Provider>
  );
};

export const useNavbarDataContext = () => {
  const context = React.useContext(NavbarDataContext);
  if (context === undefined) {
    throw new Error('useNavbarDataContext must be used within a NavbarDataProvider');
  }
  return context;
};
