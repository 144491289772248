import React, { useState } from 'react';

import ActionModal from '@/components/ActionModal';
import { PlanPrice, PlanPriceOption } from '@/interfaces/plan_price';

import IntervalSelect from './IntervalSelect';
import Stepper from './Stepper';
import TierChangeInfo from './TierChangeInfo';
import TierSelect from './TierSelect';

interface Props {
  isOpen: boolean;
  isWorking: boolean;
  onClose: () => void;
  onSubmit: (newPlanPriceId: string) => void;
  organizationId: string;
  publicationId: string;
  currentPlanPrice: PlanPrice;
  isAvailableOption?: (option: PlanPriceOption) => boolean;
  desiredPlanName?: PlanPrice['plan_name'];
  desiredInterval?: PlanPrice['interval'];
  allowSettingInterval?: boolean;
}

const ModalTitles = {
  2: ['Select your desired number of subscribers', 'Confirm your updates'],
  3: ['Select your desired billing cadence', 'Select your desired number of subscribers', 'Confirm your updates'],
};

const StepNames = {
  2: ['select_subscribers', 'confirm'],
  3: ['select_cadence', 'select_subscribers', 'confirm'],
};

const TierChangeModal: React.FC<Props> = ({
  isOpen,
  isWorking,
  onClose,
  onSubmit,
  organizationId,
  publicationId,
  currentPlanPrice,
  isAvailableOption,
  desiredPlanName = currentPlanPrice.plan_name,
  desiredInterval = currentPlanPrice.interval,
  allowSettingInterval = false,
}) => {
  const [newPlanPriceId, setNewPlanPriceId] = useState<string | undefined>();
  const [interval, setInterval] = useState(desiredInterval);
  const [step, setStep] = useState(1);

  const totalSteps = allowSettingInterval ? 3 : 2;
  const actionText = step !== totalSteps ? 'Next' : 'Confirm';
  const onProceed = step === totalSteps ? () => onSubmit(newPlanPriceId || '') : () => setStep(step + 1);
  const modalTitle = ModalTitles[totalSteps][step - 1];
  const stepName = StepNames[totalSteps][step - 1];
  const disabled =
    (stepName === 'select_cadence' && !interval) || (stepName === 'select_subscribers' && !newPlanPriceId);

  return (
    <ActionModal
      isOpen={isOpen}
      onClose={onClose}
      resourceId={organizationId}
      headerText={modalTitle}
      onProceed={onProceed}
      isWorking={isWorking}
      actionText={actionText}
      disabled={disabled}
    >
      <div className="pt4">
        {stepName === 'select_cadence' && (
          <IntervalSelect currentPlanPrice={currentPlanPrice} value={interval} onSelect={setInterval} />
        )}

        {stepName === 'select_subscribers' && interval && (
          <TierSelect
            organizationId={organizationId}
            currentPlanPrice={currentPlanPrice}
            value={newPlanPriceId}
            onSelect={setNewPlanPriceId}
            isAvailableOption={isAvailableOption}
            interval={interval}
            planName={desiredPlanName}
          />
        )}

        {newPlanPriceId && stepName === 'confirm' && (
          <TierChangeInfo
            organizationId={organizationId}
            publicationId={publicationId}
            currentPlanPrice={currentPlanPrice}
            newPlanPriceId={newPlanPriceId}
          />
        )}
        <Stepper step={step} totalSteps={totalSteps} />
      </div>
    </ActionModal>
  );
};

export default TierChangeModal;
