import { useWebsiteContext } from '@/context/website-context';

import SectionRenderer from '../../helpers/SectionRenderer';
import { RenderCondition } from '../../RenderCondition';
import { AttributeSettingProps } from '../../types';
import BorderSettings from '../BorderSettings';
import { ColorSettings } from '../ColorSettings';
import { GapSettings } from '../GapSettings';
import { IconSettings } from '../IconSettings';
import { OrientationSettings } from '../OrientationSettings';
import { PaddingSettings } from '../PaddingSettings';
import { SignupFlowSettings } from '../SignupFlowSettings';
import { SimpleNumberSettings } from '../SimpleNumberSettings';
import { SimpleTextSettings } from '../SimpleTextSettings';

export const SignupWidgetSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  const { site } = useWebsiteContext();
  const isTermsOfServiceEnabled = site?.draft_site_version?.settings?.terms_of_service_enabled;

  return (
    <RenderCondition editor={editor} allowedNodeTypes={['signup']}>
      <SectionRenderer title="Signup Flow">
        <SignupFlowSettings editor={editor} activeNodeResult={activeNodeResult} />
      </SectionRenderer>
      {isTermsOfServiceEnabled && (
        <SectionRenderer title="Terms of Service">
          <ColorSettings
            title="Checkbox"
            property="gdprCheckboxColor"
            editor={editor}
            activeNodeResult={activeNodeResult}
          />
          <ColorSettings title="Text" property="gdprTextColor" editor={editor} activeNodeResult={activeNodeResult} />
        </SectionRenderer>
      )}
      <SectionRenderer title="Container">
        <OrientationSettings editor={editor} activeNodeResult={activeNodeResult} />
        <BorderSettings editor={editor} activeNodeResult={activeNodeResult} />
        <PaddingSettings editor={editor} activeNodeResult={activeNodeResult} property="padding" />
        <ColorSettings
          title="Background"
          property="backgroundColor"
          editor={editor}
          activeNodeResult={activeNodeResult}
        />
        <GapSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="gap"
          title="Gap"
          iconDirection="horizontal"
        />
        <SimpleNumberSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="width"
          title="Width"
          unit="px"
          max={1200}
        />
        <SimpleNumberSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="height"
          title="Height"
          unit="px"
          max={1200}
        />
      </SectionRenderer>
      <SectionRenderer title="Button">
        <BorderSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          properties={{
            color: 'buttonBorderColor',
            style: 'buttonBorderStyle',
            width: 'buttonBorderWidth',
            radius: 'buttonBorderRadius',
          }}
        />
        <SimpleTextSettings editor={editor} activeNodeResult={activeNodeResult} property="buttonText" title="Text" />
        <ColorSettings editor={editor} activeNodeResult={activeNodeResult} property="buttonTextColor" title="Color" />
        <SimpleTextSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="buttonSubmittingText"
          title="Submitting"
        />
        <ColorSettings
          title="Color"
          property="buttonBackgroundColor"
          editor={editor}
          activeNodeResult={activeNodeResult}
        />
      </SectionRenderer>
      <SectionRenderer title="Input">
        <BorderSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          properties={{
            color: 'inputBorderColor',
            style: 'inputBorderStyle',
            width: 'inputBorderWidth',
            radius: 'inputBorderRadius',
          }}
        />
        <SimpleTextSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="inputPlaceholder"
          title="Placeholder"
        />
        <ColorSettings title="Text" property="inputTextColor" editor={editor} activeNodeResult={activeNodeResult} />
        <ColorSettings
          title="Placeholder"
          property="inputPlaceholderColor"
          editor={editor}
          activeNodeResult={activeNodeResult}
        />
        <ColorSettings
          title="Background"
          property="inputBackgroundColor"
          editor={editor}
          activeNodeResult={activeNodeResult}
        />
        <IconSettings editor={editor} activeNodeResult={activeNodeResult} />
        <ColorSettings title="Icon Color" property="iconColor" editor={editor} activeNodeResult={activeNodeResult} />
      </SectionRenderer>
    </RenderCondition>
  );
};
