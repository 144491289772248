export enum RSSCTAStyle {
  BUTTON = 'CTA button',
  LINK = 'CTA link',
  TITLE = 'Title as link',
  THUMBNAIL = 'Thumbnail as link',
}

export interface RSSData {
  id: string;
  url: string;
  name: string;
  content: RSSEntry[];
}

export interface RSSEntry {
  id: string;
  link: string;
  original_title?: string;
  title: string;
  author: string;
  content: string;
  categories: string[];
  post_id: string;
  pub_date: string;
  description: string;
  thumbnail?: string;
}

export interface RSSSettings {
  entriesToShow: number;
  ctaText: string;
  displayTitle: boolean;
  displayCta: boolean;
  displayAuthor: boolean;
  displayContent: boolean;
  displayDescription: boolean;
  displayPubDate: boolean;
  displayThumbnail: boolean;
  displayTitleAboveThumbnail: boolean;
  displayCategories: boolean;
  ctaStyle: RSSCTAStyle;
}

export interface ExternalRssFeed extends RSSSettings {
  id?: string;
  name: string;
  url: string;
}

export interface ExternalRssFeedApiPayload {
  url: string;
  name: string;
  entries_to_show: number;
  cta_text: string;
  display_title: boolean;
  display_cta: boolean;
  display_author: boolean;
  display_content: boolean;
  display_description: boolean;
  display_pub_date: boolean;
  display_thumbnail: boolean;
  display_title_above_thumbnail: boolean;
  display_categories: boolean;
  cta_style: RSSCTAStyle;
}

export interface ExternalRssFeedApiResponse extends ExternalRssFeedApiPayload {
  id: string;
  content?: RSSEntry[];
}
