import { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import { LinkIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

import PageHeading from '@/components/Layout/PageLayout/PageHeading';
import TabNavigation from '@/components/TabNavigation';
import { useCurrentUser } from '@/context/current-user-context';
import { useSettings } from '@/context/settings-context';
import { useCopyToClipboard } from '@/hooks';
import usePartnerDetails from '@/hooks/usePartner';
import usePartnerCommissions from '@/hooks/usePartnerCommissions';
import usePartnerLeaderboard from '@/hooks/usePartnerLeaderboard';
import usePartnerTier from '@/hooks/usePartnerTier';
import usePartnerTierSettings from '@/hooks/usePartnerTierSettings';
import useUpdatePartnerProgramDetails from '@/hooks/useUpdatePartnerProgramDetails';
import { PartnerTierSetting } from '@/interfaces/partner_tier';
import { PartnerProgramTiers } from '@/pages/PartnerProgram/PartnerTiers';
import { Card } from '@/ui/Card';
import { MetricCard } from '@/ui/MetricCard';

import PartnerProgramAnnouncementModal from './AnnouncementModal';
import { PartnerProgramDisabled } from './Disabled';
import PartnerProgramFAQ from './FAQ';
import PartnerProgramLeaderboard from './Leaderboard';
import Links from './Links';
import PartnerProgramResources from './Resources';
import PartnerProgramStartHere from './StartHere';

const PartnerProgram = () => {
  const { settings } = useSettings();

  const [selectedTab, setSelectedTab] = useState<string>('start_here');
  const [showConfetti, setShowConfetti] = useState<boolean>(false);
  const copy = useCopyToClipboard();
  const { data: partnerDetails, isLoading, refetch }: any = usePartnerDetails();
  const { data: commissions, isLoading: isCommissionsLoading } = usePartnerCommissions();
  const { data: leaderboardData }: any = usePartnerLeaderboard();
  const { data: tiers, isLoading: isTiersLoading } = usePartnerTier();
  const { data: tierSettings } = usePartnerTierSettings();

  const { currentUser } = useCurrentUser();

  const { mutate: updateDetails } = useUpdatePartnerProgramDetails(true);

  useEffect(() => {
    if (tiers && tiers.tiers.current.app_celebration_due) {
      setShowConfetti(true);
      updateDetails({app_celebration_due: false});
    }
  }, [tiers, updateDetails]);

  if (currentUser?.partner_program_disabled) {
    return <PartnerProgramDisabled />;
  }

  const tabs = [
    {
      name: 'start_here',
      label: 'Start Here',
      selected: selectedTab === 'start_here',
      onSelect: () => setSelectedTab('start_here'),
    },
    ...(settings?.partner_program_tiers_tab
      ? [
          {
            name: 'tiers',
            label: 'Tiers',
            selected: selectedTab === 'tiers',
            onSelect: () => setSelectedTab('tiers'),
          },
        ]
      :[]),
    {
      name: 'links',
      label: 'Links',
      selected: selectedTab === 'links',
      onSelect: () => setSelectedTab('links'),
    },
    {
      name: 'resources',
      label: 'Resources',
      selected: selectedTab === 'resources',
      onSelect: () => setSelectedTab('resources'),
    },
    ...(window?.env?.REACT_APP_PARTNER_LEADERBOARD_ENABLED === 'true'
      ? [
          {
            name: 'leaderboard',
            label: 'Leaderboard',
            selected: selectedTab === 'leaderboard',
            onSelect: () => setSelectedTab('leaderboard'),
          },
        ]
      : []),
    {
      name: 'faq',
      label: 'FAQ',
      selected: selectedTab === 'faq',
      onSelect: () => setSelectedTab('faq'),
    },
  ];

  const tabComponent: any = {
    start_here: <PartnerProgramStartHere />,
    tiers: <PartnerProgramTiers tiers={tiers?.tiers} settings={tierSettings?.partner_tier_settings} />,
    resources: <PartnerProgramResources />,
    leaderboard: <PartnerProgramLeaderboard leaderboardRows={leaderboardData?.leaderboard_details} />,
    faq: <PartnerProgramFAQ />,
    links: <Links refetch={refetch} links={partnerDetails?.metrics?.links} />,
  };

  const handleCopy = () => {
    copy({ text: partnerDetails?.details?.link, onSuccessText: 'Copied to clipboard!' });
  };

  const formatter = new Intl.NumberFormat('en');
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: 2,
  });

  const commissionLevel = settings?.partner_program_tiers_tab &&
    tierSettings?.partner_tier_settings.find((setting: PartnerTierSetting) =>
      setting.name === tiers?.tiers.current.name
    )?.commission || "50";

  const cardWidth = settings?.partner_program_tiers_tab ? "lg:w-1/4" : "lg:w-1/3";

  return (
    <>
      {showConfetti && <Confetti />}
      <PageHeading
        title="Partner Program"
        description="Earn commissions by simply introducing new customers to beehiiv"
      />
      <PartnerProgramAnnouncementModal />
      <div className="flex flex-col lg:flex-row w-full space-y-2 lg:space-y-0 lg:space-x-2">
        <div className="flex flex-col space-y-2 w-full lg:w-3/4">
          <div className="flex flex-col lg:flex-row w-full space-y-2 lg:space-y-0 lg:space-x-2">
            {settings?.partner_program_tiers_tab && (
              <div className={cx("w-full", cardWidth)}>
                <MetricCard
                  variant="primary"
                  isLoading={isTiersLoading}
                  label="Current Tier"
                  value={
                    isTiersLoading
                      ? 'Loading...'
                      : `<div class="flex items-center gap-3 mt-1"><img class="h-[32px] w-auto" src="/images/partner-tier-${tiers?.tiers.current.name}.svg" /><span class="capitalize !text-base">${tiers?.tiers.current.name}</span></div>`
                  }
                  tooltip="Your Partner Rewards Tier."
                  className="capitalize !bg-white"
                />
              </div>
            )}
            <div className={cx("w-full", cardWidth)}>
              <MetricCard
                variant="primary"
                isLoading={isLoading}
                label="Visitors"
                value={formatter.format(partnerDetails?.metrics?.visitors)}
                tooltip="The unique traffic you've sent to our website."
                className="!bg-white"
              />
            </div>
            <div className={cx("w-full", cardWidth)}>
              <MetricCard
                variant="primary"
                isLoading={isLoading}
                label="Leads"
                value={formatter.format(partnerDetails?.metrics?.leads)}
                tooltip="The amount of visitors that have converted into an account signup/trial."
                className="!bg-white"
              />
            </div>
            <div className={cx("w-full", cardWidth)}>
              <MetricCard
                variant="primary"
                isLoading={isLoading}
                label="Conversions"
                value={formatter.format(partnerDetails?.metrics?.conversions)}
                tooltip="The amount of leads that have converted into a paid account."
                className="!bg-white"
              />
            </div>
          </div>
          <Card density="compact">
            <div className="flex flex-col space-y-2">
              <p className="text-gray-900 font-semibold text-sm">Your Partner Link</p>
              <div className="flex flex-row">
                <input
                  placeholder="https://www.beehiiv.com?via=john"
                  disabled
                  value={partnerDetails?.details?.link}
                  className="appearance-none block w-full px-3 border border-surface-200 focus:border-[#EC4899] py-2 rounded-l-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-transparent sm:text-sm h-full disabled:cursor-not-allowed"
                />
                <button
                  className="bg-[#EEF2FF] hover:bg-[#E0E7FF] w-[150px] flex flex-row justify-center items-center space-x-1 rounded-r-md border border-[#C7D2FE]"
                  type="button"
                  onClick={handleCopy}
                >
                  <LinkIcon className="text-[#4338CA] h-4 w-4 my-auto" />
                  <p className="text-xs lg:text-sm text-[#4338CA] font-medium my-auto">Share & Earn</p>
                </button>
              </div>
              {commissionLevel && (
                <p className="text-gray-900 font-light text-xs">
                  You earn {commissionLevel}% commission for 12 months. Your audience receives a 30-day trial + 20% OFF for 3 months.
                </p>
              )}
            </div>
          </Card>
        </div>
        <div className="w-full lg:w-1/4">
          <MetricCard
            variant="secondary"
            isLoading={isCommissionsLoading}
            className="h-full"
            label="Paid Commissions"
            tooltip="Payouts that have successfully been paid. Thank you!"
            value={isCommissionsLoading ? 'Loading...' : currencyFormatter.format(commissions?.paid ?? 0)}
            initialVisibleComplements={3}
            complements={
              isCommissionsLoading
                ? []
                : [
                    {
                      label: 'Pending',
                      value: currencyFormatter.format(commissions?.pending ?? 0),
                      tooltipText: 'Payouts currently in our 30-day provisioning window',
                    },
                    {
                      label: 'Due',
                      value: currencyFormatter.format(commissions?.due ?? 0),
                      tooltipText: 'Payouts that are eligible for our next payout cycle on the 15th',
                    },
                  ]
            }
          />
        </div>
      </div>
      <TabNavigation tabs={tabs} variant="primary" className="pt-4 pb-4" />
      {tabComponent[selectedTab]}
    </>
  );
};

export default PartnerProgram;
