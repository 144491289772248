import { useEffect, useRef, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { ArrowLeft, ClockCounterClockwise, Plus } from '@phosphor-icons/react';

import { useWebsiteContext, useWebsitePageRouteGetter, WebsiteProvider } from '@/context/website-context';
import { cn } from '@/utils/cn';

import { ATTRIBUTES_PANEL_ID, VERSION_HISTORY_PANEL_ID } from '../_components/DreamEditor/constants';
import { EditorHeader } from '../_components/EditorHeader/EditorHeader';
import { Layout } from '../_components/Layout';
import { ActionMenu } from '../_components/Layout/ActionMenu';
import { NavSection } from '../_components/SideNav/NavSection';
import { SaveNavbarIndicator } from '../_components/SideNav/SaveIndicator';
import { Button, CloseButton } from '../_components/UI/Button';
import { Tabs, TabsList, TabsTrigger } from '../_components/UI/Tabs';
import { Text } from '../_components/UI/Text';
import VersionHistoryNotice from '../_components/VersionHistoryNotice';

import { NavbarDataProvider } from './_components/NavbarEditor/NavbarDataContext';
import { NavbarEditor } from './_components/NavbarEditor/NavbarEditor';

const NavbarEditorPage = () => {
  const [searchParams] = useSearchParams();
  const previousPageID = searchParams.get('previousPageID');
  const [isInsertPanelOpen, setIsInsertPanelOpen] = useState(false);
  const { previewSiteVersion, setPreviewSiteVersion } = useWebsiteContext();
  const [isVersionHistoryPanelOpen, setIsVersionHistoryPanelOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState<'desktop' | 'mobile'>('desktop');
  const pageRouteGetter = useWebsitePageRouteGetter();

  const homePageId = pageRouteGetter?.getHomePageID();

  const editorContainerRef = useRef<HTMLDivElement>(null);
  const [editorRect, setEditorRect] = useState<Partial<DOMRect>>({ height: 0, width: 0 });
  useEffect(() => {
    if (!isVersionHistoryPanelOpen) {
      setPreviewSiteVersion(undefined);
    }
  }, [isVersionHistoryPanelOpen, setPreviewSiteVersion]);

  useEffect(() => {
    const editorContainer = editorContainerRef.current;
    const resizeObserver = new ResizeObserver((entries) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const entry of entries) {
        if (entry.contentRect) {
          setEditorRect(entry.contentRect);
        }
      }
    });

    if (editorContainer) {
      // setup listener for element size change
      resizeObserver.observe(editorContainer);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <Layout
      logoDropdownProps={{
        actionText: 'Go To Dashboard',
        backPath: '/',
      }}
      mainClassName="bg-wb-secondary pb-0 pt-0"
      isSidebarResizable
      leftChildren={
        <ActionMenu
          Icon={Plus}
          text="Insert"
          isActive={isInsertPanelOpen}
          onClick={() => {
            setIsInsertPanelOpen((prev) => !prev);
          }}
        />
      }
      rightChildren={
        <Button
          variant="secondary"
          Icon={ClockCounterClockwise}
          iconWeight="bold"
          onClick={() => setIsVersionHistoryPanelOpen((prev) => !prev)}
        />
      }
      sidenavChildren={
        <>
          <div className="flex-1">
            <NavSection>
              <Link to={`/website_builder_v2/page/${previousPageID || homePageId}`} className="w-full cursor-pointer">
                <Button variant="secondary" size="sm" LeftIcon={ArrowLeft} className="w-full py-3">
                  Back to page
                </Button>
              </Link>
            </NavSection>
          </div>
          <NavSection>
            <SaveNavbarIndicator />
          </NavSection>
        </>
      }
      rightSideChildren={
        <>
          <div
            className="w-[250px] min-w-[250px] h-full bg-wb-primary border-l border-solid border-wb-primary overflow-y-scroll"
            id={ATTRIBUTES_PANEL_ID}
          />
          <div
            id={VERSION_HISTORY_PANEL_ID}
            className={cn(
              'absolute top-0 right-0 bottom-0 w-[250px] transition-transform duration-250 bg-wb-primary border-l border-solid overflow-y-auto p-3',
              isVersionHistoryPanelOpen ? 'translate-x-0' : `translate-x-[500px]`
            )}
          >
            <CloseButton
              onClose={() => setIsVersionHistoryPanelOpen(false)}
              className="-translate-x-1/2 translate-y-1/2"
            />
          </div>
        </>
      }
      titleType="page_name"
    >
      <div className="w-full flex flex-col items-center">
        <EditorHeader type="navbar" />
        <Tabs
          defaultValue="desktop"
          className="flex flex-col flex-1"
          value={selectedTab}
          onValueChange={(value) => setSelectedTab(value as 'desktop' | 'mobile')}
        >
          <TabsList className="p-2" defaultValue="desktop">
            <TabsTrigger value="desktop">
              <Text weight="medium" size="2xs" className="leading-0">
                Desktop
              </Text>
            </TabsTrigger>
            <TabsTrigger value="mobile">
              <Text weight="medium" size="2xs" className="leading-0">
                Mobile
              </Text>
            </TabsTrigger>
          </TabsList>
        </Tabs>
        <div
          ref={editorContainerRef}
          className={cn('h-full bg-transparent relative', selectedTab === 'mobile' ? 'w-[400px]' : 'w-full')}
        >
          <NavbarEditor
            editorContainerRef={editorContainerRef}
            editorRect={editorRect}
            contentWidth={selectedTab === 'mobile' ? 400 : 1024}
            isPreview={selectedTab === 'mobile'}
          />
        </div>
      </div>
      {previewSiteVersion?.id && <VersionHistoryNotice />}
    </Layout>
  );
};

export default () => (
  <WebsiteProvider>
    <NavbarDataProvider>
      <NavbarEditorPage />
    </NavbarDataProvider>
  </WebsiteProvider>
);
