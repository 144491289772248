import { AuthorsElement } from '@shared/dream-components';
import { NodeViewProps } from '@tiptap/core';
import { useReactNodeView } from '@tiptap/react';

import { getDataAttributes } from '../../utils';

export const AuthorsView = ({ HTMLAttributes, node }: NodeViewProps) => {
  const { onDragStart } = useReactNodeView();
  return (
    <AuthorsElement
      element={{
        type: 'authors',
        attrs: {
          id: node.attrs.id,
          ...node.attrs,
        },
        content: [{ id: 'text', text: '' }],
      }}
      attributes={{
        'data-node-view-wrapper': '',
        ...getDataAttributes(HTMLAttributes),
      }}
      onDragStart={onDragStart}
    >
      {null}
    </AuthorsElement>
  );
};
