import { Extension } from '@tiptap/core';

import { getMultiNodeSelectionPlugin } from './selections';
import { getNodeSelectorPlugin, getParentSelectorPlugin } from './selectors';

export const CustomSelections = Extension.create({
  name: 'customSelections',

  addProseMirrorPlugins() {
    return [
      // selections
      getMultiNodeSelectionPlugin(),

      // selectors
      getNodeSelectorPlugin(),
      getParentSelectorPlugin(this.editor),
    ];
  },
});
