import { useMemo } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { CaretDown, Plus } from '@phosphor-icons/react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@radix-ui/react-dropdown-menu';

import { useWebsiteContext } from '@/context/website-context';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import useUpdateSitePackage from '@/hooks/useSitePackages/useUpdateSitePackage';
import { useCreateSiteTemplate } from '@/hooks/useSiteTemplates';
import { SitePackage } from '@/interfaces/site_package';
import { SiteTemplate } from '@/interfaces/site_template';

import { Button } from '../../../_components/UI/Button';
import { Switch } from '../../../_components/UI/Switch';
import { Text } from '../../../_components/UI/Text';

interface Props {
  project?: SitePackage;
}

export const TemplatesTopBar = ({ project }: Props) => {
  const queryClient = useQueryClient();
  const { site } = useWebsiteContext();
  const currentPublicationId = useCurrentPublicationId();
  const pageCategories = site?.template_categories?.page;

  const renderablePageCategories = useMemo(() => {
    const pageTemplates = project?.templates
      ?.filter((template: SiteTemplate) => template.level === 'page')
      .map((template: SiteTemplate) => template.category);

    return pageCategories?.filter((category) => !pageTemplates?.includes(category.value));
  }, [project, pageCategories]);

  const createSiteTemplate = useCreateSiteTemplate({
    onSuccess: () => {
      queryClient.invalidateQueries(['publications', currentPublicationId, 'site_packages', project?.id]);
    },
  });

  const updateSitePackage = useUpdateSitePackage({
    id: project?.id || '',
    onSuccess: () => {
      toast.success('Success');
    },
  });

  const handleCreateProject = (category: { value: string; label: string }) => {
    const formData = new FormData();
    formData.append('publication_id', currentPublicationId);
    formData.append('site_template[name]', category.label);
    formData.append('site_template[description]', `${category.label} for ${project?.name}`);
    formData.append('site_template[category]', category.value);
    formData.append(
      'site_template[content]',
      JSON.stringify({
        type: 'doc',
        content: [],
      })
    );
    formData.append('site_template[level]', 'page');
    formData.append('site_template[site_package_id]', project?.id || '');

    createSiteTemplate.mutate(formData);
  };

  const handleToggle = () => {
    updateSitePackage.mutate({
      available_to_public: !project?.available_to_public,
    });
  };

  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-col space-y-2 max-w-[620px]">
        <div className="flex items-center gap-2">
          <Text size="xl" weight="semibold" variant="primary" as="h4">
            {project?.name}
          </Text>
          <Switch checked={project?.available_to_public} onCheckedChange={handleToggle} />
        </div>
      </div>

      <div className="flex gap-2 items-center">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button LeftIcon={Plus} RightIcon={CaretDown} className="gap-2">
              <Text weight="semibold" size="sm" variant="on-accent" className="tracking-tight">
                New Page
              </Text>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            sideOffset={2}
            align="end"
            className="flex flex-col gap-1 rounded-lg bg-white shadow-lg p-4 w-[250px] z-20"
          >
            {renderablePageCategories?.map((option) => (
              <DropdownMenuItem key={option.value} onClick={() => handleCreateProject(option)}>
                <Button variant="ghost" className="w-full justify-start whitespace-nowrap">
                  {option.label}
                </Button>
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
};
