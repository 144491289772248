import { useParams } from 'react-router-dom';

import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';

import BlastInfo from '../../../../components/_domain/EmailMessage/BlastInfo';
import { Breadcrumbs } from '../../../../components/Breadcrumbs';
import LoadingBox from '../../../../components/LoadingBox';
import { useEmailMessage, useSegment } from '../../../../hooks';

const Blast = () => {
  const { emailMessageId, segmentId } = useParams() as unknown as { emailMessageId: string; segmentId: string };

  const currentPublicationId = useCurrentPublicationId();
  const {
    data: emailMessage,
    isLoading: isLoadingMessage,
    isError: isErrorMessage,
  } = useEmailMessage(currentPublicationId, { emailMessageId });

  const { data: segment, isLoading: isLoadingSegment, isError: isErrorSegment } = useSegment({ segmentId });

  const subjectLine = emailMessage?.subject_line || '[No Subject]';
  const segmentName = segment?.name || 'Segment';

  const isLoading = isLoadingMessage || isLoadingSegment;
  const isError = isErrorMessage || isErrorSegment;

  return (
    <LoadingBox isLoading={isLoading} isError={isError}>
      <>
        <Breadcrumbs>
          <Breadcrumbs.Item to="/segments" backLanguage="Back to all segments">
            Segments
          </Breadcrumbs.Item>
          <Breadcrumbs.Item to={`/segments/${segmentId}/blasts`}>{segmentName}</Breadcrumbs.Item>
          <Breadcrumbs.Item to={`/segments/${segmentId}/blasts`}>{subjectLine}</Breadcrumbs.Item>
        </Breadcrumbs>
        <BlastInfo
          emailMessageId={emailMessageId}
          indexPath={`/segments/${segmentId}/blasts`}
          editPath={`/segments/${segmentId}/blasts/${emailMessageId}/edit`}
        />
      </>
    </LoadingBox>
  );
};

export default Blast;
