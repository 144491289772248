import { FC, useCallback, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { Howl } from 'howler';

import { Switch } from '@/components/Form';
import IconButton from '@/components/IconHelpers/IconButton';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import Icon from '@/components/TiptapEditor/components/ui/Icon';
import Tooltip from '@/components/Tooltip';
import { useElevenlabsVoices, usePostTextToSpeechConfig, useUpdatePostTextToSpeechConfig } from '@/hooks';
import { ElevenlabsVoice, Post } from '@/interfaces/post';
import { Button } from '@/ui/Button';
import { Dropdown } from '@/ui/Dropdown';
import { cn } from '@/utils/cn';

interface Props {
  postId: Post['id'];
}

export const TextToSpeechConfig: FC<Props> = ({ postId }) => {
  const { data: textToSpeechConfig, isLoading, refetch } = usePostTextToSpeechConfig(postId);

  const { data: voicesData = [] } = useElevenlabsVoices();

  const voices = Array.isArray(voicesData) ? voicesData : [];

  const [formData, setFormData] = useState(textToSpeechConfig);
  const [updatedTooRecently, setUpdatedTooRecently] = useState(false);

  const voiceSamplePlayer = useRef<Howl>();

  const playSample = useCallback((voice: ElevenlabsVoice) => {
    voiceSamplePlayer.current?.unload();

    const howl = new Howl({
      src: [voice.preview_url],
      loop: false,
      volume: 0.5,
    });

    howl.play();

    voiceSamplePlayer.current = howl;
  }, []);

  useEffect(() => setFormData(textToSpeechConfig), [textToSpeechConfig]);

  const postTextToSpeechConfigMutation = useUpdatePostTextToSpeechConfig({ postId, onError: refetch });

  const onChange = useCallback(
    (data: any) => {
      const newData = { ...formData, ...data };
      setFormData({ ...formData, ...data });
      setUpdatedTooRecently(false);
      postTextToSpeechConfigMutation.mutate(newData);
    },
    [formData, postTextToSpeechConfigMutation]
  );

  const saveAudio = useCallback(async () => {
    await postTextToSpeechConfigMutation.mutateAsync(formData);
    setUpdatedTooRecently(true);
    toast.success('Audio newsletter now updating. This may take a few minutes.');
    setTimeout(() => setUpdatedTooRecently(false), 15000);
  }, [formData, postTextToSpeechConfigMutation]);

  if (isLoading) {
    return (
      <div className="flex items-center">
        <LoadingSpinner className="mr-2" />
      </div>
    );
  }

  return (
    <div className="space-y-8 !mb-60">
      <div className={cn('text-sm font-medium text-gray-700 flex gap-1 items-center')}>
        <span>Audio Newsletter Settings</span>

        <Tooltip
          id="tts-config-info"
          text="Audio will capture the entire post content, regardless of post visibility settings. It might take 5-10 minutes to generate the audio transcript."
        />
      </div>

      <Switch
        size="small"
        name="text_to_speech_config_enabled"
        labelText="Add an audio transcript to your post"
        helperText='Transform your content into audio for your subscribers to listen to. Readers will see the option to "Listen Online" in the email header.'
        checked={!!formData?.enabled}
        onChange={(_, value: boolean) => onChange({ enabled: value })}
      />

      {!!formData?.enabled && (
        <div className="flex flex-row gap-2 w-full">
          <Dropdown
            disabled={!formData?.enabled}
            name="elevenlabs-voice-id"
            className="grow"
            labelText="Choose Voice"
            required
            value={formData?.voice_id || ''}
            onSelect={(_name: string, value: string) => onChange({ voice_id: value })}
            options={voices?.map((v) => {
              return {
                value: v.voice_id,
                label: v.name,
                optionAction: (
                  <IconButton
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      playSample(v);
                    }}
                  >
                    <Icon name="Volume" />
                  </IconButton>
                ),
              };
            })}
            emptyLabel="No voices available"
            placeholderText="Select a Voice"
          />
          <Button
            onClick={saveAudio}
            variant="primary-inverse"
            className="h-1/2 mt-auto"
            disabled={postTextToSpeechConfigMutation.isLoading || updatedTooRecently}
          >
            Update Audio
          </Button>
        </div>
      )}
    </div>
  );
};
