import { NavbarSerializableNode, TNavbarDropdownElement } from '@shared/dream-components';
import { v4 as uuidv4 } from 'uuid';

export const navbarListContent = (): NavbarSerializableNode => ({
  type: 'navbar_menu_list',
  attrs: {
    id: uuidv4(),
  },
  content: [
    {
      type: 'navbar_menu_list_group',
      attrs: {
        id: uuidv4(),
        type: 'left',
      },
      content: [
        {
          type: 'navbar_item',
          attrs: {
            id: uuidv4(),
            type: 'link',
            label: 'Link',
          },
        },
      ],
    },
    {
      type: 'navbar_menu_list_group',
      attrs: {
        id: uuidv4(),
        type: 'middle',
      },
      content: [
        {
          type: 'navbar_item',
          attrs: {
            id: uuidv4(),
            type: 'dropdown',
            label: 'Dropdown',
          },
          content: [
            {
              type: 'navbar_dropdown',
              attrs: {
                id: uuidv4(),
                style: 'card',
              },
              content: [
                {
                  type: 'navbar_dropdown_column',
                  attrs: {
                    id: uuidv4(),
                  },
                  content: [
                    {
                      type: 'navbar_dropdown_item',
                      attrs: {
                        id: uuidv4(),
                        title: 'Dropdown Item 1.A',
                        description: 'Dropdown Item 1.A Description',
                        style: 'description',
                      },
                    },
                    {
                      type: 'navbar_dropdown_item',
                      attrs: {
                        id: uuidv4(),
                        title: 'Dropdown Item 1.B',
                        description: 'Dropdown Item 1.B Description',
                        style: 'description',
                      },
                    },
                  ],
                },
                {
                  type: 'navbar_dropdown_column',
                  attrs: {
                    id: uuidv4(),
                  },
                  content: [
                    {
                      type: 'navbar_dropdown_item',
                      attrs: {
                        id: uuidv4(),
                        title: 'Dropdown Item 2.A',
                        description: 'Dropdown Item 2.A Description',
                        style: 'description',
                      },
                    },
                    {
                      type: 'navbar_dropdown_item',
                      attrs: {
                        id: uuidv4(),
                        title: 'Dropdown Item 2.B',
                        description: 'Dropdown Item 2.B Description',
                        style: 'description',
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: 'navbar_menu_list_group',
      attrs: {
        id: uuidv4(),
        type: 'right',
      },
      content: [
        {
          type: 'navbar_item',
          attrs: {
            id: uuidv4(),
            type: 'button',
            label: 'Button',
            background: '#F4EBFFFF',
            color: '#8A24FFFF',
          },
          content: [],
        },
        {
          type: 'navbar_item',
          attrs: {
            id: uuidv4(),
            type: 'button',
            label: 'Button',
            color: '#FFFFFFFF',
          },
          content: [],
        },
      ],
    },
  ],
});

export const navbarContent = {
  type: 'navbar_menu',
  attrs: {
    id: uuidv4(),
    width: '700px',
    rows: 1,
  },
  content: [navbarListContent()],
};


export const navbarDropdownContent = () => ({
  type: 'navbar_dropdown',
  attrs: {
    id: uuidv4(),
    label: 'Dropdown 1',
    style: 'card',
  },
  content: [
    {
      type: 'navbar_dropdown_column',
      attrs: {
        id: uuidv4(),
      },
      content: [
        {
          type: 'navbar_dropdown_item',
          attrs: {
            id: uuidv4(),
            title: 'Dropdown Item 1.A',
            description: 'Dropdown Item 1.A Description',
            style: 'description',
            href: '/item-1',
          }
        },
        {
          type: 'navbar_dropdown_item',
          attrs: {
            id: uuidv4(),
            title: 'Dropdown Item 1.B',
            description: 'Dropdown Item 1.B Description',
            style: 'description',
            href: '/item-1-b',
          }
        }
      ]
    },
    {
      type: 'navbar_dropdown_column',
      attrs: {
        id: uuidv4(),
      },
      content: [
        {
          type: 'navbar_dropdown_item',
          attrs: {
            id: uuidv4(),
            title: 'Dropdown Item 2.A',
            description: 'Dropdown Item 2.A Description',
            style: 'description',
            href: '/item-1',
          }
        },
        {
          type: 'navbar_dropdown_item',
          attrs: {
            id: uuidv4(),
            title: 'Dropdown Item 2.B',
            description: 'Dropdown Item 2.B Description',
            style: 'description',
            href: '/item-1-b',
          }
        }
      ]
    }
  ]
} as TNavbarDropdownElement)
