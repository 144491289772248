import SectionRenderer from "@/routes/website/_components/DreamEditor/AttributesPanel/helpers/SectionRenderer";

import { useNavbarContext } from "../../../NavbarContext";

import { StyleSettings } from "./StyleSettings";

export function NavDropdownItemStylingSettings() {
  const { selectedContent } = useNavbarContext();

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== "navbar_dropdown_item") return null;

  return <SectionRenderer title="Styling"  >
    <StyleSettings />
  </SectionRenderer>;
}
