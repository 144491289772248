import ParagraphExtension from '@tiptap/extension-paragraph';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { getDataAttributes } from '../utils';

import { ParagraphView } from './views/ParagraphView';

export const PARAGRAPH_DEFAULTS = {
  padding: '4px 0px 4px 0px', // top right bottom left
  paddingRoot: '4px 30px 4px 30px', // top right bottom left
  margin: '0px 0px 0px 0px', // top right bottom left
};

export const Paragraph = ParagraphExtension.extend({
  addAttributes() {
    return {
      textAlign: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-text-align'),
        renderHTML: (attributes) => ({
          'data-text-align': attributes.textAlign,
        }),
      },
      padding: {
        parseHTML: (element) => element.getAttribute('data-padding'),
        renderHTML: (attributes) => ({
          'data-padding': attributes.padding,
        }),
      },
      margin: {
        parseHTML: (element) => element.getAttribute('data-margin'),
        renderHTML: (attributes) => {
          return {
            'data-margin': attributes.margin,
          };
        },
      },
      width: {
        parseHTML: (element) => element.getAttribute('data-width'),
        renderHTML: (attributes) => ({
          'data-width': attributes.width,
        }),
      },
      width_max: {
        parseHTML: (element) => element.getAttribute('data-width-max'),
        renderHTML: (attributes) => ({
          'data-width-max': attributes.width_max,
        }),
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ['p', getDataAttributes(HTMLAttributes), 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(ParagraphView);
  },
});

export default Paragraph;
