import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { AccordionView } from './view/AccordionView';

export const Accordion = Node.create({
  name: 'accordion',

  group: 'block',

  content: 'accordionTitle accordionContent',

  defining: true,

  isolating: true,

  selectable: true,

  draggable: true,

  addAttributes() {
    return {
      width: {
        default: '100%',
        parseHTML: (element) => element.getAttribute('data-width'),
        renderHTML: (attributes) => ({
          'data-width': attributes.width,
          style: `width: ${attributes.width}`,
        }),
      },
      backgroundImage: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-background-image'),
        renderHTML: (attributes) => ({
          'data-background-image': attributes.backgroundImage,
        }),
      },
      backgroundDarken: {
        default: false,
        parseHTML: (element) => element.getAttribute('data-background-darken'),
        renderHTML: (attributes) => ({
          'data-background-darken': attributes.backgroundDarken,
        }),
      },
      height: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-height'),
        renderHTML: (attributes) => ({
          'data-height': attributes.height,
          style: `height: ${attributes.height}`,
        }),
      },
      width_max: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-width-max'),
        renderHTML: (attributes) => ({
          'data-width-max': attributes.width_max,
          style: `max-width: ${attributes.width_max || '100%'}`,
        }),
      },
      borderRadius: {
        default: '0px 0px 0px 0px',
        parseHTML: (element) => element.getAttribute('data-border-radius'),
        renderHTML: (attributes) => ({
          'data-border-radius': attributes.borderRadius,
        }),
      },
      borderWidth: {
        default: '0px',
        parseHTML: (element) => element.getAttribute('data-border-width'),
        renderHTML: (attributes) => ({
          'data-border-width': attributes.borderWidth,
        }),
      },
      borderColor: {
        default: '#000000FF',
        parseHTML: (element) => element.getAttribute('data-border-color'),
        renderHTML: (attributes) => ({
          'data-border-color': attributes.borderColor,
        }),
      },
      borderStyle: {
        default: 'none',
        parseHTML: (element) => element.getAttribute('data-border-style'),
        renderHTML: (attributes) => ({
          'data-border-style': attributes.borderStyle,
        }),
      },
      padding: {
        default: '0px 0px 0px 0px',
        parseHTML: (element) => element.getAttribute('data-padding'),
        renderHTML: (attributes) => ({
          'data-padding': attributes.padding,
          style: `--padding: ${attributes.padding || '0px 0px 0px 0px'};--padding-mobile: ${
            attributes.padding
          };--padding-root: ${attributes.padding || '0px 0px 0px 0px'};--padding-root-mobile: ${
            attributes.padding || '0px 0px 0px 0px'
          }`,
        }),
      },
      margin: {
        default: '0px 0px 0px 0px',
        parseHTML: (element) => element.getAttribute('data-margin'),
        renderHTML: (attributes) => ({
          'data-margin': attributes.margin,
          style: `margin: ${attributes.margin}`,
        }),
      },
      gap: {
        parseHTML: (element) => element.getAttribute('data-gap'),
        renderHTML: (attributes) => ({
          'data-gap': attributes.gap,
          style: `--gap: ${attributes.gap || '0px'};--gap-mobile: ${attributes.gap || '0px'};--gap-mobile-vertical: ${
            attributes.gap || '0px'
          }`,
        }),
      },
      boxShadow: {
        default: 'none',
        parseHTML: (element) => element.getAttribute('data-box-shadow'),
        renderHTML: (attributes) => ({
          'data-box-shadow': attributes.boxShadow,
          style: `--box-shadow: ${attributes.boxShadow}`,
        }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(AccordionView);
  },
});

export default Accordion;
