import { Link } from 'react-router-dom';
import { CaretRight, DiamondsFour, Layout, Question } from '@phosphor-icons/react';

import { Button } from '../UI/Button';
import { Text } from '../UI/Text';
import { Tooltip } from '../UI/Tooltip';

export const EditorHeader = ({ type, name }: { type: 'navbar' | 'footer' | 'template'; name?: string }) => {
  const typeNames = {
    navbar: 'Navigation',
    footer: 'Footer',
    template: name || 'Template',
  };
  return (
    <div className="flex items-center gap-2 py-4 w-full">
      {type === 'template' && (
        <>
          <Link to="/website_builder_v2/templates">
            <Button variant="ghost" className="bg-wb-highlight" size="sm" LeftIcon={Layout} iconWeight="bold">
              Templates
            </Button>
          </Link>
          <CaretRight size={12} weight="bold" className="text-wb-tertiary" />
        </>
      )}
      <Button variant="accent-soft" size="sm" LeftIcon={DiamondsFour} iconWeight="fill">
        {typeNames[type]}
      </Button>
      <div className="flex items-center gap-1 px-1">
        <Text size="xs" weight="medium" variant="secondary">
          {type === 'template' ? "You're editing a template" : "You're editing a component"}
        </Text>
        <Tooltip
          center={
            type === 'template'
              ? 'You can use the updated changes the next time you insert this template'
              : 'Changes here will apply to all instances of this component'
          }
          className="flex-1 flex items-center cursor-pointer"
        >
          <Question size={12} className="text-wb-secondary" weight="bold" />
        </Tooltip>
      </div>
    </div>
  );
};
