import { useCallback } from 'react';
import { ArrowsHorizontal, ArrowsVertical } from '@phosphor-icons/react';

import { Text } from '../../../UI/Text';
import { ToggleGroup, ToggleGroupItem } from '../../../UI/ToggleGroup';
import { AttributeSettingProps } from '../types';

type OrientationSettingsProps = AttributeSettingProps & {};

export const OrientationSettings = ({ editor, activeNodeResult }: OrientationSettingsProps) => {
  const { activeNodeType, activeNodePos, activeNodeAttributes } = activeNodeResult;

  const updateOrientation = useCallback(
    (value: string) => {
      if (!activeNodeType) return;

      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, 'orientation', value);
        return true;
      });
    },
    [activeNodePos, activeNodeType, editor]
  );

  return (
    <div className="flex items-center justify-stretch gap-2">
      <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
        Orientation
      </Text>
      <div className="grow bg-wb-secondary rounded-lg shadow-sm">
        <ToggleGroup
          className="p-[2px]"
          type="single"
          defaultValue="horizontal"
          value={activeNodeAttributes?.orientation}
          onValueChange={updateOrientation}
        >
          <ToggleGroupItem value="horizontal" className="grow">
            <ArrowsHorizontal weight="bold" format="outline" className="h-4 w-4 text-wb-secondary" />
          </ToggleGroupItem>
          <ToggleGroupItem value="vertical" className="grow">
            <ArrowsVertical weight="bold" format="outline" className="h-4 w-4 text-wb-secondary" />
          </ToggleGroupItem>
        </ToggleGroup>
      </div>
    </div>
  );
};
