import { NavbarSerializableNode } from "@shared/dream-components"


export const getParentOrientation = (parentNode: NavbarSerializableNode): 'vertical' | 'horizontal' => {

  switch (parentNode.type) {
    case "navbar_menu":
    case "navbar_dropdown_column":
      return 'vertical'
    case "navbar_menu_list_group":
    case "navbar_item":
    case "navbar_menu_list":
    case "navbar_dropdown":
    default:
      return 'horizontal'
  }
}

export const getParent = (content: NavbarSerializableNode, node: NavbarSerializableNode): NavbarSerializableNode | null => {
  if (content === node) {
    return null; // Node is the root, it's its own parent
  }

  const findParent = (currentNode: NavbarSerializableNode): NavbarSerializableNode => {
    if (!('content' in currentNode)) {
      return content; // If no content, return the root
    }

    if (Array.isArray(currentNode.content)) {
      for (let i = 0; i < currentNode.content.length; i += 1) {
        const child = currentNode.content[i];
        if (child === node) {
          return currentNode;
        }

        const result = findParent(child);
        if (result !== content) {
          return result;
        }
      }
    }

    return content; // If not found, return the root
  };

  return findParent(content);
};

export const getNodeByID = (content: NavbarSerializableNode, id: string): NavbarSerializableNode | null => {
  if (content.attrs?.id === id) {
    return content
  }
  if (!('content' in content)) {
    return null; // If no content, return the null
  }

  if (Array.isArray(content.content)) {
    for (let i = 0; i < content.content.length; i += 1) {
      const child = content.content[i];
      const result = getNodeByID(child, id)
      if (result) {
        return result
      }
    }
  }

  return null;
}

export const formatCamelCase = (value: string): string => {
  if (!value) return 'unknown';

  // split camel case and capitalize each word
  const formattedType = value
    .split(/(?=[A-Z])/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
  return formattedType;
}
