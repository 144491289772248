import { useCallback, useMemo } from 'react';
import toast from 'react-hot-toast';
import { UseMutationResult } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ArchiveBoxIcon } from '@heroicons/react/24/outline';
import { SparklesIcon } from '@heroicons/react/24/solid';
import { AxiosResponse } from 'axios';
import cx from 'classnames';
import moment from 'moment-mini';

import MetricsBadge from '@/components/_domain/MetricsBadge';
import { Checkbox } from '@/components/Form';
import { Typography } from '@/components/Typography';
import { Avatar } from '@/components/UI/Avatar';
import { useCopyToClipboard } from '@/hooks';
import { WebTemplatePayload } from '@/hooks/useWebTemplates/useUpdateWebTemplate';
import { PostPlatform, PostPreview, PostStatus } from '@/interfaces/post';
import { Publication } from '@/interfaces/publication';
import api from '@/services/swarm';
import { Badge, BadgeType } from '@/ui/Badge';
import { Dropdown } from '@/ui/Dropdown';

import PostStatsOverviewContainer from '../PostStatsOverviewContainer';

interface PostProps {
  post: PostPreview;
  featuredPostsIds: string[];
  publication?: Publication;
  firstItem?: boolean;
  lastItem?: boolean;
  featured?: boolean;
  selected: boolean;
  onSelect: (checked: boolean) => void;
  refetch: () => void;
  handleCreateTemplateFromPost: (post: PostPreview) => void;
  handleDeletePost: (post: PostPreview) => void;
  webTemplateMutation: UseMutationResult<AxiosResponse<any, any>, any, WebTemplatePayload, unknown>;
}

const PostContainer = ({
  post,
  featuredPostsIds,
  publication,
  firstItem,
  lastItem,
  featured,
  selected,
  onSelect,
  refetch,
  handleCreateTemplateFromPost,
  handleDeletePost,
  webTemplateMutation,
}: PostProps) => {
  const copy = useCopyToClipboard();
  const navigate = useNavigate();
  const canShowMetrics = useCallback((newPost: PostPreview) => newPost.status === PostStatus.PUBLISHED, []);
  const FEATURED_POST_LIMIT = 6;

  let postStatus;
  let postDisplayTime;
  let postBadgeType;
  let postBadgeIcon;
  let postBadgeText;

  if (post.status === PostStatus.DRAFT) {
    postStatus = 'Last edited on';
    postDisplayTime = post.updated_at;
    postBadgeType = BadgeType.DEFAULT_LINED;
    postBadgeText = 'Draft';
  } else if (post.status === PostStatus.SCHEDULED) {
    postStatus = 'Scheduled for';
    postDisplayTime = post.scheduled_at;
    postBadgeType = BadgeType.WARNING;
    postBadgeText = 'Scheduled';
  } else if (post.status === PostStatus.PUBLISHED) {
    postStatus = 'Published on';
    postDisplayTime = post.scheduled_at;
    postBadgeType = BadgeType.SUCCESS;
    postBadgeText = 'Published';
  } else if (post.status === PostStatus.ARCHIVED) {
    postStatus = '';
    postDisplayTime = '';
    postBadgeText = 'Archived';
    postBadgeType = BadgeType.DEFAULT_LINED;
    postBadgeIcon = <ArchiveBoxIcon className="w-3.5 h-3.5" />;
  } else {
    postStatus = '';
    postDisplayTime = '';
    postBadgeText = '';
  }

  const handlePostAction = async (name: string, value: string) => {
    switch (value) {
      case 'view':
        navigate(`/posts/${post.id}`);
        break;
      case 'edit':
        navigate(`/posts/${post.id}/edit`);
        break;
      case 'duplicate':
        api
          .post(`/posts/${post.id}/duplicates`)
          .then(() => {
            toast.success('Post duplicated');
            refetch();
          })
          .catch(() => {
            toast.error('Could not duplicate post');
          });
        break;
      case 'save_template':
        handleCreateTemplateFromPost(post);
        break;
      case 'feature_post':
        if (featured) {
          const newFeaturedPosts = featuredPostsIds?.filter((id: any) => id !== post.id);
          await webTemplateMutation.mutate({
            feature_post_ids: newFeaturedPosts,
          });
        } else {
          await webTemplateMutation.mutate({
            feature_post_ids: [...featuredPostsIds, post.id],
          });
        }
        break;
      case 'view_post':
        window.open(post.url, '_blank');
        break;
      case 'copy_url':
        copy({ text: post.url, onSuccessText: 'Copied post URL!' });
        break;
      case 'archive':
        api
          .patch(`/posts/${post.id}/transition`, { status: 'archived' })
          .then(() => {
            toast.success('Post archived');
            refetch();
          })
          .catch(() => {
            toast.error('Could not archive post');
          });
        break;
      case 'unarchive':
        api
          .patch(`/posts/${post.id}/transition`, { status: 'confirmed' })
          .then(() => {
            toast.success('Post unarchived');
            refetch();
          })
          .catch(() => {
            toast.error('Could not unarchive post');
          });
        break;
      case 'delete':
        handleDeletePost(post);
        break;
      default:
        break;
    }
  };

  const publishedOnWeb = useMemo(
    () =>
      post.status === PostStatus.PUBLISHED &&
      (post.platform === PostPlatform.WEB || post.platform === PostPlatform.BOTH),
    [post.platform, post.status]
  );

  const formattedContentTags =
    post.content_tags.length <= 2
      ? post.content_tags.map((tag) => tag.display).join(', ')
      : `${post.content_tags
          .slice(0, 2)
          .map((tag) => tag.display)
          .join(', ')}, +${post.content_tags.length - 2}`;

  const featuredPostLimitReached = useMemo(
    () => featuredPostsIds.length >= FEATURED_POST_LIMIT,
    [featuredPostsIds.length]
  );

  const featuredOptionLabel = useMemo(() => {
    const featurePostText = featuredPostLimitReached
      ? `Feature limit reached (${FEATURED_POST_LIMIT})`
      : 'Feature post';
    return featured ? 'Unfeature post' : featurePostText;
  }, [featured]);

  return (
    <div
      className={cx(
        'w-full flex items-center justify-between p-4',
        selected ? 'bg-surface-100' : 'bg-white hover:bg-surface-50 cursor-pointer',
        firstItem && 'rounded-t-lg',
        lastItem && 'rounded-b-lg'
      )}
      key={post.id}
    >
      <div className="flex flex-col gap-4 max-w-110">
        <div className="flex flex-row gap-2">
          <Checkbox name="select-post" checked={selected} className="pt-1" onChange={onSelect} />
          <div
            className="flex flex-row gap-4"
            onClick={() => navigate(`/posts/${post.id}`)}
            role="button"
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === 'Enter') navigate(`/posts/${post.id}`);
            }}
          >
            <div className="flex flex-col">
              <Typography
                className="max-w-96 whitespace-nowrap text-ellipsis overflow-hidden"
                token="font-medium/text/sm"
              >
                {post.title}
              </Typography>
              <Typography
                className="max-w-96 whitespace-nowrap text-ellipsis overflow-hidden"
                token="font-normal/text/sm"
              >
                {post.subtitle}
              </Typography>
            </div>
            <div className="flex flex-row">
              {post.authors.map((author, index) => (
                <Avatar.Wrapper className={cx(index > 0 && '-mx-1')} fallback={author.name}>
                  {author.profile_picture ? (
                    <Avatar.Image
                      src={author.profile_picture.thumb.url}
                      alt={author.name}
                      className="border rounded-full border-neutrals-200"
                    />
                  ) : null}
                </Avatar.Wrapper>
              ))}
            </div>
          </div>
        </div>
        {postStatus.length > 0 && (
          <div className="px-6 flex flex-row gap-2 items-end">
            <Typography token="font-normal/text/xs">
              {postStatus} {postDisplayTime && moment(postDisplayTime).format('MMM D, YYYY h:mm A')}
            </Typography>
            {formattedContentTags && <span>.</span>}
            {formattedContentTags && <Typography token="font-normal/text/xs">{formattedContentTags}</Typography>}
          </div>
        )}
      </div>
      <div className="flex flex-row gap-4">
        <div className="flex flex-col items-center gap-2">
          <div className="flex flex-row gap-4">
            <div
              className="flex flex-col gap-2 items-end"
              onClick={() => navigate(`/posts/${post.id}`)}
              role="button"
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === 'Enter') navigate(`/posts/${post.id}`);
              }}
            >
              <div className="flex flex-row gap-2">
                {featured && (
                  <Badge leadingIcon={<SparklesIcon className="w-3.5 h-3.5" />} type={BadgeType.INFO} text="Featured" />
                )}
                <Badge leadingIcon={postBadgeIcon} type={postBadgeType} text={postBadgeText} />
              </div>
              {canShowMetrics(post) && (
                <div className="flex items-center">
                  <PostStatsOverviewContainer
                    postId={post.id}
                    isPremiumEnabled={publication?.is_premium_enabled || false}
                    staleTime={Infinity}
                  >
                    {(stats) =>
                      post.platform === PostPlatform.WEB ? (
                        <MetricsBadge
                          totalWebViewed={stats.total_web_viewed}
                          totalWebClicked={stats.total_unique_web_clicked}
                          totalUpgrades={stats.total_upgrades}
                        />
                      ) : (
                        <MetricsBadge
                          totalSent={stats.total_sent}
                          openRate={stats.open_rate}
                          clickRate={stats.click_rate}
                          totalUpgrades={stats.total_upgrades}
                        />
                      )
                    }
                  </PostStatsOverviewContainer>
                </div>
              )}
            </div>
            <Dropdown
              name="bulk-actions"
              variant="ellipsis"
              optionsContainerClassNames={{
                width: 'w-fit',
                height: 'max-h-max',
              }}
              options={[
                { label: 'View', value: 'view' },
                { label: 'Edit', value: 'edit' },
                { label: 'Duplicate post', value: 'duplicate' },
                { label: 'Save post as template', value: 'save_template' },
                ...(publishedOnWeb
                  ? [
                      {
                        label: featuredOptionLabel,
                        value: 'feature_post',
                        topBorder: true,
                        disabled: featuredPostLimitReached,
                      },
                    ]
                  : []),
                ...(publishedOnWeb ? [{ label: 'View published post', value: 'view_post', topBorder: true }] : []),
                { label: 'Copy post URL', value: 'copy_url' },
                ...(post.archivable || post.status === PostStatus.ARCHIVED
                  ? [
                      {
                        label: post.status === PostStatus.ARCHIVED ? 'Unarchive' : 'Archive',
                        value: post.status === PostStatus.ARCHIVED ? 'unarchive' : 'archive',
                        danger: true,
                        topBorder: true,
                      },
                    ]
                  : []),
                ...(post.deletable ? [{ label: 'Delete', value: 'delete', danger: true }] : []),
              ]}
              value=""
              onSelect={handlePostAction}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostContainer;
