import { useCallback, useEffect, useRef, useState } from 'react';
import Tippy from '@tippyjs/react';
import { Instance, sticky } from 'tippy.js';
import { v4 as uuid } from 'uuid';

import { LineDivider } from '@/components/LineDivider';
import { FieldSet, InputField } from '@/components/TiptapEditor/components/ui/Input';
import { Panel } from '@/components/TiptapEditor/components/ui/Panel/styled';
import { ToggleSwitch } from '@/components/TiptapEditor/components/ui/ToggleSwitch';
import { ToggleWrapper } from '@/components/TiptapEditor/components/ui/ToggleWrapper';
import API from '@/components/TiptapEditor/lib/api';
import { usePublicationContext } from '@/components/TiptapEditor/lib/context/PublicationContext';
import { Typography } from '@/components/Typography';
import { RSSCTAStyle } from '@/interfaces/external_rss_feed';

import { BubbleMenu as BaseBubbleMenu } from '../../../components/menus/BubbleMenu';
import { MenuProps } from '../../../components/menus/types';
import { getRenderContainer } from '../../../components/menus/utils/getRenderContainer';
import { Button } from '../../../components/ui/Button';
import { Icon } from '../../../components/ui/Icon';
import { Divider, Toolbar } from '../../../components/ui/Toolbar';
import { Tooltip } from '../../../components/ui/Tooltip';

import Dropdown from './Dropdown';
import { RSSEntryLayoutPanel } from './RSSEntryLayoutPanel';
import { RSSEntryOrganizerPanel } from './RSSEntryOrganizerPanel';

const buttonProps = {
  $variant: 'quaternary',
  $size: 'small',
  $isIconButton: true,
};

export const RSSBlockMenu = ({ editor, appendTo }: MenuProps): JSX.Element => {
  const menuRef = useRef<HTMLDivElement>(null);
  const tippyInstance = useRef<Instance | null>(null);
  const { publicationId } = usePublicationContext();
  const { id: externalRssFeedId, data, settings } = editor.getAttributes('rss');
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [organizeContentModalOpen, setOrganizeContentModalOpen] = useState(false);
  const [ctaText, setCtaText] = useState(settings?.ctaText || 'Read More');
  const [ctaStyle, setCtaStyle] = useState(settings?.ctaStyle || RSSCTAStyle.BUTTON);
  const [entriesToShow, setEntriesToShow] = useState(settings?.entriesToShow || 6);
  const [showCtaTitleOption, setShowCtaTitleOption] = useState(settings?.displayTitle);
  const [showCtaThumbnailOption, setShowCtaThumbnailOption] = useState(settings?.displayThumbnail);

  useEffect(() => {
    if (settings) {
      setCtaText(settings.ctaText);
      setEntriesToShow(settings.entriesToShow);
      setShowCtaThumbnailOption(settings.displayThumbnail);
      setShowCtaTitleOption(settings.displayTitle);
      if (!settings.displayTitle && settings.ctaStyle === RSSCTAStyle.TITLE) {
        editor
          .chain()
          .updateAttributes('rss', { settings: { ...settings, ctaStyle: RSSCTAStyle.BUTTON } })
          .run();
        setCtaStyle(RSSCTAStyle.BUTTON);
      }
      if (!settings.displayThumbnail && settings.ctaStyle === RSSCTAStyle.THUMBNAIL) {
        editor
          .chain()
          .updateAttributes('rss', { settings: { ...settings, ctaStyle: RSSCTAStyle.BUTTON } })
          .run();
        setCtaStyle(RSSCTAStyle.BUTTON);
      }
    }
  }, [settings, showCtaTitleOption, showCtaThumbnailOption]);

  const getReferenceClientRect = useCallback(() => {
    const renderContainer = getRenderContainer(editor, 'node-rss');
    const rect = renderContainer?.getBoundingClientRect() || new DOMRect(-1000, -1000, 0, 0);

    return rect;
  }, [editor]);

  const shouldShow = useCallback(() => {
    const isActive = editor.isActive('rss');

    return isActive;
  }, [editor]);

  const removeNode = useCallback(() => {
    editor.chain().focus().deleteSelection().run();
  }, [editor]);

  const clearSelection = useCallback(() => {
    editor.chain().updateAttributes('rss', { id: undefined, data: {} }).run();
  }, [editor]);

  const onChangeEntriesToShow = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setEntriesToShow(Number(e.target.value));
  }, []);

  const onUpdateEntriesToShow = useCallback(() => {
    const currentSettings = editor.getAttributes('rss').settings;
    const newSettings = { ...currentSettings, entriesToShow: entriesToShow || 1 };
    editor.chain().updateAttributes('rss', { settings: newSettings }).run();
  }, [editor, entriesToShow]);

  const onChangeCtaText = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    let updatedValue = e.target.value;
    if (updatedValue === '') {
      updatedValue = 'Read More';
    }
    setCtaText(updatedValue);
  }, []);

  const onUpdateCtaText = useCallback(() => {
    const currentSettings = editor.getAttributes('rss').settings;
    const newSettings = { ...currentSettings, ctaText };
    editor.chain().updateAttributes('rss', { settings: newSettings }).run();
  }, [editor, ctaText]);

  const onChangeToggleDisplayTitle = useCallback(() => {
    const currentSettings = editor.getAttributes('rss').settings;
    const newSettings = { ...currentSettings, displayTitle: !currentSettings.displayTitle };
    editor.chain().updateAttributes('rss', { settings: newSettings }).run();
  }, [editor]);

  const onChangeToggleLinkToArticle = useCallback(() => {
    const currentSettings = editor.getAttributes('rss').settings;
    const newSettings = { ...currentSettings, displayCta: !currentSettings.displayCta };
    editor.chain().updateAttributes('rss', { settings: newSettings }).run();
  }, [editor]);

  const onChangeToggleDisplayAuthor = useCallback(() => {
    const currentSettings = editor.getAttributes('rss').settings;
    const newSettings = { ...currentSettings, displayAuthor: !currentSettings.displayAuthor };
    editor.chain().updateAttributes('rss', { settings: newSettings }).run();
  }, [editor]);

  const onChangeToggleDisplayContent = useCallback(() => {
    const currentSettings = editor.getAttributes('rss').settings;
    const newSettings = { ...currentSettings, displayContent: !currentSettings.displayContent };
    editor.chain().updateAttributes('rss', { settings: newSettings }).run();
  }, [editor]);

  const onChangeToggleDisplayDescription = useCallback(() => {
    const currentSettings = editor.getAttributes('rss').settings;
    const newSettings = { ...currentSettings, displayDescription: !currentSettings.displayDescription };
    editor.chain().updateAttributes('rss', { settings: newSettings }).run();
  }, [editor]);

  const onChangeToggleDisplayPubDate = useCallback(() => {
    const currentSettings = editor.getAttributes('rss').settings;
    const newSettings = { ...currentSettings, displayPubDate: !currentSettings.displayPubDate };
    editor.chain().updateAttributes('rss', { settings: newSettings }).run();
  }, [editor]);

  const onChangeToggleDisplayCategories = useCallback(() => {
    const currentSettings = editor.getAttributes('rss').settings;
    const newSettings = { ...currentSettings, displayCategories: !currentSettings.displayCategories };
    editor.chain().updateAttributes('rss', { settings: newSettings }).run();
  }, [editor]);

  const onChangeToggleDisplayThumbnail = useCallback(() => {
    const currentSettings = editor.getAttributes('rss').settings;
    const newSettings = { ...currentSettings, displayThumbnail: !currentSettings.displayThumbnail };
    editor.chain().updateAttributes('rss', { settings: newSettings }).run();
  }, [editor]);

  return (
    <BaseBubbleMenu
      editor={editor}
      pluginKey={`rssBlock-${uuid()}`}
      shouldShow={shouldShow}
      updateDelay={0}
      tippyOptions={{
        offset: [0, 8],
        popperOptions: {
          modifiers: [{ name: 'flip', enabled: false }],
        },
        getReferenceClientRect,
        appendTo: () => {
          return appendTo?.current;
        },
        onCreate: (instance: Instance) => {
          tippyInstance.current = instance;
        },
        plugins: [sticky],
        sticky: 'reference',
      }}
    >
      <Toolbar ref={menuRef} shouldShowContent={shouldShow()}>
        {externalRssFeedId && (
          <>
            <Tippy
              offset={[0, 8]}
              placement="bottom-start"
              reference={menuRef.current}
              visible={isSettingsOpen && menuRef.current !== null}
              interactive
              onClickOutside={() => {
                if (!organizeContentModalOpen) {
                  onUpdateEntriesToShow();
                  onUpdateCtaText();
                  setIsSettingsOpen(false);
                }
              }}
              render={(attrs) =>
                isSettingsOpen && (
                  <Panel style={{ width: '250px' }} {...attrs}>
                    <FieldSet>
                      <div className="flex flex-col gap-2">
                        <Typography token="font-medium/text/xs" color="white" colorWeight="900">
                          Articles to show
                        </Typography>
                        <InputField
                          value={entriesToShow}
                          type="number"
                          min={1}
                          onChange={onChangeEntriesToShow}
                          onBlur={onUpdateEntriesToShow}
                          onSuspend={onUpdateEntriesToShow}
                        />
                      </div>
                      <RSSEntryLayoutPanel editor={editor} />
                      <RSSEntryOrganizerPanel
                        editor={editor}
                        data={data}
                        settings={settings}
                        setOrganizeContentModalOpen={setOrganizeContentModalOpen}
                        organizeContentModalOpen={organizeContentModalOpen}
                      />
                      <LineDivider className="opacity-10" />
                      <ToggleWrapper
                        icon={<Icon name="Heading" />}
                        switchEl={
                          <ToggleSwitch
                            isChecked={editor.getAttributes('rss').settings.displayTitle}
                            onChange={onChangeToggleDisplayTitle}
                          />
                        }
                      >
                        Show title
                      </ToggleWrapper>
                      <ToggleWrapper
                        icon={<Icon name="Calendar" />}
                        switchEl={
                          <ToggleSwitch
                            isChecked={editor.getAttributes('rss').settings.displayPubDate}
                            onChange={onChangeToggleDisplayPubDate}
                          />
                        }
                      >
                        Show publication date
                      </ToggleWrapper>
                      <ToggleWrapper
                        icon={<Icon name="Image" />}
                        switchEl={
                          <ToggleSwitch
                            isChecked={editor.getAttributes('rss').settings.displayThumbnail}
                            onChange={onChangeToggleDisplayThumbnail}
                          />
                        }
                      >
                        Show thumbnail
                      </ToggleWrapper>
                      {
                        // eslint-disable-next-line no-nested-ternary
                        editor.getAttributes('rss').settings.displayTitle &&
                        editor.getAttributes('rss').settings.displayThumbnail ? (
                          <ToggleWrapper
                            icon={<Icon name="TitleAboveImage" />}
                            switchEl={
                              <ToggleSwitch
                                isChecked={editor.getAttributes('rss').settings.displayTitleAboveThumbnail}
                                onChange={() => {
                                  const currentSettings = editor.getAttributes('rss').settings;
                                  const newSettings = {
                                    ...currentSettings,
                                    displayTitleAboveThumbnail: !currentSettings.displayTitleAboveThumbnail,
                                  };
                                  editor.chain().updateAttributes('rss', { settings: newSettings }).run();
                                }}
                              />
                            }
                          >
                            Show title above thumbnail
                          </ToggleWrapper>
                        ) : null
                      }
                      <ToggleWrapper
                        icon={<Icon name="Tag" />}
                        noFill
                        switchEl={
                          <ToggleSwitch
                            isChecked={editor.getAttributes('rss').settings.displayCategories}
                            onChange={onChangeToggleDisplayCategories}
                          />
                        }
                      >
                        Show categories
                      </ToggleWrapper>
                      <ToggleWrapper
                        icon={<Icon name="Person" />}
                        switchEl={
                          <ToggleSwitch
                            isChecked={editor.getAttributes('rss').settings.displayAuthor}
                            onChange={onChangeToggleDisplayAuthor}
                          />
                        }
                      >
                        Show author
                      </ToggleWrapper>
                      <ToggleWrapper
                        icon={<Icon name="Subtitle" />}
                        switchEl={
                          <ToggleSwitch
                            isChecked={editor.getAttributes('rss').settings.displayDescription}
                            onChange={onChangeToggleDisplayDescription}
                          />
                        }
                      >
                        Show description
                      </ToggleWrapper>
                      <ToggleWrapper
                        icon={<Icon name="TextOnly" />}
                        switchEl={
                          <ToggleSwitch
                            isChecked={editor.getAttributes('rss').settings.displayContent}
                            onChange={onChangeToggleDisplayContent}
                          />
                        }
                      >
                        Show full article
                      </ToggleWrapper>
                      <ToggleWrapper
                        icon={<Icon name="Button" />}
                        switchEl={
                          <ToggleSwitch
                            isChecked={editor.getAttributes('rss').settings.displayCta}
                            onChange={onChangeToggleLinkToArticle}
                          />
                        }
                      >
                        Show CTA
                      </ToggleWrapper>
                      {editor.getAttributes('rss').settings.displayCta && (
                        <div className="flex flex-col gap-2">
                          <Typography token="font-medium/text/xs" color="white" colorWeight="900">
                            CTA Style
                          </Typography>
                          <Dropdown
                            actions={[
                              { label: RSSCTAStyle.BUTTON, value: RSSCTAStyle.BUTTON },
                              { label: RSSCTAStyle.LINK, value: RSSCTAStyle.LINK },
                              { label: RSSCTAStyle.TITLE, value: RSSCTAStyle.TITLE, disabled: !showCtaTitleOption },
                              {
                                label: RSSCTAStyle.THUMBNAIL,
                                value: RSSCTAStyle.THUMBNAIL,
                                disabled: !showCtaThumbnailOption,
                              },
                            ]}
                            value={ctaStyle}
                            onSelect={(name: string, value: RSSCTAStyle) => {
                              const currentSettings = editor.getAttributes('rss').settings;
                              const newSettings = { ...currentSettings, ctaStyle: value };
                              editor.chain().updateAttributes('rss', { settings: newSettings }).run();
                              setCtaStyle(value);
                            }}
                          />
                        </div>
                      )}
                      {editor.getAttributes('rss').settings.displayCta &&
                        (ctaStyle === RSSCTAStyle.BUTTON || ctaStyle === RSSCTAStyle.LINK) && (
                          <div className="flex flex-col gap-2">
                            <Typography token="font-medium/text/xs" color="white" colorWeight="900">
                              CTA Text
                            </Typography>
                            <InputField
                              value={ctaText}
                              defaultValue="View Article"
                              min={1}
                              onChange={onChangeCtaText}
                              className="w-full"
                              onBlur={onUpdateCtaText}
                            />
                          </div>
                        )}
                    </FieldSet>
                  </Panel>
                )
              }
            >
              <Tooltip title="Settings">
                <Button
                  $leftSlot={<Icon name="Settings" />}
                  onClick={() => {
                    setIsSettingsOpen(!isSettingsOpen);
                  }}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...buttonProps}
                />
              </Tooltip>
            </Tippy>
            <Tooltip title="Refresh Content">
              <Button
                $leftSlot={<Icon name="Refresh" />}
                onClick={() => {
                  API.getExternalRssFeed({ publicationId, externalRssFeedId }).then((response) => {
                    editor.chain().updateAttributes('rss', { data: response.data }).run();
                  });
                }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...buttonProps}
              />
            </Tooltip>
            <Tooltip title="Select New RSS Feed">
              <Button
                $leftSlot={<Icon name="Return" />}
                onClick={() => {
                  clearSelection();
                }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...buttonProps}
              />
            </Tooltip>
            <Divider />
          </>
        )}
        <Tooltip title="Remove Content">
          <Button
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...buttonProps}
            $leftSlot={<Icon name="Trash" />}
            onClick={removeNode}
          />
        </Tooltip>
      </Toolbar>
    </BaseBubbleMenu>
  );
};

export default RSSBlockMenu;
