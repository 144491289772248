import { useCallback } from 'react';
import { useEditorState } from '@tiptap/react';

import { AttributeSettingProps } from '../types';

import { BooleanSettings } from './BooleanSettings';

export const NewTabSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  const { href, target } = useEditorState<{ href: string; target: string }>({
    editor,
    selector: ({ editor: e }) => ({
      href: e.getAttributes('link').href,
      target: e.getAttributes('link').target || '_self',
    }),
  });

  const updateTarget = useCallback(
    (isNewTab: boolean) => {
      editor
        .chain()
        .extendMarkRange('link')
        .setLink({ href, target: isNewTab ? '_blank' : '_self' })
        .focus()
        .run();
    },
    [editor, href]
  );

  return (
    <BooleanSettings
      editor={editor}
      activeNodeResult={activeNodeResult}
      defaultValue={target === '_blank'}
      title="New Tab"
      onChange={updateTarget}
    />
  );
};
