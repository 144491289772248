import { LinkBreak } from '@phosphor-icons/react';
import classNames from 'classnames';

import { Text } from '../../../UI/Text';

type Props = {
  text?: string;
  onClick: () => void;
  className?: string;
};

const NotConnected = ({ text, onClick, className }: Props) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={classNames(
        className || 'absolute -top-8 left-1/2 transform -translate-x-1/2',
        'bg-white border border-wb-hr rounded-xl shadow-lg px-4 py-2 gap-2 flex items-center'
      )}
    >
      <div>
        <LinkBreak size={16} className="text-[#8B5CF6]" />
      </div>
      <Text size="xs" variant="secondary" as="span" className="text-[#98A2B3] hover:text-[#8B5CF6] font-medium">
        {text || 'Not connected to real data'}
      </Text>
    </button>
  );
};

export default NotConnected;
