import SectionRenderer from '../../helpers/SectionRenderer';
import { RenderCondition } from '../../RenderCondition';
import { AttributeSettingProps } from '../../types';
import BorderSettings from '../BorderSettings';
import { ColorSettings } from '../ColorSettings';
import { GapSettings } from '../GapSettings';
import { GridColumnSetting } from '../GridColumnSetting';
import { PaddingSettings } from '../PaddingSettings';

export const AuthorsWidgetSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  return (
    <RenderCondition editor={editor} allowedNodeTypes={['authors']}>
      <SectionRenderer title="Layout">
        <BorderSettings editor={editor} activeNodeResult={activeNodeResult} />
        <PaddingSettings editor={editor} activeNodeResult={activeNodeResult} property="padding" />
        <ColorSettings title="Color" property="backgroundColor" editor={editor} activeNodeResult={activeNodeResult} />
        <GapSettings title="Gap" property="gap" editor={editor} activeNodeResult={activeNodeResult} />
        <GridColumnSetting editor={editor} activeNodeResult={activeNodeResult} title="Columns" />
      </SectionRenderer>

      <SectionRenderer title="Card">
        <BorderSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          properties={{
            color: 'cardBorderColor',
            width: 'cardBorderWidth',
            style: 'cardBorderStyle',
            radius: 'cardBorderRadius',
          }}
        />
        <PaddingSettings editor={editor} activeNodeResult={activeNodeResult} property="cardPadding" />
        <ColorSettings
          title="Color"
          property="cardBackgroundColor"
          editor={editor}
          activeNodeResult={activeNodeResult}
        />
        <ColorSettings title="Name" property="cardTextColor" editor={editor} activeNodeResult={activeNodeResult} />
        <ColorSettings title="Socials" property="cardSocialFill" editor={editor} activeNodeResult={activeNodeResult} />
      </SectionRenderer>
    </RenderCondition>
  );
};
