import SectionRenderer from '@/routes/website/_components/DreamEditor/AttributesPanel/helpers/SectionRenderer';

import { useNavbarContext } from '../../../NavbarContext';

import { ColorSettings } from './ColorSettings';
import { FontSettings } from './FontSettings';
import { LogoSrcSettings } from './LogoSrcSettings';

export function NavItemStylingSettings() {
  const { selectedContent } = useNavbarContext();

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== 'navbar_item') return null;

  return (
    <SectionRenderer title="Styling">
      {selectedContent.attrs?.type !== 'logo' && <FontSettings />}
      {selectedContent.attrs?.type !== 'logo' && <ColorSettings title="Text" property="color" />}
      {selectedContent.attrs?.type === 'button' && <ColorSettings title="Fill" property="background" />}
      {selectedContent.attrs?.type === 'logo' && <LogoSrcSettings />}
    </SectionRenderer>
  );
}
