import { ReactNodeViewRenderer } from '@tiptap/react';

import { Heading as TiptapHeading } from '@/components/TiptapEditor/extensions/Heading';

import { HeadingView } from './views/HeadingView';

export const Heading = TiptapHeading.extend({
  renderHTML({ HTMLAttributes }) {
    // only take the data- attributes prefix
    const dataAttributes = Object.fromEntries(
      Object.entries(HTMLAttributes).filter(([key]) => key.startsWith('data-'))
    );

    return ['h1', dataAttributes, 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(HeadingView);
  },
});
