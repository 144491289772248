import { useState } from 'react';
import toast from 'react-hot-toast';
import { Navigate } from 'react-router-dom';

import Modal from '@/components/Modal';
import Text from '@/components/Text';
import { useCurrentUser } from '@/context/current-user-context';
import { PlanPrice } from '@/interfaces/plan_price';
import appendSettingsWorkspaceId from '@/utils/appendSettingsWorkspaceId';
import { PLAN } from '@/utils/plans';

import { useCreatePlanPriceChange } from '../../../_hooks';
import TierChangeModal from '../TierChangeModal';

import ChooseDowngradeOption from './ChooseDowngradeOption';

interface Props {
  organizationId: string;
  publicationId: string;
  planPrice: PlanPrice;
  isOpen: boolean;
  onClose: () => void;
}

const DowngradePlanModal: React.FC<Props> = ({ organizationId, publicationId, planPrice, isOpen, onClose }) => {
  const { currentUser } = useCurrentUser();
  const hasPublicationSettingsV2 = currentUser?.has_settings_v2;

  const [selectedOption, setSelectedOption] = useState<string>();

  const onCancel = () => {
    setSelectedOption(undefined);
    onClose();
  };

  const downgradeMutation = useCreatePlanPriceChange({
    publicationId,
    organizationId,
    onSuccess: () => {
      toast.success('Plan tier changed');
      setTimeout(() => window.location.reload(), 500);
    },
  });

  if (!planPrice) {
    return null;
  }

  const downgradRedirectPath = hasPublicationSettingsV2
    ? appendSettingsWorkspaceId('/settings/workspace/billing_and_plan/downgrade', organizationId, publicationId)
    : '/settings/billing/downgrade';

  // Custom plans must downgrade via reaching out to their CSM
  if (planPrice.plan_name === PLAN.CUSTOM) {
    return (
      <Modal isOpen={isOpen} onClose={onCancel}>
        <div className="p-8 max-w-2xl">
          <div className="space-y-4">
            <Text size="lg" type="bold" className="text-gray-800">
              Downgrading your enterprise plan
            </Text>
            <p className="text-base text-gray-500">Please contact your CSM to modify your plan.</p>
          </div>
        </div>
      </Modal>
    );
  }

  // If someone is on monthly Scale already, there isn't any downgrade options available other than going back to free, so go ahead
  // and redirect to the churn recovery flow. Also, if they have selected to downgrade to free, redirect to the churn recovery flow
  const onScaleMonthly = planPrice.plan_name === PLAN.SCALE && planPrice.interval === 'month';
  if ((isOpen && onScaleMonthly) || selectedOption === 'downgrade_to_free') {
    return <Navigate to={downgradRedirectPath} />;
  }

  // Send then through the tier change flow and limit their selections to just monthly prices
  if (selectedOption === 'downgrade_to_monthly') {
    return (
      <TierChangeModal
        isOpen={isOpen}
        isWorking={downgradeMutation.isLoading}
        onClose={onCancel}
        onSubmit={(newPlanPriceId) => downgradeMutation.mutate({ newPlanPriceId })}
        organizationId={organizationId}
        publicationId={publicationId}
        currentPlanPrice={planPrice}
        desiredInterval="month"
      />
    );
  }

  if (selectedOption === 'downgrade_to_scale') {
    return (
      <TierChangeModal
        isOpen={isOpen}
        isWorking={downgradeMutation.isLoading}
        onClose={onCancel}
        onSubmit={(newPlanPriceId) => downgradeMutation.mutate({ newPlanPriceId })}
        organizationId={organizationId}
        publicationId={publicationId}
        currentPlanPrice={planPrice}
        desiredPlanName={PLAN.SCALE}
        allowSettingInterval
      />
    );
  }

  // No option selected, give them the ability to choose how to proceed
  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <ChooseDowngradeOption planPrice={planPrice} onSelect={(option) => setSelectedOption(option)} />
    </Modal>
  );
};

export default DowngradePlanModal;
