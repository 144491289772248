import React, { useMemo, useState } from 'react';
import toast from 'react-hot-toast';

import ActionModal from '@/components/ActionModal';
import { SimpleSelect } from '@/components/Form';
import SplitButton from '@/components/SplitButton';
import Tooltip from '@/components/Tooltip';
import { Typography, TypographyStack } from '@/components/Typography';
import { useStripeBillingSession } from '@/hooks/useBilling';
import { usePausePlan } from '@/hooks/usePausePlan';
import { BillingActions } from '@/interfaces/billing';
import { PlanPrice, PlanPriceTierSizes } from '@/interfaces/plan_price';
import { capitalize } from '@/utils';
import { PLAN } from '@/utils/plans';
import pluralize from '@/utils/pluralize';

import { useCreatePlanPriceChange } from '../../_hooks';

import TierChangeModal from './TierChangeModal';

interface Props {
  activeSubscriptions: number;
  organizationId: string;
  publicationId: string;
  planPrice: PlanPrice;
  upcomingPlanPrice: PlanPrice;
}

const UpgradeSplitButton: React.FC<Props> = ({
  activeSubscriptions,
  organizationId,
  publicationId,
  planPrice,
  upcomingPlanPrice,
}) => {
  const { data: pausePlanData } = usePausePlan(organizationId);
  const isPaused = !!pausePlanData?.resumes_at;

  const [isUpgradeTierModalOpen, setIsUpgradeTierModalOpen] = useState(false);
  const [isLaunchUpgradeModalOpen, setIsLaunchUpgradeModalOpen] = useState(false);
  const neededMaxSubscriptions = useMemo(
    () => PlanPriceTierSizes.find((size) => size >= activeSubscriptions),
    [activeSubscriptions]
  );
  const [selectedMaxSubscriptions, setSelectedMaxSubscriptions] = useState(String(neededMaxSubscriptions));

  const isPendingDowngrade = planPrice?.id !== upcomingPlanPrice?.id;
  const onTopYearlyPlan = planPrice?.plan_name === PLAN.MAX && planPrice?.interval === 'year';
  const allowPlanUpgrade = planPrice?.plan_name !== PLAN.CUSTOM && !onTopYearlyPlan;
  const allowTierUpgrade = planPrice?.plan_name !== PLAN.CUSTOM && planPrice?.has_next_tier;

  const stripeUpgradeSession = useStripeBillingSession({
    publicationId,
    organizationId,
    action: BillingActions.UPGRADE,
  });

  const tierChangeMutation = useCreatePlanPriceChange({
    publicationId,
    organizationId,
    onSuccess: () => {
      toast.success('Plan tier changed!');
      setTimeout(() => window.location.reload(), 500);
    },
  });

  // If upgrading plans from the launch plan, we want to give you options for the tier size you want and then
  // take you to stripe. If the plan and not on launch, go directly to stripe.
  const onPlanUpgrade =
    planPrice.plan_name === PLAN.LAUNCH
      ? () => setIsLaunchUpgradeModalOpen(true)
      : () => stripeUpgradeSession.mutate({});

  if (!allowPlanUpgrade && !allowTierUpgrade) {
    return null;
  }

  return (
    <>
      {planPrice.plan_name === PLAN.LAUNCH && (
        <ActionModal
          isOpen={isLaunchUpgradeModalOpen}
          onClose={() => setIsLaunchUpgradeModalOpen(false)}
          onProceed={() =>
            stripeUpgradeSession.mutate({ maxSubscriptions: parseInt(String(selectedMaxSubscriptions), 10) })
          }
          isWorking={stripeUpgradeSession.isLoading}
          resourceId={organizationId}
          headerText="Select your desired number of subscribers"
          actionText="Upgrade"
          disabled={!neededMaxSubscriptions}
        >
          <TypographyStack gap="2">
            <Typography>
              Select your desired number of subscriptions below and then click Upgrade to be
              redirected to Stripe where you can select a plan and checkout.
            </Typography>
          </TypographyStack>
          <SimpleSelect
            className="my-6"
            name="desired_plan_price"
            value={selectedMaxSubscriptions}
            onSelect={(_, val) => setSelectedMaxSubscriptions(val)}
            options={PlanPriceTierSizes.map((num) => ({
              label: `Up to ${num.toLocaleString()} Subscriptions`,
              value: String(num),
            }))}
            helperText={`You currently have ${pluralize('subscription', activeSubscriptions.toLocaleString())} which means you will need to be on ${neededMaxSubscriptions ? `the ${neededMaxSubscriptions.toLocaleString()} subscription tier of the Scale or Max plan` : `a custom plan`} in order to send.`}
          />
        </ActionModal>
      )}

      <TierChangeModal
        isOpen={isUpgradeTierModalOpen}
        isWorking={tierChangeMutation.isLoading}
        onClose={() => setIsUpgradeTierModalOpen(false)}
        onSubmit={(newPlanPriceId) => tierChangeMutation.mutate({ newPlanPriceId })}
        organizationId={organizationId}
        publicationId={publicationId}
        currentPlanPrice={planPrice}
        isAvailableOption={(option) => option.max_subscriptions > planPrice.max_subscriptions}
      />
      <Tooltip
        id="organization-upgrade-button"
        showIcon={false}
        text={
          isPaused
            ? 'You must unpause your plan in order to make modifications'
            : 'You must cancel the pending downgrade to modify your plan.'
        }
        isEnabled={isPendingDowngrade || isPaused}
      >
        <SplitButton
          size="xs"
          variant="primary"
          disabled={isPendingDowngrade || isPaused}
          buttons={[
            {
              label: 'Upgrade Plan',
              helperText: 'Upgrade to annual billing or to Scale and Max plans',
              onClick: onPlanUpgrade,
              loading: stripeUpgradeSession.isLoading,
              disabled: !allowPlanUpgrade,
              disabledTooltip: 'You are currently on Max billed annually which means there are no further plan upgrades available.',
              button: {
                text: 'Upgrade',
              },
            },
            {
              label: 'Raise Subscriber Limit',
              helperText: 'Move to a higher tier of your current plan to unlock more subscribers',
              onClick: () => setIsUpgradeTierModalOpen(true),
              defaultSelected: !allowPlanUpgrade,
              disabled: !allowTierUpgrade,
              disabledTooltip: `You are currently on the highest tier of the ${capitalize(planPrice.plan_name)} plan. ${planPrice.plan_name === PLAN.LAUNCH && 'You need to upgrade plans in order to unlock higher subscriber limits.'}`,
              button: {
                text: 'Upgrade',
              },
            },
          ]}
        />
      </Tooltip>
    </>
  );
};

export default UpgradeSplitButton;
