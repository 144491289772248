import { Link } from 'react-router-dom';
import { DotsThree } from '@phosphor-icons/react';

import { Button } from '../../_components/UI/Button';
import { Text } from '../../_components/UI/Text';

import { DisplayOption } from './DisplayOption';

interface Props {
  redirectTo: string;
  name: string;
  siteTemplateId: string;
  onDeleteSuccess?: () => void;
}

export const DisplayCard = ({ redirectTo, name, siteTemplateId, onDeleteSuccess }: Props) => {
  return (
    <div>
      <Link className="flex flex-col group" to={redirectTo}>
        <div className="cursor-pointer p-4 overflow-hidden bg-neutral-50 dark:bg-neutral-900 border border-solid border-wb-primary shadow-wb-md aspect-video rounded-xl h-[150px] relative">
          <div className="overflow-hidden bg-wb-primary shadow-wb-xl rounded-md h-[200px] border border-solid border-wb-primary">
            <div className="w-[800%] scale-[0.125] h-[800%] overflow-hidden origin-top-left">
              {/* TODO: @putrikarunia render the actual page preview */}
              {/* <iframe src="https://beehiiv.com" className="w-full h-full" title={page?.draft_page_version?.name} /> */}
              {/* this iframe was causing a recursive loop */}
              <div className="absolute top-0 left-0 w-full h-full cursor-pointer" />
            </div>
          </div>
        </div>
      </Link>

      <div className="flex items-start gap-2 py-3">
        <div className="flex flex-col flex-1 text-ellipsis overflow-hidden">
          <Text size="sm" weight="semibold" as="p" className="truncate">
            {name}
          </Text>
        </div>

        <DisplayOption align="end" siteTemplateId={siteTemplateId} onDeleteSuccess={onDeleteSuccess}>
          <Button
            variant="secondary"
            size="sm"
            Icon={DotsThree}
            iconClassName="h-5 w-5 text-wb-secondary"
            className="p-1.5 opacity-0 group-hover:opacity-100 data-[state=open]:opacity-100 transition-all"
          />
        </DisplayOption>
      </div>
    </div>
  );
};
