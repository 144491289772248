import { useCallback } from 'react';

import { Button } from '@/routes/website/_components/UI/Button';
import { Text } from '@/routes/website/_components/UI/Text';

import SectionRenderer from '../../helpers/SectionRenderer';
import { RenderCondition } from '../../RenderCondition';
import { AttributeSettingProps } from '../../types';
import { AlignmentSettings } from '../AlignmentSettings';
import { ColorSettings } from '../ColorSettings';
import { FontSettings } from '../FontSettings';
import { FontSizeSettings } from '../FontSizeSettings';
import { FontStyleSettings } from '../FontStyleSettings';
import { FormatSettings } from '../FormatSettings';
import { HighlightSettings } from '../HighlightSettings';
import { LinkToSettings } from '../LinkToSettings';
import { NewTabSettings } from '../NewTabSettings';

export const TypographySettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  const hasLink = editor.isActive('link');
  const onRemoveLink = useCallback(() => editor.chain().focus().unsetLink().run(), [editor]);
  const onAddLink = useCallback(() => editor.chain().focus().setLink({ href: '' }).run(), [editor]);

  return (
    <RenderCondition editor={editor} allowedNodeTypes={['paragraph', 'heading']}>
      <SectionRenderer title="Typography">
        <AlignmentSettings editor={editor} activeNodeResult={activeNodeResult} />
        <FormatSettings editor={editor} />
        <FontSettings editor={editor} />
        <FontStyleSettings editor={editor} />
        <FontSizeSettings editor={editor} activeNodeResult={activeNodeResult} />
        <ColorSettings
          title="Text"
          property="color"
          editor={editor}
          activeNodeResult={activeNodeResult}
          isMarkStyle
          hasGradientPicker
          isFontGradient
        />
        <HighlightSettings editor={editor} />
        {hasLink ? (
          <SectionRenderer
            title="Link"
            actions={
              <Button variant="ghost" size="sm" className="text-wb-accent" onClick={onRemoveLink}>
                Remove
              </Button>
            }
            collapsible={hasLink}
          >
            <LinkToSettings editor={editor} activeNodeResult={activeNodeResult} />
            <NewTabSettings editor={editor} activeNodeResult={activeNodeResult} />
          </SectionRenderer>
        ) : (
          <div className="border-b border-border">
            <div className="my-5 flex justify-between">
              <Text variant="primary" weight="semibold">
                Link
              </Text>
              <Button variant="ghost" size="sm" className="text-wb-accent" onClick={onAddLink}>
                Add
              </Button>
            </div>
          </div>
        )}
      </SectionRenderer>
    </RenderCondition>
  );
};
