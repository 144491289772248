import { useCallback } from 'react';
import { Palette } from '@phosphor-icons/react';

import { useCurrentPublication } from '@/hooks';
import { useSite } from '@/hooks/useSite';
import { WebTheme } from '@/interfaces/web_theme';

import { Button } from '../../../../../UI/Button';
import { Popover, PopoverContent, PopoverTrigger } from '../../../../../UI/Popover';
import { Text } from '../../../../../UI/Text';
import { Tooltip } from '../../../../../UI/Tooltip';
import { AttributeSettingProps } from '../../../types';

import { THEME_ATTRS } from './consts';

const ThemeSettings = ({ editor }: AttributeSettingProps) => {
  const { data: currentPublication } = useCurrentPublication();
  const { data: site } = useSite();
  const webTheme = currentPublication?.web_theme;

  const onApplyTheme = useCallback(() => {
    if (!site?.theme_rules) return;
    if (!webTheme) return;

    editor
      .chain()
      .focus()
      .applyTheme({
        theme: webTheme,
        mapping: site?.theme_rules,
      })
      .run();
  }, [editor, site?.theme_rules, webTheme]);

  return (
    <Popover>
      <Tooltip center="Apply Theme" delay={300}>
        <PopoverTrigger asChild>
          <Button variant="secondary" size="sm" LeftIcon={Palette} className="text-wb-secondary" onClick={() => {}} />
        </PopoverTrigger>
      </Tooltip>

      <PopoverContent className="w-[325px] p-0" align="start" side="left" sideOffset={110}>
        <div className="max-h-[500px] overflow-y-auto p-3 flex flex-col gap-4">
          <div className="flex items-center justify-between gap-2">
            <Text size="sm" weight="semibold">
              Theme
            </Text>
          </div>

          <div className="flex flex-col gap-2">
            {THEME_ATTRS.map((attr) => {
              const normalizedAttr = attr.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
              const value = webTheme?.[attr as keyof WebTheme];

              return (
                <div key={attr} className="flex items-center justify-between gap-2">
                  <Text size="xs" weight="medium" variant="secondary">
                    {normalizedAttr}
                  </Text>
                  <div className="flex items-center gap-2">
                    <Text size="xs" weight="medium">
                      {value}
                    </Text>
                    {value?.includes('#') && (
                      <div
                        className="w-4 h-4 rounded-md shadow-sm border border-wb-secondary"
                        style={{ backgroundColor: value }}
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </div>

          <Button variant="primary" onClick={onApplyTheme}>
            Apply Theme
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default ThemeSettings;
