import { ATTRIBUTES_PANEL_ID } from '../DreamEditor/constants';

const AttributesPanelPortal = () => {
  return (
    <div
      className="w-[250px] min-w-[250px] h-full bg-wb-primary border-l border-solid border-wb-primary overflow-y-scroll"
      id={ATTRIBUTES_PANEL_ID}
    />
  );
};

export default AttributesPanelPortal;
