import { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';

import { useUpdateWebTemplate } from '@/hooks';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import analytics from '@/utils/analytics';

import ActionModal from '../../../../../../components/ActionModal';
import { Switch } from '../../../../../../components/Form';
import { UpgradeIntent } from '../../../../../../components/UpgradeIntent';
import { useSettings } from '../../../../../../context/settings-context';
import { useCurrentPublication, useUpdatePublication } from '../../../../../../hooks/usePublications';
import useUpgradeIntent from '../../../../../../hooks/useUpgradeIntent';
import { IntentAction } from '../../../../../../interfaces/upgrades';
import { SignupFlowItem, WebTemplate } from '../../../../../../interfaces/web_template';
import { PLAN } from '../../../../../../utils/plans';

import FlowStep from './FlowStep';
import FormOptions from './FormOptions';
import { UpdateMessage, useUpdateMessage } from './UpdateMessage';

interface Props {
  step: SignupFlowItem;
  webTemplate: WebTemplate;
}

const SubscribeSurveyStep = ({ step, webTemplate }: Props) => {
  const { settings } = useSettings();
  const queryClient = useQueryClient();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDisableModalOpen, setIsDisableModalOpen] = useState(false);
  const [selectedForm, setSelectedForm] = useState<any>(null);
  const { data: currentPublication } = useCurrentPublication();
  const currentPublicationId = useCurrentPublicationId();
  const publicationMutation = useUpdatePublication(currentPublicationId);
  const webTemplateMutation = useUpdateWebTemplate();
  const signupMessaging = webTemplate?.signup_flow_messaging;

  const { isOpen, handleOpen, handleClose } = useUpgradeIntent({
    publicationId: currentPublicationId,
    intentAction: IntentAction.USE_FORMS,
  });
  const {
    isOpen: isMessageModalOpen,
    message,
    handleClose: handleCloseMessageModal,
    handleOpen: handleOpenMessageModal,
    onChange,
  } = useUpdateMessage({ message: signupMessaging?.subscribe_survey_message || '' });

  const handleUpdateMessage = async () => {
    await webTemplateMutation.mutateAsync({
      signup_flow_messaging: JSON.stringify({
        ...signupMessaging,
        subscribe_survey_message: message,
      }),
    });

    queryClient.invalidateQueries(['web_template', currentPublication?.id]);
    toast.success('Saved!');
    handleCloseMessageModal();
  };

  const removeSubscribeSurvey = async () => {
    await publicationMutation.mutateAsync({ post_subscribe_form_id: null });
    queryClient.invalidateQueries(['web_template', currentPublication?.id]);
    toast.success('Saved!');
    setIsDisableModalOpen(false);
  };

  const handleClick = () => {
    setIsModalOpen(true);
  };

  const handleSetSelection = (selectedValue: string) => {
    setSelectedForm(selectedValue);
  };

  const onProceed = async () => {
    if (!selectedForm) {
      toast.error('Please select a form');
      return;
    }

    analytics.track('Add Subscribe Survey');
    await publicationMutation.mutateAsync({
      post_subscribe_form_id: selectedForm,
    });

    queryClient.invalidateQueries(['web_template', currentPublication?.id]);
    toast.success('Saved!');
    setIsModalOpen(false);
  };

  return (
    <>
      {/* Disable Action Modal */}
      <ActionModal
        isOpen={isDisableModalOpen}
        onClose={() => setIsDisableModalOpen(false)}
        resourceId=""
        headerText={step.modal_header}
        onProceed={removeSubscribeSurvey}
        isWorking={publicationMutation.isLoading}
        actionText={step.action_text}
      >
        <div className="text-sm space-y-4">{step.modal_body}</div>
      </ActionModal>

      {/* Enable Action Modal */}
      {settings?.forms ? (
        <ActionModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          resourceId=""
          headerText={currentPublication?.post_subscribe_form_id ? 'Update Subscribe Survey' : 'Add Subscribe Survey'}
          onProceed={onProceed}
          isWorking={publicationMutation.isLoading}
          actionText={currentPublication?.post_subscribe_form_id ? 'Update' : 'Add Survey'}
        >
          <div className="text-sm space-y-4">
            <FormOptions onSelection={handleSetSelection} defaultValue={currentPublication?.post_subscribe_form_id} />
          </div>
        </ActionModal>
      ) : (
        <UpgradeIntent
          isOpen={isOpen}
          onClose={handleClose}
          intentAction={IntentAction.USE_FORMS}
          preselectedPlan={PLAN.GROW}
        />
      )}

      {/** Custom Page Message Modal */}
      <UpdateMessage
        isOpen={isMessageModalOpen}
        onClose={handleCloseMessageModal}
        isLoading={false}
        message={message}
        onProceed={handleUpdateMessage}
        name={step?.title?.split(' ')?.join('_')?.toLowerCase()}
        onChange={onChange}
      />

      <FlowStep
        title={step.title}
        enabled={step.enabled}
        url={`${step.full_url}?email=preview@beehiiv.com`}
        onEdit={currentPublication?.post_subscribe_form_id ? () => setIsModalOpen(true) : undefined}
        onUpdateMessage={handleOpenMessageModal}
      >
        <Switch
          name="survey"
          checked={step.enabled}
          onChange={() => {
            if (step.enabled) return setIsDisableModalOpen(true);

            return settings?.forms ? handleClick() : handleOpen();
          }}
          variant="primary"
        />
      </FlowStep>
    </>
  );
};

export default SubscribeSurveyStep;
