const DEFAULT_BACKGROUND_SECONDARY_COLOR = '#F9FAFB';
const WHITE_COLOR = '#FFFFFF';
const BLACK_COLOR = '#000000';

const DEFAULT_PRIMARY_COLOR = '#030712';
const DEFAULT_TEXT_ON_PRIMARY_COLOR = '#F9FAFB';
const DEFAULT_BORDER_COLOR = '#E5E7EB';
const DEFAULT_BORDER_SECONDARY_COLOR = '#f1f1f1';

const DEFAULT_BORDER_RADIUS = '8px';


export {
  BLACK_COLOR,
  DEFAULT_BACKGROUND_SECONDARY_COLOR,
  DEFAULT_BORDER_COLOR,
  DEFAULT_BORDER_RADIUS,
  DEFAULT_BORDER_SECONDARY_COLOR,
  DEFAULT_PRIMARY_COLOR,
  DEFAULT_TEXT_ON_PRIMARY_COLOR,
  WHITE_COLOR,
};
