import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { CaretUp, ClockCounterClockwise } from "@phosphor-icons/react";

import { Button } from "../../UI/Button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../../UI/DropdownMenu";
import { Input } from "../../UI/Input";
import Modal from "../../UI/Modal"
import { Text } from "../../UI/Text";

export const SaveVersionPopup = ({ onSaveToVersionHistory, isSaveVersionLoading }:
  {
    onSaveToVersionHistory: (isDraft: boolean, versionName: string) => Promise<void>;
    isSaveVersionLoading: boolean;
  }
) => {

  const [isOpen, setIsOpen] = useState(false);

  const [versionName, setVersionName] = useState('');

  const handleSaveToVersionHistory = async () => {
    await onSaveToVersionHistory(false, versionName);
    toast.success('Version saved to version history');
    setIsOpen(false);
  }

  const reset = () => {
    setVersionName('');
  }

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen]);

  return <>
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          size="sm"
          Icon={CaretUp}
          className="p-[2px] rounded-full"
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" sideOffset={16} alignOffset={-8}>
        <DropdownMenuItem
          LeftIcon={ClockCounterClockwise}
          onClick={() => setIsOpen(true)}
        >
          Save to Version History
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu><Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title="Save to Version History"
      onConfirm={handleSaveToVersionHistory}
      ctaText={isSaveVersionLoading ? 'Saving...' : 'Save'}
      ctaVariant="primary"
      isLoading={isSaveVersionLoading}
    >
      <Text size="sm" weight="medium" variant="secondary" as="p" className='mb-4'>
        You&apos;ll be able to view and restore this version later.
      </Text>
      <Input
        labelText="Version Name"
        placeholder="e.g. Version 1 - Added Hero Section"
        value={versionName}
        onChange={(e) => {
          setVersionName(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSaveToVersionHistory();
          }
        }}
      />
    </Modal>
  </>
}
