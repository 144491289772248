import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SerializableNode } from '@shared/dream-components';
import { Editor } from '@tiptap/core';

import { useBeforeNavigate } from '@/hooks/useBeforeNavigate';
import { usePage } from '@/hooks/usePages';
import usePageVersion from '@/hooks/usePageVersion/usePageVersion';
import useUpdatePageVersion from '@/hooks/usePageVersion/useUpdatePageVersion';
import useCreateSiteVersion from '@/hooks/useSiteVersion/useCreateSiteVersion';
import { findPageVersionFromRoute } from '@/routes/website/_utils/findPageVersionFromRoute';
import { getAllRoutes } from '@/routes/website/_utils/getAllRoutes';

import { useWebsiteContext } from './website-context';

interface IDreamEditorContext {
  editor: Editor | null;
  setEditor: (editor: Editor) => void;
  initialContent: SerializableNode | null;
  save: () => void;
  changesMade: boolean;
  setChangesMade: (changesMade: boolean) => void;
  isSaveLoading: boolean;
  onSaveToVersionHistory: (autoSave: boolean, versionName?: string) => Promise<void>;
  isSaveVersionLoading: boolean;
  previewContent: SerializableNode | undefined;
}

export const DreamEditorContext = createContext<IDreamEditorContext | undefined>(undefined);

DreamEditorContext.displayName = 'DreamEditorContext';

export const DreamEditorProvider = ({ children }: { children: React.ReactNode }) => {
  const { previewSiteVersion } = useWebsiteContext();
  const [initialContent, setInitialContent] = useState<SerializableNode | null>(null);
  const [changesMade, setChangesMade] = useState(false);

  const { pageId } = useParams();
  const { data: page, isLoading: isPageLoading } = usePage({ pageId: pageId as string });

  const allRoutes =
    previewSiteVersion?.routes && previewSiteVersion?.default_routes
      ? getAllRoutes(previewSiteVersion.routes, previewSiteVersion.default_routes)
      : undefined;
  const pageVersionId = allRoutes ? findPageVersionFromRoute(allRoutes, pageId as string) : undefined;

  const { data: previewPageVersion } = usePageVersion({
    pageId: pageId as string,
    pageVersionId: pageVersionId as string,
    enabled: !!pageVersionId,
  });

  const [editor, setEditor] = useState<Editor | null>(null);

  // Handle moving to new page
  const currentPageIdRef = useRef(pageId);
  useEffect(() => {
    if (pageId !== currentPageIdRef.current) {
      editor?.commands.blur();
      setInitialContent(null);
      currentPageIdRef.current = pageId;
      return;
    }

    // page id changed
    if (pageId && page && !isPageLoading && !initialContent) {
      setInitialContent(page?.draft_page_version?.content as SerializableNode);
    }
  }, [pageId, page, initialContent, isPageLoading, editor]);

  const {
    mutate: updatePageVersion,
    mutateAsync: updatePageVersionAsync,
    isLoading: isSaveLoading,
  } = useUpdatePageVersion({
    pageId: page?.id || '',
    id: page?.draft_page_version?.id || '',
  });

  const { isLoading: isSaveVersionLoading, mutateAsync: createSiteVersionAsync } = useCreateSiteVersion({});

  const save = useCallback(() => {
    updatePageVersion({ content: editor?.getJSON() });
    setChangesMade(false);
  }, [updatePageVersion, editor]);

  const onSaveToVersionHistory = useCallback(
    async (autoSave: boolean = false, versionName?: string): Promise<void> => {
      if (previewSiteVersion) return; // don't save if previewing other versions

      await updatePageVersionAsync({ content: editor?.getJSON() });
      setChangesMade(false);
      await createSiteVersionAsync({
        version_name: versionName,
        version_type: autoSave ? 'autosave' : 'usersave',
      });
    },
    [updatePageVersionAsync, createSiteVersionAsync, editor, previewSiteVersion]
  );

  // Auto save every 10 seconds
  useEffect(() => {
    const autoSaveInterval = setInterval(() => {
      if (changesMade) {
        save();
      }
    }, 10000); // 10 seconds

    return () => {
      clearInterval(autoSaveInterval);
    };
  }, [changesMade, save]);

  // Auto save Version History every 10 minutes
  useEffect(() => {
    const autoSaveInterval = setInterval(() => {
      onSaveToVersionHistory(true);
    }, 10 * 60000); // 10 minutes

    return () => {
      clearInterval(autoSaveInterval);
    };
  }, [changesMade, onSaveToVersionHistory]);

  // Save on navigate away & prompt on reload or leaving page
  useBeforeNavigate(save, !!changesMade);

  const value = useMemo(
    () => ({
      initialContent,
      editor,
      setEditor,
      changesMade,
      setChangesMade,
      save,
      isSaveLoading,
      onSaveToVersionHistory,
      previewContent: previewPageVersion?.content as SerializableNode,
      isSaveVersionLoading,
    }),
    [
      initialContent,
      setEditor,
      changesMade,
      save,
      isSaveLoading,
      onSaveToVersionHistory,
      isSaveVersionLoading,
      previewPageVersion?.content,
      editor,
    ]
  );

  return <DreamEditorContext.Provider value={value}>{children}</DreamEditorContext.Provider>;
};

export const useDreamEditorContext = () => {
  const context = useContext(DreamEditorContext);
  if (!context) {
    throw new Error('useDreamEditorContext must be used within a DreamEditorProvider');
  }
  return context;
};
