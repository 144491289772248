import { ArrowRightIcon } from '@heroicons/react/20/solid';

import { Typography } from '@/components/Typography';
import { DnsRecord } from '@/interfaces/custom_domain';

interface DnsRecordProps {
  record: DnsRecord;
  className?: string;
  replaceWithValue?: string;
}

const DnsRecordDisplay: React.FC<DnsRecordProps> = ({ record, className, replaceWithValue }: DnsRecordProps) => (
  <div className={`table ${className}`}>
    <div className="table-row-group">
      <div className="table-row">
        <div className="table-cell pt-1">
          <Typography token="font-semibold/text/sm" colorWeight="700">
            Type:
          </Typography>
        </div>
        <div className="table-cell pl-2">
          <Typography token="font-normal/text/sm" colorWeight="500" as="code" className="bg-surface-100 px-2 py-1">
            {record.type}
          </Typography>
        </div>
      </div>
      <div className="table-row">
        <div className="table-cell pt-1">
          <Typography token="font-semibold/text/sm" colorWeight="700">
            Name:
          </Typography>
        </div>
        <div className="table-cell pl-2">
          <Typography
            token="font-normal/text/sm"
            colorWeight="500"
            as="code"
            className="bg-surface-100 px-2 py-1 rounded-sm"
          >
            {record.name}
          </Typography>
        </div>
      </div>
      <div className="table-row">
        <div className="table-cell pt-1">
          <Typography token="font-semibold/text/sm" colorWeight="700">
            Value:
          </Typography>
        </div>
        <div className="table-cell pl-2">
          <div className="flex items-center">
            <Typography
              token="font-normal/text/sm"
              colorWeight="500"
              as="code"
              className="bg-surface-100 px-2 py-1 rounded-sm"
            >
              {record.value}
            </Typography>

            {replaceWithValue && (
              <>
                <ArrowRightIcon className="w-4 h-4 mx-2 inline-block" />
                <Typography
                  token="font-normal/text/sm"
                  colorWeight="500"
                  as="code"
                  className="bg-surface-100 px-2 py-1 rounded-sm"
                >
                  {replaceWithValue}
                </Typography>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default DnsRecordDisplay;
