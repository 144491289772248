import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { AxiosError } from 'axios';
import cx from 'classnames';

import { AssetSelect, DatePicker, Input, Switch, Textarea } from '@/components/Form';
import { SEOPreview } from '@/components/SEOPreview';
import Text from '@/components/Text';
import { API } from '@/components/TiptapEditor/lib/api';
import { useSettings } from '@/context/settings-context';
import { useCurrentPublication } from '@/hooks';
import { usePremiumSettings } from '@/hooks/usePublications';
import useUsers from '@/hooks/useUsers';
import { Asset } from '@/interfaces/asset';
import { CommentSectionState } from '@/interfaces/comment';
import { Post, PostStatus } from '@/interfaces/post';
import { Dropdown } from '@/ui/Dropdown';
import { capitalize } from '@/utils';

import SlugInput from '../SlugInput';

import Authors from './Authors';
import ContentTags from './ContentTags';
import FeaturePostAction from './FeaturePostAction';
import GuestAuthors from './GuestAuthors';
import PostDelivery from './PostDelivery';
import SplitTestForm from './SplitTestForm';
import { TextToSpeechConfig } from './TextToSpeechConfig';
import { SidebarTab } from './types';

interface Props {
  post: Post;
  errors: { [key: string]: string };
  onChange: (data: any) => void;
  activeSection: SidebarTab;
}

const COMMENT_SECTION_OPTIONS = [
  {
    value: CommentSectionState.DEFAULT,
    label: 'All subscribers', // For right now, if the global settings is disabled then this won't show in the UI anyways, so it's okay to hardcode as Enabled here.
  },
  {
    value: CommentSectionState.PREMIUM,
    label: 'Paid subscribers only',
  },
  {
    value: CommentSectionState.DISABLED,
    label: 'Disabled: Hide comments section',
  },
  {
    value: CommentSectionState.LOCKED,
    label: 'Locked: Show comments section, but disable new comments',
  },
];

const Configure = ({ post, onChange, errors, activeSection }: Props) => {
  const { settings } = useSettings();
  const { data: publication } = useCurrentPublication();
  const { data: premiumSettings } = usePremiumSettings();
  const isPremiumEnabled = premiumSettings?.premium_enabled;
  const permissionOptions = COMMENT_SECTION_OPTIONS.filter((option) => {
    if (option.value === CommentSectionState.PREMIUM) {
      return isPremiumEnabled;
    }

    return true;
  });

  const contentTagsEnabled = post.content_tags_enabled;
  const hasAtLeastOneCustomSEOField =
    post.meta_default_title ||
    post.meta_default_description ||
    post.meta_og_title ||
    post.meta_og_description ||
    post.meta_twitter_title ||
    post.meta_twitter_description;

  const [showSEODetails, setShowSEODetails] = useState(Boolean(hasAtLeastOneCustomSEOField));
  const [showAdvancedEmailOptions, setShowAdvancedEmailOptions] = useState(false);
  const [thumbnail, setThumbnail] = useState<Asset | null>(post.thumbnail);
  const [splitTesting, setSplitTesting] = useState(post.split_test);
  const [adOpportunities, setAdOpportunities] = useState<
    Array<{ id: string; advertiser_name: string; selected_date: string }>
  >([]);

  const MAX_META_TITLE_LENGTH = 70;
  const MAX_META_DESCRIPTION_LENGTH = 160;

  const metaTitleFallback = post.meta_default_title || post.web_title;
  const metaDescriptionFallback = post.meta_default_description || post.web_subtitle || publication?.description || '';

  const { data: users, isLoading: isLoadingUsers, isError: isErrorUsers } = useUsers();

  const handlePostTitleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const updatedPostData: Partial<Post> = {
        web_title: e.target.value,
      };

      if (!post.email_subject_line || post.email_subject_line === post.web_title) {
        updatedPostData.email_subject_line = updatedPostData.web_title;
      }

      onChange(updatedPostData);
    },
    [post, onChange]
  );

  const handlePostSubtitleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const updatedPostData: Partial<Post> = {
        web_subtitle: e.target.value,
      };

      if (!post.email_preview_text || post.email_preview_text === post.web_subtitle) {
        updatedPostData.email_preview_text = updatedPostData.web_subtitle;
      }

      onChange(updatedPostData);
    },
    [post, onChange]
  );

  useEffect(() => {
    API.getAdvertisementOpportunitiesOptions({
      publicationId: publication?.id || '',
      page: 1,
    })
      .then((res) => {
        setAdOpportunities(res.data.options);
      })
      .catch((errPayload: AxiosError) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      });
  }, [publication]);

  if (!publication) return null;

  let updatedShareOptions = post.social_share_options.filter((option) => option !== 'comments_and_likes_only');
  if (post.platform !== 'both') {
    updatedShareOptions = post.social_share_options.filter((option) => option !== 'with_comments_and_likes');
  }

  return (
    <>
      {/* Section */}
      <div className={cx(activeSection === 'post' ? 'block' : 'hidden')}>
        <div className="p-4">
          <div className="flex text-lg font-bold items-center space-x-2">
            <div>Post Settings</div>
            <div className="pt-0.5">
              {errors?.web_title && <ExclamationCircleIcon className="h-4 w-4 text-red-500" />}
            </div>
          </div>
          {adOpportunities.length > 0 && (
            <div className="bg-gray-200 p-4 mt-4 rounded w-full border text-gray-800">
              <div className="flex space-x-4 pb-4">
                <div>💰</div>
                <div>You have an ad available!</div>
                <div>💰</div>
              </div>
              <div className="flex text-sm space-x-2">
                <div>Type</div>
                <pre>/advertisement</pre>
                <div>in the Composer to monetize via ads</div>
              </div>
            </div>
          )}
        </div>
        {/* Content */}
        <div className="p-4">
          <div className="space-y-6 pb-4">
            <Input
              name="title"
              value={post.web_title}
              labelText="Title"
              onChange={handlePostTitleChange}
              required
              errorText={errors.web_title}
            />
            <Switch
              size="small"
              name="title_in_email"
              labelText="Show title in email"
              checked={post.display_title_in_email}
              onChange={(_name: string, value: boolean) => onChange({ display_title_in_email: value })}
            />
            <Input
              name="subtitle"
              value={post.web_subtitle || ''}
              labelText="Subtitle"
              onChange={handlePostSubtitleChange}
            />
            <Switch
              size="small"
              name="subtitle_in_email"
              labelText="Show subtitle in email"
              checked={post.display_subtitle_in_email}
              onChange={(_name: string, value: boolean) => onChange({ display_subtitle_in_email: value })}
            />
            {contentTagsEnabled && (
              <ContentTags
                tags={post.content_tag_options.map((tag) => ({ id: tag[0], name: tag[1] }))}
                selectedTags={post.content_tag_ids}
                handleChange={onChange}
              />
            )}
            {!isLoadingUsers && !isErrorUsers && users && (
              <Authors
                handleChange={onChange}
                users={users}
                defaultSelection={users.filter((user) => post.user_ids.includes(user.id)).map((user) => user.id)}
              />
            )}
            <GuestAuthors post={post} onChange={onChange} />

            <div>
              <Switch
                size="small"
                name="byline_in_email"
                labelText="Show byline in email"
                checked={post.display_byline_in_email}
                onChange={(_name: string, value: boolean) => onChange({ display_byline_in_email: value })}
              />
            </div>
            <div>
              <Switch
                size="small"
                name="hide_from_feed"
                labelText="Hide from feed"
                checked={post.hide_from_feed}
                onChange={(_name: string, value: boolean) => onChange({ hide_from_feed: value })}
              />
            </div>
            <div>
              <div className="text-sm font-medium text-gray-800 pb-2">Displayed Date</div>
              <DatePicker
                value={post.override_scheduled_at}
                onChange={(date) => {
                  const setDate = date || null; // force null if undefined

                  onChange({ override_scheduled_at: setDate });
                }}
                helperText="Setting this will not affect the actual publish time"
                maxDate={new Date()}
              />
            </div>
          </div>
        </div>
      </div>
      {/* Section */}
      <div className={cx(activeSection === 'email' ? 'block' : 'hidden')}>
        <div className="p-4">
          <div className="flex text-lg font-bold items-center space-x-2">
            <div>Email Settings</div>
            <div className="pt-0.5">
              {errors?.email_subject_line && <ExclamationCircleIcon className="h-4 w-4 text-red-500" />}
            </div>
          </div>
        </div>
        {/* Content */}
        <div className="p-4">
          <div className="space-y-6 pb-4">
            <div className="space-y-3">
              <SplitTestForm post={post} splitTesting={splitTesting} setSplitTesting={setSplitTesting}>
                <Input
                  name="subject_line"
                  value={post.email_subject_line || ''}
                  labelText="Subject Line A"
                  onChange={(e) => onChange({ email_subject_line: e.target.value })}
                  required
                  disabled={post.locked_fields.email_subject_line || post.status !== PostStatus.DRAFT}
                  errorText={errors.email_subject_line}
                  helperText={
                    post.locked_fields.email_subject_line
                      ? 'You can no longer change the subject line'
                      : 'Will be same as post title unless modified'
                  }
                />
              </SplitTestForm>
              {!splitTesting && (
                <Input
                  name="subject_line"
                  value={post.email_subject_line || ''}
                  labelText="Subject Line"
                  onChange={(e) => onChange({ email_subject_line: e.target.value })}
                  required
                  disabled={post.locked_fields.email_subject_line}
                  errorText={errors.email_subject_line}
                  helperText={
                    post.locked_fields.email_subject_line
                      ? 'You can no longer change the subject line'
                      : 'Will be same as post title unless modified'
                  }
                />
              )}
            </div>
            <Input
              name="preview_text"
              value={post.email_preview_text || ''}
              labelText="Preview Text"
              onChange={(e) => onChange({ email_preview_text: e.target.value })}
              disabled={post.locked_fields.email_preview_text}
              helperText={
                post.locked_fields.email_preview_text
                  ? 'You can no longer change the preview text'
                  : 'Will be same as post subtitle unless modified'
              }
            />
            <div>
              <Dropdown
                disabled={post.locked_fields.social_share}
                name="social_share"
                labelText="Display social and engagement buttons in email header?"
                value={post.social_share}
                onSelect={(_name: string, value: string) => onChange({ social_share: value })}
                options={updatedShareOptions.map((o) => {
                  const newLabel = (l: string) => {
                    if (l === 'top') return 'social_buttons';
                    if (l === 'with_comments_and_likes') return 'social_and_engagement_buttons';

                    return o;
                  };

                  const label = newLabel(o)
                    .split('_')
                    .map((word) => capitalize(word))
                    .join(' ');

                  return {
                    value: o,
                    label,
                  };
                })}
                helperText={
                  post.locked_fields.social_share
                    ? 'You can no longer change social share settings'
                    : 'These buttons will appear in the email for readers to share, like and comment on this specific post.'
                }
              />
            </div>

            <div className="flex flex-col space-y-1">
              <button
                className="flex items-center bg-transparent hover:bg-gray-50 rounded w-fit py-2"
                type="button"
                onClick={() => setShowAdvancedEmailOptions(!showAdvancedEmailOptions)}
              >
                <ChevronDownIcon
                  className={cx(
                    'h-5 w-5 mr-2 text-gray-700 transform transition-all',
                    showAdvancedEmailOptions ? 'rotate-180' : ''
                  )}
                />{' '}
                <Text type="subtitle" size="sm" className="font-semibold w-fit">
                  Advanced
                </Text>
              </button>
              <Text type="body" size="xs" className="font-light">
                Expand the dropdown for advanced customization of your email.
              </Text>
            </div>

            {showAdvancedEmailOptions && (
              <div className="flex flex-col space-y-4">
                <div className="flex flex-col space-y-2">
                  <div className="flex flex-col space-y-4 px-4 py-4 border-l-4 border-gray-100 ">
                    <Input
                      name="custom_live_url"
                      value={post.custom_live_url || ''}
                      labelText="Read Online URL"
                      onChange={(e) => onChange({ custom_live_url: e.target.value })}
                      errorText={errors.custom_live_url}
                      disabled={post.locked_fields.custom_live_url}
                      helperText={
                        post.locked_fields.custom_live_url
                          ? 'You can no longer change the Read Online URL'
                          : 'By default, the Read Online link will lead to web version of your post.'
                      }
                    />
                    <Text size="xs" type="bold">
                      We recommend leaving this blank unless you want to deliberately send those who click &quot;Read
                      Online&quot; in your newsletter to content hosted on a non-beehiiv website.
                    </Text>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Section */}
      <div className={cx(activeSection === 'website' ? 'block' : 'hidden')}>
        <div className="p-4">
          <div className="flex text-lg font-bold items-center space-x-2">
            <div>Website Settings</div>
          </div>
        </div>
        {/* Content */}
        <div className="p-4">
          <div className="space-y-8 pb-4">
            <SlugInput postId={post.id} postScheduledAt={post.scheduled_at} slug={post.slug} />
            <Dropdown
              name="email_capture_type_override"
              labelText="Advanced email capture"
              value={post.email_capture_type_override || post.email_capture_type}
              onSelect={(_name: string, value: string) => onChange({ email_capture_type_override: value })}
              options={post.email_capture_type_override_options.map((o) => ({
                value: o,
                label: capitalize(o),
              }))}
            />
            {publication?.comments_enabled && (
              <Dropdown
                name="comments_state"
                labelText="Comments Section"
                value={post.comments_state}
                onSelect={(_name: string, value: string) => onChange({ comments_state: value })}
                options={permissionOptions}
              />
            )}
            <AssetSelect
              labelText="Thumbnail"
              asset={thumbnail}
              publicationId={publication.id}
              dimensionSuggestion="1200px x 630px recommended"
              onSelect={(selected) => {
                setThumbnail(selected);
                onChange({ thumbnail_id: selected.id });
              }}
              onClear={() => {
                setThumbnail({} as Asset);
                onChange({ thumbnail_id: null });
              }}
            />
            <Switch
              size="small"
              name="display_thumbnail_on_web"
              labelText="Display image at the top of web version"
              checked={post.display_thumbnail_on_web}
              onChange={(_name: string, value: boolean) => onChange({ display_thumbnail_on_web: value })}
            />
            <FeaturePostAction post={post} />
            {settings?.editor_text_to_speech && <TextToSpeechConfig postId={post.id} />}
          </div>
        </div>
      </div>

      {/* Section */}
      <div className={cx(activeSection === 'seo' ? 'block' : 'hidden')}>
        <div className="p-4">
          <div className="flex text-lg font-bold items-center space-x-2">
            <div>SEO Settings</div>
          </div>
        </div>
        {/* Content */}
        <div className="p-4">
          <div className="space-y-6 pb-4">
            <div className="flex flex-col space-y-4">
              <Input
                name="meta_default_title"
                value={post.meta_default_title || ''}
                placeholderText={metaTitleFallback}
                labelText="Title"
                onChange={(e) => onChange({ meta_default_title: e.target.value })}
                errorText={errors.meta_default_title}
                maxLength={MAX_META_TITLE_LENGTH}
              />
              <Textarea
                name="meta_default_description"
                labelText="Description"
                value={post.meta_default_description || ''}
                placeholderText={metaDescriptionFallback}
                onChange={(e) => onChange({ meta_default_description: e.target.value })}
                errorText={errors.meta_default_description}
                maxLength={MAX_META_DESCRIPTION_LENGTH}
              />
            </div>
            <div className="bg-gray-50 p-4 rounded">
              <SEOPreview
                title={`${metaTitleFallback} | ${publication.name}`}
                description={metaDescriptionFallback}
                url={`${publication.url}p/${post.slug}`}
              />
            </div>
            <div className="flex flex-col space-y-1">
              <button
                className="flex items-center bg-transparent hover:bg-gray-50 rounded w-fit py-2"
                type="button"
                onClick={() => setShowSEODetails(!showSEODetails)}
              >
                <ChevronDownIcon
                  className={cx(
                    'h-5 w-5 mr-2 text-gray-700 transform transition-all',
                    showSEODetails ? 'rotate-180' : ''
                  )}
                />{' '}
                <Text type="subtitle" size="sm" className="font-semibold w-fit">
                  Advanced
                </Text>
              </button>
              <Text type="body" size="xs" className="font-light">
                Expand the dropdown to further customize metadata for specific platforms.
              </Text>
            </div>

            {showSEODetails && (
              <div className="flex flex-col space-y-4">
                <div className="flex flex-col space-y-2">
                  <Text type="subtitle" size="md">
                    Open Graph (Facebook & LinkedIn)
                  </Text>
                  <div className="flex flex-col space-y-4 px-4 py-4 border-l-4 border-gray-100 ">
                    <Input
                      name="meta_og_title"
                      value={post.meta_og_title || ''}
                      labelText="Title"
                      onChange={(e) => onChange({ meta_og_title: e.target.value })}
                      errorText={errors.meta_og_title}
                      maxLength={MAX_META_TITLE_LENGTH}
                      placeholderText={metaTitleFallback}
                    />
                    <Textarea
                      name="meta_og_description"
                      labelText="Description"
                      value={post.meta_og_description || ''}
                      onChange={(e) => onChange({ meta_og_description: e.target.value })}
                      errorText={errors.meta_og_description}
                      maxLength={MAX_META_DESCRIPTION_LENGTH}
                      placeholderText={metaDescriptionFallback}
                    />
                    <SEOPreview
                      title={`${post.meta_og_title || metaTitleFallback} | ${publication.name}`}
                      description={post.meta_og_description || metaDescriptionFallback}
                      url={`${publication.url}`}
                      variant="facebook"
                      imageUrl={post.thumbnail?.url}
                    />
                  </div>
                </div>
                <div className="flex flex-col space-y-2">
                  <Text type="subtitle" size="md">
                    Twitter
                  </Text>
                  <div className="flex flex-col space-y-4 px-4 py-4 border-l-4 border-gray-100">
                    <Input
                      name="meta_twitter_title"
                      value={post.meta_twitter_title || ''}
                      labelText="Title"
                      onChange={(e) => onChange({ meta_twitter_title: e.target.value })}
                      errorText={errors.meta_twitter_title}
                      maxLength={MAX_META_TITLE_LENGTH}
                      placeholderText={metaTitleFallback}
                    />
                    <Textarea
                      name="meta_twitter_description"
                      labelText="Description"
                      value={post.meta_twitter_description || ''}
                      onChange={(e) => onChange({ meta_twitter_description: e.target.value })}
                      errorText={errors.meta_twitter_description}
                      maxLength={MAX_META_DESCRIPTION_LENGTH}
                      placeholderText={metaDescriptionFallback}
                    />
                    <SEOPreview
                      title={`${post.meta_twitter_title || metaTitleFallback} | ${publication.name}`}
                      description={post.meta_twitter_description || metaDescriptionFallback}
                      url={`${publication.url}`}
                      variant="twitter"
                      imageUrl={post.thumbnail?.url}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Section */}
      <div className={cx(activeSection === 'delivery' ? 'block' : 'hidden')}>
        <PostDelivery post={post} onChange={onChange} />
      </div>
    </>
  );
};

export default Configure;
