import SectionRenderer from '../../helpers/SectionRenderer';
import { RenderCondition } from '../../RenderCondition';
import { AttributeSettingProps } from '../../types';
import { AlignmentSettings } from '../AlignmentSettings';
import { BooleanSettings } from '../BooleanSettings';
import BorderSettings from '../BorderSettings';
import ColorGroupSettings from '../ColorGroupSettings';
import { ColorPopoverGroupSettings } from '../ColorPopoverGroupSettings';
import { ColorSettings } from '../ColorSettings';
import { GapSettings } from '../GapSettings';
import { PaddingSettings } from '../PaddingSettings';
import ShadowSettings from '../ShadowSettings';
import { SignupFlowSettings } from '../SignupFlowSettings';
import { SimpleTextSettings } from '../SimpleTextSettings';

import OfferSettings from './OfferSettings';
import RecommendedSetting from './RecommendedSetting';

export const PricingSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  const { activeNodeAttributes } = activeNodeResult;

  return (
    <RenderCondition editor={editor} allowedNodeTypes={['pricing']}>
      <SectionRenderer title="Checkout">
        <BooleanSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="showFreeTier"
          title="Show Free Tier"
        />
        <SignupFlowSettings editor={editor} activeNodeResult={activeNodeResult} />
        <OfferSettings editor={editor} activeNodeResult={activeNodeResult} property="offerId" title="Offer" />
        <BooleanSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="internalCheckout"
          title="Internal"
        />
        {activeNodeAttributes?.internalCheckout && (
          <>
            <SimpleTextSettings
              editor={editor}
              activeNodeResult={activeNodeResult}
              property="internalCheckoutCta"
              title="Text"
            />
            <ColorPopoverGroupSettings
              editor={editor}
              activeNodeResult={activeNodeResult}
              title="Button"
              properties={[
                { title: 'Background', property: 'buttonColor' },
                { title: 'Text', property: 'buttonTextColor' },
              ]}
            />
            <ColorPopoverGroupSettings
              editor={editor}
              activeNodeResult={activeNodeResult}
              title="Input"
              properties={[
                { title: 'Background', property: 'inputBackgroundColor' },
                { title: 'Text', property: 'inputTextColor' },
                { title: 'Placeholder', property: 'inputPlaceholderColor' },
              ]}
            />
          </>
        )}
      </SectionRenderer>

      <SectionRenderer title="Recommended">
        <RecommendedSetting editor={editor} activeNodeResult={activeNodeResult} />
        <ColorSettings
          title="Background"
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="recommendedBackgroundColor"
        />
        <ColorSettings
          title="Text"
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="recommendedTextColor"
        />
      </SectionRenderer>

      <SectionRenderer title="Layout">
        <AlignmentSettings editor={editor} activeNodeResult={activeNodeResult} property="alignment" />
        <PaddingSettings editor={editor} activeNodeResult={activeNodeResult} property="padding" />
        <GapSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="gap"
          title="Gap"
          iconDirection="horizontal"
        />
        <BorderSettings editor={editor} activeNodeResult={activeNodeResult} />
      </SectionRenderer>

      <SectionRenderer title="Cards">
        <PaddingSettings editor={editor} activeNodeResult={activeNodeResult} property="cardPadding" />
        <BorderSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          properties={{
            color: 'cardBorderColor',
            style: 'cardBorderStyle',
            width: 'cardBorderWidth',
            radius: 'cardBorderRadius',
          }}
        />
        <ColorSettings
          title="Background"
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="cardBackgroundColor"
        />
        <ShadowSettings editor={editor} activeNodeResult={activeNodeResult} property="cardShadow" title="Shadow" />
        <ColorGroupSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          title="Text"
          properties={[
            { title: 'Name', property: 'nameColor' },
            { title: 'Description', property: 'descriptionColor' },
            { title: 'Price', property: 'priceColor' },
            { title: 'Offer', property: 'offerDetailsColor' },
            { title: 'Offer Icon', property: 'offerDetailsIconColor' },
            { title: 'Feature', property: 'featureColor' },
            { title: 'Feature Icon', property: 'featureIconColor' },
          ]}
        />
        {!activeNodeAttributes?.internalCheckout && (
          <ColorPopoverGroupSettings
            editor={editor}
            activeNodeResult={activeNodeResult}
            title="Button"
            properties={[
              { title: 'Background', property: 'buttonColor' },
              { title: 'Text', property: 'buttonTextColor' },
            ]}
          />
        )}
        <ColorPopoverGroupSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          title="Tab"
          properties={[
            { title: 'Text', property: 'tabTextColor' },
            { title: 'Background', property: 'tabBackgroundColor' },
            { title: 'Selected Text', property: 'tabSelectedTextColor' },
            { title: 'Selected Background', property: 'tabSelectedBackgroundColor' },
          ]}
        />
        <ColorPopoverGroupSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          title="Selected"
          properties={[
            { title: 'Border', property: 'cardSelectedBorderColor' },
            { title: 'Background', property: 'cardSelectedBackgroundColor' },
          ]}
        />
      </SectionRenderer>
    </RenderCondition>
  );
};
