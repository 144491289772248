import { CaretDown } from '@phosphor-icons/react';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/routes/website/_components/UI/DropdownMenu';
import { Text } from '@/routes/website/_components/UI/Text';

import { useNavbarContext } from '../../../NavbarContext';

export const ActionSettings = () => {
  const { selectedContent, onUpdateNodeAttributes } = useNavbarContext();

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== 'navbar_item' || !['button', 'link'].includes(selectedContent.attrs?.type)) return null;

  const contentValue =
    selectedContent?.attrs && 'action' in selectedContent.attrs ? selectedContent.attrs.action || 'none' : 'none';

  const options: Record<string, string> = {
    sign_up: 'Sign up',
    sign_in: 'Sign in',
    none: 'None',
  };

  const handleActionChange = (value: string) => {
    if (!selectedContent.attrs?.id) return;
    onUpdateNodeAttributes(selectedContent.attrs?.id, { action: value === 'none' ? undefined : value });
  };

  return (
    <div className="flex items-center justify-stretch gap-2">
      <Text className="w-[80px] min-w-[80px]" variant="secondary" size="2xs" weight="medium">
        Action
      </Text>
      <DropdownMenu>
        <DropdownMenuTrigger asChild className="cursor-pointer">
          <div className="grow w-full bg-wb-secondary rounded-lg shadow-sm justify-between flex items-center gap-2 p-2">
            <div className="flex items-center gap-1">
              <Text size="2xs" weight="medium" variant={contentValue === 'none' ? 'secondary' : 'primary'}>
                {options[contentValue]}
              </Text>
            </div>
            <CaretDown className="text-wb-secondary w-3 h-3" weight="bold" />
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-full min-w-[8rem] cursor-pointer" align="end">
          {Object.keys(options).map((optionKey) => (
            <DropdownMenuItem
              key={optionKey}
              onSelect={() => {
                handleActionChange(optionKey);
              }}
            >
              {options[optionKey]}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};
