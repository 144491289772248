import { useState } from 'react';
import { autoPlacement, offset, useClick, useDismiss, useFloating, useInteractions } from '@floating-ui/react';
import { CaretRight, X } from '@phosphor-icons/react';
import { Editor } from '@tiptap/core';

import { Text } from '../../../UI/Text';
import { useActiveNode } from '../../extensions/ActiveNode/hooks/useActiveNode';

const fontStyles = [
  {
    label: 'Heading 1',
    isSelected: (editor: Editor) => {
      const attributes = editor.getAttributes('textStyle');
      return attributes.fontSize === '32px' && attributes.fontWeight === '700';
    },
    style: {
      fontSize: '32px',
      fontWeight: '700',
    },
    handleClick: (editor: Editor) => {
      editor.chain().selectTextBlock().setFontSize('32px').setFontWeight('700').focus().run();
    },
  },
  {
    label: 'Heading 2',
    isSelected: (editor: Editor) => {
      const attributes = editor.getAttributes('textStyle');
      return attributes.fontSize === '30px' && attributes.fontWeight === '700';
    },
    style: {
      fontSize: '30px',
      fontWeight: '700',
    },
    handleClick: (editor: Editor) => {
      editor.chain().selectTextBlock().setFontSize('30px').setFontWeight('700').focus().run();
    },
  },
  {
    label: 'Heading 3',
    isSelected: (editor: Editor) => {
      const attributes = editor.getAttributes('textStyle');
      return attributes.fontSize === '24px' && attributes.fontWeight === '700';
    },
    style: {
      fontSize: '24px',
      fontWeight: '700',
    },
    handleClick: (editor: Editor) => {
      editor.chain().selectTextBlock().setFontSize('24px').setFontWeight('700').focus().run();
    },
  },
  {
    label: 'Subtitle 1',
    isSelected: (editor: Editor) => {
      const attributes = editor.getAttributes('textStyle');
      return attributes.fontSize === '20px' && attributes.fontWeight === '400' && attributes.color === '#98A2B3';
    },
    style: {
      fontSize: '20px',
      fontWeight: '400',
      color: 'var(--color-text-text-secondary, #98A2B3)', // is this the right color?
    },
    handleClick: (editor: Editor) => {
      editor.chain().selectTextBlock().setFontSize('20px').setFontWeight('400').setColor('#98A2B3').focus().run();
    },
  },
  {
    label: 'Subtitle 2',
    isSelected: (editor: Editor) => {
      const attributes = editor.getAttributes('textStyle');
      return attributes.fontSize === '16px' && attributes.fontWeight === '400' && attributes.color === '#98A2B3';
    },
    style: {
      fontSize: '16px',
      fontWeight: '400',
      color: 'var(--color-text-text-secondary, #98A2B3)', // is this the right color?
    },
    handleClick: (editor: Editor) => {
      editor.chain().selectTextBlock().setFontSize('16px').setFontWeight('400').setColor('#98A2B3').focus().run();
    },
  },
  {
    label: 'Subtitle 3',
    isSelected: (editor: Editor) => {
      const attributes = editor.getAttributes('textStyle');
      return attributes.fontSize === '13px' && attributes.fontWeight === '400' && attributes.color === '#98A2B3';
    },
    style: {
      fontSize: '13px',
      fontWeight: '400',
      color: 'var(--color-text-text-secondary, #98A2B3)', // is this the right color?
    },
    handleClick: (editor: Editor) => {
      editor.chain().selectTextBlock().setFontSize('13px').setFontWeight('400').setColor('#98A2B3').focus().run();
    },
  },
  {
    label: 'Paragraph',
    isSelected: (editor: Editor) => {
      const attributes = editor.getAttributes('textStyle');
      return !attributes.fontSize && !attributes.fontWeight && !attributes.color;
    },
    style: {},
    handleClick: (editor: Editor) => {
      editor.chain().selectTextBlock().unsetFontSize().unsetFontWeight().unsetColor().focus().run();
    },
  },
  {
    label: 'Small Text',
    isSelected: (editor: Editor) => {
      const attributes = editor.getAttributes('textStyle');
      return attributes.fontSize === '12px' && attributes.fontWeight === '400';
    },
    style: {
      fontSize: '12px',
      fontWeight: '400',
    },
    handleClick: (editor: Editor) => {
      editor.chain().selectTextBlock().setFontSize('12px').setFontWeight('400').focus().run();
    },
  },
];

export const FontStyleSettings = ({ editor }: { editor: Editor }) => {
  const { activeNodeType, activeNodeAttributes } = useActiveNode(editor);
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'left-start',
    middleware: [autoPlacement(), offset(20)],
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);

  const selectedFontStyle = fontStyles.find((fontStyle) => fontStyle.isSelected(editor));
  let selectedStyle = 'Custom';
  if (selectedFontStyle) {
    selectedStyle = selectedFontStyle.label;
  }

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss]);

  return (
    <>
      <div ref={refs.setReference} className="flex items-center justify-stretch gap-2">
        <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
          Style
        </Text>
        <div className="grow bg-wb-secondary rounded-lg shadow-sm cursor-pointer" {...getReferenceProps()}>
          <div className="w-full justify-between flex items-center gap-2 p-2">
            <div className="flex items-center gap-1">
              <Text size="2xs" weight="medium">
                {selectedStyle}
              </Text>
            </div>
            <CaretRight className="text-wb-secondary" weight="bold" />
          </div>
        </div>
      </div>
      {isOpen && (
        <div
          ref={refs.setFloating}
          className="bg-wb-primary w-[255px] max-h-[500px] overflow-y-auto p-3 rounded-lg shadow-xl flex flex-col gap-2"
          style={floatingStyles}
          {...getFloatingProps()}
        >
          <div className="flex items-center justify-between gap-2">
            <Text size="sm" weight="semibold">
              Text Styles
            </Text>

            <div
              className="p-1 text-wb-secondary bg-wb-secondary rounded-full"
              onClick={() => setIsOpen(false)}
              role="button"
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  setIsOpen(false);
                }
              }}
            >
              <X className="cursor-pointer" />
            </div>
          </div>
          {fontStyles.map((fontStyle) => (
            <div
              key={fontStyle.label}
              className={`flex items-center px-3 py-2 rounded-lg cursor-pointer border ${
                fontStyle.isSelected(editor)
                  ? 'bg-wb-button-accent-soft border-wb-accent-soft'
                  : 'bg-wb-secondary border-transparent'
              }`}
              role="button"
              tabIndex={0}
              onKeyDown={(e) => {
                if (!activeNodeType || !activeNodeAttributes) return;
                if (e.key === 'Enter' || e.key === ' ') {
                  fontStyle.handleClick(editor);
                }
              }}
              onClick={() => {
                if (!activeNodeType || !activeNodeAttributes) return;
                fontStyle.handleClick(editor);
              }}
            >
              <div style={fontStyle.style}>{fontStyle.label}</div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};
