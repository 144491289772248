import ActionModal from '@/components/ActionModal';
import { Typography, TypographyStack } from '@/components/Typography';
import { useCustomDomainToggleUsage } from '@/hooks/useCustomDomains';
import { CustomDomain, CustomDomainTypes } from '@/interfaces/custom_domain';

import { DISCONNECT_EFFECTS } from './constants';

interface Props {
  customDomain: CustomDomain;
  type: CustomDomainTypes;
  isOpen: boolean;
  onClose: () => void;
}

const DisconnectDomainForm = ({ customDomain, type, isOpen, onClose }: Props) => {
  const { mutate: toggleUsage, isLoading } = useCustomDomainToggleUsage();

  const handleProceed = () => {
    toggleUsage({ id: customDomain.id, type, enabled: false });
  };

  return (
    <ActionModal
      resourceId={customDomain.id}
      isOpen={isOpen}
      onClose={onClose}
      headerText="Disconnect Custom Domain"
      actionText="Disconnect Domain"
      buttonType="danger"
      buttonShade="dark"
      onProceed={handleProceed}
      isWorking={isLoading}
      modalMessageType="danger"
    >
      <TypographyStack gap="4" className="mt-4">
        <Typography token="font-normal/text/sm" colorWeight="700">
          You are about to disconnect (<strong>{customDomain.domain}</strong>). This action is irreversible and cannot
          be undone.
        </Typography>

        <Typography token="font-normal/text/sm" colorWeight="700">
          <b>What happens when you disconnect:</b>
          <br />
          <ul className="list-disc list-inside">
            {DISCONNECT_EFFECTS[type].map((text) => (
              <li key={text}>{text}</li>
            ))}
          </ul>
        </Typography>
      </TypographyStack>
    </ActionModal>
  );
};

export default DisconnectDomainForm;
