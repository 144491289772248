import { useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  ArrowLeftOnRectangleIcon,
  ArrowTrendingUpIcon,
  BanknotesIcon,
  BellIcon,
  ChartBarIcon,
  ChatBubbleLeftIcon,
  Cog8ToothIcon,
  ComputerDesktopIcon,
  CubeTransparentIcon,
  GlobeAmericasIcon,
  LightBulbIcon,
  LockClosedIcon,
  PencilIcon,
  QuestionMarkCircleIcon,
  RocketLaunchIcon,
  ShieldCheckIcon,
  Squares2X2Icon,
  UserIcon,
  UsersIcon,
} from '@heroicons/react/20/solid';

import { NotificationsModal } from '@/components/Notifications/NotificationsModal';
import { UnreadCountBadge } from '@/components/Notifications/UnreadCountBadge';
import { PlanCard } from '@/components/Plan';
import { useAuth } from '@/context/auth-context';
import { useCurrentUser } from '@/context/current-user-context';
import { usePermissions } from '@/context/permissions-context';
import { useSettings } from '@/context/settings-context';
import { useCurrentPublication } from '@/hooks';
import usePlan from '@/hooks/usePlan';
import analytics from '@/utils/analytics';
import appendSettingsPublicationId from '@/utils/appendSettingsPublicationId';

import NavItemWithModal from './NavItems/NavItemWithModal';
import { NavItemLink, NavItemParent, NavSection } from './NavItems';

const Nav = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { data: currentPublication } = useCurrentPublication();
  const { currentUser } = useCurrentUser();
  const hasSettingsV2 = currentUser?.has_settings_v2;
  const { plan, isLoading } = usePlan();
  const { settings } = useSettings();
  const { checkPermissions, hasAnyReadPermissions, permissions } = usePermissions();
  const settingsMenuAccess = checkPermissions('views/nav/settings', 'read');
  const analyzeReportAccess = checkPermissions('views/nav/analyze/reports', 'read');

  // Walkthrough Active Checks
  const [searchParams] = useSearchParams();
  const isSettingsActive = searchParams.get('walkthrough_step') === '2';
  const isViewSiteActive = searchParams.get('walkthrough_step') === '7';

  // Handle Logout
  const { logout } = useAuth();
  const handleLogout = () => {
    // Even though this should redirect by default to login after a logout
    // happens, this is to ensure we don't append any redirect params since
    // it was user initiated to logout
    analytics.track('Signed Out');
    logout.mutateAsync().then(() => navigate({ pathname: '/login', search: '' }));
  };

  // Admin Checks
  const [isMasquerading] = useState(!!localStorage.getItem('masqueradeToken'));
  const showSystemAdmin = Boolean(currentUser?.isSystemAdmin()) && currentPublication && !isMasquerading;
  const showAdNetworkAdmin = Boolean(currentUser?.isAdNetworkAdmin());
  const showAccountantAdmin = Boolean(currentUser?.isBeehiivAccountant());
  const audienceTabEnabled =
    checkPermissions('views/nav/audience/subscribers', 'read') ||
    checkPermissions('views/nav/audience/segments', 'read') ||
    checkPermissions('views/nav/audience/automations', 'read') ||
    checkPermissions('views/nav/audience/polls', 'read') ||
    checkPermissions('views/nav/audience/surveys', 'read') ||
    checkPermissions('views/nav/audience/subscribe_forms', 'read');

  return (
    <div>
      <NavSection>
        <NavItemLink title="Dashboard" Icon={Squares2X2Icon} to="/" />

        <NavItemWithModal
          title="Notifications"
          Icon={BellIcon}
          to="/notifications"
          modal={<NotificationsModal />}
          badge={
            <div className="-mt-1.5">
              <UnreadCountBadge />
            </div>
          }
        />
        {settings?.web_builder && (
          <NavItemLink
            Icon={ComputerDesktopIcon}
            title="Website Builder"
            to="/website_builder"
            disabled={!checkPermissions('views/nav/design/website_builder', 'read')}
          />
        )}

        <NavItemParent title="Write" Icon={PencilIcon}>
          <NavItemLink title="Posts" to="/posts" />
          <NavItemLink
            title="Content Tags"
            to="/content_tags"
            disabled={!checkPermissions('views/posts/content_tags', 'update')}
          />
        </NavItemParent>

        <NavItemParent
          title="Grow"
          Icon={ArrowTrendingUpIcon}
          disabled={!hasAnyReadPermissions(permissions?.views?.nav?.grow || {})}
        >
          <NavItemLink
            title="Referral Program"
            to="/referral_program/overview"
            planType={plan}
            additionalIsActiveCheck={location.pathname.startsWith('/referral_program')}
            disabled={!checkPermissions('views/nav/grow/referral_program', 'read')}
          />
          <NavItemLink
            title="Recommendations"
            to="/recommendations"
            disabled={!checkPermissions('views/nav/grow/recommendations', 'read')}
          />
          <NavItemLink
            title="Magic Links"
            to="/magic_links"
            disabled={!checkPermissions('views/nav/grow/magic_links', 'read')}
          />
          <NavItemLink
            title="Boosts"
            to="/grow/boosts/overview"
            planType={plan}
            notIncludedInTrial
            additionalIsActiveCheck={location.pathname.startsWith('/grow/boosts')}
            disabled={!checkPermissions('views/nav/grow/boosts', 'read')}
          />
        </NavItemParent>

        <NavItemParent
          title="Monetization"
          Icon={BanknotesIcon}
          disabled={!hasAnyReadPermissions(permissions?.views?.nav?.monetize || {})}
        >
          <NavItemLink
            title="Ads"
            planType={plan}
            to="/monetize/ads"
            additionalIsActiveCheck={location.pathname.startsWith('/monetize/ads')}
            disabled={!checkPermissions('views/nav/monetize/ads', 'read')}
          />
          <NavItemLink
            title="Subscriptions"
            to="/monetize/subscriptions"
            planType={plan}
            includedInGrow
            disabled={!checkPermissions('views/nav/monetize/subscriptions', 'read')}
          />
          <NavItemLink
            title="Boosts"
            to="/monetize/boosts"
            planType={plan}
            notIncludedInTrial
            disabled={!checkPermissions('views/nav/monetize/boosts', 'read')}
          />
        </NavItemParent>

        <NavItemParent title="Audience" Icon={UsersIcon} disabled={!audienceTabEnabled}>
          <NavItemLink
            title="Subscribers"
            to="/subscribers"
            disabled={!checkPermissions('views/nav/audience/subscribers', 'read')}
          />
          <NavItemLink
            title="Segments"
            to="/segments"
            additionalIsActiveCheck={location.pathname.startsWith('/blasts')}
            disabled={!checkPermissions('views/nav/audience/segments', 'read')}
          />
          <NavItemLink
            title="Automations"
            planType={plan}
            to="/automations"
            disabled={!checkPermissions('views/nav/audience/automations', 'read')}
          />
          <NavItemLink
            title="Polls"
            to="/polls"
            planType={plan}
            includedInGrow
            disabled={!checkPermissions('views/nav/audience/polls', 'read')}
          />
          <NavItemLink
            title="Surveys"
            planType={plan}
            to="/forms"
            includedInGrow
            disabled={!checkPermissions('views/nav/audience/surveys', 'read')}
          />
          <NavItemLink
            title="Subscribe Forms"
            to="/subscribe_forms"
            disabled={!checkPermissions('views/nav/audience/subscribe_forms', 'read')}
          />
        </NavItemParent>

        <NavItemParent
          title="Analyze"
          Icon={ChartBarIcon}
          disabled={!hasAnyReadPermissions(permissions?.views?.nav?.analyze || {})}
        >
          <NavItemLink
            title="Subscribers Report"
            to="/reports/subscriber"
            planType={plan}
            disabled={!analyzeReportAccess}
          />
          <NavItemLink title="Posts Report" to="/reports/post" planType={plan} disabled={!analyzeReportAccess} />
          <NavItemLink title="Clicks Report" to="/reports/click" planType={plan} disabled={!analyzeReportAccess} />
        </NavItemParent>

        <NavItemParent title="Learn" Icon={LightBulbIcon}>
          <NavItemLink title="Blog" href="https://blog.beehiiv.com/" />
          <NavItemLink title="Updates" href="https://product.beehiiv.com/" />
          <NavItemLink title="Tutorials" href="https://www.youtube.com/@beehiiv" />
          <NavItemLink title="NewsletterXP" href="https://www.beehiiv.com/courses/newsletter-xp" />
        </NavItemParent>

        <NavItemLink
          title="Wallet"
          Icon={BanknotesIcon}
          to="/wallet"
          disabled={!checkPermissions('views/nav/wallet', 'read')}
        />
        {settings?.partner_program && (
          <NavItemLink
            title="Partner Program"
            Icon={RocketLaunchIcon}
            to="/partner_program"
            disabled={!checkPermissions('views/nav/share_and_earn', 'read')}
          />
        )}
      </NavSection>
      <NavSection>
        {!isLoading && <PlanCard />}
        <NavItemLink
          title="Settings"
          Icon={Cog8ToothIcon}
          to={
            hasSettingsV2
              ? appendSettingsPublicationId('/settings/publication/general', currentPublication?.id || '')
              : '/settings'
          }
          additionalIsActiveCheck={isSettingsActive}
          disabled={!settingsMenuAccess}
        />
        {hasSettingsV2 ? (
          <>
            <NavItemLink title="Personal Info" Icon={UserIcon} to="/current_user/personal_info" />
            <NavItemLink title="Account Security" Icon={LockClosedIcon} to="/current_user/account_security" />
            <NavItemLink title="Notification Preferences" Icon={BellIcon} to="/current_user/notification_preferences" />
          </>
        ) : null}
        <NavItemLink
          title="View Site"
          Icon={GlobeAmericasIcon}
          href={currentPublication?.url || ''}
          additionalIsActiveCheck={isViewSiteActive}
        />
        <NavItemLink title="Help" Icon={QuestionMarkCircleIcon} to="/help" />
        <NavItemLink
          id="nav-ada-chat-button"
          classNames="hidden"
          title="Chatbot Assistant"
          Icon={ChatBubbleLeftIcon}
          onClick={window.adaEmbed.toggle}
        />
        <NavItemLink title="Sign Out" Icon={ArrowLeftOnRectangleIcon} onClick={handleLogout} />
        {(showSystemAdmin || showAdNetworkAdmin || showAccountantAdmin) && (
          <>
            {showAdNetworkAdmin && <NavItemLink title="Ad network" Icon={CubeTransparentIcon} to="/ad_network" />}
            {showSystemAdmin && <NavItemLink title="System admin" Icon={ShieldCheckIcon} to="/system_admin/users" />}
            {showAccountantAdmin && (
              <NavItemLink title="Admin Reports" Icon={ArrowLeftOnRectangleIcon} to="/admin_reports/ledgers" />
            )}
          </>
        )}
      </NavSection>
    </div>
  );
};

export default Nav;
