import { DateFilterTypes } from '../interfaces/dates';

interface PostSearchProps {
  afterDate?: Date;
  audience?: string[];
  authorIds?: string[];
  beforeDate?: Date;
  contentTags?: string[];
  dateField?: string;
  endDate?: Date;
  options?: {
    advanced: boolean;
    datesEnabled: boolean;
    dateFilterType: DateFilterTypes;
  };
  order?: string;
  sort?: 'newest_first' | 'oldest_first' | 'most_relevant';
  platform?: string[];
  search?: string;
  startDate?: Date;
  status?: string;
  statuses?: string[];
  includeSegments?: string[];
  excludeSegments?: string[];
}

export default class PostSearch implements PostSearchProps {
  afterDate: PostSearchProps['afterDate'];

  audience: PostSearchProps['audience'];

  authorIds: PostSearchProps['authorIds'];

  beforeDate: PostSearchProps['beforeDate'];

  contentTags: PostSearchProps['contentTags'];

  dateField: PostSearchProps['dateField'];

  endDate: PostSearchProps['endDate'];

  options: PostSearchProps['options'];

  order: PostSearchProps['order'];

  sort: PostSearchProps['sort'];

  platform: PostSearchProps['platform'];

  search: PostSearchProps['search'];

  startDate: PostSearchProps['startDate'];

  status: PostSearchProps['status'];

  statuses: PostSearchProps['statuses'];

  includeSegments: PostSearchProps['includeSegments'];

  excludeSegments: PostSearchProps['excludeSegments'];

  params(): object {
    const advancedEnabled = this.options?.advanced;
    const datesEnabled = this.options?.advanced && this.options?.datesEnabled;

    return {
      ...(this.order && { order: this.order }),
      ...(this.sort && { sort: this.sort }),
      ...(this.search && { q: this.search }),
      ...(this.status && { status: this.status }),
      ...(this.statuses && { statuses: this.statuses }),
      ...(advancedEnabled && this.audience && { audience: this.audience }),
      ...(advancedEnabled && this.authorIds && { author_ids: this.authorIds }),
      ...(advancedEnabled && this.platform && { platform: this.platform }),
      ...(advancedEnabled && this.contentTags && { content_tags: this.contentTags }),
      ...(datesEnabled && this.dateField && { date_field: this.dateField }),
      ...(datesEnabled &&
        this.options?.dateFilterType === DateFilterTypes.BETWEEN &&
        this.startDate && { start_date: this.startDate }),
      ...(datesEnabled &&
        this.options?.dateFilterType === DateFilterTypes.BETWEEN &&
        this.endDate && { end_date: this.endDate }),
      ...(datesEnabled &&
        this.options?.dateFilterType === DateFilterTypes.BEFORE &&
        this.beforeDate && { before_date: this.beforeDate }),
      ...(datesEnabled &&
        this.options?.dateFilterType === DateFilterTypes.AFTER &&
        this.afterDate && { after_date: this.afterDate }),
    };
  }

  constructor(props: PostSearchProps) {
    this.afterDate = props.afterDate;
    this.audience = props.audience;
    this.authorIds = props.authorIds;
    this.beforeDate = props.beforeDate;
    this.contentTags = props.contentTags;
    this.dateField = props.dateField;
    this.endDate = props.endDate;
    this.options = props.options;
    this.order = props.order;
    this.sort = props.sort;
    this.platform = props.platform;
    this.search = props.search;
    this.startDate = props.startDate;
    this.status = props.status;
    this.statuses = props.statuses;
  }
}
