export enum Setting {
  AD_NETWORK = 'ad_network',
  ADA_WIDGET = 'ada_widget',
  AI_EDITOR_REQUESTS = 'ai_editor_requests',
  APEX_DOMAINS = 'apex_domains',
  API_V1 = 'api_v1',
  API_V2 = 'api_v2',
  AUDIENCE_LEADERBOARD = 'audience_leaderboard',
  AUDIO_NEWSLETTERS = 'audio_newsletters',
  AUTOMATIONS = 'automations',
  AUTOMATIONS_WEBHOOK_STEP = 'automations_webhook_step',
  AUTOMATIONS_USE_APIARY = 'automations_use_apiary',
  BOOST_QUALITY_COMPARISON = 'boost_quality_comparison',
  BOOSTS = 'boosts',
  BOOSTS_IDENTITY_VERIFICATION = 'boosts_identity_verification',
  BOOSTS_INVITES = 'boosts_invites',
  BOOSTS_MONETIZE_V2 = 'boosts_monetize_v2',
  BOOSTS_PLUS = 'boosts_plus',
  BOOSTS_STRICT_VERIFICATION = 'boosts_strict_verification',
  BOOSTS_V2 = 'boosts_v2',
  BYPASS_DISABLE_RECAPTCHA_CHECKS = 'bypass_disable_recaptcha_checks',
  CAN_APPLY_FOR_BOOSTS = 'can_apply_for_boosts',
  CARDLESS_TRIALS = 'cardless_trials',
  COLLABORATIVE_EDITING = 'collaborative_editing',
  CONDITIONALLY_SHOWN_CPM_ADS = 'conditionally_shown_cpm_ads',
  COMMENTS = 'comments',
  CONTENT_TAGS = 'content_tags',
  CONTRIBUTOR_ROLE = 'contributor_role',
  CUSTOM_BRANDED_LINKS = 'custom_branded_links',
  CUSTOM_DOMAINS = 'custom_domains',
  CUSTOM_FIELDS = 'custom_fields',
  CUSTOM_HTML_BLOCKS = 'custom_html_blocks',
  CUSTOM_PAGES = 'custom_pages',
  CUSTOM_SPONSOR_TEXT_ON_POST = 'custom_sponsor_text_on_post',
  DARK_MODE = 'dark_mode',
  DONATION_PAYMENTS = 'donation_payments',
  EDIT_AD_LOGO_ON_POST = 'edit_ad_logo_on_post',
  EDITOR_THREADS = 'editor_threads',
  EDITOR_TEXT_TO_SPEECH = 'editor_text_to_speech',
  ENTRI = 'entri',
  EXTERNAL_TEST_SENDS = 'external_test_sends',
  FORMS = 'forms',
  FREE_SUPPORT = 'free_support',
  GIFT_PREMIUM_SUBSCRIPTIONS = 'gift_premium_subscriptions',
  HIIV_DREAM = 'hiiv_dream',
  LANDING_PAGES = 'landing_pages',
  LOCALIZATION = 'localization',
  MAILCHIMP_INTEGRATION = 'mailchimp_integration',
  MAX_AUTOMATION_DELAY_DAYS = 'max_automation_delay_days',
  MAX_AUTOMATION_STEPS = 'max_automation_steps',
  MAX_DAILY_PUBLICATION_TEST_SENDS = 'max_daily_publication_test_sends',
  MAX_IMPORTED_SUBSCRIPTIONS = 'max_imported_subscriptions',
  MAX_MONTHLY_BOOST_AGREEMENTS = 'max_monthly_boost_agreements',
  MAX_MONTHLY_BOOST_INVITATIONS = 'max_monthly_boost_invitations',
  MAX_PREMIUM_BENEFITS = 'max_premium_benefits',
  MAX_PREMIUM_TIERS = 'max_premium_tiers',
  MAX_PUBLICATIONS = 'max_publications',
  MAX_SEGMENTS = 'max_segments',
  MAX_SPLIT_TEST_OPTIONS = 'max_split_test_options',
  MAX_SUBSCRIPTIONS = 'max_subscriptions',
  MAX_TIP_TAP_CHARACTER_LIMIT = 'max_tip_tap_character_limit',
  MAX_TEAM_MEMBERS = 'max_team_members',
  NAVIGATION = 'navigation',
  ONE_CLICK_UNSUBSCRIBE = 'one_click_unsubscribe',
  ONE_TIME_PAYMENTS = 'one_time_payments',
  OPT_IN_EMAIL_EDITOR = 'opt_in_email_editor',
  ORGANIZATION_BOOSTS = 'organization_boosts',
  PARTNER_PROGRAM = 'partner_program',
  PARTNER_PROGRAM_TIERS_TAB = 'partner_program_tiers_tab',
  PAYWALLS = 'paywalls',
  POLLS = 'polls',
  POLLS_V2 = 'polls_v2',
  POST_EDITOR_V2 = 'post_editor_v2',
  POST_TOP_SUBSCRIBERS_TABLE = 'post_top_subscribers_table',
  POSTS_DASHBOARD_V2 = 'posts_dashboard_v2',
  POSTS_INDEX_V2 = 'posts_index_v2',
  POSTS_CLICK_MAP_LINK_GROUPING = 'posts_click_map_link_grouping',
  POSTS_CLICK_TRACKING = 'posts_click_tracking',
  PREMIUM_SUBSCRIPTIONS = 'premium_subscriptions',
  PREMIUM_TIERS_ROLLOUT = 'premium_tiers_rollout',
  PRIVATE_PUBLICATIONS = 'private_publications',
  PUBLICATION_SETTINGS_V2 = 'publication_settings_v2',
  RAISE_SUBSCRIBER_IMPORT_LIMIT_FORM = 'raise_subscriber_import_limit_form',
  RECOMMENDATIONS = 'recommendations',
  RECOMMENDATIONS_WIDGET = 'recommendations_widget',
  RECOMMENDED_SENDING_TIME = 'recommended_sending_time',
  REFERRAL_PROGRAM = 'referral_program',
  REFERRAL_PROGRAM_V2 = 'referral_program_v2',
  REFERRAL_PROGRAM_PREMIUM_GIFT_REWARD = 'referral_program_premium_gift_reward',
  REPORT_ABUSE = 'report_abuse',
  REPORTS = 'reports',
  RSS_TO_SEND = 'rss_to_send',
  SEND_TO_SEGMENTS = 'send_to_segments',
  SMART_WARMING = 'smart_warming',
  SPLIT_TEST_CUSTOMIZATION = 'split_test_customization',
  SPLIT_TEST_OVERRIDE_ENABLED = 'split_test_override_enabled',
  SPONSOR_NETWORK = 'sponsor_network',
  STRIPE_IDENTITY_VERIFICATION = 'stripe_identity_verification',
  SUBSCRIBER_PROFILE_V2 = 'subscriber_profile_v2',
  SUBSCRIBER_TAGGING = 'subscriber_tagging',
  SUBSCRIBERS_PREMIUM_DASHBOARD = 'subscribers_premium_dashboard',
  SUBSCRIBERS_PREMIUM_DASHBOARD_V2 = 'subscribers_premium_dashboard_v2',
  SUBSCRIBERS_PREFERENCES = 'subscribers_preferences',
  SUBSCRIPTION_PASSWORD_RESET = 'subscription_password_reset',
  SUBSCRIPTION_PASSWORD = 'subscription_password',
  SUPPORT_TICKETS = 'support_tickets',
  SURVEYS_V2 = 'surveys_v2',
  SYSTEM_ADMIN = 'system_admin',
  TESTIMONIALS = 'testimonials',
  THROTTLE_DAILY_SENDS = 'throttle_daily_sends',
  TIERED_PRICING = 'tiered_pricing',
  TIERED_PRICING_ROLLOUT = 'tiered_pricing_rollout',
  TWO_FACTOR_AUTH = 'two_factor_auth',
  UPGRADE_PAGE = 'upgrade_page',
  USE_APIARY = 'use_apiary',
  USER_INVITES_V2 = 'user_invites_v2',
  USER_ROLES_V2 = 'user_roles_v2',
  UTM_PARAMS = 'utm_params',
  VERIFICATION_REQUIRED = 'verification_required',
  WALLET = 'wallet',
  WEB_BUILDER = 'web_builder',
  WEBHOOKS = 'webhooks',
  WHITE_LABELING = 'white_labeling',
}

export interface Settings {
  [Setting.AD_NETWORK]: boolean;
  [Setting.ADA_WIDGET]: boolean;
  [Setting.AI_EDITOR_REQUESTS]: number;
  [Setting.APEX_DOMAINS]: boolean;
  [Setting.API_V1]: boolean;
  [Setting.API_V2]: boolean;
  [Setting.AUDIENCE_LEADERBOARD]?: boolean;
  [Setting.AUDIO_NEWSLETTERS]?: boolean;
  [Setting.AUTOMATIONS_WEBHOOK_STEP]: boolean;
  [Setting.AUTOMATIONS_USE_APIARY]: boolean;
  [Setting.AUTOMATIONS]: boolean;
  [Setting.BOOST_QUALITY_COMPARISON]: boolean;
  [Setting.BOOSTS_IDENTITY_VERIFICATION]: boolean;
  [Setting.BOOSTS_INVITES]: boolean;
  [Setting.BOOSTS_MONETIZE_V2]: boolean;
  [Setting.BOOSTS_PLUS]: boolean;
  [Setting.BOOSTS_STRICT_VERIFICATION]: boolean;
  [Setting.BOOSTS_V2]: boolean;
  [Setting.BOOSTS]: boolean;
  [Setting.BYPASS_DISABLE_RECAPTCHA_CHECKS]: boolean;
  [Setting.CAN_APPLY_FOR_BOOSTS]: boolean;
  [Setting.CARDLESS_TRIALS]: boolean;
  [Setting.COLLABORATIVE_EDITING]: boolean;
  [Setting.CONDITIONALLY_SHOWN_CPM_ADS]: boolean;
  [Setting.COMMENTS]: boolean;
  [Setting.CONTENT_TAGS]: boolean;
  [Setting.CONTRIBUTOR_ROLE]: boolean;
  [Setting.CUSTOM_DOMAINS]: boolean;
  [Setting.CUSTOM_BRANDED_LINKS]: boolean;
  [Setting.CUSTOM_FIELDS]: boolean;
  [Setting.CUSTOM_HTML_BLOCKS]: boolean;
  [Setting.CUSTOM_PAGES]: boolean;
  [Setting.CUSTOM_SPONSOR_TEXT_ON_POST]: boolean;
  [Setting.DARK_MODE]: boolean;
  [Setting.DONATION_PAYMENTS]: boolean;
  [Setting.EDIT_AD_LOGO_ON_POST]: boolean;
  [Setting.EDITOR_THREADS]: boolean;
  [Setting.EDITOR_TEXT_TO_SPEECH]: boolean;
  [Setting.ENTRI]: boolean;
  [Setting.EXTERNAL_TEST_SENDS]: boolean;
  [Setting.FORMS]: boolean;
  [Setting.FREE_SUPPORT]: boolean;
  [Setting.GIFT_PREMIUM_SUBSCRIPTIONS]: boolean;
  [Setting.HIIV_DREAM]: boolean;
  [Setting.LANDING_PAGES]: boolean;
  [Setting.LOCALIZATION]: boolean;
  [Setting.MAILCHIMP_INTEGRATION]: boolean;
  [Setting.MAX_AUTOMATION_DELAY_DAYS]: number;
  [Setting.MAX_AUTOMATION_STEPS]: number;
  [Setting.MAX_DAILY_PUBLICATION_TEST_SENDS]: number;
  [Setting.MAX_IMPORTED_SUBSCRIPTIONS]: number;
  [Setting.MAX_MONTHLY_BOOST_AGREEMENTS]?: number;
  [Setting.MAX_MONTHLY_BOOST_INVITATIONS]: number;
  [Setting.MAX_PREMIUM_BENEFITS]: number;
  [Setting.MAX_PREMIUM_TIERS]: number;
  [Setting.MAX_PUBLICATIONS]: number;
  [Setting.MAX_SEGMENTS]: number;
  [Setting.MAX_SPLIT_TEST_OPTIONS]: number;
  [Setting.MAX_SUBSCRIPTIONS]: number;
  [Setting.MAX_TIP_TAP_CHARACTER_LIMIT]?: number;
  [Setting.MAX_TEAM_MEMBERS]: number;
  [Setting.NAVIGATION]: boolean;
  [Setting.ONE_CLICK_UNSUBSCRIBE]: boolean;
  [Setting.ONE_TIME_PAYMENTS]: boolean;
  [Setting.OPT_IN_EMAIL_EDITOR]: boolean;
  [Setting.ORGANIZATION_BOOSTS]: boolean;
  [Setting.PARTNER_PROGRAM]: boolean;
  [Setting.PARTNER_PROGRAM_TIERS_TAB]: boolean;
  [Setting.PAYWALLS]: boolean;
  [Setting.POLLS_V2]: boolean;
  [Setting.POLLS]: boolean;
  [Setting.POST_EDITOR_V2]: boolean;
  [Setting.POSTS_INDEX_V2]: boolean;
  [Setting.POST_TOP_SUBSCRIBERS_TABLE]: boolean;
  [Setting.POSTS_DASHBOARD_V2]: boolean;
  [Setting.POSTS_CLICK_MAP_LINK_GROUPING]: boolean;
  [Setting.POSTS_CLICK_TRACKING]: boolean;
  [Setting.PREMIUM_SUBSCRIPTIONS]: boolean;
  [Setting.PREMIUM_TIERS_ROLLOUT]: boolean;
  [Setting.PRIVATE_PUBLICATIONS]: boolean;
  [Setting.PUBLICATION_SETTINGS_V2]: boolean;
  [Setting.RAISE_SUBSCRIBER_IMPORT_LIMIT_FORM]: boolean;
  [Setting.RECOMMENDATIONS_WIDGET]: boolean;
  [Setting.RECOMMENDATIONS]: boolean;
  [Setting.RECOMMENDED_SENDING_TIME]: boolean;
  [Setting.REFERRAL_PROGRAM_V2]: boolean;
  [Setting.REFERRAL_PROGRAM_PREMIUM_GIFT_REWARD]: boolean;
  [Setting.REFERRAL_PROGRAM]: boolean;
  [Setting.REPORT_ABUSE]: boolean;
  [Setting.REPORTS]: boolean;
  [Setting.RSS_TO_SEND]: boolean;
  [Setting.SEND_TO_SEGMENTS]: boolean;
  [Setting.SMART_WARMING]: boolean;
  [Setting.SPLIT_TEST_CUSTOMIZATION]: boolean;
  [Setting.SPLIT_TEST_OVERRIDE_ENABLED]: boolean;
  [Setting.SPONSOR_NETWORK]: boolean;
  [Setting.STRIPE_IDENTITY_VERIFICATION]: boolean;
  [Setting.SUBSCRIBER_PROFILE_V2]: boolean;
  [Setting.SUBSCRIBER_TAGGING]: boolean;
  [Setting.SUBSCRIBERS_PREMIUM_DASHBOARD_V2]: boolean;
  [Setting.SUBSCRIBERS_PREMIUM_DASHBOARD]: boolean;
  [Setting.SUBSCRIBERS_PREFERENCES]: boolean;
  [Setting.SUBSCRIPTION_PASSWORD_RESET]: boolean;
  [Setting.SUBSCRIPTION_PASSWORD]: boolean;
  [Setting.SUPPORT_TICKETS]: boolean;
  [Setting.SURVEYS_V2]: boolean;
  [Setting.SYSTEM_ADMIN]: boolean;
  [Setting.TESTIMONIALS]: boolean;
  [Setting.THROTTLE_DAILY_SENDS]: boolean;
  [Setting.TIERED_PRICING_ROLLOUT]: boolean;
  [Setting.TIERED_PRICING]: boolean;
  [Setting.TWO_FACTOR_AUTH]: boolean;
  [Setting.UPGRADE_PAGE]: boolean;
  [Setting.USE_APIARY]: boolean;
  [Setting.USER_INVITES_V2]: boolean;
  [Setting.USER_ROLES_V2]: boolean;
  [Setting.UTM_PARAMS]: boolean;
  [Setting.VERIFICATION_REQUIRED]: boolean;
  [Setting.WALLET]?: boolean;
  [Setting.WEB_BUILDER]: boolean;
  [Setting.WEBHOOKS]: boolean;
  [Setting.WHITE_LABELING]: boolean;
}

export interface OrganizationSetting {
  code: string;
  description: string;
  id: string | null;
  name: string;
  setting_type: string;
  value: string | boolean | number;
  value_origin: string;
}
