import { PropsWithChildren, useState } from 'react';
import { Pencil, Trash } from '@phosphor-icons/react';
import { DropdownMenuContentProps, DropdownMenuProps } from '@radix-ui/react-dropdown-menu';

import { useDeleteSiteTemplate } from '@/hooks/useSiteTemplates';
import { Button } from '@/routes/website/_components/UI/Button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/routes/website/_components/UI/DropdownMenu';
import Modal from '@/routes/website/_components/UI/Modal';
import { Text } from '@/routes/website/_components/UI/Text';

type Props = {
  siteTemplateId: string;
  onDeleteSuccess?: () => void;
};

export const DisplayOption = ({
  children,
  align,
  siteTemplateId,
  onDeleteSuccess,
  ...props
}: PropsWithChildren<{ align: DropdownMenuContentProps['align'] } & DropdownMenuProps & Props>) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const deleteSiteTemplate = useDeleteSiteTemplate({
    siteTemplateId,
    onSuccess: () => {
      onDeleteSuccess?.();
      setIsDeleteModalOpen(false);
    },
  });

  const handleDelete = () => {
    deleteSiteTemplate.mutate();
  };

  return (
    <>
      <DropdownMenu {...props}>
        <DropdownMenuTrigger asChild>{children}</DropdownMenuTrigger>
        <DropdownMenuContent sideOffset={2} align={align} className="w-[150px]">
          <DropdownMenuItem LeftIcon={Pencil} asLink linkProps={{ to: `/website_builder_v2/` }}>
            Edit
          </DropdownMenuItem>
          <DropdownMenuItem
            LeftIcon={Trash}
            onSelect={(e) => {
              e.stopPropagation();
              setIsDeleteModalOpen(true);
            }}
            disabled={false}
          >
            <Button
              variant="ghost"
              size="sm"
              className="w-full"
              isLoading={deleteSiteTemplate.isLoading}
              onClick={(e) => {
                e.stopPropagation();
                setIsDeleteModalOpen(true);
              }}
            >
              Delete
            </Button>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title="Delete Site Template"
        onConfirm={handleDelete}
        ctaText={deleteSiteTemplate.isLoading ? 'Deleting...' : 'Delete Site Template'}
        ctaVariant="danger"
        isLoading={deleteSiteTemplate.isLoading}
      >
        <Text size="sm" weight="medium" variant="secondary" as="p" className="mb-4">
          Are you sure you want to delete this site template? This will delete the current site template.
        </Text>
      </Modal>
    </>
  );
};
