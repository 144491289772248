import { NodeSelection, Plugin, PluginKey, TextSelection } from '@tiptap/pm/state';

import { modKey } from '../../utils';

import { drawMultinodeSelectionDecorations, MultiNodeSelection } from './MultiNodeSelection';

export const MultiNodeSelectionPluginKey = new PluginKey('multiNodeSelection');

export const getMultiNodeSelectionPlugin = () => {
  return new Plugin({
    key: MultiNodeSelectionPluginKey,

    props: {
      decorations: drawMultinodeSelectionDecorations,

      handleDOMEvents: {
        mousedown: (view, event) => {
          if (!event[modKey]) {
            return;
          }

          const eventPos = view.posAtCoords({ left: event.clientX, top: event.clientY });

          if (!eventPos) {
            return;
          }

          const { inside: nodePos } = eventPos;

          const { doc, selection } = view.state;

          if (nodePos === -1) {
            return;
          }

          const $nodePos = doc.resolve(nodePos);

          let newSelection: MultiNodeSelection | NodeSelection | null = null;

          if (selection instanceof NodeSelection && nodePos !== selection.from) {
            newSelection = new MultiNodeSelection([selection.$from, $nodePos]);
          }

          if (selection instanceof MultiNodeSelection) {
            if (selection.posList.includes(nodePos)) {
              const $newPosList = selection.$posList.filter((val) => val.pos !== nodePos);

              if ($newPosList.length === 0) {
                newSelection = new NodeSelection(selection.$posList[0]);
              } else {
                newSelection = new MultiNodeSelection($newPosList);
              }
            } else {
              newSelection = new MultiNodeSelection([...selection.$posList, $nodePos]);
            }
          }

          if (selection instanceof TextSelection) {
            const $currentNodePos = doc.resolve(selection.$from.start() - 1);
            const $clickedNodePos = $nodePos;

            if ($currentNodePos.pos === $clickedNodePos.pos) {
              newSelection = new NodeSelection($clickedNodePos);
            } else {
              newSelection = new MultiNodeSelection([$currentNodePos, $clickedNodePos]);
            }
          }

          if (!newSelection) {
            return;
          }

          view.dispatch(view.state.tr.setSelection(newSelection));

          event.preventDefault();
        },
      },
    },
  });
};
