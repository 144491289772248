import { FC } from 'react';
import { SignupElement } from '@shared/dream-components';
import { NodeViewProps, useReactNodeView } from '@tiptap/react';

import { useWebsiteContext } from '@/context/website-context';

import { getDataAttributes } from '../../utils';

export const SignupView: FC<NodeViewProps> = ({ HTMLAttributes, node }) => {
  const { onDragStart } = useReactNodeView();
  const { site } = useWebsiteContext();
  const isTermsOfServiceEnabled = site?.draft_site_version?.settings?.terms_of_service_enabled;
  const hasCustomTermsOfService = site?.draft_site_version?.settings?.terms_of_service;

  return (
    <SignupElement
      hasTermsOfService={isTermsOfServiceEnabled}
      hasCustomTermsOfService={hasCustomTermsOfService}
      element={{
        type: 'signup',
        attrs: {
          id: node.attrs.id,
          ...node.attrs,
        },
        content: [{ id: 'text', text: '' }],
      }}
      onDragStart={onDragStart}
      attributes={{
        'data-node-view-wrapper': '',
        ...getDataAttributes(HTMLAttributes),
      }}
      className="relative"
    >
      {/* So signup can easily be selected, try removing and see what happens */}
      <div className="absolute top-0 left-0 w-full h-full bg-transparent" />
    </SignupElement>
  );
};
