import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import appendSettingsPublicationId from '@/utils/appendSettingsPublicationId';

import useUpdateCustomDomain from './useUpdateCustomDomain';

export const useRedirectAfterEntriSetup = (publicationId: string, customDomainIds: string[]) => {
  const navigate = useNavigate();
  const { mutateAsync: updateCustomDomain } = useUpdateCustomDomain({});

  useEffect(() => {
    const completeSetup = () => {
      navigate(appendSettingsPublicationId('/settings/publication/domain', publicationId));
    };

    const updateDomainEntriStarted = async () => {
      const formData = new FormData();
      formData.append('custom_domain[entri_started_at]', new Date().toISOString());

      await Promise.all(customDomainIds.map((id) => updateCustomDomain({ id, formData })));

      completeSetup();
    };

    window.addEventListener('onEntriClose', updateDomainEntriStarted);

    return () => {
      window.removeEventListener('onEntriClose', updateDomainEntriStarted);
    };
  }, [navigate, publicationId, customDomainIds, updateCustomDomain]);
};
