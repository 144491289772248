import SectionRenderer from '../../helpers/SectionRenderer';
import { RenderCondition } from '../../RenderCondition';
import { AttributeSettingProps } from '../../types';
import { AlignmentSettings } from '../AlignmentSettings';
import BorderSettings from '../BorderSettings';
import { ColorSettings } from '../ColorSettings';
import { GapSettings } from '../GapSettings';
import { PaddingSettings } from '../PaddingSettings';
import ShadowSettings from '../ShadowSettings';

export const SurveyWidgetSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  return (
    <RenderCondition editor={editor} allowedNodeTypes={['survey']}>
      <SectionRenderer title="Container">
        <AlignmentSettings editor={editor} activeNodeResult={activeNodeResult} />
        <PaddingSettings editor={editor} activeNodeResult={activeNodeResult} property="padding" />
        <GapSettings editor={editor} activeNodeResult={activeNodeResult} property="gap" title="Gap" />
        <BorderSettings editor={editor} activeNodeResult={activeNodeResult} />
        <ShadowSettings editor={editor} activeNodeResult={activeNodeResult} property="shadow" title="Shadow" />
        <ColorSettings title="Background" editor={editor} activeNodeResult={activeNodeResult} property="background" />
      </SectionRenderer>
      <SectionRenderer title="Inputs">
        <BorderSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          properties={{
            color: 'inputBorderColor',
            style: 'inputBorderStyle',
            width: 'inputBorderWidth',
            radius: 'inputBorderRadius',
          }}
        />
        <ColorSettings
          title="Background"
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="inputBackgroundColor"
        />
        <ColorSettings
          title="Placeholder"
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="inputPlaceholderColor"
        />
        <ColorSettings title="Value" editor={editor} activeNodeResult={activeNodeResult} property="inputTextColor" />

        <ColorSettings title="Label" editor={editor} activeNodeResult={activeNodeResult} property="labelColor" />
        <ColorSettings title="Helper" editor={editor} activeNodeResult={activeNodeResult} property="helperTextColor" />
        <ColorSettings title="Required" editor={editor} activeNodeResult={activeNodeResult} property="requiredColor" />
        <ColorSettings title="Button" editor={editor} activeNodeResult={activeNodeResult} property="buttonColor" />
        <ColorSettings
          title="Button Text"
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="buttonTextColor"
        />
      </SectionRenderer>
    </RenderCondition>
  );
};
