import { Input, Switch } from '@/components/Form';
import { ExternalRssFeed, RSSCTAStyle } from '@/interfaces/external_rss_feed';
import { Dropdown } from '@/ui/Dropdown';

import BodyContainer from '../../../_components/BodyContainer';
import FormRow from '../../../_components/FormRow';

interface Props {
  externalRssFeed: ExternalRssFeed;
  onChange: (newExternalRssFeed: ExternalRssFeed) => void;
}

const DefaultsForm = ({ externalRssFeed, onChange }: Props) => {
  const {
    entriesToShow,
    ctaStyle,
    ctaText,
    displayAuthor,
    displayContent,
    displayCta,
    displayDescription,
    displayCategories,
    displayThumbnail,
    displayTitleAboveThumbnail,
    displayPubDate,
    displayTitle,
  } = externalRssFeed;

  const handleChange = (field: string, value: any) => {
    if (onChange) {
      onChange({
        ...externalRssFeed,
        [field]: value,
      });
    }
  };

  const handleThumbnailChange = (field: string, checked: any) => {
    onChange({
      ...externalRssFeed,
      displayThumbnail: checked,
      ctaStyle: !checked && ctaStyle === RSSCTAStyle.THUMBNAIL ? RSSCTAStyle.BUTTON : ctaStyle,
    });
  };

  const handleTitleChange = (field: string, checked: any) => {
    onChange({
      ...externalRssFeed,
      displayTitle: checked,
      ctaStyle: !checked && ctaStyle === RSSCTAStyle.TITLE ? RSSCTAStyle.BUTTON : ctaStyle,
    });
  };

  return (
    <BodyContainer>
      <Input
        type="number"
        name="entriesToShow"
        labelText="Articles to show"
        helperText="Specify the number of articles to show"
        id="create-external-rss-feed-entries-to-show"
        min={1}
        value={entriesToShow?.toString() || '1'}
        onChange={(e) => handleChange('entriesToShow', Number(e.target.value))}
      />
      <FormRow label="Display Article Title" helperText="By default show the article title">
        <Switch name="enabled" checked={displayTitle} variant="primary" size="large" onChange={handleTitleChange} />
      </FormRow>
      <FormRow label="Display Publication Date" helperText="By default show the date the article was published">
        <Switch
          name="enabled"
          checked={displayPubDate}
          variant="primary"
          size="large"
          onChange={(_, checked: boolean) => handleChange('displayPubDate', checked)}
        />
      </FormRow>
      <FormRow label="Display Thumbnail" helperText="Show the thumbnail image for the article">
        <Switch
          name="enabled"
          checked={displayThumbnail}
          variant="primary"
          size="large"
          onChange={handleThumbnailChange}
        />
      </FormRow>
      {displayThumbnail && displayTitle && (
        <FormRow label="Display Title Above Thumbnail" helperText="Show the title above the thumbnail">
          <Switch
            name="enabled"
            checked={displayTitleAboveThumbnail}
            variant="primary"
            size="large"
            onChange={(_, checked: boolean) => handleChange('displayTitleAboveThumbnail', checked)}
          />
        </FormRow>
      )}
      <FormRow label="Display Categories" helperText="Show the article categories">
        <Switch
          name="enabled"
          checked={displayCategories}
          variant="primary"
          size="large"
          onChange={(_, checked: boolean) => handleChange('displayCategories', checked)}
        />
      </FormRow>
      <FormRow label="Display Author" helperText="Show the byline for the article">
        <Switch
          name="enabled"
          checked={displayAuthor}
          variant="primary"
          size="large"
          onChange={(_, checked: boolean) => handleChange('displayAuthor', checked)}
        />
      </FormRow>
      <FormRow label="Display Description" helperText="Show the article description">
        <Switch
          name="enabled"
          checked={displayDescription}
          variant="primary"
          size="large"
          onChange={(_, checked: boolean) => handleChange('displayDescription', checked)}
        />
      </FormRow>
      <FormRow label="Display Full Article" helperText="Show the full article contents">
        <Switch
          name="enabled"
          checked={displayContent}
          variant="primary"
          size="large"
          onChange={(_, checked: boolean) => handleChange('displayContent', checked)}
        />
      </FormRow>
      <FormRow label="Display CTA" helperText="Show a link to the full article">
        <Switch
          name="enabled"
          checked={displayCta}
          variant="primary"
          size="large"
          onChange={(_, checked: boolean) => handleChange('displayCta', checked)}
        />
      </FormRow>
      {displayCta && (
        <Dropdown
          name="ctaStyle"
          labelText="CTA Style"
          value={ctaStyle}
          options={[
            { label: RSSCTAStyle.BUTTON, value: RSSCTAStyle.BUTTON },
            { label: RSSCTAStyle.LINK, value: RSSCTAStyle.LINK },
            { label: RSSCTAStyle.TITLE, value: RSSCTAStyle.TITLE, disabled: !displayTitle },
            {
              label: RSSCTAStyle.THUMBNAIL,
              value: RSSCTAStyle.THUMBNAIL,
              disabled: !displayThumbnail,
            },
          ]}
          onSelect={(name, value) => handleChange('ctaStyle', value)}
        />
      )}
      {displayCta && (ctaStyle === RSSCTAStyle.BUTTON || ctaStyle === RSSCTAStyle.LINK) && (
        <Input
          type="text"
          name="ctaText"
          label="CTA Text"
          id="create-external-rss-feed-cta-text"
          labelText="CTA Text"
          helperText="Customize the call to action text"
          value={ctaText}
          placeholder="View Article"
          onChange={(e) => handleChange('ctaText', e.target.value)}
        />
      )}
    </BodyContainer>
  );
};

export default DefaultsForm;
