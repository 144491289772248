import SectionRenderer from '../../helpers/SectionRenderer';
import { RenderCondition } from '../../RenderCondition';
import { AttributeSettingProps } from '../../types';
import BorderSettings from '../BorderSettings';
import { ColorSettings } from '../ColorSettings';
import { GapSettings } from '../GapSettings';
import { GridColumnSetting } from '../GridColumnSetting';
import { PaddingSettings } from '../PaddingSettings';
import ShadowSettings from '../ShadowSettings';

export const TestimonialsSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  return (
    <RenderCondition editor={editor} allowedNodeTypes={['testimonials']}>
      <SectionRenderer title="Layout">
        <GridColumnSetting editor={editor} activeNodeResult={activeNodeResult} title="Columns" />
        <PaddingSettings editor={editor} activeNodeResult={activeNodeResult} property="padding" />
        <GapSettings editor={editor} activeNodeResult={activeNodeResult} property="gap" title="Gap" />
      </SectionRenderer>
      <SectionRenderer title="Cards">
        <ShadowSettings editor={editor} activeNodeResult={activeNodeResult} property="shadow" title="Shadow" />
        <BorderSettings editor={editor} activeNodeResult={activeNodeResult} />
        <ColorSettings
          title="Background"
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="backgroundColor"
        />
        <ColorSettings title="Message" editor={editor} activeNodeResult={activeNodeResult} property="messageColor" />
        <ColorSettings title="Name" editor={editor} activeNodeResult={activeNodeResult} property="nameColor" />
        <ColorSettings
          title="Description"
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="descriptionColor"
        />
      </SectionRenderer>
    </RenderCondition>
  );
};
