import { useCallback } from 'react';
import { AlignBottom, AlignCenterVertical, AlignTop } from '@phosphor-icons/react';

import { Text } from '../../../UI/Text';
import { ToggleGroup, ToggleGroupItem } from '../../../UI/ToggleGroup';
import { AttributeSettingProps } from '../types';

export const getDefaultAlignment = () => {
  return 'left';
};

type VerticalAlignmentSettingsProps = AttributeSettingProps & {
  property?: string;
};

export const VerticalAlignmentSettings = ({
  editor,
  activeNodeResult,
  property = 'verticalAlign',
}: VerticalAlignmentSettingsProps) => {
  const { activeNodeType, activeNodePos, activeNodeAttributes } = activeNodeResult;

  const updateAlignment = useCallback(
    (value: string) => {
      if (!value) return;
      if (activeNodePos === undefined || !activeNodeType) return;

      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, property, value);
        return true;
      });
    },
    [activeNodePos, activeNodeType, property, editor]
  );

  return (
    <div className="flex items-center justify-stretch gap-2">
      <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
        Vertical
      </Text>
      <div className="grow bg-wb-secondary rounded-lg shadow-sm">
        <ToggleGroup
          className="p-[2px]"
          type="single"
          defaultValue="align_start"
          value={activeNodeAttributes?.[property]}
          onValueChange={updateAlignment}
        >
          <ToggleGroupItem value="align_start" className="grow">
            <AlignTop weight="bold" format="outline" className="h-4 w-4 text-wb-secondary" />
          </ToggleGroupItem>
          <ToggleGroupItem value="align_center" className="grow">
            <AlignCenterVertical weight="bold" format="outline" className="h-4 w-4 text-wb-secondary" />
          </ToggleGroupItem>
          <ToggleGroupItem value="align_end" className="grow">
            <AlignBottom weight="bold" format="outline" className="h-4 w-4 text-wb-secondary" />
          </ToggleGroupItem>
        </ToggleGroup>
      </div>
    </div>
  );
};
