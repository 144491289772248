import { Link, useParams } from 'react-router-dom';
import { ArrowRight } from '@phosphor-icons/react';

import { usePage } from '@/hooks/usePages';
import useUpdatePageVersion from '@/hooks/usePageVersion/useUpdatePageVersion';
import { Button } from '@/routes/website/_components/UI/Button';

import SectionRenderer from '../../helpers/SectionRenderer';
import { useAttributesPanelNavbarFooterContext } from '../../navbarFooterContextProvider';
import { ShowOnPageSettings } from '../ShowOnPageSettings';

export function NavbarSettings() {
  const { pageId } = useParams();

  const { data: page } = usePage({ pageId: pageId as string });
  const context = useAttributesPanelNavbarFooterContext();
  const { isNavbarSelected } = context || {};

  const { mutate: updatePageVersion, isLoading } = useUpdatePageVersion({
    pageId: page?.id || '',
    id: page?.draft_page_version?.id || '',
  });

  const isShowOnPageEnabled =
    'show_navbar' in (page?.draft_page_version?.customization || {})
      ? page?.draft_page_version?.customization?.show_navbar
      : true; // default to true if show_navbar is not defined

  if (!isNavbarSelected) {
    return null;
  }

  const handleShowOnPageChange = (value: boolean) => {
    updatePageVersion({
      customization: { show_navbar: value },
    });
  };

  return (
    <div className="navbar-settings">
      <SectionRenderer title="Navbar" collapsible={false}>
        <Link to={`/website_builder_v2/navbar?previousPageID=${pageId}`}>
          <Button variant="secondary" RightIcon={ArrowRight} className="w-full">
            Edit Navbar
          </Button>
        </Link>
      </SectionRenderer>
      <SectionRenderer title="Show on Page">
        {page && (
          <ShowOnPageSettings value={isShowOnPageEnabled} onChange={handleShowOnPageChange} isLoading={isLoading} />
        )}
      </SectionRenderer>
    </div>
  );
}
