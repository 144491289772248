import { useNavigate } from 'react-router-dom';
import moment from 'moment-mini';

import Badge from '@/components/Badge';
import { Typography, TypographyStack } from '@/components/Typography';
import { WebhookEndpoint } from '@/interfaces/webhooks/endpoint';
import { Button } from '@/ui/Button';
import { Card } from '@/ui/Card';
import appendSettingsPublicationId from '@/utils/appendSettingsPublicationId';

import CardHeader from '../../../_components/CardHeader';
import { usePublicationSettings } from '../../context';

interface Props {
  endpoint: Partial<WebhookEndpoint>;
  showEditButton?: boolean;
}

const Summary = ({ endpoint, showEditButton = false }: Props) => {
  const { currentPublicationId } = usePublicationSettings();
  const navigate = useNavigate();

  return (
    <Card className="flex flex-col gap-y-6">
      <CardHeader
        title="Summary"
        cta={
          endpoint.id && showEditButton ? (
            <Button
              size="xs"
              onClick={() =>
                navigate(
                  appendSettingsPublicationId(
                    `/settings/publication/webhooks/${endpoint.id}/edit`,
                    currentPublicationId
                  )
                )
              }
            >
              Edit
            </Button>
          ) : null
        }
      />
      {!endpoint.id ? (
        <Typography token="font-normal/text/sm" colorWeight="700">
          Webhook events you configure will only trigger notifications to your specified endpoint and not affect other
          parts of your application.
        </Typography>
      ) : null}
      {endpoint.id ? (
        <TypographyStack>
          <Typography token="font-normal/text/sm" colorWeight="600">
            Creation Date
          </Typography>
          <Typography token="font-normal/text/sm" as="time">
            {moment(endpoint.created_at).format('LLL')}
          </Typography>
        </TypographyStack>
      ) : null}
      <TypographyStack>
        <Typography token="font-normal/text/sm" colorWeight="600">
          Endpoint URL
        </Typography>
        <Typography token="font-normal/text/sm" className="break-all">
          {endpoint.url || '-'}
        </Typography>
      </TypographyStack>
      <TypographyStack>
        <Typography token="font-normal/text/sm" colorWeight="600">
          Endpoint Description
        </Typography>
        <Typography token="font-normal/text/sm" className="break-all">
          {endpoint.description || '-'}
        </Typography>
      </TypographyStack>
      <TypographyStack>
        <Typography token="font-normal/text/sm" colorWeight="600">
          Webhook Status
        </Typography>
        <Typography token="font-normal/text/sm">
          <Badge type={endpoint.disabled ? 'alert' : 'success'} className="w-fit h-fit">
            {endpoint.disabled ? 'Disabled' : 'Active'}
          </Badge>
        </Typography>
      </TypographyStack>
      <TypographyStack>
        <Typography token="font-normal/text/sm" colorWeight="600">
          Event Types
        </Typography>
        <div className="flex flex-row flex-wrap gap-y-2 gap-x-2 mt-2">
          {endpoint?.event_types && endpoint?.event_types.length > 0
            ? endpoint.event_types?.map((eventType) => <Badge>{eventType}</Badge>)
            : '-'}
        </div>
      </TypographyStack>
    </Card>
  );
};

export default Summary;
