import React from 'react';

import { Textarea } from '@/components/Form';
import Tooltip from '@/components/Tooltip';
import { NO_PERMISSION_MESSAGE } from '@/interfaces/permissions';
import Input from '@/ui/TextInput';

interface Props {
  automationName: string;
  automationDescription: string;
  onAutomationNameChange: (value: string) => void;
  onAutomationDescriptionChange: (value: string) => void;
  disabled?: boolean;
}

const MetadataForm = ({
  automationName,
  automationDescription,
  onAutomationNameChange,
  onAutomationDescriptionChange,
  disabled = false,
}: Props) => {
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => onAutomationNameChange(e.target.value);
  const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    onAutomationDescriptionChange(e.target.value);

  return (
    <div className="space-y-6 w-full">
      <Tooltip
        tooltipClass="text-center"
        id="automation-description-tooltip"
        text={disabled ? NO_PERMISSION_MESSAGE : ''}
        showIcon={false}
        autoWidth
        isEnabled={disabled}
      >
        <Input
          name="name"
          labelText="Name"
          placeholderText="Automation name"
          type="text"
          value={automationName}
          onChange={handleNameChange}
          required
          disabled={disabled}
        />
      </Tooltip>

      <Tooltip
        tooltipClass="text-center"
        id="automation-description-tooltip"
        text={disabled ? NO_PERMISSION_MESSAGE : ''}
        showIcon={false}
        autoWidth
        isEnabled={disabled}
      >
        <Textarea
          name="description"
          labelText="Description"
          placeholderText="Automation description"
          inputClassOverride={disabled ? 'cursor-not-allowed w-full' : 'w-full'}
          value={automationDescription}
          onChange={handleDescriptionChange}
          disabled={disabled}
        />
      </Tooltip>
    </div>
  );
};

export default MetadataForm;
