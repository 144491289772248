import { ReactNode, useRef, useState } from 'react';
import { Combobox } from '@headlessui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import Modal from '@/components/Modal';
import TabNavigation from '@/components/TabNavigation';
import { Typography, TypographyStack } from '@/components/Typography';
import { useSettings } from '@/context/settings-context';
import { useCurrentPublication } from '@/hooks';
import useCreateBoostInvitationsRestrictions from '@/hooks/boosts/grow/useCreateBoostInvitationsRestrictions';
import useOptions from '@/hooks/useOptions';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { BoostInvite } from '@/interfaces/boosts/grow/boost_invite';
import { Tab } from '@/interfaces/general';
import { PublicationSearchWithBoostInvite } from '@/interfaces/publication_search';
import { Dropdown } from '@/ui/Dropdown';
import ActiveItem from '@/ui/SearchPublication/ActiveItem';
import SearchListItem from '@/ui/SearchPublication/SearchListItem';
import TagSelect from '@/ui/SearchPublication/TagSelect';
import countries from '@/utils/countries.json';
import pluralize from '@/utils/pluralize';

import Teaser from '../Teaser';

import CreateBoostInviteForm from './CreateBoostInviteForm';

type Props = {
  boostOfferId: string;
  publications: PublicationSearchWithBoostInvite<BoostInvite>[];
  isFetchingPublications: boolean;
  query: string;
  language: string;
  country: string;
  onSetQuery: (query: string) => void;
  onSetLanguage: (language: string) => void;
  onSetCountry: (country: string) => void;
  ActiveListItemComponent?: (props: any) => JSX.Element;
  header?: ReactNode;
  activePublicationId?: string;
  tabs?: Tab[];
  onSubmitInvite: () => void;
  onDeleteInvite: () => void;
};

export default function SearchPublication({
  boostOfferId,
  query,
  language,
  country,
  onSetQuery,
  onSetLanguage,
  onSetCountry,
  publications,
  isFetchingPublications,
  header,
  activePublicationId,
  tabs = [],
  ActiveListItemComponent = ActiveItem,
  onSubmitInvite,
  onDeleteInvite,
}: Props) {
  const [isUpsellModalOpen, setIsUpsellModalOpen] = useState(false);
  const { refetch } = useCurrentPublication();
  const { remainingInvites, canUpgrade } = useCreateBoostInvitationsRestrictions();
  const { settings } = useSettings();
  const isNoResults = publications.length === 0;

  const inputRef = useRef<HTMLInputElement>(null);

  const changeQuery = (str: string) => {
    onSetQuery(str);

    if (inputRef.current) inputRef.current.value = str;
  };

  const selectedPublication = activePublicationId
    ? publications.find((publication) => publication.id === activePublicationId)
    : publications[0];

  // Set Language Options
  const currentPublicationId = useCurrentPublicationId();
  const languages = useOptions(currentPublicationId, 'languages');
  const { data: languageData } = languages;
  const languageOptions = [{ label: 'Not specified', value: 'not_specified' }].concat(
    languageData?.options.map((option: any) => {
      return { label: option[1], value: option[0] };
    }) || []
  );

  const countryOptions = [{ label: 'Not specified', value: 'not_specified' }].concat(
    countries.map((item) => ({
      label: item.name,
      value: item.code,
    }))
  );

  const handleSubmitInvitation = () => {
    refetch();
    onSubmitInvite();
  };

  const handleDeleteInvitation = () => {
    refetch();
    onDeleteInvite();
  };

  return (
    <>
      <Modal isOpen={isUpsellModalOpen} onClose={() => setIsUpsellModalOpen(false)}>
        <div className="m-6">
          <Teaser showEnabledToast={false} />
        </div>
      </Modal>
      <>
        {header}
        <Combobox value={selectedPublication} onChange={() => {}}>
          {({ activeOption }) => {
            return (
              <>
                <div className="flex gap-x-3 py-4 px-6">
                  <div className="relative flex w-1/2 py-2 px-3 border border-surface-200 rounded-md shadow-sm">
                    <MagnifyingGlassIcon className="pointer-events-none h-5 w-5 text-gray-400" aria-hidden="true" />
                    <Combobox.Input
                      className="border-0 p-0 text-sm w-full focus:ring-0 focus:outline-none ml-1"
                      placeholder="Search by publication or #content tag"
                      onChange={(e) => changeQuery(e.target.value)}
                      ref={inputRef}
                    />
                    <TagSelect query={query} onChangeQuery={changeQuery} />
                    <LoadingSpinner
                      className="pointer-events-none"
                      size="md"
                      delay={500}
                      visible={isFetchingPublications}
                    />
                  </div>
                  <div className="flex w-1/2 gap-x-3">
                    <Dropdown
                      value={language}
                      placeholderText="Language"
                      name="search-publication-language-select"
                      options={languageOptions}
                      onSelect={(_, value: string) => onSetLanguage(value)}
                      className="w-1/2"
                    />

                    <Dropdown
                      value={country}
                      placeholderText="Country"
                      name="search-publication-country-select"
                      options={countryOptions}
                      onSelect={(_, value: string) => onSetCountry(value)}
                      className="w-1/2"
                    />
                  </div>
                </div>

                <Combobox.Options as="div" static hold className="flex divide-x divide-gray-100">
                  <div
                    className={classNames(
                      'max-h-96 min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 pb-4',
                      activeOption ? 'sm:h-96' : ''
                    )}
                  >
                    <div className="w-full my-2">
                      <TabNavigation tabs={tabs} variant="primary" />
                    </div>

                    <div className="text-sm text-gray-700">
                      {publications.map((publication) => (
                        <Combobox.Option
                          as="div"
                          key={publication.id}
                          value={publication}
                          className={({ active }) =>
                            classNames(
                              'flex select-none items-center rounded-md p-2',
                              active && 'bg-gray-100 text-gray-900'
                            )
                          }
                        >
                          {({ active }) => <SearchListItem isActive={active} publication={publication} />}
                        </Combobox.Option>
                      ))}
                    </div>
                  </div>

                  <div className="hidden w-1/2 flex-none flex-col divide-y divide-gray-100 overflow-y-auto sm:flex">
                    {activeOption && (
                      <>
                        <ActiveListItemComponent
                          publication={activeOption}
                          onTagClick={(tag: string) => changeQuery(`${query} #${tag} `)}
                        />
                        <CreateBoostInviteForm
                          boostOfferId={boostOfferId}
                          publicationId={activeOption.id}
                          boostInvite={activeOption.boost_invite}
                          onSubmit={handleSubmitInvitation}
                          onDelete={handleDeleteInvitation}
                        />
                      </>
                    )}
                    {isNoResults && (
                      <div className="py-14 px-6 text-center text-sm sm:px-14">
                        <ChatBubbleBottomCenterTextIcon className="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
                        <p className="mt-4 font-semibold text-gray-900">No publications found</p>
                      </div>
                    )}
                  </div>
                </Combobox.Options>
              </>
            );
          }}
        </Combobox>
        <div className="py-6 px-16 bg-violet-50">
          <TypographyStack className="text-center">
            <Typography
              token="font-medium/text/xs"
              className="bg-violet-200 px-3 py-1 border border-violet-300 text-violet-900 rounded-sm"
            >
              {remainingInvites}/{settings?.max_monthly_boost_invitations}{' '}
              {pluralize('invite', remainingInvites, undefined, true)} left
            </Typography>
            <Typography token="font-light/text/xs">
              {canUpgrade ? (
                <span>
                  If you want to invite more publications than your plan limit above allows per month, you can{' '}
                  <button type="button" onClick={() => setIsUpsellModalOpen(true)}>
                    <Typography token="font-medium/text/xs" className="text-violet-900">
                      upgrade your plan
                    </Typography>
                  </button>
                </span>
              ) : (
                <span>
                  If you want to invite more publications than your plan limit above allows per month, please reach out
                  to{' '}
                  <a href="mailto:support@beehiiv.com">
                    <Typography token="font-medium/text/xs" className="text-violet-900">
                      support@beehiiv.com
                    </Typography>
                  </a>
                </span>
              )}
            </Typography>
          </TypographyStack>
        </div>
      </>
    </>
  );
}
