import { useCallback, useEffect, useState } from 'react';
import { LinkBreak } from '@phosphor-icons/react';

import { ColorPicker } from '../../../UI/ColorPicker';
import { SimpleInputWrapper } from '../../../UI/Input';
import { Popover, PopoverContent, PopoverTrigger } from '../../../UI/Popover';
import { Text } from '../../../UI/Text';
import { ToggleGroup, ToggleGroupItem } from '../../../UI/ToggleGroup';
import { AttributeSettingProps } from '../types';

import GradientSettings from './GradientSettings';

type Props = AttributeSettingProps & {
  title: string;
  property: string;
  onOverrideSetColor?: (value: string | null) => void;
  properties?: string[];
  initialColor?: string | null;
  onUnGroup?: () => void;
  hasGradientPicker?: boolean;
  isFontGradient?: boolean;
  gradientProperty?: string;
  // if true, will set the color as a mark style instead of a node attribute
  isMarkStyle?: boolean;
};

const DEFAULT_COLOR = '#000000FF';

const reformatColor = (color?: string) => {
  if (!color) return undefined;

  // if color is not 9 digits (including alpha & '#')
  if (color?.length === 4) {
    return `#${color.replace('#', '')}${color.replace('#', '')}FF`;
  }
  if (color?.length === 7) {
    return `${color}FF`;
  }
  if (color?.length === 9) {
    // do nothing
  }

  return color;
};

export const ColorSettings = ({
  editor,
  title,
  property,
  properties,
  activeNodeResult,
  onOverrideSetColor,
  initialColor,
  isMarkStyle = false,
  onUnGroup,
  hasGradientPicker = false,
  isFontGradient = false,
  gradientProperty = 'background',
}: Props) => {
  const { activeNodeType, activeNodePos, activeNodeAttributes } = activeNodeResult;
  const [color, setColor] = useState<string | undefined>(initialColor || undefined);
  const [pickerType, setPickerType] = useState<'color' | 'gradient'>('color');

  const onSetColor = useCallback(
    (value: string | null) => {
      if (isMarkStyle) {
        const isLinkActive = editor.isActive('link');

        if (isLinkActive) {
          editor.chain().extendMarkRange('link').updateAttributes('link', { color: value }).focus().run();
          return;
        }

        if (value) {
          editor?.chain().selectTextBlock().unsetFontGradient().setColor(value).focus().run();
        } else {
          editor?.chain().selectTextBlock().unsetColor().focus().run();
        }

        return;
      }

      if (activeNodePos === undefined || !activeNodeType) return;

      editor.commands.command(({ tr }) => {
        // This is in tandem with ColorGroupSettings, where we set multiple properties at once
        if (properties) {
          properties.forEach((propertyType: string) => {
            tr.setNodeAttribute(activeNodePos, propertyType, value);
          });
        } else {
          tr.setNodeAttribute(activeNodePos, property, value);
        }
        return true;
      });
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editor, activeNodePos, property, isMarkStyle]
  );

  useEffect(() => {
    let c: string;
    if (isMarkStyle) {
      const textStyleColor = editor.getAttributes('textStyle').color;
      c = textStyleColor || DEFAULT_COLOR;
    } else {
      c = activeNodeAttributes[property];
    }

    setColor(reformatColor(c));
  }, [activeNodeAttributes, editor, property, isMarkStyle]);

  const onReset = useCallback(() => {
    setColor(DEFAULT_COLOR);
    onSetColor(null);
  }, [onSetColor]);

  return (
    <Popover>
      <div className="flex items-center justify-stretch gap-2 select-none">
        <Text className="w-[80px] shrink-0" variant="secondary" size="2xs" weight="medium">
          {title}
        </Text>
        <PopoverTrigger asChild>
          <SimpleInputWrapper className="justify-start relative">
            {onUnGroup && (
              <button
                type="button"
                className="absolute -left-5 top-2 hover:cursor-pointer text-wb-secondary hover:text-wb-primary transition-all"
                onClick={onUnGroup}
              >
                <LinkBreak className="w-4 h-4" />
              </button>
            )}
            {color ? (
              <div className="w-4 h-4 rounded-md" style={{ backgroundColor: color }} />
            ) : (
              <div className="w-4 h-4 rounded-md flex items-center justify-center bg-wb-primary p-2">
                <Text size="2xs" weight="medium">
                  ?
                </Text>
              </div>
            )}
            <Text size="2xs" weight="medium" className="">
              {color?.toUpperCase() || 'Inherit'}
            </Text>
          </SimpleInputWrapper>
        </PopoverTrigger>
      </div>
      <PopoverContent className="w-[280px] p-0" align="center" side="left" sideOffset={110}>
        <div className="max-h-[700px] overflow-y-auto p-3 flex flex-col gap-4">
          {hasGradientPicker && (
            <div className="w-full flex flex-col gap-2">
              <div className="grow flex items-center">
                <SimpleInputWrapper className="h-[38px] px-1">
                  <ToggleGroup
                    className="py-[1px] w-full"
                    type="single"
                    defaultValue={pickerType}
                    onValueChange={(type) => setPickerType(type as 'color' | 'gradient')}
                  >
                    <ToggleGroupItem asChild value="color">
                      <Text
                        variant="secondary"
                        size="2xs"
                        weight="medium"
                        className="grow data-[state=on]:text-wb-accent"
                      >
                        Color
                      </Text>
                    </ToggleGroupItem>
                    <ToggleGroupItem asChild value="gradient">
                      <Text
                        variant="secondary"
                        size="2xs"
                        weight="medium"
                        className="grow data-[state=on]:text-wb-accent"
                      >
                        Gradient
                      </Text>
                    </ToggleGroupItem>
                  </ToggleGroup>
                </SimpleInputWrapper>
              </div>
            </div>
          )}
          {hasGradientPicker && pickerType === 'gradient' && (
            <GradientSettings
              editor={editor}
              activeNodeResult={activeNodeResult}
              property={gradientProperty}
              isFontGradient={isFontGradient}
            />
          )}
          {(pickerType === 'color' || !hasGradientPicker) && (
            <ColorPicker
              key={activeNodeAttributes.id}
              selectedColor={color}
              onSetColor={(value: string | null) => {
                setColor(value || DEFAULT_COLOR);

                // Used as an escape hatch to prevent this from submitting if it's consumed by a parent component like in BorderSettings
                if (onOverrideSetColor) {
                  onOverrideSetColor(value);
                } else {
                  onSetColor(value);
                }
              }}
              onReset={onReset}
            />
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
};
