import { useNavigate, useSearchParams } from 'react-router-dom';

import LoadingBox from '@/components/LoadingBox';
import TabNavigation from '@/components/TabNavigation';
import { usePublication } from '@/hooks/usePublications';
import { Tab } from '@/interfaces/general';
import { Button } from '@/ui/Button';
import { Card } from '@/ui/Card';
import appendSettingsPublicationId from '@/utils/appendSettingsPublicationId';

import BodyContainer from '../../../_components/BodyContainer';
import CardHeader from '../../../_components/CardHeader';
import PageContainer from '../../../_components/PageContainer';
import { usePublicationSettings } from '../../context';

import Analytics from './Analytics';
import Header from './Header';
import Overview from './Overview';
import Polls from './Polls';
import PremiumData from './PremiumData';

const PublicationWelcomeEmailSettings: React.FC = () => {
  const navigate = useNavigate();
  const { currentPublicationId } = usePublicationSettings();
  const { data: publication, isSuccess, isError } = usePublication(currentPublicationId);

  const [searchParams, setSearchParams] = useSearchParams();
  const currentTab = searchParams.get('tab') || 'overview';
  const handleSelectTab = (name: string) => {
    searchParams.set('tab', name);
    setSearchParams(searchParams);
  };
  const tabs: Tab[] = [
    {
      name: 'overview',
      label: 'Overview',
      selected: currentTab === 'overview',
      onSelect: () => handleSelectTab('overview'),
    },
    {
      name: 'analytics',
      label: 'Analytics',
      selected: currentTab === 'analytics',
      onSelect: () => handleSelectTab('analytics'),
    },
    {
      name: 'polls',
      label: 'Polls',
      selected: currentTab === 'polls',
      onSelect: () => handleSelectTab('polls'),
    },
  ];

  if (publication?.is_premium_enabled) {
    tabs.push({
      name: 'premium_data',
      label: 'Premium data',
      selected: currentTab === 'premium_data',
      onSelect: () => handleSelectTab('premium_data'),
    });
  }

  return (
    <LoadingBox isLoading={!isSuccess} isError={isError}>
      {publication ? (
        <PageContainer key={currentPublicationId} isWide>
          <Header currentPublicationId={currentPublicationId} showEdit={false} />
          <Card>
            <BodyContainer>
              <CardHeader
                title="Welcome Email"
                description="The email that is sent out to new readers right after they signup for your newsletter"
                cta={
                  <Button
                    size="xs"
                    onClick={() =>
                      navigate(
                        appendSettingsPublicationId('/settings/publication/emails/welcome_email/edit', publication.id)
                      )
                    }
                  >
                    Edit email
                  </Button>
                }
              />
              <TabNavigation tabs={tabs} variant="tertiary" />
              {currentTab === 'overview' ? <Overview publication={publication} /> : null}
              {currentTab === 'analytics' ? <Analytics publication={publication} /> : null}
              {currentTab === 'polls' ? <Polls publication={publication} /> : null}
              {currentTab === 'premium_data' ? <PremiumData publication={publication} /> : null}
            </BodyContainer>
          </Card>
        </PageContainer>
      ) : null}
    </LoadingBox>
  );
};

export default PublicationWelcomeEmailSettings;
