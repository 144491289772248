import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/24/solid';

import LoadingBox from '@/components/LoadingBox';
import { Typography, TypographyRow, TypographyStack } from '@/components/Typography';
import { useExternalRssFeeds } from '@/hooks/useExternalRssFeeds';
import { Button } from '@/ui/Button';
import { Card } from '@/ui/Card';
import appendSettingsPublicationId from '@/utils/appendSettingsPublicationId';

import BodyContainer from '../../_components/BodyContainer';
import CardHeader from '../../_components/CardHeader';

import ExternalFeedListItem from './ExternalFeedListItem';

interface Props {
  publicationId: string;
}

const ExternalRssFeeds: React.FC<Props> = ({ publicationId }) => {
  const { data: externalRssFeeds, isLoading, isError } = useExternalRssFeeds();
  const navigate = useNavigate();

  const hasFeeds = externalRssFeeds && externalRssFeeds.length > 0;

  return (
    <LoadingBox isLoading={isLoading} isError={isError}>
      <BodyContainer>
        <Card>
          <CardHeader
            title="RSS Ingestion"
            cta={
              hasFeeds && (
                <Button
                  variant="primary"
                  type="submit"
                  Icon={PlusIcon}
                  onClick={() => navigate(appendSettingsPublicationId(`/settings/publication/rss/new`, publicationId))}
                >
                  New External RSS Feed
                </Button>
              )
            }
          />

          <TypographyStack gap="4" className="mt-4 mb-6">
            <TypographyRow className="w-2/3">
              <Typography token="font-normal/text/sm" colorWeight="500">
                {hasFeeds
                  ? 'Generate an RSS Feed which allows you to sync posts made on beehiiv with a custom website. '
                  : 'Add an external RSS feed to your publication. This will allow you to display posts from other sources on your website.'}
              </Typography>
            </TypographyRow>
            {!hasFeeds && (
              <Typography token="font-normal/text/sm" colorWeight="500">
                For example, if you are using WordPress or another 3rd party tool to publish your content via an RSS
                Feed, you can pull that content into your posts by adding your feed here.
              </Typography>
            )}
          </TypographyStack>

          <div className="flex flex-col gap-4">
            {hasFeeds &&
              externalRssFeeds.map((feed) => (
                <ExternalFeedListItem key={feed.id} externalRssFeed={feed} publicationId={publicationId} />
              ))}
          </div>
          {!hasFeeds && (
            <Button
              variant="primary"
              type="submit"
              Icon={PlusIcon}
              onClick={() => navigate(appendSettingsPublicationId(`/settings/publication/rss/new`, publicationId))}
            >
              New External RSS Feed
            </Button>
          )}
        </Card>
      </BodyContainer>
    </LoadingBox>
  );
};

export default ExternalRssFeeds;
