import { useEffect, useMemo, useState } from 'react';
import { ArrowLeft, MagnifyingGlass, X } from '@phosphor-icons/react';
import { PostElement, TPostAttributes } from '@shared/dream-components';
import { NodeViewProps } from '@tiptap/core';

import useInfinitePosts from '@/hooks/useDreamBuilder/useInfinitePosts';
import { useInfiniteScrollSiteTemplates } from '@/hooks/useSiteTemplates';
import { PostPreview } from '@/interfaces/post';

import { cn } from '../../../../../_utils/cn';
import { Button } from '../../../../UI/Button';
import { Checkbox } from '../../../../UI/Checkbox';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '../../../../UI/Dialog';
import { Input } from '../../../../UI/Input';
import { Label } from '../../../../UI/Label';
import { Text } from '../../../../UI/Text';

// import { selectThreeRandomPosts } from '../../../dummyData/posts';
import PostTypeSelect from './PostTypeSelect';

// Helpful for testing
// const fakePosts = selectThreeRandomPosts({
//   count: 9,
// });

const PostsSelectionModal = ({
  editor,
  node,
  isOpen,
  onClose,
  setIsModalOpen,
  getPos,
}: NodeViewProps & {
  isOpen: boolean;
  onClose: () => void;
  setIsModalOpen: (isOpen: boolean) => void;
}) => {
  const [search, setSearch] = useState('');
  const [selectedPosts, setSelectedPosts] = useState<PostPreview[] | any[]>([]);
  const [selectedLayout, setSelectedLayout] = useState<any>(null);
  const [isLayoutOpen, setIsLayoutOpen] = useState(false);
  const [isCategorySelected, setIsCategorySelected] = useState(false);
  const [postType, setPostType] = useState<'free_selection' | 'latest' | 'featured' | any>('free_selection');

  const { data: siteTemplatesData } = useInfiniteScrollSiteTemplates({ enabled: isOpen, category: 'posts' });
  const siteTemplates = siteTemplatesData?.pages.flatMap((page) => page.site_templates) || [];
  const hasSiteTemplates = siteTemplates?.length > 0;

  const title = 'Select posts display';

  useEffect(() => {
    if (hasSiteTemplates) {
      setSelectedLayout(siteTemplates[0]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSiteTemplates]);

  const { data } = useInfinitePosts({
    enabled: isOpen,
  });
  const posts = data?.pages.flatMap((page) => page.posts) || [];
  // const posts = fakePosts;

  const selectedPostsIds = useMemo(() => selectedPosts.map((post) => post.id), [selectedPosts]);

  const handleSubmit = () => {
    const updatedAttributes = {
      ...node?.attrs,
      insertedFromSidebar: false,
      data: {
        posts: !isCategorySelected
          ? selectedPosts
          : selectedPosts.map((post, index) => ({
              ...post,
              content_tags: [{ id: `${postType}-${index}`, display: postType }],
            })), // This will ensure all posts in a section have the same tag
      },
    };

    editor?.commands.command(({ tr }) => {
      tr.setNodeAttribute(getPos(), 'insertedFromSidebar', false);
      tr.setNodeAttribute(getPos(), 'hasFakeData', false);
      tr.setNodeAttribute(getPos(), 'data', updatedAttributes.data);
      return true;
    });

    setIsModalOpen(false);
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="w-[60vw] max-w-none h-[90vh] flex flex-col overflow-hidden">
        <DialogHeader>
          <DialogTitle>
            <div className="flex items-center gap-2">
              {isLayoutOpen && <Button variant="ghost" onClick={() => setIsLayoutOpen(false)} LeftIcon={ArrowLeft} />}
              <Text size="xl" weight="semibold" variant="primary" as="h4">
                {title}
              </Text>
            </div>
          </DialogTitle>
        </DialogHeader>

        {isLayoutOpen && (
          <div className="flex flex-col w-full gap-4">
            <div className="grid grid-cols-2 gap-4">
              {siteTemplates?.map((template) => {
                const isSelected = selectedLayout?.id === template.id;

                return (
                  <Label
                    htmlFor={template.id}
                    className={cn(
                      'flex flex-col cursor-pointer hover:shadow-md border shadow p-0 rounded-md h-72 focus:outline-none relative justify-center items-center',
                      isSelected ? 'border-wb-accent border-2' : 'border-wb-primary'
                    )}
                  >
                    <div className="scale-50 w-full max-w-[300px]">
                      <PostElement
                        element={{
                          type: 'post',
                          attrs: {
                            ...(node?.attrs as TPostAttributes),
                            cardStructure: template?.content?.attrs?.cardStructure,
                            data: {
                              posts: template.content?.attrs?.data?.posts.slice(0, 1),
                            },
                          },
                        }}
                      >
                        {null}
                      </PostElement>
                    </div>
                    <Checkbox
                      id={template.id}
                      className={cn('absolute -top-2 -right-2', isSelected ? 'opacity-100' : 'opacity-0')}
                      checked={Boolean(isSelected)}
                      onCheckedChange={() => setSelectedLayout(template)}
                    />

                    <Text
                      weight="semibold"
                      variant="primary"
                      size="sm"
                      as="span"
                      className="absolute -bottom-6 left-1/2 transform -translate-x-1/2"
                    >
                      {template.name}
                    </Text>
                  </Label>
                );
              })}
            </div>
          </div>
        )}

        {/** PREFERENCES SECTION */}

        <div className="grid grid-cols-2 gap-8 items-end">
          <div className="flex flex-col gap-4">
            {/** avoids fetching options when modal is closed */}
            {isOpen && (
              <PostTypeSelect
                setIsCategorySelected={setIsCategorySelected}
                setPostType={setPostType}
                node={node}
                editor={editor}
                getPos={getPos}
              />
            )}
          </div>

          <Input
            name="name"
            className="w-full"
            LeftIcon={MagnifyingGlass}
            type="text"
            placeholder="Search posts"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        <div className="flex flex-col gap-2 w-full">
          <div className="flex gap-2">
            {selectedPosts.map((post) => (
              <button
                type="button"
                key={post.id}
                className="flex items-center justify-center px-2 py-1 bg-wb-accent-soft text-wb-accent rounded-md text-[10px] gap-1"
                onClick={() => setSelectedPosts(selectedPosts.filter((p) => p.id !== post.id))}
              >
                <Text
                  weight="regular"
                  variant="accent"
                  size="3xs"
                  as="span"
                  className="line-clamp-1 max-w-[150px] truncate"
                >
                  {post.web_title}
                </Text>
                <X className="w-3 h-3" />
              </button>
            ))}
          </div>
        </div>

        <div className="flex flex-col gap-4 w-full overflow-y-auto no-scrollbar">
          <div className="flex flex-col gap-4">
            <Text size="sm" weight="semibold" variant="secondary" as="p">
              Results
            </Text>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 pb-32">
              {posts.map((post) => {
                const isSelected = selectedPostsIds.includes(post.id);

                return (
                  <Label
                    key={post.id}
                    htmlFor={post.id}
                    className={cn(
                      'flex flex-col cursor-pointer hover:shadow-md border shadow p-0 rounded-md focus:outline-none relative overflow-hidden',
                      isSelected ? 'border-wb-accent border-2' : 'border-wb-primary'
                    )}
                  >
                    <div className="w-full h-36 overflow-hidden">
                      <img
                        src={post.image_url}
                        alt={post.web_title}
                        className="w-full h-full object-cover aspect-video"
                      />
                    </div>
                    <div className="flex justify-between items-center gap-2 p-3">
                      <div className="flex flex-col">
                        <Text weight="semibold" variant="primary" size="xs" as="span" className="line-clamp-1">
                          {post.web_title}
                        </Text>
                        <Text weight="regular" variant="secondary" size="2xs" as="span" className="line-clamp-1">
                          {post.web_subtitle}
                        </Text>
                      </div>
                      <Checkbox
                        id={post.id}
                        className={cn(isSelected ? 'opacity-100' : 'opacity-0')}
                        checked={Boolean(isSelected)}
                        onCheckedChange={() => {
                          if (isSelected) {
                            setSelectedPosts(selectedPosts.filter((p) => p.id !== post.id));
                          } else {
                            setSelectedPosts([...selectedPosts, post]);
                          }
                        }}
                      />
                    </div>
                  </Label>
                );
              })}
            </div>
          </div>
        </div>

        <DialogFooter className="flex justify-between items-center absolute bottom-0 left-0 right-0 p-4 bg-white border-t border-wb-primary">
          <div className="flex gap-2 w-full justify-end">
            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleSubmit}>
              Select posts
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default PostsSelectionModal;
