import { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { SparklesIcon } from '@heroicons/react/20/solid';

import LoadingBox from '@/components/LoadingBox';
import { useSettings } from '@/context/settings-context';
import { Button } from '@/ui/Button';
import { SettingsGroup } from '@/ui/Settings/SettingsContainer';
import appendSettingsWorkspaceId from '@/utils/appendSettingsWorkspaceId';

type Props = PropsWithChildren<{
  organizationId: string;
  publicationId: string;
}>;

const EnsurePremiumAccess: React.FC<Props> = ({ children, organizationId, publicationId }) => {
  const { settings, isLoading, isError } = useSettings();
  const navigate = useNavigate();

  return (
    <LoadingBox backgroundClassName="bg-transparent" isLoading={isLoading} isError={isError}>
      {!settings?.premium_subscriptions && (
        <div className="min-w-[48rem]">
          <SettingsGroup
            title="Paid Subscriptions"
            description="Enable and configure paid subscriptions on a beehiiv paid plan."
          >
            <Button
              Icon={SparklesIcon}
              onClick={() =>
                navigate(
                  appendSettingsWorkspaceId('/settings/workspace/billing_and_plan', organizationId, publicationId)
                )
              }
              className="w-fit"
            >
              Upgrade to Scale
            </Button>
          </SettingsGroup>
        </div>
      )}

      {settings?.premium_subscriptions && children}
    </LoadingBox>
  );
};

export default EnsurePremiumAccess;
