import { Document as TiptapDocument } from '@tiptap/extension-document';

export const getDocument = (type: 'template' | 'page' = 'page') => {
  const content =
    type === 'template'
      ? '(block|columns|section|tableBlock|footnotesNode|paywallBreak|serviceEmbed|codeBlockGroup)+'
      : 'section+';

  return TiptapDocument.extend({ content });
};
