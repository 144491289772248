import React, { useCallback, useEffect, useRef, useState } from 'react';
import { HexColorInput, RgbaColorPicker } from 'react-colorful';

import { SimpleInput, SimpleInputWrapper } from './Input';
import { Text } from './Text';

import './ColorPicker.css';

export interface ColorPickerProps {
  selectedColor?: string;
  onSetColor: (color: string | null) => void;
  onReset?: () => void;
}

const convertHexToRgba = (hex: string) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  const a = hex.length === 9 ? parseInt(hex.slice(7, 9), 16) / 255 : 1;
  return { r, g, b, a };
};

const convertRgbaToHex = (rgba: { r: number; g: number; b: number; a?: number }, options?: { alpha?: boolean }) => {
  const { r, g, b, a = 1 } = rgba;
  const alpha = Math.round(a * 255)
    .toString(16)
    .padStart(2, '0');

  if (options?.alpha) {
    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b
      .toString(16)
      .padStart(2, '0')}${alpha}`.toUpperCase();
  }
  return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b
    .toString(16)
    .padStart(2, '0')}`.toUpperCase();
};

export const ColorPicker = ({
  selectedColor = '#939393FF',
  onSetColor = () => null,
  onReset = () => null,
}: ColorPickerProps): JSX.Element => {
  const rgbaSelectedColor = convertHexToRgba(selectedColor ?? '#939393FF');
  const [newColor, setNewColor] = useState(rgbaSelectedColor);
  const colorInputValue = useRef<string | undefined>(undefined);
  const [alpha, setAlpha] = useState(
    Number.isNaN(Math.round(rgbaSelectedColor.a * 100)) ? 100 : Math.round(rgbaSelectedColor.a * 100)
  );

  const lastSetColor = useRef(selectedColor);

  useEffect(() => {
    const hexNewColor = convertRgbaToHex(newColor, { alpha: true });
    if (lastSetColor.current !== hexNewColor) {
      lastSetColor.current = hexNewColor;
      setAlpha(Math.round(newColor.a * 100));
      onSetColor(hexNewColor);
    }
  }, [newColor, onSetColor]);

  const handleAlphaChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = parseInt(e.target.value, 10);
      setAlpha(Number.isNaN(value) ? 0 : value);
    },
    [setAlpha]
  );

  const handleAlphaKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && alpha) {
        setNewColor((prev) => ({ ...prev, a: alpha / 100 }));
      }
    },
    [alpha, setNewColor]
  );

  const setNewHexColor = (hex: string) => {
    let h = hex;
    if (h.length === 4) {
      h = h[0] + h[1] + h[1] + h[2] + h[2] + h[3] + h[3];
    }
    colorInputValue.current = hex;
  };

  const handleHexKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const cInput = colorInputValue.current;
      if (e.key === 'Enter' && cInput) {
        setNewColor((prev) => {
          return { ...convertHexToRgba(cInput), a: prev.a };
        });
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setNewHexColor]
  );

  return (
    <div className="wb-react-colorful">
      <RgbaColorPicker color={newColor} onChange={setNewColor} />
      <div className="flex items-center justify-between gap-2 max-w-full mt-4">
        <div className="flex items-center p-2 bg-wb-secondary rounded-md">
          <Text size="2xs" weight="medium" className="leading-[18px]">
            Hex
          </Text>
        </div>
        <SimpleInputWrapper>
          <div
            className="h-4 w-4 rounded-md"
            style={{ backgroundColor: convertRgbaToHex(newColor, { alpha: true }) }}
          />
          <div className="wb-react-colorful-input-wrapper">
            <HexColorInput
              prefixed
              color={convertRgbaToHex(newColor) || '#000000'}
              onChange={setNewHexColor}
              onKeyDown={handleHexKeyDown}
            />
          </div>
        </SimpleInputWrapper>
        <SimpleInputWrapper className="gap-0">
          <SimpleInput value={alpha} type="number" onChange={handleAlphaChange} onKeyDown={handleAlphaKeyDown} />
          <Text size="2xs" weight="medium">
            %
          </Text>
        </SimpleInputWrapper>
        <Text size="2xs" weight="medium" className="hover:underline cursor-pointer" onClick={onReset}>
          Reset
        </Text>
      </div>
    </div>
  );
};
