import { useQuery } from 'react-query';

import { PriceInterval } from '@/interfaces/tier';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

interface Props {
  enabled?: boolean;
  offerId?: string;
  appendFreeTier?: boolean;
}

const FREE_TIER = {
  id: 'free',
  name: 'free plan',
  description: '',
  redirect_url: '',
  prices: [
    {
      id: 'free',
      amount_cents: 0,
      original_amount_cents: 0,
      currency: 'usd',
      interval: PriceInterval.MONTH,
      denominator: '',
      cta: 'free cta',
      features: [],
    },
  ],
};

const usePricingTiers = ({ enabled = false, offerId, appendFreeTier = false }: Props) => {
  const [publicationId] = useCurrentPublicationState();

  return useQuery<any>(
    ['tiers', 'dream_editor', publicationId, offerId, appendFreeTier],
    () =>
      api
        .get<any>(`/dream_editor/tiers`, {
          params: {
            publication_id: publicationId,
            premium_offer_id: offerId,
          },
        })
        .then((res) => {
          if (appendFreeTier) {
            return {
              tiers: [
                FREE_TIER,
                ...res.data.tiers,
              ]
            };
          }

          return res.data
        }),
    {
      enabled: !!publicationId && enabled,
    }
  );
};

export default usePricingTiers;
