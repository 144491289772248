import React, { useCallback, useEffect, useMemo, useState } from "react";
import { SerializableNode } from '@shared/dream-components'
import { Editor } from "@tiptap/react";

import { DreamEditorContext } from "@/context/dream-editor-context";
import { useWebsiteContext } from "@/context/website-context";
import { useBeforeNavigate } from '@/hooks/useBeforeNavigate';
import { useUpdateSiteVersion } from "@/hooks/useSiteVersion";
import useCreateSiteVersion from "@/hooks/useSiteVersion/useCreateSiteVersion";


export const FooterEditorProvider = ({ children }: { children: React.ReactNode }) => {
  const { site, previewSiteVersion } = useWebsiteContext();
  const [initialContent, setInitialContent] = useState<SerializableNode | null>(null);
  const [changesMade, setChangesMade] = useState(false);
  const [editor, setEditor] = useState<Editor | null>(null);

  useEffect(() => {
    // page id changed
    if (site?.draft_site_version) {
      setInitialContent(site.draft_site_version.footer as SerializableNode || null);
    }
    return () => {
      editor?.commands.blur();
      setInitialContent(null);
    };
  }, [site?.draft_site_version, editor]);

  const {
    mutate: updateSiteVersion,
    mutateAsync: updateSiteVersionAsync,
    isLoading: isSaveLoading,
  } = useUpdateSiteVersion({
    id: site?.draft_site_version?.id || '',
  });

  const { isLoading: isSaveVersionLoading, mutateAsync: createSiteVersionAsync } = useCreateSiteVersion({});

  const save = useCallback(() => {
    if (previewSiteVersion) return; // don't save if previewing other versions
    updateSiteVersion({ footer: editor?.getJSON() as SerializableNode });
    setChangesMade(false);
  }, [updateSiteVersion, previewSiteVersion, editor]);

  const onSaveToVersionHistory = useCallback(
    async (autoSave: boolean = false, versionName?: string): Promise<void> => {
      if (previewSiteVersion) return; // don't save if previewing other versions

      await updateSiteVersionAsync({ footer: editor?.getJSON() as SerializableNode });
      setChangesMade(false);
      await createSiteVersionAsync({
        version_name: versionName,
        version_type: autoSave ? 'autosave' : 'usersave',
      });
    },
    [updateSiteVersionAsync, createSiteVersionAsync, editor, previewSiteVersion]
  );

  // Auto save every 10 seconds
  useEffect(() => {
    const autoSaveInterval = setInterval(() => {
      if (changesMade) {
        save();
      }
    }, 10000); // 10 seconds

    return () => {
      clearInterval(autoSaveInterval);
    };
  }, [changesMade, save]);

  // Auto save Version History every 10 minutes
  useEffect(() => {
    const autoSaveInterval = setInterval(() => {
      onSaveToVersionHistory(true);
    }, 10 * 60000); // 10 minutes

    return () => {
      clearInterval(autoSaveInterval);
    };
  }, [changesMade, onSaveToVersionHistory]);

  // Save on navigate away & prompt on reload or leaving page
  useBeforeNavigate(save, !!changesMade);

  const value = useMemo(
    () => ({
      initialContent,
      setEditor,
      changesMade,
      setChangesMade,
      save,
      isSaveLoading,
      onSaveToVersionHistory,
      previewContent: previewSiteVersion?.footer as SerializableNode,
      isSaveVersionLoading,
      editor,
    }),
    [
      initialContent,
      setEditor,
      changesMade,
      save,
      isSaveLoading,
      onSaveToVersionHistory,
      isSaveVersionLoading,
      previewSiteVersion?.footer,
      editor,
    ]
  );

  return <DreamEditorContext.Provider value={value}>{children}</DreamEditorContext.Provider>;
};


export const useFooterDataContext = () => {
  const context = React.useContext(DreamEditorContext);
  if (context === undefined) {
    throw new Error('useFooterDataContext must be used within a FooterDataProvider');
  }
  return context;
};
