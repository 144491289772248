import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import api from '@/services/swarm';

import type { CreateEntriModalRecordsSuccessResponse } from './types';

const useCreateEntriModalRecords = (publicationId: string) => {
  const queryClient = useQueryClient();

  return useMutation<CreateEntriModalRecordsSuccessResponse, Error>(
    async () => {
      const response = await api.post('/custom_domains/entri_modal_records', { publication_id: publicationId });
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['entri_modal_records', publicationId]);
      },
      onError: () => {
        toast.error('Failed to create Entri DNS records. Please try again.');
      },
    }
  );
};

export default useCreateEntriModalRecords;
