import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { PencilSquareIcon } from '@heroicons/react/20/solid';

import { Accordion } from '@/components/Accordion';
import ActionModal from '@/components/ActionModal';
import Badge from '@/components/Badge';
import { Color } from '@/components/DesignTokens';
import DmarcWarningBanner from '@/components/DmarcWarningBanner';
import EllipsisDropdown from '@/components/EllipsisDropdown';
import { Input } from '@/components/Form';
import { Typography } from '@/components/Typography';
import useSmartWarmingProgress from '@/hooks/useSmartWarmingProgress';
import { useUpdateSubdomain } from '@/hooks/useSubdomain';
import { SubdomainPayload } from '@/hooks/useSubdomain/useUpdateSubdomain';
import { CustomDomain, CustomDomainTypes } from '@/interfaces/custom_domain';
import { EllipsisDropdownOption } from '@/interfaces/general';
import type { SmartWarmingProgressData } from '@/interfaces/smart_warming_progress';
import SmartWarmingFaqModal from '@/pages/Settings/Pages/Publication/Domains/SmartWarmingFaqModal';
import { Button } from '@/ui/Button';
import analytics from '@/utils/analytics';

import useVerificationModals from '../hooks/useVerificationModals';
import { isDomainVerified } from '../utils';

import { SECONDARY_EMAIL_HELPER_TEXT } from './constants';
import DisconnectDomainForm from './DisconnectDomainForm';
import SmartWarmingChart from './SmartWarmingChart';
import StatusBadge, { SmartWarmingBadge } from './StatusBadge';

interface Props {
  subdomain: string;
  customDomain: CustomDomain;
  onSharedWeb: boolean;
  disabled: boolean;
  publicationId: string;
}

const EmailCustom: React.FC<Props> = ({ subdomain, customDomain, onSharedWeb, publicationId, disabled }) => {
  const {
    id,
    domain,
    email_username: emailUsername,
    entri_verification_pending: entriVerificationPending,
    warming_up: warmingUp,
  } = customDomain;
  const isVerified = isDomainVerified(customDomain, CustomDomainTypes.EMAIL);

  const { handleOpenDnsRecordsModal } = useVerificationModals();
  const smartWarmingProgressQuery = useSmartWarmingProgress(publicationId, id, warmingUp);

  const [newEmailUsername, setNewEmailUsername] = useState<string>(emailUsername || subdomain || 'hi');
  const [newSubdomain, setNewSubdomain] = useState<string>(subdomain);
  const [editUsernameModalOpen, setEditUsernameModalOpen] = useState(false);
  const [editSubdomainModalOpen, setEditSubdomainModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [smartWarmingOpen, setSmartWarmingOpen] = useState<boolean>(false);
  const [smartWarmingFaqModalOpen, setSmartWarmingFaqModalOpen] = useState<boolean>(false);

  const { data: smartWarmingProgress, isLoading: smartWarmingProgressIsLoading } = smartWarmingProgressQuery;

  const { mutateAsync: updateSubdomain, isLoading: updateSubdomainIsLoading } = useUpdateSubdomain();

  const type = CustomDomainTypes.EMAIL;

  const dropdownOptions = useMemo(() => {
    const options: EllipsisDropdownOption[] = [];

    // TODO: [BEE-7083] Allow viewing Entri records when verified
    if (!entriVerificationPending) {
      options.push({
        label: isVerified ? 'View DNS Records' : 'Verify',
        onClick: () => handleOpenDnsRecordsModal(type),
      });
    }

    return [
      ...options,
      {
        label: 'Disconnect Domain',
        onClick: () => setDeleteModalOpen(true),
        isDestructive: true,
      },
    ];
  }, [entriVerificationPending, isVerified, handleOpenDnsRecordsModal, type]);

  const handleSaveUsername = async (data: SubdomainPayload) => {
    try {
      await updateSubdomain({
        ...data,
        custom_email_domain_id: id,
      });

      analytics.track('Configured email domain');

      toast.success('Saved!');
      setEditUsernameModalOpen(false);
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  useEffect(() => {
    setNewEmailUsername(emailUsername || subdomain || 'hi');
    setNewSubdomain(subdomain);
  }, [emailUsername, subdomain]);

  const formattedSmartWarmingProgress = useMemo(() => {
    if (!smartWarmingProgress?.data || !smartWarmingProgress?.labels) return undefined;

    return {
      labels: smartWarmingProgress.labels,
      data: smartWarmingProgress.data.map((item) => ({
        ...item,
        name: new Intl.DateTimeFormat(navigator.language || 'en-US', {
          month: 'short',
          day: 'numeric',
        }).format(new Date(item.name)),
      })),
    } satisfies SmartWarmingProgressData;
  }, [smartWarmingProgress]);

  return (
    <>
      <ActionModal
        resourceId={emailUsername}
        isOpen={editUsernameModalOpen}
        onClose={() => setEditUsernameModalOpen(false)}
        isWorking={updateSubdomainIsLoading}
        onProceed={() => handleSaveUsername({ email_username: newEmailUsername })}
        headerText="Update Email Username"
      >
        <div className="my-4 space-y-4">
          <Input
            name="subdomain"
            labelText="New Email Username"
            trailingText={`@${customDomain.domain}`}
            value={newEmailUsername}
            className="flex-grow"
            onChange={(e) => setNewEmailUsername(e.target.value)}
          />
        </div>
      </ActionModal>

      <ActionModal
        resourceId={subdomain}
        isOpen={editSubdomainModalOpen}
        onClose={() => setEditSubdomainModalOpen(false)}
        isWorking={updateSubdomainIsLoading}
        onProceed={() => handleSaveUsername({ subdomain: newSubdomain })}
        headerText="Update Secondary Email Username"
      >
        <div className="my-4 space-y-4">
          <Input
            name="subdomain"
            labelText="New Secondary Email Username"
            trailingText="@mail.beehiiv.com"
            helperText={SECONDARY_EMAIL_HELPER_TEXT}
            value={newSubdomain}
            className="flex-grow"
            onChange={(e) => setNewSubdomain(e.target.value)}
          />

          {onSharedWeb && (
            <Typography token="font-normal/text/xs" color={Color.DANGER} as="div" className="mt-4">
              <b>NOTE:</b> Changing this will also update your web URL.
            </Typography>
          )}
        </div>
      </ActionModal>

      <DisconnectDomainForm
        customDomain={customDomain}
        type={type}
        isOpen={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
      />

      <SmartWarmingFaqModal isOpen={smartWarmingFaqModalOpen} onClose={() => setSmartWarmingFaqModalOpen(false)} />

      <div className="p-4 bg-gray-50 border border-surface-200 rounded-lg space-y-4">
        <DmarcWarningBanner publicationId={publicationId} domain={domain} variant="compact" />
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-start space-x-2">
            <Badge size="sm">Email</Badge>
            <StatusBadge domain={customDomain} type={type} />
          </div>
          {!disabled && <EllipsisDropdown options={dropdownOptions} />}
        </div>

        <div className="flex items-end space-x-4">
          <Input
            name="username"
            labelText="Email Username"
            trailingText={`@${domain}`}
            value={emailUsername}
            className="flex-grow"
            readOnly
          />
          <Button
            variant="primary-inverse"
            onClick={() => setEditUsernameModalOpen(true)}
            Icon={PencilSquareIcon}
            className="h-[38] flex-shrink"
            disabled={disabled}
          >
            Edit
          </Button>
        </div>

        {warmingUp && (
          <div className="flex items-end space-x-4">
            <Input
              name="subdomain"
              labelText="Secondary Email Username"
              trailingText="@mail.beehiiv.com"
              value={subdomain}
              className="flex-grow"
              readOnly
            />
            <Button
              variant="primary-inverse"
              onClick={() => setEditSubdomainModalOpen(true)}
              Icon={PencilSquareIcon}
              className="h-[38] flex-shrink"
              disabled={disabled}
            >
              Edit
            </Button>
          </div>
        )}

        <div>
          <Input name="domain" labelText="Domain" value={domain} className="flex-grow" readOnly />
        </div>

        {warmingUp && (
          <div className="bg-white rounded-lg p-4 border border-surface-200">
            <Accordion
              defaultOpen={smartWarmingOpen}
              onClick={() => setSmartWarmingOpen(!smartWarmingOpen)}
              titleChildren={<SmartWarmingBadge />}
            >
              <div className="pt-4 space-y-4">
                <div>
                  <Typography token="font-medium/text/base" colorWeight="900" as="h3" className="mb-2">
                    Smart Warming
                  </Typography>

                  <Typography token="font-normal/text/sm" colorWeight="500" as="p">
                    We are gradually increasing the sending volume on <strong>{domain}</strong> to optimize
                    deliverability for you. This happens automatically in the background, so nothing will be impacted
                    for you. Depending on your publication&apos;s list size, this process typically takes 2-4 weeks to
                    complete.
                  </Typography>

                  <Typography
                    token="font-medium/text/sm"
                    colorWeight="600"
                    color="secondary"
                    className="underline cursor-pointer"
                  >
                    <button type="button" onClick={() => setSmartWarmingFaqModalOpen(true)}>
                      See FAQs about Smart Warming
                    </button>
                  </Typography>
                </div>

                <SmartWarmingChart data={formattedSmartWarmingProgress} isLoading={smartWarmingProgressIsLoading} />
              </div>
            </Accordion>
          </div>
        )}
      </div>
    </>
  );
};

export default EmailCustom;
