import { useMemo } from 'react';
import { File, Plus } from '@phosphor-icons/react';
import cx from 'classnames';

import { useWebsiteContext } from '@/context/website-context';
import { useCreatePage } from '@/hooks/usePages';
import { TemplateCategory } from '@/interfaces/dream_builder/site';

import { Text } from '../UI/Text';
import { Tooltip } from '../UI/Tooltip';

const AddableDefaultPages = () => {
  const { site, defaultRoutes } = useWebsiteContext();

  const createPage = useCreatePage({
    routesType: 'default',
  });

  const createPageMutation = (parentPath: string[], slug: string, name: string) => {
    createPage.mutate({ parentPath, slug, name });
  };

  const pageCategories = site?.template_categories?.page;

  const renderablePageCategories = useMemo(() => {
    return pageCategories?.filter((category) => !defaultRoutes?.children_keys?.includes(category?.slug || ''));
  }, [pageCategories, defaultRoutes]);

  return (
    <div className="flex flex-col">
      {renderablePageCategories?.map((category: TemplateCategory) => {
        return (
          <div
            key={category.value}
            className={cx(
              'px-1.5 py-2 flex rounded-lg flex-row w-full items-center justify-between gap-2 group bg-white hover:bg-wb-secondary pl-[30px] opacity-30'
            )}
          >
            <File weight="bold" size={16} className="my-auto text-wb-secondary" />
            <Text as="p" size="2xs" weight="medium" className="truncate">
              {category.label}
            </Text>

            <Tooltip center="Create Default Page" className="flex-1 flex items-center cursor-pointer">
              <button
                type="button"
                className="hover:bg-wb-highlight rounded-full p-0.5"
                onClick={() => createPageMutation([], category?.slug || '', category?.label || 'Untitled')}
              >
                <Plus weight="bold" className="my-auto text-wb-secondary h-4 w-4" />
              </button>
            </Tooltip>
          </div>
        );
      })}
    </div>
  );
};

export default AddableDefaultPages;
