import { useCallback, useEffect, useMemo, useState } from 'react';
import { autoPlacement, offset, useClick, useDismiss, useFloating, useInteractions } from '@floating-ui/react';
import { CaretRight, Check, TextT, X } from '@phosphor-icons/react';
import { Editor } from '@tiptap/core';

import { getFontFamilies } from '../../../../../_utils/getFonts';
import { Search } from '../../../../UI/Search';
import { Text } from '../../../../UI/Text';
import { ToggleGroup, ToggleGroupItem } from '../../../../UI/ToggleGroup';
import { useActiveNode } from '../../../extensions/ActiveNode/hooks/useActiveNode';

const DEFAULT_FONT_FAMILY = 'Inter';

export const FontSettings = ({ editor }: { editor: Editor }) => {
  const { activeNodeType, activeNodeAttributes } = useActiveNode(editor);
  const [fonts, setFonts] = useState<string[]>([]);
  const [query, setQuery] = useState('');
  const [group, setGroup] = useState<'all' | 'in-page'>('all');

  const inPageFonts = editor.storage.fontFamily.usedFonts;

  useEffect(() => {
    getFontFamilies().then((w) => setFonts(w));
  }, []);

  const filteredFonts = useMemo(() => {
    if (!query) return fonts;
    return fonts.filter((font) => font.toLowerCase().includes(query.toLowerCase()));
  }, [fonts, query]);

  const currentFont = editor.getAttributes('textStyle').fontFamily;

  const handleSetFontFamily = useCallback(
    (fontFamily: string) => {
      editor.chain().selectTextBlock().setFontFamily(fontFamily).focus().run();
    },
    [editor]
  );

  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'left-start',
    middleware: [autoPlacement(), offset(20)],
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss]);

  return (
    <>
      <div ref={refs.setReference} className="flex items-center justify-stretch gap-2">
        <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
          Font
        </Text>
        <div className="grow bg-wb-secondary rounded-lg shadow-sm cursor-pointer" {...getReferenceProps()}>
          <div className="w-full justify-between flex items-center gap-2 p-2">
            <div className="flex items-center gap-1">
              <TextT className="text-wb-secondary" weight="bold" />
              <Text size="2xs" weight="medium">
                {currentFont || DEFAULT_FONT_FAMILY}
              </Text>
            </div>
            <CaretRight className="text-wb-secondary" weight="bold" />
          </div>
        </div>
      </div>
      {isOpen && (
        <div
          ref={refs.setFloating}
          className="bg-wb-primary w-[255px] max-h-[400px] min-h-0 p-3 rounded-lg shadow-xl flex flex-col gap-2"
          style={floatingStyles}
          {...getFloatingProps()}
        >
          <div className="flex items-center justify-between gap-2">
            <Text size="sm" weight="semibold">
              Fonts
            </Text>

            <div
              className="p-1 text-wb-secondary bg-wb-secondary rounded-full"
              onClick={() => setIsOpen(false)}
              role="button"
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  setIsOpen(false);
                }
              }}
            >
              <X className="cursor-pointer" />
            </div>
          </div>
          <div className="grow bg-wb-secondary rounded-lg shadow-sm mb-3">
            <ToggleGroup
              className="p-[2px] grid grid-cols-2"
              type="single"
              defaultValue="left"
              value={group}
              onValueChange={(s) => setGroup(s as 'all' | 'in-page')}
            >
              <ToggleGroupItem value="all">All</ToggleGroupItem>
              <ToggleGroupItem value="in-page">In this page</ToggleGroupItem>
            </ToggleGroup>
          </div>

          {group === 'all' ? (
            <>
              <Search placeholder="Search font..." value={query} onChange={(e) => setQuery(e.target.value)} />

              <div className="grow min-h-0 overflow-y-scroll flex flex-col gap-2">
                {filteredFonts.map((font) => (
                  <div
                    key={font}
                    className={`flex items-center px-3 py-2 rounded-lg cursor-pointer border hover:bg-wb-secondary ${
                      font === currentFont
                        ? 'bg-wb-button-accent-soft border-wb-accent-soft'
                        : 'bg-wb-primary border-transparent'
                    }`}
                    role="button"
                    tabIndex={0}
                    onKeyDown={(e) => {
                      if (!activeNodeType || !activeNodeAttributes) return;
                      if (e.key === 'Enter' || e.key === ' ') {
                        handleSetFontFamily(font);
                      }
                    }}
                    onClick={() => {
                      if (!activeNodeType || !activeNodeAttributes) return;
                      handleSetFontFamily(font);
                    }}
                  >
                    {font}
                  </div>
                ))}
              </div>
            </>
          ) : (
            <div className="grow min-h-0 overflow-y-scroll flex flex-col gap-2">
              {Object.entries(inPageFonts as Record<string, any>).map(([key]) => (
                <div
                  key={key}
                  className={`flex items-center justify-between px-3 py-2 rounded-lg cursor-pointer border hover:bg-wb-secondary ${
                    key === currentFont
                      ? 'bg-wb-button-accent-soft border-wb-accent-soft'
                      : 'bg-wb-primary border-transparent'
                  }`}
                  role="button"
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (!activeNodeType || !activeNodeAttributes) return;
                    if (e.key === 'Enter' || e.key === ' ') {
                      handleSetFontFamily(key);
                    }
                  }}
                  onClick={() => {
                    if (!activeNodeType || !activeNodeAttributes) return;
                    handleSetFontFamily(key);
                  }}
                >
                  {key}
                  {key === currentFont && <Check weight="bold" className="text-wb-accent" />}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};
