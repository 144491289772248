import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import Frame, { useFrame } from 'react-frame-component';
import { useNavigate, useParams } from 'react-router-dom';
import { ClockCounterClockwise, Plus } from '@phosphor-icons/react';
import { getUsedFonts, getUsedFontsFromFamilies } from '@shared/dream-components';
import isEqual from 'lodash.isequal';
import { StyleSheetManager } from 'styled-components';

import { DreamEditorProvider, useDreamEditorContext } from '@/context/dream-editor-context';
import { useWebsiteContext, useWebsitePageRouteGetter, WebsiteProvider } from '@/context/website-context';

import { AttributesPanelNavbarFooterContextProvider } from '../_components/DreamEditor/AttributesPanel/navbarFooterContextProvider';
import { Layout } from '../_components/Layout';
import { ActionMenu } from '../_components/Layout/ActionMenu';
import AttributesPanelPortal from '../_components/Layout/AttributesPanelPortal';
import InsertPanelPortal from '../_components/Layout/InsertPanelPortal';
import SidePanelTabs from '../_components/Layout/SidePanelTabs';
import VersionHistoryPortal from '../_components/Layout/VersionHistoryPortal';
import { Button } from '../_components/UI/Button';
import VersionHistoryNotice from '../_components/VersionHistoryNotice';
import { FooterPreview } from '../footer/_components/FooterEditor';
import { NavbarPreview } from '../navbar/_components/NavbarEditor/NavbarPreview';

import { Editor } from './_components/Editor';

interface IframeWrapperProps {
  children: ReactNode;
  width: number;
  height: number;
  iframeProps?: React.IframeHTMLAttributes<HTMLIFrameElement>;
}

const StyleManager = ({ children }: { children: React.ReactNode }) => {
  const { document } = useFrame();
  return (
    <StyleSheetManager target={document?.head}>
      <>{children}</>
    </StyleSheetManager>
  );
};

const IFRAME_CONTENT_WIDTH = 1024; // px

const IframeWrapper = React.forwardRef<HTMLIFrameElement, IframeWrapperProps>(
  ({ children, width, height, iframeProps }, ref) => {
    const { site, previewSiteVersion } = useWebsiteContext();
    const { editor, previewContent } = useDreamEditorContext();

    const navbarContent = previewSiteVersion ? previewSiteVersion?.navbar : site?.draft_site_version?.navbar;
    const footerContent = previewSiteVersion ? previewSiteVersion?.footer : site?.draft_site_version?.footer;
    const [initialContent, setInitialContent] = useState('');
    const [fontHrefs, setFontHrefs] = useState<Set<string>>(new Set());
    const [fetchedEditorFonts, setfetchedEditorFonts] = useState<Set<string>>(new Set());

    useEffect(() => {
      fetch('/canvas/index._html')
        .then((d) => d.text())
        .then(setInitialContent);
    }, []);

    const inPageFonts = editor?.storage?.fontFamily?.usedFonts;

    const inPageFontsKeys = inPageFonts ? Object.keys(inPageFonts) : undefined;

    const getNavbarFontLinks = useCallback(async () => {
      if (!navbarContent) return;
      const renderableFonts = await getUsedFonts(navbarContent);
      setFontHrefs((fonts) => new Set(Array.from(fonts).concat(renderableFonts)));
    }, [navbarContent]);

    const getFooterFontLinks = useCallback(async () => {
      if (!footerContent) return;
      const renderableFonts = await getUsedFonts(footerContent);
      setFontHrefs((fonts) => new Set(Array.from(fonts).concat(renderableFonts)));
    }, [footerContent]);

    const getContentFontLinks = useCallback(async () => {
      if (!previewContent) return;
      const renderableFonts = await getUsedFonts(previewContent);
      setFontHrefs((fonts) => new Set(Array.from(fonts).concat(renderableFonts)));
    }, [previewContent]);

    useEffect(() => {
      getNavbarFontLinks();
    }, [navbarContent, getNavbarFontLinks]);

    useEffect(() => {
      getFooterFontLinks();
    }, [footerContent, getFooterFontLinks]);

    useEffect(() => {
      if (!previewContent) return;
      getContentFontLinks();
    }, [previewContent, getContentFontLinks]);

    useEffect(() => {
      if (!inPageFontsKeys) return;
      if (previewSiteVersion) return;

      const getInPageFontLinks = async (families: string[] | undefined) => {
        if (!families) return;
        const renderableFonts = await getUsedFontsFromFamilies(families);
        setfetchedEditorFonts(new Set(families));
        setFontHrefs((fonts) => new Set(Array.from(fonts).concat(renderableFonts)));
      };

      if (!isEqual(Array.from(fetchedEditorFonts), inPageFontsKeys)) {
        getInPageFontLinks(inPageFontsKeys);
      }
    }, [inPageFontsKeys, previewSiteVersion, fetchedEditorFonts]);

    const links = Array.from(new Set(fontHrefs)).map((href) => ({ rel: 'stylesheet', href }));

    if (!initialContent) return <div>loading..</div>;
    const scale = width / IFRAME_CONTENT_WIDTH;

    return (
      <Frame
        ref={ref}
        initialContent={initialContent}
        {...iframeProps}
        head={
          <>
            {links.map((link) => (
              <link key={link.href} rel={link.rel} href={link.href} />
            ))}
          </>
        }
        title="Page Editor Content"
        mountTarget="body"
        style={{
          transform: `scale(${scale})`,
          transformOrigin: 'top left',
          width: IFRAME_CONTENT_WIDTH,
          height: height / scale,
          border: 'none',
          outline: 'none',
          ...iframeProps?.style,
        }}
      >
        <StyleManager>{children}</StyleManager>
      </Frame>
    );
  }
);

const PageEditorPage = () => {
  const { pagesRoutes, defaultRoutes } = useWebsiteContext();
  const navigate = useNavigate();
  const [isInsertPanelOpen, setIsInsertPanelOpen] = useState(false);
  const { previewSiteVersion, setPreviewSiteVersion } = useWebsiteContext();
  const [isVersionHistoryPanelOpen, setIsVersionHistoryPanelOpen] = useState(false);
  const { pageId } = useParams();
  const pageRouteGetter = useWebsitePageRouteGetter();
  const pageRoute = pageId ? pageRouteGetter?.getPageRouteFromID(pageId) : undefined;
  const homePageId = pageRouteGetter?.getHomePageID();

  const iframeRef = useRef<HTMLIFrameElement>(null);
  const portalRef = useRef<HTMLDivElement>(null);
  const editorContainerRef = useRef<HTMLDivElement>(null);
  const [editorRect, setEditorRect] = useState<Partial<DOMRect>>({ height: 0, width: 0 });

  useEffect(() => {
    if (!isVersionHistoryPanelOpen) {
      setPreviewSiteVersion(undefined);
    }
  }, [isVersionHistoryPanelOpen, setPreviewSiteVersion]);

  useEffect(() => {
    if (pageId && homePageId && !pageRoute) {
      navigate(`/website_builder_v2/page/${homePageId}`);
    }
  }, [pageId, navigate, homePageId, pageRoute]);

  useEffect(() => {
    const editorContainer = editorContainerRef.current;
    const resizeObserver = new ResizeObserver((entries) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const entry of entries) {
        if (entry.contentRect) {
          setEditorRect(entry.contentRect);
        }
      }
    });

    if (editorContainer) {
      // setup listener for element size change
      resizeObserver.observe(editorContainer);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <Layout
      logoDropdownProps={{
        actionText: 'Go To Dashboard',
        backPath: '/',
      }}
      mainClassName="bg-wb-secondary"
      isSidebarResizable
      leftChildren={
        <ActionMenu
          Icon={Plus}
          text="Insert"
          isActive={isInsertPanelOpen}
          onClick={() => {
            setIsInsertPanelOpen((prev) => !prev);
          }}
        />
      }
      rightChildren={
        <Button
          variant="secondary"
          Icon={ClockCounterClockwise}
          iconWeight="bold"
          onClick={() => setIsVersionHistoryPanelOpen((prev) => !prev)}
        />
      }
      sidenavChildren={
        <>
          <SidePanelTabs
            pagesRoutes={pagesRoutes}
            defaultRoutes={defaultRoutes}
            previewSiteVersion={previewSiteVersion}
          />
          <InsertPanelPortal isInsertPanelOpen={isInsertPanelOpen} />
        </>
      }
      rightSideChildren={
        <>
          <AttributesPanelPortal />
          <VersionHistoryPortal
            isVersionHistoryPanelOpen={isVersionHistoryPanelOpen}
            setIsVersionHistoryPanelOpen={setIsVersionHistoryPanelOpen}
          />
        </>
      }
      titleType="page_name"
    >
      <div ref={editorContainerRef} className="w-full h-full shadow-md bg-wb-primary">
        <IframeWrapper width={editorRect.width || 0} height={editorRect.height || 0} ref={iframeRef}>
          <NavbarPreview portalRef={portalRef} iframeRef={iframeRef} />
          <Editor key={pageId} />
          <FooterPreview iframeRef={iframeRef} />
          <div ref={portalRef} id="portal-ref" className="z-50" />
        </IframeWrapper>
      </div>
      {previewSiteVersion?.id && <VersionHistoryNotice />}
    </Layout>
  );
};

export default () => (
  <WebsiteProvider>
    <DreamEditorProvider>
      <AttributesPanelNavbarFooterContextProvider>
        <PageEditorPage />
      </AttributesPanelNavbarFooterContextProvider>
    </DreamEditorProvider>
  </WebsiteProvider>
);
