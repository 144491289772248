import { NavbarSerializableNode } from '@shared/dream-components';

import { Text } from '@/routes/website/_components/UI/Text';
import { ToggleGroup, ToggleGroupItem } from '@/routes/website/_components/UI/ToggleGroup';

import { navbarListContent } from '../../../defaultContent';
import { useNavbarContext } from '../../../NavbarContext';
import { getParent } from '../../../utils';

export const RowNumberSettings = () => {
  const {
    content,
    selectedContent: rawSelectedContent,
    onUpdateNodeAttributes,
    onUpdateNodeContent,
  } = useNavbarContext();

  const selectedContent =
    rawSelectedContent?.type === 'navbar_menu_list' && content
      ? getParent(content, rawSelectedContent)
      : rawSelectedContent;

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== 'navbar_menu' && selectedContent.type !== 'navbar_menu_list') return null;

  const contentValue =
    selectedContent?.attrs && 'rows' in selectedContent.attrs
      ? selectedContent.attrs.rows
      : selectedContent?.content?.length || 1;

  const handleRowNumberChange = (value: string) => {
    if (!selectedContent.attrs?.id) return;
    onUpdateNodeAttributes(selectedContent.attrs?.id, { rows: parseInt(value, 10) });

    if (selectedContent.content?.length && selectedContent.content?.length < parseInt(value, 10)) {
      const childContent = selectedContent.content as NavbarSerializableNode[];
      const newContent = childContent.concat(
        Array.from({ length: parseInt(value, 10) - childContent.length }).map(() => navbarListContent())
      );
      onUpdateNodeContent(selectedContent.attrs?.id, newContent);
    }
  };

  const value = contentValue?.toString() || '1';

  return (
    <div className="flex items-center justify-stretch gap-2">
      <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
        Rows
      </Text>
      <div className="grow bg-wb-secondary rounded-lg shadow-sm">
        <ToggleGroup
          className="p-[2px]"
          type="single"
          defaultValue="1"
          value={value}
          onValueChange={handleRowNumberChange}
        >
          <ToggleGroupItem value="1" className="grow">
            <Text size="2xs" weight="semibold" variant={value === '1' ? 'accent' : 'secondary'}>
              1
            </Text>
          </ToggleGroupItem>
          <ToggleGroupItem value="2" className="grow">
            <Text size="2xs" weight="semibold" variant={value === '2' ? 'accent' : 'secondary'}>
              2
            </Text>
          </ToggleGroupItem>
        </ToggleGroup>
      </div>
    </div>
  );
};
