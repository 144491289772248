import { Text } from '@/routes/website/_components/UI/Text';
import { ToggleGroup, ToggleGroupItem } from '@/routes/website/_components/UI/ToggleGroup';

import { useNavbarContext } from '../../../NavbarContext';

export const ShowSettings = () => {
  const { selectedContent, onUpdateNodeAttributes } = useNavbarContext();

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== 'navbar_item') return null;

  const contentValue =
    selectedContent?.attrs && 'showOnMobile' in selectedContent.attrs ? selectedContent.attrs.showOnMobile : false;

  const handleShowOnMobileChange = (value: string) => {
    if (!selectedContent.attrs?.id) return;
    onUpdateNodeAttributes(selectedContent.attrs?.id, { showOnMobile: value === 'true' });
  };

  const value = contentValue?.toString() || 'true';

  return (
    <div className="flex items-center justify-stretch gap-2">
      <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
        Visibility
      </Text>
      <div className="grow bg-wb-secondary rounded-lg shadow-sm">
        <ToggleGroup
          className="p-[2px]"
          type="single"
          defaultValue="true"
          value={value}
          onValueChange={handleShowOnMobileChange}
        >
          <ToggleGroupItem value="true" className="grow">
            <Text size="2xs" weight="semibold" variant={value === 'true' ? 'accent' : 'secondary'}>
              Show
            </Text>
          </ToggleGroupItem>
          <ToggleGroupItem value="false" className="grow">
            <Text size="2xs" weight="semibold" variant={value === 'false' ? 'accent' : 'secondary'}>
              Hide
            </Text>
          </ToggleGroupItem>
        </ToggleGroup>
      </div>
    </div>
  );
};
