import { FC, useEffect } from 'react';
import { PricingElement, TPricingAttributes } from '@shared/dream-components';
import { NodeViewProps, useReactNodeView } from '@tiptap/react';

import usePricingTiers from '@/hooks/useDreamBuilder/usePricingTiers';

export const PricingView: FC<NodeViewProps> = ({ node, editor, getPos }) => {
  const { onDragStart } = useReactNodeView();
  const { hasFakeData, showFreeTier, offerId } = node.attrs;

  const { data } = usePricingTiers({ enabled: true, offerId, appendFreeTier: showFreeTier });
  const tiers = data?.tiers;
  const tiersLength = tiers?.length || 0;
  const hasTiers = tiersLength > 0;

  // TODO: This is only showing up after clicking on the page but the data is being set correctly.
  useEffect(() => {
    if (hasTiers) {
      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(getPos(), 'hasFakeData', false);
        tr.setNodeAttribute(getPos(), 'data', tiers);
        return true;
      });
    }
  }, [hasTiers, tiers, editor, getPos, hasFakeData, offerId, showFreeTier]);

  return (
    <PricingElement
      element={{
        type: 'pricing',
        attrs: {
          id: node.attrs.id,
          ...node.attrs,
        } as TPricingAttributes,
      }}
      attributes={{
        'data-node-view-wrapper': '',
      }}
      onDragStart={onDragStart}
      tiers={tiers || []}
      setSelectedTier={() => {}}
      setSelectedPrice={() => {}}
      isStripeLoading={false}
      email=""
      onEmailChange={() => {}}
      showTaxIdField={false}
      taxId=""
      isSubmitting={false}
      onTierClick={() => {}}
      onPriceClick={() => {}}
      stripeSessionIds={null}
      onTaxIdChange={() => {}}
      isInternalCheckout={node.attrs.internalCheckout}
      showContinueButton={false}
      isDonationAmountFieldRequired={false}
      donationAmountCents={0}
      onDonationAmountCentsChange={() => {}}
    >
      {null}
    </PricingElement>
  );
};
