/* eslint-disable */

import { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import {
  ArrayParam,
  BooleanParam,
  createEnumParam,
  DateParam,
  StringParam,
  useQueryParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';

import Card from '@/components/Card';
import { SelectOptions } from '@/components/SelectAllCheckbox';
import { SelectingState } from '@/components/SelectCheckbox/SelectCheckbox';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useCreateDraft, usePosts, usePostsSelection, useUpdateWebTemplate, useWebTemplate } from '@/hooks';
import { useAuthors } from '@/hooks/useAuthors';
import useOptions from '@/hooks/useOptions';
import { Author } from '@/interfaces/author';
import { BetweenDateEnum, DateFields, DateFilterTypes, PostDateField } from '@/interfaces/dates';
import { Order } from '@/interfaces/general';
import {
  PostAdvancedSearchForm,
  PostBulkActions,
  PostFormQueryParams,
  PostPlatform,
  PostPreview,
  PostStatus,
  ShowingPosts,
} from '@/interfaces/post';
import PostSearch from '@/models/postSearch';
import api from '@/services/swarm';
import pluralize from '@/utils/pluralize';

import { MessageTypes } from './List/Header';
import List, { ListHeader } from './List/index';
import DeleteModal from './DeleteModal';
import Header from './Header';
import LoadPostsButton from './LoadPostsButton';
import NoResults from './NoResults';
import Search from './Search';
import DeletePostModal from './DeletePostModal';

import { Skeleton, SkeletonLoader } from '@/components/SkeletonLoader';
import SaveAsTemplateModal from '../Post/Edit/v2/Compose/SaveAsTemplateModal';
import useResourcePermissions from '@/hooks/useResourcePermissions/useResourcePermissions';
import { NO_PERMISSION_MESSAGE } from '@/interfaces/permissions';
import { usePermissions } from '@/context/permissions-context';

const FEATURED_POST_LIMIT = 6;

export const Posts: React.FunctionComponent = () => {
  const [currentPublicationId] = useCurrentPublicationState();

  const navigate = useNavigate();

  const [search, setSearch] = useQueryParam('search', StringParam);
  const [statusFilter, setStatusFilter] = useQueryParam('status', StringParam);
  const [shouldResetSearch, setShouldResetSearch] = useState(false);
  const [selectedPosts, setSelectedPosts] = useState<string[]>([]);
  const [currentSelectOptions, setCurrentSelectOptions] = useState<SelectOptions[]>([]);
  const [selectionLoading, setSelectionLoading] = useState(false);
  const [openBulkModal, setOpenBulkModal] = useState<PostBulkActions>(PostBulkActions.NONE);
  const [confirmedDelete, setConfirmedDelete] = useState(false);
  const [deleteConfirmationValue, setDeleteConfirmationValue] = useState('');
  const [reRunSearch, setReRunsearch] = useState(false);
  const [messageType, setMessageType] = useState<MessageTypes>(MessageTypes.NONE);
  const [deletePostModalIsOpen, setDeletePostModalIsOpen] = useState<boolean>(false);
  const [selectedPostId, setSelectedPostId] = useState<string>('');
  const [saveAsTemplateModalOpen, setSaveAsTemplateModalOpen] = useState<boolean>(false);

  const OrderParam = withDefault(createEnumParam(Object.values(Order)), Order.DESC);
  const [orderDir, setOrderDir] = useQueryParam('order', OrderParam);
  const { checkPermissions } = usePermissions();
  const canViewAnalytics = checkPermissions('views/posts/performance', 'read');

  const DefaultArrayParam = withDefault(ArrayParam, []);

  const defaultDate = (change = -1, date = new Date()) => {
    date.setMonth(date.getMonth() + change);

    return date;
  };

  const [formQueryParams, setFormQueryParams] = useQueryParams<PostFormQueryParams>({
    advancedEnabled: withDefault(BooleanParam, false),
    datesEnabled: withDefault(BooleanParam, false),
    audience: DefaultArrayParam,
    authorIds: DefaultArrayParam,
    platforms: DefaultArrayParam,
    contentTags: DefaultArrayParam,
    dateField: withDefault(createEnumParam<DateFields>(Object.values(PostDateField)), PostDateField.SEND_COMPLETED_AT),
    dateFilterType: withDefault(createEnumParam(Object.values(DateFilterTypes)), DateFilterTypes.BETWEEN),
    startDate: withDefault(DateParam, defaultDate()),
    endDate: withDefault(DateParam, defaultDate(0)),
    beforeDate: withDefault(DateParam, defaultDate(0)),
    afterDate: withDefault(DateParam, defaultDate()),
  });

  const filteredAudience = useMemo(
    () => formQueryParams.audience.filter((value): value is string => value !== null),
    [formQueryParams.audience]
  );

  const filteredPlatforms = useMemo(
    () => formQueryParams.platforms.filter((value): value is string => value !== null),
    [formQueryParams.platforms]
  );

  const filteredContentTags = useMemo(
    () => formQueryParams.contentTags.filter((value): value is string => value !== null),
    [formQueryParams.contentTags]
  );

  const filteredAuthorIds = useMemo(
    () => formQueryParams.authorIds.filter((value): value is string => value !== null),
    [formQueryParams.authorIds]
  );

  const query = new PostSearch({
    afterDate: formQueryParams.afterDate,
    audience: filteredAudience,
    authorIds: filteredAuthorIds,
    beforeDate: formQueryParams.beforeDate,
    contentTags: filteredContentTags,
    dateField: formQueryParams.dateField,
    endDate: formQueryParams.endDate,
    options: {
      advanced: formQueryParams.advancedEnabled || false,
      datesEnabled: formQueryParams.datesEnabled || false,
      dateFilterType: formQueryParams.dateFilterType,
    },
    order: orderDir || Order.DESC,
    platform: filteredPlatforms,
    search: search || '',
    startDate: formQueryParams.startDate,
    status: statusFilter || '',
  });

  const postsQuery = usePosts(query, reRunSearch);

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, isFetching } = postsQuery;

  const posts = data?.pages.flatMap((page) => page.posts) || [];
  const pagination = data?.pages[0]?.pagination;
  const totalPostCount = pagination?.total || 0;
  const showingPosts = posts.map<ShowingPosts>((post) => ({
    id: post.id,
    audience: post.audience,
    platform: post.platform,
  }));

  const webTemplateMutation = useUpdateWebTemplate();
  const webTemplateRequest = useWebTemplate();
  const webTemplate = webTemplateRequest?.data;
  const featuredPosts = webTemplate?.feature_post_ids;
  const featuredPostsIds = featuredPosts?.map((post: PostPreview) => post.id);

  const contentTagsQuery = useOptions(currentPublicationId, 'content_tags');
  const contentTags = contentTagsQuery?.data?.options.map((option: any) => [option.id, option.display]);

  const authorsData: Author[] | undefined = useAuthors().data;
  const authors = authorsData?.map((author) => [author.id, author.name]) || [];

  const selectCheckOptions = {
    currentSelectOptions,
    posts,
    selectedPosts,
    setSelectedPosts,
    totalPostCount,
  };

  // After triggering search reset, switch boolean back to false
  useEffect(() => {
    if (shouldResetSearch) {
      setShouldResetSearch(false);
    }
  }, [shouldResetSearch, setShouldResetSearch]);

  useEffect(() => {
    if (isFetching) {
      handleSelectionChange(currentSelectOptions);
      setReRunsearch(false);
    }
  }, [isFetching]);

  // Handle post filtering
  const handlePostFilter = (val: string) => {
    if (val === 'all') {
      setStatusFilter(undefined);
    } else {
      setStatusFilter(val.toLowerCase());
    }
  };

  // Handle order dir change
  const handleOrderClick = () => {
    const newOrderDir = orderDir === Order.ASC ? Order.DESC : Order.ASC;

    setOrderDir(newOrderDir);
  };

  // Handle advanced search options
  const handleAdvancedSearch = (form: PostAdvancedSearchForm) => {
    if (!form.showAdvanced) {
      setFormQueryParams({
        ...formQueryParams,
        advancedEnabled: false,
      });

      return;
    }

    const dateParams = {
      afterDate: form.dates[DateFilterTypes.AFTER],
      beforeDate: form.dates[DateFilterTypes.BEFORE],
      dateField: form.dates.dateField,
      dateFilterType: form.dates.dateFilterType,
      endDate: form.dates[DateFilterTypes.BETWEEN][BetweenDateEnum.END],
      startDate: form.dates[DateFilterTypes.BETWEEN][BetweenDateEnum.START],
    };

    const newParams = {
      advancedEnabled: true,
      audience: Object.values(form.bools.audience),
      authorIds: Object.values(form.bools.authorIds),
      contentTags: Object.values(form.bools.contentTags),
      datesEnabled: form.dates.datesEnabled,
      platforms: Object.values(form.bools.platforms),
      ...(form.dates.datesEnabled && dateParams),
    };

    setFormQueryParams({ ...newParams });
  };

  // Reset search when no results
  const handleResetSearch = () => {
    setSearch(undefined);
    setShouldResetSearch(true);
  };

  // Reset search when no results
  const handleResetFilter = () => {
    setStatusFilter(undefined);
  };

  const handlePostSelect = (postId: string) => (checked: boolean) => {
    let newSelectedPosts = null;

    if (checked) {
      newSelectedPosts = [...selectedPosts, postId];
    } else {
      newSelectedPosts = [...selectedPosts.filter((id) => id !== postId)];
    }

    setSelectedPosts(newSelectedPosts);

    const newMessageType = newSelectedPosts.length > 0 ? MessageTypes.SELECTING : MessageTypes.NONE;
    setMessageType(newMessageType);

    if (currentSelectOptions.includes(SelectOptions.ALL)) {
      setCurrentSelectOptions([]);
    }
  };

  const createDraftMutation = useCreateDraft();

  const postRoute = (p: PostPreview) =>
    p.status === PostStatus.PUBLISHED && canViewAnalytics ? `/posts/${p.id}/analytics` : `/posts/${p.id}`;

  const postOptions = useCallback(
    (post: PostPreview) => {
      const { data: recordPermissions, isSuccess: recordPermissionSuccess } = useResourcePermissions({
        resourceType: 'Post',
        resourceId: post.id,
        policyClass: 'Api::V2::PostPolicy',
      });
      const canUpdate = recordPermissionSuccess && recordPermissions.includes('update');

      const basicOptions = [
        {
          label: 'View',
          onClick: () => {
            navigate(postRoute(post));
          },
        },
        {
          label: 'Edit',
          onClick: () => {
            navigate(`/posts/${post.id}/edit`);
          },
          disabled: !canUpdate,
          disabledTooltip: !canUpdate ? NO_PERMISSION_MESSAGE : undefined,
        },
        {
          label: 'Duplicate post',
          onClick: () => {
            api
              .post(`/posts/${post.id}/duplicates`)
              .then(() => {
                toast.success('Post duplicated');
                postsQuery.refetch();
              })
              .catch(() => {
                toast.error('Could not duplicate post');
              });
          },
        },
        {
          label: 'Save post as template',
          onClick: () => {
            setSelectedPostId(post.id);
            setSaveAsTemplateModalOpen(true);
          },
        },
      ];

      const { checkPermissions } = usePermissions();
      const canArchive = checkPermissions('views/posts/archive', 'update');
      const canFeature = checkPermissions('views/posts/feature', 'update');
      const canDelete = recordPermissionSuccess && recordPermissions.includes('delete');
      const isFeaturedPost = featuredPostsIds?.includes(post.id);
      const isDisabled = !isFeaturedPost && featuredPosts?.length >= FEATURED_POST_LIMIT;
      const featureText = isDisabled ? `Feature Limit Reached (${FEATURED_POST_LIMIT})` : 'Feature';
      const featuredOptions =
        post.status === PostStatus.PUBLISHED &&
        (post.platform === PostPlatform.WEB || post.platform === PostPlatform.BOTH)
          ? [
              {
                disabled: isDisabled || !canFeature,
                disabledTooltip: !canFeature ? NO_PERMISSION_MESSAGE : undefined,
                label: isFeaturedPost ? 'Unfeature' : featureText,
                onClick: async () => {
                  if (isFeaturedPost) {
                    const newFeaturedPosts = featuredPostsIds?.filter((id: any) => id !== post.id);
                    await webTemplateMutation.mutate({
                      feature_post_ids: newFeaturedPosts,
                    });
                  } else {
                    await webTemplateMutation.mutate({
                      feature_post_ids: [...featuredPostsIds, post.id],
                    });
                  }
                },
              },
            ]
          : [];

      const publishedOptions =
        post.status === PostStatus.PUBLISHED &&
        (post.platform === PostPlatform.WEB || post.platform === PostPlatform.BOTH)
          ? [
              {
                label: 'View published',
                onClick: () => {
                  window.open(post.url, '_blank');
                },
              },
            ]
          : [];

      const deleteOption = post.deletable
        ? {
            label: 'Delete',
            color: 'red',
            onClick: () => {
              setSelectedPostId(post.id);
              setDeletePostModalIsOpen(true);
            },
            disabled: !canDelete,
            disabledTooltip: !canDelete ? NO_PERMISSION_MESSAGE : undefined,
          }
        : null;

      const archiveOption = post.archivable
        ? {
            label: 'Archive',
            color: 'red',
            onClick: () => {
              api
                .patch(`/posts/${post.id}/transition`, { status: 'archived' })
                .then(() => {
                  toast.success('Post archived');
                  postsQuery.refetch();
                })
                .catch(() => {
                  toast.error('Could not archive post');
                });
            },
            disabled: !canArchive,
            disabledTooltip: !canArchive ? NO_PERMISSION_MESSAGE : undefined,
          }
        : null;

      const unarchiveOption =
        post.status === PostStatus.ARCHIVED
          ? {
              label: 'Unarchive',
              onClick: () => {
                api
                  .patch(`/posts/${post.id}/transition`, { status: 'confirmed' })
                  .then(() => {
                    toast.success('Post unarchived');
                    postsQuery.refetch();
                  })
                  .catch(() => {
                    toast.error('Could not unarchive post');
                  });
              },
              disabled: !canArchive,
              disabledTooltip: !canArchive ? NO_PERMISSION_MESSAGE : undefined,
            }
          : null;

      const destructiveOptions = [deleteOption, archiveOption, unarchiveOption].filter((option) => option) as Array<{
        label: string;
        onClick: () => void;
      }>;

      return [basicOptions, featuredOptions, publishedOptions, destructiveOptions];
    },
    [navigate, postsQuery]
  );

  const filterShowingPosts = (showingPosts: ShowingPosts[], newSelectOptions: SelectOptions[]) =>
    showingPosts.filter((post) => {
      return (
        newSelectOptions.includes(SelectOptions.ALL) ||
        (newSelectOptions.includes(SelectOptions.FREE) && post.audience === 'free') ||
        (newSelectOptions.includes(SelectOptions.PREMIUM) && post.audience === 'premium') ||
        (newSelectOptions.includes(SelectOptions.BOTH_AUDIENCE) && post.audience === 'both') ||
        (newSelectOptions.includes(SelectOptions.EMAIL) && post.platform === 'email') ||
        (newSelectOptions.includes(SelectOptions.WEB) && post.platform === 'web') ||
        (newSelectOptions.includes(SelectOptions.BOTH_PLATFORM) && post.platform === 'both')
      );
    });

  const handleSelectionChange = async (newSelectOptions: SelectOptions[]) => {
    setSelectionLoading(true);
    setCurrentSelectOptions(newSelectOptions);

    const newMessageType = newSelectOptions.length > 0 ? MessageTypes.SELECTING : MessageTypes.NONE;
    setMessageType(newMessageType);

    const tempSelectedPosts = filterShowingPosts(showingPosts, newSelectOptions).map((post) => post.id);
    setSelectedPosts(tempSelectedPosts);

    const props = {
      selectOptions: newSelectOptions,
      currentPublicationId,
      query,
    };

    usePostsSelection(props)
      .then((posts) => posts.map((post) => post.id))
      .then((ids) => {
        setSelectedPosts(ids);
        setSelectionLoading(false);
      });
  };

  const handleBulkChange = (type: PostBulkActions) => {
    setOpenBulkModal(type);
  };

  const handleBulkDeleteModalClose = () => {
    setOpenBulkModal(PostBulkActions.NONE);
    setConfirmedDelete(false);
    setDeleteConfirmationValue('');
  };

  const handleBulkDeleteModalProceed = () => {
    const params = { data: { ids: selectedPosts } };

    api
      .delete(`/posts/bulk_delete?publication_id=${currentPublicationId}`, params)
      .then(() => {
        setSelectedPosts([]);
        setCurrentSelectOptions([]);
        setReRunsearch(true);
        setConfirmedDelete(false);
        setDeleteConfirmationValue('');
        setMessageType(MessageTypes.DELETING);
      })
      .catch((errPayload) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      })
      .finally(() => setOpenBulkModal(PostBulkActions.NONE));
  };

  const handleLoadPostButtonClick = () => {
    fetchNextPage()
      .then((resp) => resp.data?.pages.flatMap((page) => page.posts.map((post) => post.id)))
      .then((postIds) => {
        if (showingPosts.length > 0 && selectedPosts.length > 0) {
          const newIds = postIds?.filter((id) => selectedPosts.includes(id)) || [];

          setSelectedPosts(Array.from(new Set([...selectedPosts, ...newIds])));
        }
      });
  };

  let selecting = SelectingState.NONE;

  if (currentSelectOptions.includes(SelectOptions.ALL)) {
    selecting = SelectingState.ALL;
  } else if (selectedPosts.length > 0) {
    selecting = SelectingState.SOME;
  }

  return (
    <>
      <DeletePostModal
        isOpen={deletePostModalIsOpen}
        setIsOpen={setDeletePostModalIsOpen}
        postId={selectedPostId}
        postsQuery={postsQuery}
      />

      <SaveAsTemplateModal
        isOpen={saveAsTemplateModalOpen}
        onClose={() => setSaveAsTemplateModalOpen(false)}
        postId={selectedPostId}
      />

      <DeleteModal
        disabled={!confirmedDelete}
        postIds={selectedPosts}
        isOpen={openBulkModal === PostBulkActions.DELETE}
        deleteConfirmationValue={deleteConfirmationValue}
        setDeleteConfirmationValue={setDeleteConfirmationValue}
        setConfirmedDelete={setConfirmedDelete}
        handleOnProceed={handleBulkDeleteModalProceed}
        handleOnClose={handleBulkDeleteModalClose}
        headerText={`Delete ${pluralize('Post', selectedPosts.length)}?`}
      />

      <Header createDraftMutation={createDraftMutation} />

      <Search
        advancedSearchOptions={formQueryParams}
        authors={authors}
        contentTags={contentTags}
        search={search}
        setSearch={setSearch}
        shouldResetSearch={shouldResetSearch}
        statusFilter={statusFilter}
        handleAdvancedSearch={handleAdvancedSearch}
        handlePostFilter={handlePostFilter}
        handleResetSearch={handleResetSearch}
      />
      <SkeletonLoader
        isLoading={isLoading}
        skeletons={
          <div>
            <div className="mb-6">
              <Skeleton className="h-8 rounded" />
            </div>
            <div className="mb-1">
              <Skeleton className="h-5 rounded" />
            </div>
            <div>
              <Skeleton className="h-5 rounded" />
            </div>
          </div>
        }
      >
        <ListHeader
          currentSelectOptions={currentSelectOptions}
          handleDeleteClick={handleBulkChange}
          handleOrderClick={handleOrderClick}
          handleSelectionChange={handleSelectionChange}
          isLoading={isLoading || selectionLoading}
          messageType={messageType}
          orderDir={orderDir}
          selectCheckOptions={selectCheckOptions}
          selectedPostCount={selectedPosts.length}
          selectedPosts={selectedPosts}
          selecting={selecting}
          showingPosts={showingPosts.map((post) => post.id)}
          totalPostCount={totalPostCount}
        />

        <Card noPadding>
          {data && posts.length === 0 ? (
            <NoResults
              isLoading={isLoading}
              search={search as string}
              hasStatusFilter={!!statusFilter}
              handleResetFilter={handleResetFilter}
              handleResetSearch={handleResetSearch}
              createDraftMutation={createDraftMutation}
            />
          ) : (
            <List
              posts={posts}
              featuredPostsIds={featuredPostsIds}
              postRoute={postRoute}
              postOptions={postOptions}
              selectedPosts={selectedPosts}
              handlePostSelect={handlePostSelect}
            />
          )}

          {!!hasNextPage && (
            <LoadPostsButton
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
              fetchNextPage={handleLoadPostButtonClick}
            />
          )}
        </Card>
      </SkeletonLoader>
    </>
  );
};
