import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import { InformationCircleIcon } from '@heroicons/react/20/solid';

import { Button } from '@/ui/Button';
import { Dropdown } from '@/ui/Dropdown';

import Badge from '../../../../../../components/Badge';
import Text from '../../../../../../components/Text';
import { useCurrentPublicationState } from '../../../../../../context/current-publication-context';
import { useSettings } from '../../../../../../context/settings-context';
import { useCustomDomainToggleUsage, useVerifiedCustomDomains } from '../../../../../../hooks/useCustomDomains';
import { usePublication } from '../../../../../../hooks/usePublications';
import { CustomDomain, CustomDomainTypes } from '../../../../../../interfaces/custom_domain';
import SmartWarmingFaqModal from '../SmartWarmingFaqModal';

const CustomDomainEmailConfig = () => {
  const navigate = useNavigate();
  const [currentPublicationId] = useCurrentPublicationState();
  const { has_waiting_posts_with_split_tests: hasWaitingPostsWithSplitTests } =
    usePublication(currentPublicationId)?.data || {};
  const { data: customDomains } = useVerifiedCustomDomains();
  const { settings } = useSettings();
  const smartWarmingEnabled = settings?.smart_warming;
  const displaySplitTestWarning = smartWarmingEnabled && hasWaitingPostsWithSplitTests;
  const [isSmartWarmingFaqModalOpen, setIsSmartWarmingFaqModalOpen] = useState(false);

  const initializeSelectedDomain = () => customDomains?.find((d: CustomDomain) => d.email_enabled)?.id || '';
  const [selectedDomain, setSelectedDomain] = useState(initializeSelectedDomain());

  useEffect(() => {
    if (!selectedDomain) {
      initializeSelectedDomain();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customDomains]);

  const onUpdateSuccess = () => {
    navigate(`/settings/publication/domains?configuration=${CustomDomainTypes.EMAIL}`);
  };

  const toggleUsageMutation = useCustomDomainToggleUsage(onUpdateSuccess);

  const handleSubmit = () => {
    if (selectedDomain) {
      toggleUsageMutation.mutate({ id: selectedDomain, type: CustomDomainTypes.EMAIL, enabled: true });
    } else {
      toast.error('Please select a domain');
    }
  };

  const smartWarmingHtml = (
    <div>
      <Text type="subtitle">We&apos;ll take care of warming up your Custom Domain</Text>
      <div className="space-y-3">
        <Text size="sm" className="text-gray-500">
          To optimize deliverability, sending volume on your custom domain will be gradually increased through a process
          called Smart Warming. This happens automatically in the background, so nothing will be impacted for you.
        </Text>
        {displaySplitTestWarning && (
          <Text size="sm">
            <b className="text-gray-800 font-semibold">A note on A/B testing:</b>{' '}
            <span className="text-gray-500">
              We don&apos;t currently support A/B testing of subject lines while Smart Warming is active. A/B tests for
              your draft Posts will be removed.
            </span>
          </Text>
        )}
        <Text size="sm">
          <button
            type="button"
            onClick={() => setIsSmartWarmingFaqModalOpen(true)}
            className="text-primary-700 font-medium hover:text-primary-800 flex"
          >
            <InformationCircleIcon className="w-4 h-4 self-center mr-1" />
            Frequently Asked Questions about Smart Warming
          </button>
        </Text>
      </div>
    </div>
  );

  const warningHtml = (
    <div>
      <Text type="bold" size="md" className="flex items-center mb-2">
        <span className="mr-2">Before you configure a custom domain</span>
        <Badge type="alert">
          <b className="uppercase">Warning</b>
        </Badge>
      </Text>

      <dl className="space-y-5 px-4 py-2 border-l-4 border-gray-100">
        <div>
          <dt>
            <Text type="subtitle" size="sm" className="text-gray-900">
              Starting a new newsletter?
            </Text>
          </dt>
          <dd className="mt-1">
            <Text type="body" size="sm" className="text-gray-500">
              Read our blog post on{' '}
              <a
                href="https://blog.beehiiv.com/p/how-to-warm-up-your-email-domain"
                className="text-red-900 hover:text-red-800 underline"
                target="_blank"
                rel="noreferrer"
              >
                warming up your email domain
              </a>{' '}
              to learn more about using a custom domain for your emails and about deliverability.
            </Text>
          </dd>
        </div>

        <div>
          <dt>
            <Text type="subtitle" size="sm" className="text-gray-900">
              Migrating an existing newsletter that used this domain?
            </Text>
          </dt>
          <dd className="mt-1">
            <Text type="body" size="sm" className="text-gray-500">
              Changing the email platform behind a sending domain can have an impact on your deliverability. We&apos;re
              here to help you out and minimize the risk. Before making this change{' '}
              <Link reloadDocument to="/?new_support_ticket" className="text-red-900 hover:text-red-800 underline">
                contact our support
              </Link>
              .
            </Text>
          </dd>
        </div>

        <div>
          <dt>
            <Text type="subtitle" size="sm" className="text-gray-900">
              Have you been sending using our shared beehiiv.com domain?
            </Text>
          </dt>
          <dd className="mt-1">
            <Text type="body" size="sm" className="text-gray-500">
              Stop right there! Making this change could potentially hurt your deliverability.{' '}
              <Link reloadDocument to="/?new_support_ticket" className="text-red-900 hover:text-red-800 underline">
                Contact our support
              </Link>{' '}
              first BEFORE making this change.
            </Text>
          </dd>
        </div>
      </dl>
    </div>
  );

  return (
    <>
      <SmartWarmingFaqModal isOpen={isSmartWarmingFaqModalOpen} onClose={() => setIsSmartWarmingFaqModalOpen(false)} />
      <div className="flex flex-col w-full space-y-8">
        <div>
          <h2 className="text-lg leading-6 font-medium text-gray-900 ml-0">Email Custom Domain</h2>
          <p className="mt-1 text-gray-500 text-sm">
            In order for email to be sent from your own domain, we must setup your domain to point to beehiiv. Select a
            verified domain below to start the process, then follow the instructions to get it set up with your DNS
            provider.
          </p>
        </div>

        {smartWarmingEnabled ? smartWarmingHtml : warningHtml}

        {customDomains && (
          <Dropdown
            labelText="Select a domain"
            name="email_domain"
            value={selectedDomain}
            options={customDomains.map((domain) => ({
              label: domain.domain,
              value: domain.id,
            }))}
            onSelect={(_label, value) => {
              setSelectedDomain(value);
            }}
          />
        )}
        <div className="flex flex-row justify-end space-x-2">
          <Button
            variant="primary-inverse"
            type="button"
            onClick={() => {
              navigate('/settings/publication/domains');
            }}
          >
            Back
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={() => {
              handleSubmit();
            }}
            loading={toggleUsageMutation.isLoading}
            disabled={toggleUsageMutation.isLoading}
          >
            Configure Domain
          </Button>
        </div>
      </div>
    </>
  );
};

export default CustomDomainEmailConfig;
