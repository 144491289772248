import { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { Editor } from '@tiptap/core';
import * as htmlToImage from 'html-to-image';
import { v4 as uuid } from 'uuid';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useCurrentUser } from '@/context/current-user-context';
import { useSite } from '@/hooks/useSite';
import { useCreateSiteTemplate } from '@/hooks/useSiteTemplates';
import { TemplateLevel } from '@/interfaces/site_template';

import { Input } from '../../UI/Input';
import InputWrapper from '../../UI/InputWrapper';
import Modal from '../../UI/Modal';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '../../UI/Select';
import { Switch } from '../../UI/Switch';
import { Textarea } from '../../UI/Textarea';
import { ActiveNodeResult } from '../extensions/ActiveNode/types';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  isEditing?: boolean;
  editor: Editor;
  activeNodeResult: ActiveNodeResult;
}

function getLevel(nodeJSON: any): TemplateLevel {
  if (nodeJSON.type === 'section') {
    return 'section';
  }

  if (nodeJSON.type === 'doc') {
    return 'page';
  }

  return 'block';
}

function dataURLToFile(dataUrl: string, fileName: string) {
  const arr = dataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)?.[1] ?? '';
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  // eslint-disable-next-line no-plusplus
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], fileName, { type: mime });
}

const TemplateModal = ({ isOpen, onClose, isEditing, editor, activeNodeResult }: Props) => {
  const { activeNode } = activeNodeResult;

  const { data: site } = useSite();
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();

  const { currentUser } = useCurrentUser();
  const [isMasquerading] = useState(!!localStorage.getItem('masqueradeToken'));
  const isSystemAdmin = Boolean(currentUser?.isSystemAdmin()) && !isMasquerading;

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [availableToPublic, setAvailableToPublic] = useState(false);

  const title = isEditing ? 'Edit Template' : 'Create Template';
  const ctaText = isEditing ? 'Save' : 'Create';
  const isLoadingText = isEditing ? 'Saving...' : 'Creating...';
  const categories =
    activeNode?.type?.name === 'section' ? site?.template_categories?.section : site?.template_categories?.block;

  const createSiteTemplate = useCreateSiteTemplate({
    onSuccess: () => {
      queryClient.invalidateQueries('site_templates');
      onClose();
    },
  });

  const handleCreateSiteTemplate = () => {
    const domNode = editor.view.nodeDOM(activeNodeResult.activeNodePos) as HTMLElement;
    if (!domNode) {
      return;
    }

    htmlToImage
      .toPng(domNode, {
        quality: 1,
        pixelRatio: 5,
        backgroundColor: '#ffffff',
      })
      .then((dataUrl) => {
        const fileName = `preview-${uuid()}.png`;
        const file = dataURLToFile(dataUrl, fileName);

        const content = activeNodeResult.activeNode?.toJSON();
        const formData = new FormData();
        formData.append('publication_id', currentPublicationId);
        formData.append('site_template[name]', name);
        formData.append('site_template[description]', description);
        formData.append('site_template[category]', category);
        formData.append('site_template[available_to_public]', availableToPublic.toString());
        formData.append('site_template[created_by_super_admin]', isSystemAdmin.toString());
        formData.append('site_template[content]', JSON.stringify(content));
        formData.append('site_template[level]', getLevel(content));
        formData.append('site_template[image_preview]', file);

        createSiteTemplate.mutate(formData);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      onConfirm={handleCreateSiteTemplate}
      ctaText={ctaText}
      ctaVariant="primary"
      isLoading={createSiteTemplate.isLoading}
      isLoadingText={isLoadingText}
    >
      <InputWrapper name="category" labelText="Category" className="w-full">
        <Select
          defaultValue=""
          onValueChange={(value: any) => {
            setCategory(value);
          }}
        >
          <SelectTrigger className="w-full" id="by_status">
            <SelectValue placeholder="Select a category" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              {categories?.map((cat) => (
                <SelectItem key={cat.value} value={cat.value}>
                  {cat.label}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
      </InputWrapper>
      <Input
        name="name"
        labelText="Name"
        placeholder="New Template"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <Textarea
        name="description"
        placeholder="A reusable section for landing pages..."
        value={description}
        labelText="Description"
        onChange={(e) => {
          setDescription(e.target.value);
        }}
      />
      {isSystemAdmin && (
        <Switch
          id="available_to_public"
          labelText="Available to public"
          checked={availableToPublic}
          onCheckedChange={(value: boolean) => {
            setAvailableToPublic(value);
          }}
        />
      )}
    </Modal>
  );
};

export default TemplateModal;
