import React, { Suspense } from 'react';
import { useLocation } from 'react-router-dom';

import { useAuth } from '@/context/auth-context';
import { useRoles } from '@/context/roles-context';
import { AssignedRole } from '@/interfaces/role';
import Loading from '@/pages/Loading';
import NoPermission from '@/pages/NoPermission';
import Unmasquerade from '@/pages/Unmasquerade';

interface RequireAuthProps {
  roles?: AssignedRole[] | [];
  children: JSX.Element;
  redirectTo?: string;
  fallback?: JSX.Element;
}

const RequireAuth: React.FC<RequireAuthProps> = ({ roles = [], children, redirectTo = '/', fallback }) => {
  const { roles: userRoles, isLoading } = useRoles();
  const { isMasquerading } = useAuth();
  const location = useLocation();

  // Check for masquerade and redirect to ErrorPage to handle routing
  if (isMasquerading && location.pathname.startsWith('/system_admin')) {
    return <Unmasquerade />;
  }

  // Check if any of the user's roles match the allowed roles
  // If no roles are provided, allow access to all users
  const hasAccess = roles.length === 0 || roles.some((role) => userRoles?.includes(role));

  // User is not authorized
  if (!hasAccess) {
    // Render the fallback component if provided
    if (fallback) {
      return fallback;
    }

    // Redirect to the provided path or the default path
    return <NoPermission redirectPath={redirectTo} />;
  }

  // If user has access, render the children (protected component)
  return <Suspense fallback={<Loading />}>{!isLoading ? children : null}</Suspense>;
};

export default RequireAuth;
