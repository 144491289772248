import {
  ArrowsOutLineVertical,
  CursorClick,
  Image as ImageIcon,
  ListBullets,
  ListNumbers,
  Quotes,
  Square,
  SquareSplitHorizontal,
  Tag,
  Textbox,
  TextHOne,
  TextHThree,
  TextHTwo,
  TextT,
  Users,
  XLogo} from '@phosphor-icons/react';

import { selectThreeRandomAuthors } from '../dummyData/authors';
import { selectThreeRandomPosts } from '../dummyData/posts';
import { tiers } from '../dummyData/pricing';
import { survey } from '../dummyData/survey';
import { DESTINATION_TAG, FOOD_AND_DRINK_TAG, HIKING_TAG, HISTORY_AND_CULTURE_TAG } from '../dummyData/tags';
import { TESTIMONIALS } from '../dummyData/testimonials';
import {
  Accordion,
  AccordionContent,
  AccordionTitle,
  Authors,
  BulletList,
  Button,
  Column,
  Columns,
  Container,
  Divider,
  Heading,
  Image,
  ListItem,
  OrderedList,
  Paragraph,
  Post,
  Pricing,
  Section,
  Signup,
  Socials,
  Survey,
  Tags,
  Testimonials,
} from '../extensions';

export const components = {
  paragraph: {
    label: 'Text',
    category: 'text',
    Icon: TextT,
    block: () => ({
      type: Paragraph.name,
      attr: {
        textAlign: 'center',
      },
      content: [
        {
          type: 'text',
          text: 'paragraph',
        },
      ],
    }),
  },
  heading1: {
    label: 'Head 1',
    category: 'text',
    Icon: TextHOne,
    block: () => ({
      type: Heading.name,
      attrs: {
        level: 1,
        textAlign: 'center',
      },
      content: [
        {
          type: 'text',
          text: 'Heading 1',
        },
      ],
    }),
  },
  heading2: {
    label: 'Head 2',
    category: 'text',
    Icon: TextHTwo,
    block: () => ({
      type: Heading.name,
      attrs: {
        level: 2,
        textAlign: 'center',
      },
      content: [
        {
          type: 'text',
          text: 'Heading 2',
        },
      ],
    }),
  },
  heading3: {
    label: 'Head 3',
    category: 'text',
    Icon: TextHThree,
    block: () => ({
      type: Heading.name,
      attrs: {
        level: 3,
        textAlign: 'center',
      },
      content: [
        {
          type: 'text',
          text: 'Heading 3',
        },
      ],
    }),
  },
  pricing: {
    label: 'Pricing Cards',
    category: 'widget',
    Icon: SquareSplitHorizontal,
    block: () => ({
      type: Pricing.name,
      attrs: {
        data: tiers,
      },
    }),
  },
  testimonials: {
    label: 'Testimonials',
    category: 'widget',
    Icon: Quotes,
    block: () => ({
      type: Testimonials.name,
      attrs: {
        data: TESTIMONIALS,
      },
    }),
  },
  section: {
    label: 'Section',
    category: 'layout',
    Icon: SquareSplitHorizontal,
    block: () => ({
      type: Section.name,
      attrs: {
        innerWidthMax: '1280px',
      },
    }),
  },
  container: {
    label: 'Container',
    category: 'layout',
    Icon: Square,
    block: () => ({
      type: Container.name,
      attrs: {},
    }),
  },
  columns: {
    label: 'Columns',
    category: 'layout',
    Icon: SquareSplitHorizontal,
    block: () => ({
      type: Columns.name,
      attrs: {
        width: '100%',
        padding: '20px',
      },
      content: [
        {
          type: Column.name,
          attrs: {
            width: '50%',
          },
          content: [
            {
              type: Container.name,
              attrs: {},
            },
          ],
        },
        {
          type: Column.name,
          attrs: {
            width: '50%',
          },
          content: [
            {
              type: Container.name,
              attrs: {},
            },
          ],
        },
      ],
    }),
  },
  signup: {
    label: 'Signup',
    category: 'widget',
    Icon: Textbox,
    block: () => ({
      type: Signup.name,
      attrs: {
        width: '400px',
      },
      content: [],
    }),
  },
  divider: {
    label: 'Divider',
    category: 'layout',
    Icon: ArrowsOutLineVertical,
    block: () => ({
      type: Divider.name,
      attrs: {},
      content: [],
    }),
  },
  numberedList: {
    label: 'Numbered List',
    category: 'lists',
    Icon: ListNumbers,
    block: () => ({
      type: OrderedList.name,
      attrs: {},
      content: [
        {
          type: ListItem.name,
          attrs: {},
          content: [
            {
              type: Paragraph.name,
              attrs: {},
              content: [
                {
                  type: 'text',
                  text: 'List Item 1',
                },
              ],
            },
          ],
        },
      ],
    }),
  },
  bulletedList: {
    label: 'Bullet List',
    category: 'lists',
    Icon: ListBullets,
    block: () => ({
      type: BulletList.name,
      attrs: {},
      content: [
        {
          type: ListItem.name,
          attrs: {},
          content: [
            {
              type: Paragraph.name,
              attrs: {},
              content: [
                {
                  type: 'text',
                  text: 'List Item 1',
                },
              ],
            },
          ],
        },
      ],
    }),
  },
  accordion: {
    label: 'Accordion',
    category: 'layout',
    Icon: Square,
    block: () => ({
      type: Accordion.name,
      attrs: {},
      content: [
        {
          type: AccordionTitle.name,
          attrs: {},
          content: [
            {
              type: Paragraph.name,
              content: [
                {
                  type: 'text',
                  text: 'Title',
                },
              ],
            },
          ],
        },
        {
          type: AccordionContent.name,
          attrs: {},
          content: [
            {
              type: Paragraph.name,
              content: [
                {
                  type: 'text',
                  text: 'Content',
                },
              ],
            },
          ],
        },
      ],
    }),
  },
  post: {
    label: 'Post',
    category: 'widget',
    Icon: ListBullets,
    block: () => ({
      type: Post.name,
      attrs: {
        width: 'full',
        cardCount: 3,
        gap: 8,
        orientation: 'horizontal',

        data: {
          posts: selectThreeRandomPosts({ count: 3 }),
        },
      },
      content: [],
    }),
  },
  survey: {
    label: 'Survey',
    Icon: ImageIcon,
    category: 'widget',
    block: () => ({
      type: Survey.name,
      attrs: {
        src: '',
        data: survey,
      },
      content: [],
    }),
  },
  tags: {
    label: 'Tags',
    Icon: Tag,
    category: 'widget',
    block: () => ({
      type: Tags.name,
      attrs: {
        src: '',
        data: [ DESTINATION_TAG, FOOD_AND_DRINK_TAG, HIKING_TAG, HISTORY_AND_CULTURE_TAG]
      },
      content: [],
    }),
  },
  media: {
    label: 'Image',
    Icon: ImageIcon,
    category: 'media',
    block: () => ({
      type: Image.name,
      attrs: {},
      content: [],
    }),
  },
  authors: {
    label: 'Authors',
    category: 'widget',
    Icon: Users,
    block: () => ({
      type: Authors.name,
      attrs: {
        data: {
          authors: selectThreeRandomAuthors({ count: 3 }),
        },
      },
      content: [],
    }),
  },
  socials_complete: {
    label: 'Socials',
    category: 'widget',
    Icon: XLogo,
    block: () => ({
      type: Socials.name,
      content: [],
    }),
  },
  button: {
    label: 'Button',
    category: 'widget',
    Icon: CursorClick,
    block: () => ({
      type: Button.name,
      content: [
        {
          type: 'text',
          text: 'Subscribe',
        },
      ],
    }),
  },
};
