import { useState } from 'react';
import { UseMutationResult } from 'react-query';
import moment from 'moment-mini';

import { RadioSelect, Switch } from '@/components/Form';
import CurrencyInput from '@/components/Form/CurrencyInput';
import { Typography } from '@/components/Typography';
import { useCurrentUser } from '@/context/current-user-context';
import { AdNetworkPublication } from '@/interfaces/ad_network/internal/publication';
import {
  AdNetworkPublicationProfile,
  AdNetworkReadiness,
  BadAppleReason,
  SalesTeamType,
} from '@/interfaces/ad_network/internal/publication_profile';
import { Dropdown } from '@/ui/Dropdown';

interface FormProps {
  onSubmitMutation: UseMutationResult<any, any, any, any>;
  publication: AdNetworkPublication;
  publicationProfile: AdNetworkPublicationProfile | null;
}

const Form = ({ onSubmitMutation, publication, publicationProfile }: FormProps) => {
  const { mutateAsync } = onSubmitMutation;
  const { currentUser } = useCurrentUser();

  const [acceptsAffiliatePricing, setAcceptsAffiliatePricing] = useState(
    publicationProfile?.accepts_pricing_model_affiliate ?? true
  );
  const [acceptsPricingModelCpc, setAcceptsPricingModelCpc] = useState(
    publicationProfile?.accepts_pricing_model_cpc ?? true
  );
  const [acceptsPricingModelCpm, setAcceptsCpmPricing] = useState(
    publicationProfile?.accepts_pricing_model_cpm ?? true
  );
  const [acceptsFlatRatePricing, setAcceptsFlatRatePricing] = useState(
    publicationProfile?.accepts_pricing_model_flat_rate ?? true
  );
  const [acceptsPrimaryAds, setAcceptsPrimaryAds] = useState(publicationProfile?.accepts_ad_type_primary ?? true);
  const [acceptsSecondaryAds, setAcceptsSecondaryAds] = useState(publicationProfile?.accepts_ad_type_secondary ?? true);
  const [acceptsTertiaryAds, setAcceptsTertiaryAds] = useState(publicationProfile?.accepts_ad_type_tertiary ?? true);
  const [acceptsDedicatedAds, setAcceptsDedicatedAds] = useState(publicationProfile?.accepts_ad_type_dedicated ?? true);
  const [acceptsPollAds, setAcceptsPollAds] = useState(publicationProfile?.accepts_ad_type_poll ?? true);
  const [adNetworkReadiness, setAdNetworkReadiness] = useState(
    publicationProfile?.ad_network_readiness ?? AdNetworkReadiness.READY
  );
  const [minimumSuccessMonthlyPayoutCents, setMinimumSuccessMonthlyPayoutCents] = useState(
    publicationProfile?.minimum_success_monthly_payout_cents ?? 0
  );
  const [minimumSendSuccessPayoutCents, setMinimumSendSuccessPayoutCents] = useState(
    publicationProfile?.minimum_success_send_payout_cents ?? 0
  );
  const [salesTeamType, setSalesTeamType] = useState(publicationProfile?.sales_team_type ?? SalesTeamType.NONE);
  const [usBased, setUsBased] = useState(publicationProfile?.us_based ?? true);
  const [badApple, setBadApple] = useState(publicationProfile?.bad_apple ?? false);
  const [badAppleReason, setBadAppleReason] = useState(
    publicationProfile?.bad_apple_reason ?? BadAppleReason.INFLATED_CLICKS
  );
  const [acceptsContentCannabis, setAcceptsContentCannabis] = useState(
    publicationProfile?.accepts_content_cannabis ?? true
  );
  const [tier, setTier] = useState(publication.tier?.name);
  const [tierLocked, setTierLocked] = useState(!!publication.tier?.locked_at);
  const [nextTierEval, setNextTierEval] = useState(publication.tier?.next_evaluated_at);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('publication_profile[accepts_pricing_model_affiliate]', acceptsAffiliatePricing.toString());
    formData.append('publication_profile[accepts_pricing_model_cpc]', acceptsPricingModelCpc.toString());
    formData.append('publication_profile[accepts_pricing_model_cpm]', acceptsPricingModelCpm.toString());
    formData.append('publication_profile[accepts_pricing_model_flat_rate]', acceptsFlatRatePricing.toString());
    formData.append('publication_profile[accepts_ad_type_primary]', acceptsPrimaryAds.toString());
    formData.append('publication_profile[accepts_ad_type_secondary]', acceptsSecondaryAds.toString());
    formData.append('publication_profile[accepts_ad_type_tertiary]', acceptsTertiaryAds.toString());
    formData.append('publication_profile[accepts_ad_type_dedicated]', acceptsDedicatedAds.toString());
    formData.append('publication_profile[accepts_ad_type_poll]', acceptsPollAds.toString());
    formData.append('publication_profile[accepts_content_cannabis]', acceptsContentCannabis.toString());
    formData.append('publication_profile[ad_network_readiness]', adNetworkReadiness);
    formData.append(
      'publication_profile[minimum_success_monthly_payout_cents]',
      String(minimumSuccessMonthlyPayoutCents)
    );
    formData.append('publication_profile[minimum_success_send_payout_cents]', String(minimumSendSuccessPayoutCents));
    formData.append('publication_profile[sales_team_type]', salesTeamType);
    formData.append('publication_profile[us_based]', String(usBased));
    formData.append('publication_profile[bad_apple]', String(badApple));

    if (badApple) {
      formData.append('publication_profile[bad_apple_reason]', badAppleReason);
    }

    if (tier) {
      formData.append('ad_network_publication_tier[update_tier]', String(tier));
    }

    formData.append('ad_network_publication_tier[next_evaluated_at]', tierLocked ? 'null' : String(nextTierEval));

    // Send if tier is being locked or unlocked
    if (tierLocked !== !!publication.tier?.locked_at) {
      formData.append('ad_network_publication_tier[locked_at]', tierLocked ? String(moment().toISOString()) : 'null');
      formData.append('ad_network_publication_tier[locked_by_user_id]', tierLocked ? String(currentUser?.id) : 'null');
    }

    mutateAsync(formData);
  };

  const badAppleReasons = [
    { label: 'Inflated Clicks', value: BadAppleReason.INFLATED_CLICKS },
    { label: 'Poor Delivery', value: BadAppleReason.POOR_DELIVERY },
    { label: 'Bad Content', value: BadAppleReason.BAD_CONTENT },
    { label: 'Disintermediation', value: BadAppleReason.DISINTERMEDIATION },
    { label: 'Gaming the System', value: BadAppleReason.GAMING_THE_SYSTEM },
    { label: 'Not Brand Safe', value: BadAppleReason.NOT_BRAND_SAFE },
  ];

  return (
    <div className="p-4">
      <div className="max-w-xl mx-auto w-full space-y-6">
        <form className="divide-y" onSubmit={onSubmit} id="publication-profile">
          <div className="space-y-6 py-6">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">Pricing Models</h2>
              <p className="text-sm text-gray-500">Select the types of pricing models this publication accepts.</p>
            </div>
            <div className="flex flex-col space-y-6">
              <Switch
                name="publication_profile[accepts_pricing_model_cpc]"
                checked={acceptsPricingModelCpc}
                onChange={(_name, value) => setAcceptsPricingModelCpc(value)}
                labelText="CPC"
                variant="primary"
              />
              <Switch
                name="publication_profile[accepts_pricing_model_cpm]"
                checked={acceptsPricingModelCpm}
                onChange={(_name, value) => setAcceptsCpmPricing(value)}
                labelText="CPM"
                variant="primary"
              />
              <Switch
                name="publication_profile[accepts_pricing_model_flat_rate]"
                checked={acceptsFlatRatePricing}
                onChange={(_name, value) => setAcceptsFlatRatePricing(value)}
                labelText="Flat Rate"
                variant="primary"
              />
              <Switch
                name="publication_profile[accepts_pricing_model_affiliate]"
                checked={acceptsAffiliatePricing}
                onChange={(_name, value) => setAcceptsAffiliatePricing(value)}
                labelText="Affiliate"
                variant="primary"
              />
            </div>
          </div>
          <div className="space-y-6 py-6">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">Ad Types</h2>
              <p className="text-sm text-gray-500">Select the types of ads this publication accepts.</p>
            </div>
            <div className="flex flex-col space-y-6">
              <Switch
                name="publication_profile[accepts_ad_type_primary]"
                checked={acceptsPrimaryAds}
                onChange={(_name, value) => setAcceptsPrimaryAds(value)}
                labelText="Primary"
                variant="primary"
              />
              <Switch
                name="publication_profile[accepts_ad_type_secondary]"
                checked={acceptsSecondaryAds}
                onChange={(_name, value) => setAcceptsSecondaryAds(value)}
                labelText="Secondary"
                variant="primary"
              />
              <Switch
                name="publication_profile[accepts_ad_type_tertiary]"
                checked={acceptsTertiaryAds}
                onChange={(_name, value) => setAcceptsTertiaryAds(value)}
                labelText="Tertiary"
                variant="primary"
              />
              <Switch
                name="publication_profile[accepts_ad_type_dedicated]"
                checked={acceptsDedicatedAds}
                onChange={(_name, value) => setAcceptsDedicatedAds(value)}
                labelText="Dedicated"
                variant="primary"
              />
              <Switch
                name="publication_profile[accepts_ad_type_poll]"
                checked={acceptsPollAds}
                onChange={(_name, value) => setAcceptsPollAds(value)}
                labelText="Poll"
                variant="primary"
              />
            </div>
          </div>
          <div className="space-y-6 py-6">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">Sensitive Content Types</h2>
              <p className="text-sm text-gray-500">
                Select the sensitive/controversial types of content this publication accepts.
              </p>
            </div>
            <div className="flex flex-col space-y-6">
              <Switch
                name="publication_profile[accepts_content_cannabis]"
                checked={acceptsContentCannabis}
                onChange={(_name, value) => setAcceptsContentCannabis(value)}
                labelText="Cannabis"
                variant="primary"
              />
            </div>
          </div>
          <div className="space-y-6 py-6">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">Sales Team</h2>
              <p className="text-sm text-gray-500">Select the types of sales teams this publication has.</p>
            </div>
            <div className="flex flex-col space-y-6">
              <RadioSelect
                value={salesTeamType}
                onSelect={(value) => setSalesTeamType(value as SalesTeamType)}
                options={[
                  { name: 'None', value: SalesTeamType.NONE },
                  { name: 'Self', value: SalesTeamType.SELF },
                  { name: 'Team', value: SalesTeamType.TEAM },
                ]}
              />
            </div>
          </div>
          <div className="space-y-6 py-6">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">Tier</h2>
              <p className="text-sm text-gray-500">Select the CPM Tier of this publication</p>
            </div>
            <div className="flex flex-col space-y-6">
              <Dropdown
                name="ad_network_publication_tier[update_tier]"
                value={tier}
                onSelect={(_, v) => setTier(v)}
                options={[
                  { label: 'One', value: 'tier_one' },
                  { label: 'Two', value: 'tier_two' },
                  { label: 'Three', value: 'tier_three' },
                  { label: 'Four', value: 'tier_four' },
                ]}
              />
              {!tierLocked && (
                <label htmlFor="next_evaluated_at">
                  <Typography token="font-medium/text/sm" color="surface" colorWeight="500">
                    Next Automatic Evaluation
                  </Typography>
                  <input
                    type="datetime-local"
                    name="ad_network_publication_tier[next_evaluated_at]"
                    className="rounded border-gray-200 text-gray-600 w-full mt-1"
                    value={nextTierEval ? moment(nextTierEval).format('YYYY-MM-DDTHH:mm') : undefined}
                    onChange={(e) => setNextTierEval(moment(e.target.value).toISOString())}
                  />
                </label>
              )}
              <Switch
                name="ad_network_publication_tier[locked_at]"
                checked={tierLocked}
                onChange={(_name, value) => setTierLocked(value)}
                labelText="Disable Automatic Evaluation"
                variant="primary"
              />
            </div>
          </div>
          <div className="space-y-6 py-6">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">Ad Network Readiness</h2>
              <p className="text-sm text-gray-500">Select the readiness of this publication.</p>
            </div>
            <div className="flex flex-col space-y-6">
              <RadioSelect
                value={adNetworkReadiness}
                onSelect={(value) => setAdNetworkReadiness(value as AdNetworkReadiness)}
                options={[
                  {
                    name: 'Ready',
                    value: AdNetworkReadiness.READY,
                    description: 'Open to running ads, have not run any yet.',
                  },
                  { name: 'Not Ready', value: AdNetworkReadiness.NOT_READY, description: 'Not open to running ads.' },
                  { name: 'Running', value: AdNetworkReadiness.RUNNING, description: 'Currently running ads.' },
                ]}
              />
            </div>
          </div>

          <div className="space-y-6 py-6">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">Minimum Success Payouts</h2>
              <p className="text-sm text-gray-500">
                What is the minimum amount this publication wants to consider the ad network a success?
              </p>
            </div>
            <div className="flex flex-col space-y-6">
              <CurrencyInput
                name="publication_profile[minimum_success_send_payout_cents]"
                onChange={(val) => setMinimumSendSuccessPayoutCents(val)}
                value={minimumSendSuccessPayoutCents}
                labelText="Minimum Send Success Payout"
              />
              <CurrencyInput
                name="publication_profile[minimum_success_monthly_payout_cents]"
                onChange={(val) => setMinimumSuccessMonthlyPayoutCents(val)}
                value={minimumSuccessMonthlyPayoutCents}
                labelText="Minimum Monthly Success Payout"
              />
            </div>
          </div>

          <div className="space-y-6 py-6">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">Location</h2>
              <p className="text-sm text-gray-500">Select the location of this publication.</p>
            </div>
            <div className="flex flex-col space-y-6">
              <Switch
                name="publication_profile[us_based]"
                checked={usBased}
                onChange={(_name, value) => setUsBased(value)}
                labelText="US Based"
                variant="primary"
              />
            </div>
          </div>

          <div className="space-y-6 py-6">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">Bad Apple</h2>
              <p className="text-sm text-gray-500">If this publication is a bad apple, please select the reason why.</p>
            </div>
            <div className="flex flex-col space-y-6">
              <Switch
                name="publication_profile[bad_apple]"
                checked={badApple}
                onChange={(_name, value) => setBadApple(value)}
                labelText="Yes"
                variant="primary"
              />

              {badApple && (
                <div className="space-y-2">
                  {badAppleReasons.map((reason) => (
                    <div className="flex text-sm items-center space-x-2 text-gray-700">
                      <input
                        key={reason.value}
                        type="radio"
                        name="publication_profile[bad_apple_reason]"
                        value={reason.value}
                        onChange={() => setBadAppleReason(reason.value)}
                        checked={badAppleReason === reason.value}
                        id={reason.value}
                        className="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-600"
                      />
                      <label htmlFor={reason.value} className="block text-sm font-medium leading-6 text-gray-900">
                        {reason.label}
                      </label>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Form;
