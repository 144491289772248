import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { DEFAULT_BORDER_COLOR, DEFAULT_BORDER_RADIUS, DEFAULT_PRIMARY_COLOR } from '../constants';

import { AuthorsView } from './views/AuthorsView';

export const Authors = Node.create<{}, {}>({
  name: 'authors',
  group: 'block',
  atom: true,
  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-id'),
        renderHTML: (attributes) => ({ 'data-id': attributes.id }),
      },
      gap: {
        default: '16px',
        parseHTML: (element) => element.getAttribute('data-gap'),
        renderHTML: (attributes) => ({ 'data-gap': attributes.gap }),
      },
      columns: {
        default: 3,
        parseHTML: (element) => element.getAttribute('data-columns'),
        renderHTML: (attributes) => ({ 'data-columns': attributes.columns }),
      },
      alignment: {
        default: 'left',
        parseHTML: (element) => element.getAttribute('data-alignment'),
        renderHTML: (attributes) => ({ 'data-alignment': attributes.alignment }),
      },
      borderRadius: {
        default: DEFAULT_BORDER_RADIUS,
        parseHTML: (element) => element.getAttribute('data-border-radius'),
        renderHTML: (attributes) => ({ 'data-border-radius': attributes.borderRadius }),
      },
      borderWidth: {
        default: '0px',
        parseHTML: (element) => element.getAttribute('data-border-width'),
        renderHTML: (attributes) => ({ 'data-border-width': attributes.borderWidth }),
      },
      borderColor: {
        default: DEFAULT_BORDER_COLOR,
        parseHTML: (element) => element.getAttribute('data-border-color'),
        renderHTML: (attributes) => ({ 'data-border-color': attributes.borderColor }),
      },
      borderStyle: {
        default: 'solid',
        parseHTML: (element) => element.getAttribute('data-border-style'),
        renderHTML: (attributes) => ({ 'data-border-style': attributes.borderStyle }),
      },
      cardBorderRadius: {
        default: DEFAULT_BORDER_RADIUS,
        parseHTML: (element) => element.getAttribute('data-card-border-radius'),
        renderHTML: (attributes) => ({ 'data-card-border-radius': attributes.cardBorderRadius }),
      },
      cardBorderWidth: {
        default: '1px',
        parseHTML: (element) => element.getAttribute('data-card-border-width'),
        renderHTML: (attributes) => ({ 'data-card-border-width': attributes.cardBorderWidth }),
      },
      cardBorderColor: {
        default: DEFAULT_BORDER_COLOR,
        parseHTML: (element) => element.getAttribute('data-card-border-color'),
        renderHTML: (attributes) => ({ 'data-card-border-color': attributes.cardBorderColor }),
      },
      cardBorderStyle: {
        default: 'solid',
        parseHTML: (element) => element.getAttribute('data-card-border-style'),
        renderHTML: (attributes) => ({ 'data-card-border-style': attributes.cardBorderStyle }),
      },
      cardBackgroundColor: {
        default: '#FFFFFFFF',
        parseHTML: (element) => element.getAttribute('data-card-background-color'),
        renderHTML: (attributes) => ({ 'data-card-background-color': attributes.cardBackgroundColor }),
      },
      cardPadding: {
        default: '16px',
        parseHTML: (element) => element.getAttribute('data-padding'),
        renderHTML: (attributes) => ({ 'data-padding': attributes.padding }),
      },
      cardHeight: {
        default: 'auto',
        parseHTML: (element) => element.getAttribute('data-card-height'),
        renderHTML: (attributes) => ({ 'data-card-height': attributes.cardHeight }),
      },
      cardWidth: {
        default: 'auto',
        parseHTML: (element) => element.getAttribute('data-card-width'),
        renderHTML: (attributes) => ({ 'data-card-width': attributes.cardWidth }),
      },
      cardSocialFill: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-card-social-fill'),
        renderHTML: (attributes) => ({ 'data-card-social-fill': attributes.cardSocialFill }),
      },
      cardTextColor: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-card-text-color'),
        renderHTML: (attributes) => ({ 'data-card-text-color': attributes.cardTextColor }),
      },
      imageBorderRadius: {
        default: '0px',
        parseHTML: (element) => element.getAttribute('data-image-border-radius'),
        renderHTML: (attributes) => ({ 'data-image-border-radius': attributes.imageBorderRadius }),
      },
      padding: {
        default: '0px',
        parseHTML: (element) => element.getAttribute('data-padding'),
        renderHTML: (attributes) => ({ 'data-padding': attributes.padding }),
      },
      data: {
        default: {
          authors: [],
        },
        parseHTML: (element) => JSON.parse(element.getAttribute('data-data') || '{}'),
        renderHTML: (attributes) => ({ 'data-data': JSON.stringify(attributes.data) }),
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', { ...HTMLAttributes, 'data-type': this.name }];
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(AuthorsView, {
      className: 'w-full',
    });
  },
});
