import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TextSelection } from '@tiptap/pm/state';
import { isNodeSelection, minMax, posToDOMRect } from '@tiptap/react';
import { Instance, Props } from 'tippy.js';

import { Button } from '@/components/TiptapEditor/extensions';
import { Link } from '@/components/TiptapEditor/extensions/Link';
import { useThemeData } from '@/components/TiptapEditor/lib/hooks/useThemeData';
import getInsertionEndPosition from '@/components/TiptapEditor/lib/utils/getInsertionEndPosition';
import { EDITOR_MENUS_Z_INDEX } from '@/components/zIndexes';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import usePublicationSettings from '@/hooks/usePublications/usePublicationSettings';

import { ToolbarButton } from '../../buttons/ToolbarButton';
import { AiToolsPanel } from '../../panels/AiTools';
import { useAITools } from '../../panels/AiTools/hooks';
import { CommentingPanel } from '../../panels/Commenting';
import { EditLinkMenu } from '../../panels/EditLink';
import { TextStylesPanel } from '../../panels/TextStyles';
import { useSelection } from '../../panels/TextStyles/hooks';
import { useThreadComposer } from '../../panels/ThreadComposer/Context';
import { HeadingsToolbar, HighlightingToolbar, ListsToolbar, TextAlignmentsToolbar } from '../../toolbars';
import { Divider, Toolbar } from '../../ui/Toolbar';
import { BubbleMenu as BaseBubbleMenu } from '../BubbleMenu';
import { ImproveWritingMenu } from '../ImproveWriting';
import { MenuProps, ShouldShowProps } from '../types';
import { forceUpdateTippy } from '../utils/forceUpdateTippy';
import { isCustomNodeSelected } from '../utils/isCustomNodeSelected';
import { isTextSelected } from '../utils/isTextSelected';

import {
  useAlignmentOptions,
  useFormattingOptions,
  useHeadingOptions,
  useHighlighting,
  useLinkHandlers,
  useListOptions,
  useTextStyleOptions,
} from './hooks';

export const TextMenu = ({ editor, appendTo }: MenuProps): JSX.Element => {
  const currentPublicationId = useCurrentPublicationId();
  const { data: settings } = usePublicationSettings(currentPublicationId);

  const { isComposing } = useThreadComposer();

  const [shouldHide, setShouldHide] = useState(false);

  const tippyInstance = useRef<Instance | null>(null);

  const [showImproveWriting, setShowImproveWriting] = useState(false);

  const [isLinkActive, setIsLinkActive] = useState(false);
  const [isButtonActive, setIsButtonActive] = useState(false);

  const editorRef = useRef(editor);
  const { setComposing } = useThreadComposer();

  const handleCommentClick = useCallback(() => {
    setComposing(true);
  }, [setComposing]);

  const onImproveWritingBack = useCallback(() => {
    setShowImproveWriting(false);
  }, []);

  const onImproveWriting = useCallback(() => {
    setShowImproveWriting(true);
  }, []);

  const getReferenceClientRect = useCallback(() => {
    const {
      view,
      state,
      state: {
        selection: { from, to },
      },
    } = editor;

    if (isNodeSelection(state.selection)) {
      const node = view.nodeDOM(from) as HTMLElement;

      if (node && node.getBoundingClientRect) {
        return node.getBoundingClientRect();
      }
    }

    return posToDOMRect(view, from, to);
  }, [editor]);

  useEffect(() => {
    if (!editor) {
      return;
    }

    const mouseDownHandler = () => setShouldHide(true);

    const mouseUpHandler = () => setShouldHide(false);

    editor.view.dom.addEventListener('mousedown', mouseDownHandler);

    editor.view.dom.addEventListener('mouseup', mouseUpHandler);

    // eslint-disable-next-line consistent-return
    return () => {
      editor.view.dom.removeEventListener('mousedown', mouseDownHandler);
      editor.view.dom.removeEventListener('mouseup', mouseUpHandler);
    };
  }, [editor]);

  const shouldShow = useCallback(
    ({ view, from }: ShouldShowProps) => {
      if (shouldHide || isComposing) {
        return false;
      }

      const domAtPos = view.domAtPos(from || 0).node as HTMLElement;
      const nodeDOM = view.nodeDOM(from || 0) as HTMLElement;
      const node = nodeDOM || domAtPos;

      if (isCustomNodeSelected(editor, node)) {
        return false;
      }

      setIsLinkActive(editor.isActive(Link.name));
      setIsButtonActive(editor.isActive(Button.name));

      return isTextSelected({ editor });
    },
    [editor, shouldHide, isComposing]
  );

  const onReposition = useCallback(() => {
    if (tippyInstance.current) {
      forceUpdateTippy(tippyInstance.current, getReferenceClientRect);
    }
  }, [getReferenceClientRect]);

  const link = useLinkHandlers(editor, onReposition);

  // Repositioning of BubbleMenu as soon as the link menu is opened
  useEffect(() => {
    if (link.showEdit) {
      onReposition();
    }
  }, [link.showEdit, onReposition]);

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const textMenuIsVisible = shouldShow({
        view: editor.view,
        from: editor.state.selection.from,
      });

      if (textMenuIsVisible && (event.metaKey || event.ctrlKey) && event.key === 'k') {
        setShowImproveWriting(false);
        link.setShowEdit(true);
        event.preventDefault();

        return true;
      }

      return false;
    },
    [editor.view, editor.state.selection.from, link, shouldShow]
  );

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);

  const boldShortcut = useMemo(() => ['Mod', 'B'], []);
  const italicShortcut = useMemo(() => ['Mod', 'I'], []);
  const underlineShortcut = useMemo(() => ['Mod', 'U'], []);
  const strikeShortcut = useMemo(() => ['Mod', 'Shift', 'X'], []);
  const codeShortcut = useMemo(() => ['Mod', 'E'], []);
  const superscriptShortcut = useMemo(() => ['Mod', '.'], []);
  const subscriptShortcut = useMemo(() => ['Mod', ','], []);
  const codeBlockShortcut = useMemo(() => ['Mod', 'Alt', 'C'], []);
  const blockquoteShortcut = useMemo(() => ['Mod', 'Shift', 'B'], []);

  const bubbleMenuTippyOptions = useMemo<Partial<Props>>(
    () => ({
      getReferenceClientRect,
      appendTo: () => {
        return appendTo?.current;
      },
      onHidden: () => {
        setShowImproveWriting(false);
        link.setShowEdit(false);
      },
      onCreate: (instance: Instance) => {
        tippyInstance.current = instance;
      },
      zIndex: EDITOR_MENUS_Z_INDEX,
      maxWidth: window.innerWidth - 10,
      placement: isLinkActive || isButtonActive ? 'bottom' : 'top',
      offset: isButtonActive ? [0, 18] : [0, 10],
    }),
    [appendTo, link, getReferenceClientRect, isLinkActive, isButtonActive]
  );

  useEffect(() => {
    tippyInstance.current?.setProps(bubbleMenuTippyOptions);
  }, [bubbleMenuTippyOptions]);

  const onAiText = useCallback((text: string) => {
    if (!editorRef.current) {
      return;
    }

    const range = {
      from: editorRef.current.state.selection.from,
      to: editorRef.current.state.selection.to,
    };

    editorRef.current
      .chain()
      .focus()
      .insertContentAt(range, text)
      .command(({ dispatch, tr }) => {
        if (dispatch) {
          const insertionEndPosition = getInsertionEndPosition(tr, tr.steps.length - 1, -1);

          if (insertionEndPosition !== -1) {
            const { doc } = tr;
            const newTo = insertionEndPosition.to;
            const minPos = TextSelection.atStart(doc).from;
            const maxPos = TextSelection.atEnd(doc).to;
            const resolvedFrom = minMax(range.from, minPos, maxPos);
            const resolvedEnd = minMax(newTo, minPos, maxPos);
            const selection = TextSelection.create(doc, resolvedFrom, resolvedEnd);

            tr.setSelection(selection);

            return dispatch(tr);
          }
        }

        return true;
      })
      .run();
  }, []);

  const selectedText =
    Math.abs(editor.state.selection.from - editor.state.selection.to) > 0
      ? editor.state.doc.textBetween(editor.state.selection.from, editor.state.selection.to, ' ')
      : null;

  const formattingOptions = useFormattingOptions(editor);
  const highlighting = useHighlighting(editor);
  const headlines = useHeadingOptions(editor);
  const lists = useListOptions(editor);
  const align = useAlignmentOptions(editor);
  const textStyles = useTextStyleOptions(editor);
  const aiTools = useAITools({ onText: onAiText, text: selectedText });

  const { selection } = editor.state;
  const themeColors = useThemeData('colors');

  const { isDisabled } = useSelection(editor, selection, themeColors);

  const showsSubMenu = !!link.showEdit || !!showImproveWriting;

  const menuContent = editor.isActive('blockquoteFigure') ? (
    <Toolbar>
      {link.showEdit && showsSubMenu && (
        <EditLinkMenu
          onSetLink={link.onSetLink}
          onUnsetLink={link.onUnsetLink}
          onBack={link.onBack}
          onSetTarget={link.onSetTarget}
          editor={editor}
        />
      )}
      {showImproveWriting && showsSubMenu && <ImproveWritingMenu editor={editor} onBack={onImproveWritingBack} />}
      {!showsSubMenu && (
        <>
          <ToolbarButton
            can={formattingOptions.canBold}
            icon="Bold"
            is={formattingOptions.isBold}
            onClick={formattingOptions.onBold}
            title="Bold"
            tooltipShortcut={boldShortcut}
          />
          <ToolbarButton
            can={formattingOptions.canItalic}
            icon="Italic"
            is={formattingOptions.isItalic}
            onClick={formattingOptions.onItalic}
            title="Italic"
            tooltipShortcut={italicShortcut}
          />
          <ToolbarButton
            can={formattingOptions.canUnderline}
            icon="Underline"
            is={formattingOptions.isUnderline}
            onClick={formattingOptions.onUnderline}
            title="Underline"
            tooltipShortcut={underlineShortcut}
          />
          <ToolbarButton
            can={formattingOptions.canStrike}
            icon="Strike"
            is={formattingOptions.isStrike}
            onClick={formattingOptions.onStrike}
            title="Strike"
            tooltipShortcut={strikeShortcut}
          />
          <HighlightingToolbar
            isColorActive={highlighting.isHighlightActive}
            onSelect={highlighting.onHighlightSelect}
            onClear={highlighting.onHighlightClear}
            currentColor={editor.getAttributes('highlight')?.color}
            hasSelection={!!editor.state.selection}
            isActive={editor.isActive('highlight')}
            isDisabled={!editor.can().toggleHighlight()}
            documentColors={editor.storage.documentColors?.colors}
          />
          <ToolbarButton can={link.canLink} icon="Link" is={false} onClick={link.onEditLinkClick} title="Link" />
          <ToolbarButton
            can={formattingOptions.canCode}
            icon="Code"
            is={formattingOptions.isCode}
            onClick={formattingOptions.onCode}
            title="Code"
            tooltipShortcut={codeShortcut}
          />
          <ToolbarButton
            can={formattingOptions.canSuperScript}
            icon="Superscript"
            is={formattingOptions.isSuperScript}
            onClick={formattingOptions.onSuperScript}
            title="Superscript"
            tooltipShortcut={superscriptShortcut}
          />
          <ToolbarButton
            can={formattingOptions.canSubScript}
            icon="Subscript"
            is={formattingOptions.isSubScript}
            onClick={formattingOptions.onSubScript}
            title="Subscript"
            tooltipShortcut={subscriptShortcut}
          />
          <Divider />
          <TextAlignmentsToolbar
            canAlign={align.canAlign}
            alignment={align.alignment}
            onAlignCenter={align.onAlignCenter}
            onAlignJustify={align.onAlignJustify}
            onAlignLeft={align.onAlignLeft}
            onAlignRight={align.onAlignRight}
          />
          <TextStylesPanel
            color={textStyles.color}
            fontFamily={textStyles.fontFamily}
            fontSize={textStyles.fontSize}
            onSetColor={textStyles.onSetColor}
            onSetFontFamily={textStyles.onSetFontFamily}
            onSetFontSize={textStyles.onSetFontSize}
            isDisabled={isDisabled}
            documentColors={editor.storage.documentColors?.colors}
          />
          <Divider />
          <AiToolsPanel
            isFetching={aiTools.isFetching}
            consumption={aiTools.consumption}
            isConsumptionExceeded={aiTools.isConsumptionExceeded}
            refetchConsumption={aiTools.refetchConsumption}
            showConsumption={aiTools.showConsumption}
            onComplete={aiTools.onCompleteSentence}
            onFixSpelling={aiTools.onFixSpelling}
            onTranslate={aiTools.onTranslate}
            onShowImproveWriting={onImproveWriting}
          />
        </>
      )}
    </Toolbar>
  ) : (
    <Toolbar>
      {link.showEdit && showsSubMenu && (
        <EditLinkMenu
          onSetLink={link.onSetLink}
          onUnsetLink={link.onUnsetLink}
          onBack={link.onBack}
          onSetTarget={link.onSetTarget}
          editor={editor}
        />
      )}
      {showImproveWriting && showsSubMenu && <ImproveWritingMenu editor={editor} onBack={onImproveWritingBack} />}
      {!showsSubMenu && (
        <>
          <ToolbarButton
            can={formattingOptions.canBold}
            icon="Bold"
            is={formattingOptions.isBold}
            onClick={formattingOptions.onBold}
            title="Bold"
            tooltipShortcut={boldShortcut}
          />
          <ToolbarButton
            can={formattingOptions.canItalic}
            icon="Italic"
            is={formattingOptions.isItalic}
            onClick={formattingOptions.onItalic}
            title="Italic"
            tooltipShortcut={italicShortcut}
          />
          <ToolbarButton
            can={formattingOptions.canUnderline}
            icon="Underline"
            is={formattingOptions.isUnderline}
            onClick={formattingOptions.onUnderline}
            title="Underline"
            tooltipShortcut={underlineShortcut}
          />
          <ToolbarButton
            can={formattingOptions.canStrike}
            icon="Strike"
            is={formattingOptions.isStrike}
            onClick={formattingOptions.onStrike}
            title="Strike"
            tooltipShortcut={strikeShortcut}
          />
          <HighlightingToolbar
            isColorActive={highlighting.isHighlightActive}
            onSelect={highlighting.onHighlightSelect}
            onClear={highlighting.onHighlightClear}
            currentColor={editor.getAttributes('highlight')?.color}
            hasSelection={!!editor.state.selection}
            isActive={editor.isActive('highlight')}
            isDisabled={!editor.can().toggleHighlight()}
            documentColors={editor.storage.documentColors?.colors}
          />
          <ToolbarButton can={link.canLink} icon="Link" is={false} onClick={link.onEditLinkClick} title="Link" />
          <ToolbarButton
            can={formattingOptions.canCode}
            icon="Code"
            is={formattingOptions.isCode}
            onClick={formattingOptions.onCode}
            title="Code"
            tooltipShortcut={codeShortcut}
          />
          <ToolbarButton
            can={formattingOptions.canSuperScript}
            icon="Superscript"
            is={formattingOptions.isSuperScript}
            onClick={formattingOptions.onSuperScript}
            title="Superscript"
            tooltipShortcut={superscriptShortcut}
          />
          <ToolbarButton
            can={formattingOptions.canSubScript}
            icon="Subscript"
            is={formattingOptions.isSubScript}
            onClick={formattingOptions.onSubScript}
            title="Subscript"
            tooltipShortcut={subscriptShortcut}
          />
          <Divider />
          <HeadingsToolbar
            level={headlines.currentLevel}
            canHeadline={headlines.canHeading}
            onHeadline={headlines.onHeading}
            onReset={headlines.onReset}
          />
          <ListsToolbar
            isBulletList={lists.isBulletListActive}
            isOrderedList={lists.isOrderedListActive}
            onBulletList={lists.onBulletList}
            onOrderedList={lists.onOrderedList}
            onResetList={lists.onReset}
          />
          <ToolbarButton
            can={formattingOptions.canBlockquote}
            icon="Blockquote"
            is={formattingOptions.isBlockquote}
            onClick={formattingOptions.onBlockquote}
            title="Blockquote"
            tooltipShortcut={blockquoteShortcut}
          />
          <ToolbarButton
            can={formattingOptions.canCodeBlock}
            icon="CodeBlock"
            is={formattingOptions.isCodeBlock}
            onClick={formattingOptions.onCodeBlock}
            title="Code Block"
            tooltipShortcut={codeBlockShortcut}
          />
          <Divider />
          <TextAlignmentsToolbar
            canAlign={align.canAlign}
            alignment={align.alignment}
            onAlignCenter={align.onAlignCenter}
            onAlignJustify={align.onAlignJustify}
            onAlignLeft={align.onAlignLeft}
            onAlignRight={align.onAlignRight}
          />
          <TextStylesPanel
            color={textStyles.color}
            fontFamily={textStyles.fontFamily}
            fontSize={textStyles.fontSize}
            onSetColor={textStyles.onSetColor}
            onSetFontFamily={textStyles.onSetFontFamily}
            onSetFontSize={textStyles.onSetFontSize}
            isDisabled={isDisabled}
            documentColors={editor.storage.documentColors?.colors}
          />
          <Divider />
          {settings?.editor_threads && settings?.collaborative_editing && (
            <CommentingPanel onOpenCommentingPanel={handleCommentClick} />
          )}
          <AiToolsPanel
            isFetching={aiTools.isFetching}
            consumption={aiTools.consumption}
            isConsumptionExceeded={aiTools.isConsumptionExceeded}
            refetchConsumption={aiTools.refetchConsumption}
            showConsumption={aiTools.showConsumption}
            onComplete={aiTools.onCompleteSentence}
            onFixSpelling={aiTools.onFixSpelling}
            onTranslate={aiTools.onTranslate}
            onShowImproveWriting={onImproveWriting}
          />
        </>
      )}
    </Toolbar>
  );

  return (
    <BaseBubbleMenu
      editor={editor}
      pluginKey="textMenu"
      shouldShow={shouldShow}
      updateDelay={100}
      tippyOptions={bubbleMenuTippyOptions}
    >
      {!shouldHide && !isComposing && menuContent}
    </BaseBubbleMenu>
  );
};
