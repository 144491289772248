import { ElementType, FC } from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/24/solid';
import cx from 'classnames';

import Tooltip from '../Tooltip';

const COLORS = {
  flush: 'border border-transparent',
  warning: 'bg-yellow-100 text-yellow-800 border-yellow-200 border border-gray-300',
  alert: 'bg-red-100 text-red-800 border-red-200 border border-gray-300',
  success: 'bg-green-100 text-green-800 border-green-200 border border-gray-300',
  information: 'bg-surface-50 text-surface-700 border border-surface-200',
  info_blue: 'bg-blue-100 text-blue-800 border-blue-200 border border-gray-300',
  tertiary: 'bg-action-tertiary-50 text-action-tertiary-900 border border-action-tertiary-200',
  selectedTag: 'flex text-gray-900 font-normal bg-surface-100',
};

export interface BadgeProps {
  type?: 'warning' | 'alert' | 'success' | 'information' | 'info_blue' | 'tertiary' | 'flush' | 'selectedTag';
  typeColor?: 'warning' | 'alert' | 'success' | 'information' | 'info_blue' | 'tertiary' | 'flush' | 'selectedTag';
  size?: 'sm' | 'md';
  className?: string;
  children: React.ReactNode;
  onDismiss?: () => void;
  alwaysShowDismiss?: boolean;
  dismissClassName?: string;
  tooltip?: string;
  Icon?: ElementType;
}

const Badge: FC<BadgeProps> = ({
  dismissClassName,
  type = 'information',
  typeColor,
  size = 'md',
  children,
  className = '',
  onDismiss,
  alwaysShowDismiss = false,
  tooltip,
  Icon,
}: BadgeProps) => {
  if (!children) return null;

  const colorClasses = COLORS[typeColor || type] ?? 'bg-gray-100 text-gray-800';

  return (
    <span
      className={cx(
        'shadow-xs inline-flex items-center text-xs font-medium relative group',
        className,
        colorClasses,
        { 'h-4.5 px-1.5 text-xs': size === 'sm' && type !== 'selectedTag' },
        { 'px-2 py-1': size === 'md' && type !== 'selectedTag' },
        { 'text-sm px-2 py-0.5': type === 'selectedTag' },
        { 'text-xs': type !== 'selectedTag' },
        { rounded: size === 'sm' },
        { 'rounded-md': size === 'md' },
        Icon ? 'gap-x-1' : 'gap-x-2'
      )}
    >
      {tooltip ? (
        <Tooltip id={`badge-tooltip-${btoa(tooltip)}`} text={tooltip} showIcon={false}>
          <div className="flex flex-row gap-x-1 items-center">
            <InformationCircleIcon className="w-3 h-3" />
            {children}
          </div>
        </Tooltip>
      ) : (
        <>
          {Icon && <Icon className="w-3 h-3" />}
          {children}
        </>
      )}
      {onDismiss && (
        <XMarkIcon
          onClick={onDismiss}
          className={cx(
            alwaysShowDismiss ? 'w-4 opacity-100' : 'w-0 group-hover:w-4 opacity-0 group-hover:opacity-100',
            'cursor-pointer h-3 transition-opacity duration-200',
            type !== 'selectedTag' && 'mr-3',
            dismissClassName
          )}
        />
      )}
    </span>
  );
};

export default Badge;
