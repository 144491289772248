import { Outlet, useNavigate } from 'react-router-dom';
import { UsersIcon } from '@heroicons/react/20/solid';

import PageHeading from '@/components/Layout/PageLayout/PageHeading';
import TabNavigation from '@/components/TabNavigation';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useCurrentUser } from '@/context/current-user-context';
import { useSetting } from '@/hooks';
import { useTutorial } from '@/hooks/useTutorials';
import { Tab } from '@/interfaces/general';
import { Setting } from '@/interfaces/setting';
import { TutorialType } from '@/interfaces/tutorial';
import { Button } from '@/ui/Button';
import appendSettingsPublicationId from '@/utils/appendSettingsPublicationId';

const Layout = () => {
  const tutorial = useTutorial(TutorialType.SUBSCRIBERS);
  const navigate = useNavigate();

  const { currentUser } = useCurrentUser();
  const hasSettingsV2 = currentUser?.has_settings_v2;
  const [currentPublicationId] = useCurrentPublicationState();

  const { pathname } = window.location;

  const tabs = [
    {
      name: 'subscribers',
      label: 'Subscribers',
      targetRoute: `/subscribers`,
      selected: pathname === '/subscribers',
    },
    {
      name: 'custom_fields',
      label: 'Custom Fields',
      targetRoute: `/subscribers/custom_fields`,
      selected: pathname.startsWith('/subscribers/custom_fields'),
    },
    {
      name: 'tags',
      label: 'Tags',
      targetRoute: `/subscribers/tags`,
      selected: pathname.startsWith('/subscribers/tags'),
    },
    {
      name: 'preferences',
      label: 'Preferences',
      targetRoute: `/subscribers/preferences`,
      selected: pathname.startsWith('/subscribers/preferences'),
    },
  ] as Tab[];

  const isTagsEnabled = useSetting({ setting: Setting.SUBSCRIBER_TAGGING, defaultValue: true });
  const isCustomFieldsEnabled = useSetting({ setting: Setting.CUSTOM_FIELDS, defaultValue: true });
  const arePreferencesEnabled = useSetting({ setting: Setting.SUBSCRIBERS_PREFERENCES, defaultValue: false });

  const filteredTabs = tabs.filter((tab) => {
    if (tab.name === 'tags' && !isTagsEnabled) {
      return false;
    }
    if (tab.name === 'custom_fields' && !isCustomFieldsEnabled) {
      return false;
    }
    if (tab.name === 'preferences' && !arePreferencesEnabled) {
      return false;
    }
    return true;
  });

  return (
    <>
      <PageHeading title="Subscribers" tutorial={tutorial}>
        <Button
          Icon={UsersIcon}
          variant="primary"
          onClick={() =>
            navigate(
              hasSettingsV2
                ? appendSettingsPublicationId('/settings/publication/subscribers_import', currentPublicationId)
                : '/settings/publication/import_subscribers'
            )
          }
        >
          Import Subscribers
        </Button>
      </PageHeading>
      <TabNavigation tabs={filteredTabs} variant="tertiary" className="mb-4" />
      <Outlet />
    </>
  );
};

export default Layout;
