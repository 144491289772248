import { useCallback, useEffect, useState } from 'react';
import * as SliderPrimitive from '@radix-ui/react-slider';

import { cn } from '../../../../_utils/cn';
import { ColorPicker } from '../../../UI/ColorPicker';
import { SimpleInput, SimpleInputWrapper } from '../../../UI/Input';
import { Text } from '../../../UI/Text';
import { ToggleGroup, ToggleGroupItem } from '../../../UI/ToggleGroup';
import { AttributeSettingProps } from '../types';

type GradientSettingsProps = AttributeSettingProps & {
  property?: string;
  isFontGradient?: boolean;
};

const GradientSettings = ({
  editor,
  activeNodeResult,
  property = 'background',
  isFontGradient,
}: GradientSettingsProps) => {
  const [value, setValue] = useState(50);
  const [colorOne, setColorOne] = useState('#000000');
  const [colorTwo, setColorTwo] = useState('#E3E3E3');
  const [gradientType, setGradientType] = useState('linear');
  const [degree, setDegree] = useState(90);
  const [isColorOnePickerOpen, setIsColorOnePickerOpen] = useState(true);
  const [isColorTwoPickerOpen, setIsColorTwoPickerOpen] = useState(false);

  const buildTailwindGradient = (percentage: number, c1: string, c2: string) => {
    if (gradientType === 'linear') {
      return `linear-gradient(${degree}deg, ${c1} 0%, ${c2} ${percentage}%)`;
    }

    return `radial-gradient(circle, ${c1} 0%, ${c2} ${percentage}%)`;
  };

  const onSetGradient = useCallback(
    (gradient: string) => {
      if (!editor || !activeNodeResult) return;

      if (isFontGradient) {
        editor.chain().selectTextBlock().setFontGradient(gradient).unsetColor().run();
      } else {
        editor.commands.command(({ tr }) => {
          tr.setNodeAttribute(activeNodeResult.activeNodePos, property, gradient);
          return true;
        });
      }
    },
    [editor, activeNodeResult, property, isFontGradient]
  );

  // Update the gradient whenever value, colorOne, or colorTwo changes
  useEffect(() => {
    const computedGradient = buildTailwindGradient(value, colorOne, colorTwo);
    const currentGradient = activeNodeResult.activeNodeAttributes.backgroundGradientTWClass;

    if (computedGradient !== currentGradient) {
      onSetGradient(computedGradient);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, colorOne, colorTwo, isColorOnePickerOpen, onSetGradient, degree]);

  const handleValueChange = (newValue: number[]) => {
    setValue(newValue[0]);
  };

  return (
    <div
      className="flex flex-col gap-2"
      style={
        {
          '--gradient-color-one': colorOne,
          '--gradient-color-two': colorTwo,
        } as React.CSSProperties
      }
    >
      <div className="w-full flex flex-col gap-2">
        <div className="flex items-center justify-stretch gap-2">
          <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
            Gradient
          </Text>
          <div className="grow flex items-center">
            <SimpleInputWrapper className="h-[38px] px-1">
              <ToggleGroup
                className="py-[1px] w-full"
                type="single"
                defaultValue={gradientType}
                onValueChange={(type) => setGradientType(type)}
              >
                <ToggleGroupItem asChild value="linear">
                  <Text variant="secondary" size="2xs" weight="medium" className="grow data-[state=on]:text-wb-accent">
                    Linear
                  </Text>
                </ToggleGroupItem>
                <ToggleGroupItem asChild value="radial">
                  <Text variant="secondary" size="2xs" weight="medium" className="grow data-[state=on]:text-wb-accent">
                    Radial
                  </Text>
                </ToggleGroupItem>
              </ToggleGroup>
            </SimpleInputWrapper>
          </div>
        </div>

        {gradientType === 'linear' && (
          <div className="flex items-center justify-stretch gap-2 select-none min-w-0">
            <Text className="w-[80px] shrink-0" variant="secondary" size="2xs" weight="medium">
              Degree
            </Text>
            <SimpleInputWrapper className="h-[32px] relative">
              <SimpleInput
                type="number"
                min={0}
                max={90}
                className="text-xs"
                defaultValue={degree}
                onChange={(e) => setDegree(parseInt(e.target.value, 10))}
              />
              <Text
                size="2xs"
                weight="medium"
                variant="secondary"
                className="absolute top-1/2 right-3 -translate-y-1/2"
              >
                deg
              </Text>
            </SimpleInputWrapper>
          </div>
        )}

        <div className="flex items-center justify-stretch gap-2 select-none min-w-0">
          <Text className="w-[80px] shrink-0" variant="secondary" size="2xs" weight="medium">
            Color One
          </Text>
          <button
            type="button"
            onClick={() => {
              setIsColorOnePickerOpen(true);
              setIsColorTwoPickerOpen(false);
            }}
            className="flex items-center justify-stretch gap-2 select-none min-w-0"
          >
            <SimpleInputWrapper className="h-[32px] relative">
              <div
                style={{ backgroundColor: colorOne }}
                className="absolute top-1/2 left-2 -translate-y-1/2 w-4 h-4 rounded-md"
              />
              <SimpleInput type="text" className="text-xs pl-6" value={colorOne} onChange={() => {}} />
            </SimpleInputWrapper>
          </button>
        </div>

        <div className="flex items-center justify-stretch gap-2 select-none min-w-0">
          <Text className="w-[80px] shrink-0" variant="secondary" size="2xs" weight="medium">
            Color Two
          </Text>
          <button
            type="button"
            onClick={() => {
              setIsColorTwoPickerOpen(true);
              setIsColorOnePickerOpen(false);
            }}
            className="flex items-center justify-stretch gap-2 select-none min-w-0"
          >
            <SimpleInputWrapper className="h-[32px] relative">
              <div
                style={{ backgroundColor: colorTwo }}
                className="absolute top-1/2 left-2 -translate-y-1/2 w-4 h-4 rounded-md"
              />
              <SimpleInput type="text" className="text-xs pl-6" value={colorTwo} onChange={() => {}} />
            </SimpleInputWrapper>
          </button>
        </div>

        <SliderPrimitive.Root
          className={cn('relative flex w-full touch-none select-none items-center py-4')}
          defaultValue={[50]} // Center the thumb initially
          max={100} // Assuming max range is 100
          step={5}
          onValueChange={handleValueChange} // Update state on slider change
        >
          <SliderPrimitive.Track className="relative h-1.5 w-[calc(100%-40px)] grow rounded-full bg-wb-secondary">
            <div className="absolute left-0 h-full bg-[var(--gradient-color-one)]" style={{ width: `${value}%` }} />

            <div
              className="absolute right-0 h-full bg-[var(--gradient-color-two)]"
              style={{ width: `${100 - value}%` }}
            />

            <SliderPrimitive.Range className="absolute h-full bg-transparent" />
          </SliderPrimitive.Track>
          <SliderPrimitive.Thumb className="block h-4 w-4 rounded-full border border-white bg-white shadow transition-colors cursor-grab active:cursor-grabbing focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50" />
        </SliderPrimitive.Root>
      </div>

      {isColorOnePickerOpen && (
        <ColorPicker
          selectedColor={colorOne}
          onSetColor={(color: string | null) => {
            setColorOne(color || '#000000');
          }}
          onReset={() => {
            setColorOne('#000000');
          }}
        />
      )}

      {isColorTwoPickerOpen && (
        <ColorPicker
          selectedColor={colorTwo}
          onSetColor={(color: string | null) => {
            setColorTwo(color || '#E3E3E3');
          }}
          onReset={() => {
            setColorTwo('#E3E3E3');
          }}
        />
      )}
    </div>
  );
};

export default GradientSettings;
