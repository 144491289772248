import { useState } from 'react';
import { UserCircleIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

import ActionModal from '@/components/ActionModal';
import Badge, { BadgeProps } from '@/components/Badge';
import EllipsisDropdown from '@/components/EllipsisDropdown';
import { ItemColumn, ItemRow } from '@/components/ResourceList';
import { AlignType } from '@/components/ResourceList/types';
import { Typography, TypographyStack } from '@/components/Typography';
import { useCancelInvite, useSendInvite } from '@/hooks/useInvites';
import { useCurrentOrganizationId, useOrganization } from '@/hooks/useOrganization';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import usePublicationSettings from '@/hooks/usePublications/usePublicationSettings';
import { EllipsisDropdownOption } from '@/interfaces/general';
import { TeamMember } from '@/interfaces/team_member';
import useDeleteInvite from '@/routes/settings/workspace/team/_hooks/useDeleteInvite';

import DeleteModal from './delete/DeleteModal';
import EditModal from './edit/EditModal';
import { ASSIGNABLE_LEVEL_NAMES, TEAM_MEMBER_PERMISSION_NAMES } from './constants';
import EditMember from './edit';

interface Props {
  member: TeamMember;
  onResendInvite: () => void;
  onCancelInvite: () => void;
  onDeleteInvite: () => void;
  onUpdate: () => void;
  canChangeInvite: boolean;
}

const BADGE_TYPE_BY_STATUS: Record<TeamMember['status'], BadgeProps['type']> = {
  pending: 'warning',
  accepted: 'information',
  declined: 'alert',
  expired: 'alert',
};

const MemberListItem = ({
  member,
  onResendInvite,
  onCancelInvite,
  onUpdate,
  canChangeInvite,
  onDeleteInvite
}: Props) => {
  const organizationId = useCurrentOrganizationId();
  const publicationId = useCurrentPublicationId();
  const { data: organizationData } = useOrganization(organizationId, publicationId);
  const { organization } = organizationData || {};
  const [confirmResendInvite, setConfirmResendInvite] = useState<boolean>(false);
  const [confirmCancelInvite, setConfirmCancelInvite] = useState<boolean>(false);
  const [confirmDeleteInvite, setConfirmDeleteInvite] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { data: publicationSettings } = usePublicationSettings(publicationId);
  const isOwner = organization?.owner_id === member.user_id;
  const assignableName = member.assignable_type === 'Organization' ? organization?.name : member.publication_name;

  const resendMutation = useSendInvite(organizationId);
  const handleResendInvite = async () => {
    await resendMutation.mutateAsync({
      invite: {
        invited_email: member.email,
        role_id: String(member.role_id),
        invitable_type: member.assignable_type,
        invitable_id: member.assignable_id,
      },
      publicationId,
    });
    setConfirmResendInvite(false);
    onResendInvite();
  };

  const cancelMutation = useCancelInvite(organizationId, publicationId, String(member.id));
  const handleCancelInvite = async () => {
    const today = new Date();
    await cancelMutation.mutateAsync({
      invitable_type: member.assignable_type,
      invitable_id: member.assignable_id,
      declined_at: today.toISOString(),
    });
    setConfirmCancelInvite(false);
    onCancelInvite();
  };

  const handleCloseEditForm = () => {
    setIsEditing(false);
    onUpdate();
  };

  const deleteMutation = useDeleteInvite(organizationId, publicationId, String(member.id));
  const handleDeleteInvite = async () => {
    await deleteMutation.mutateAsync();
    setConfirmDeleteInvite(false);
    onDeleteInvite();
  }

  const actions: EllipsisDropdownOption[] = [];
  if (member.source === 'assignment') {
    actions.push({
      label: 'Edit Team Member',
      onClick: () => setIsEditing(true),
    });
    if (publicationSettings?.user_invites_v2 && !isOwner) {
      actions.push({
        label: 'Remove Team Member',
        onClick: () => setIsDeleting(true),
        danger: true,
        isDestructive: true,
      });
    }
  } else if (member.source === 'invite' && canChangeInvite) {
    if (member.status === 'expired') {
      actions.push({
        label: 'Resend Invite',
        onClick: () => setConfirmResendInvite(true),
      });
      actions.push({
        label: 'Delete Invite',
        onClick: () => setConfirmDeleteInvite(true),
        danger: true,
        isDestructive: true,
      })
    } else if (member.status === 'pending') {
      actions.push({
        label: 'Cancel Invite',
        onClick: () => setConfirmCancelInvite(true),
      });
    } else if (member.status === 'declined') {
      actions.push({
        label: 'Delete Invite',
        onClick: () => setConfirmDeleteInvite(true),
        danger: true,
        isDestructive: true,
      })
    }
  }

  return (
    <>
      <ActionModal
        isOpen={confirmResendInvite}
        onClose={() => setConfirmResendInvite(false)}
        onProceed={handleResendInvite}
        resourceId={`resend-invite-${member.id}`}
        disabled={resendMutation.isLoading}
        isWorking={resendMutation.isLoading}
        headerText="Resend Invite"
        descriptionText="Are you sure you want to resend this team invite? You can always cancel it later."
        actionText="Resend"
        modalMessageType="info"
      />
      <ActionModal
        isOpen={confirmCancelInvite}
        onClose={() => setConfirmCancelInvite(false)}
        onProceed={handleCancelInvite}
        resourceId={`cancel-invite-${member.id}`}
        disabled={cancelMutation.isLoading}
        isWorking={cancelMutation.isLoading}
        headerText={`Cancel invite for ${member.name || member.email} to ${assignableName}?`}
        descriptionText="This action is permanent. You will need to send a new invitation if you want to add them again later."
        actionText="Cancel Invite"
        buttonType="danger"
        cancelText="Nevermind"
        modalMessageType="danger"
        buttonShade="dark"
      />
      <ActionModal
        isOpen={confirmDeleteInvite}
        onClose={() => setConfirmDeleteInvite(false)}
        onProceed={handleDeleteInvite}
        resourceId={`cancel-invite-${member.id}`}
        disabled={cancelMutation.isLoading}
        isWorking={cancelMutation.isLoading}
        headerText="Delete Invite"
        descriptionText="Are you sure you want to delete this invite? While this action cannot be undone, you can always send a new invite."
        actionText="Delete"
        modalMessageType="danger"
        buttonShade="light"
      />
      { publicationSettings?.user_invites_v2 ? (
        <>
          <EditModal
            isOpen={isEditing}
            onClose={handleCloseEditForm}
            organization={organization}
            contributorEnabled={!!publicationSettings?.contributor_role}
            member={member}
          />
          <DeleteModal
            isOpen={isDeleting}
            onClose={() => setIsDeleting(false)}
            organization={organization}
            member={member}
          />
        </>
      ) : (
        <EditMember
          isOpen={isEditing}
          onClose={handleCloseEditForm}
          name={member.name}
          email={member.email}
          assignmentId={parseInt(member.id, 10)}
          assignmentUserId={member.user_id || undefined}
          imageUrl={member.profile_picture_url || ''}
          currentRoleOption={{
            object_id: member.assignable_id,
            object_type: member.assignable_type,
            role_id: parseInt(member.role_id, 10),
            name: member.role_name,
          }}
          roleOptions={organization.role_options || []}
          publicationId={publicationId}
        />
      )}
      <ItemRow>
        <ItemColumn align={AlignType.LEFT}>
          <div className="flex flex-col gap-y-2 sm:flex-row sm:items-center">
            <div className="w-10 h-10 overflow-hidden rounded-full box-content mr-4 flex items-center justify-center bg-surface-100">
              {member.profile_picture_url ? (
                <img src={member.profile_picture_url} className="object-cover" alt="Member Profile" />
              ) : (
                <UserCircleIcon className="h-5 w-5" />
              )}
            </div>
            <TypographyStack
              gap="1"
              className={classNames(member.source === 'invite' ? 'sm:w-40 sm:break-all sm:px-4' : 'sm:px-4')}
            >
              <div className="flex flex-row space-x-2 items-center">
                {member.name ? <Typography token="font-medium/text/xs">{member.name}</Typography> : null}
                {isOwner ? <Badge type="info_blue" className="h-fit w-fit capitalize" size="sm">Owner</Badge> : null}
              </div>
              <Typography token="font-normal/text/xs" colorWeight="500">
                {member.email}
              </Typography>
            </TypographyStack>
            {member.source === 'invite' && canChangeInvite ? (
              <Badge className="h-fit w-fit capitalize" type={BADGE_TYPE_BY_STATUS[member.status]} size="sm">
                Invite {member.status}
              </Badge>
            ) : null}
          </div>
        </ItemColumn>
        <ItemColumn align={AlignType.LEFT}>
          <Badge className="capitalize">{TEAM_MEMBER_PERMISSION_NAMES[member.permission]}</Badge>
        </ItemColumn>
        <ItemColumn align={AlignType.LEFT}>
          <Badge>{ASSIGNABLE_LEVEL_NAMES[member.role_name]}</Badge>
        </ItemColumn>
        <ItemColumn align={AlignType.LEFT}>
          <Typography token="font-normal/text/sm" colorWeight="500" className="capitalize">
            {member.publication_name}
          </Typography>
        </ItemColumn>
        <ItemColumn align={AlignType.LEFT}>
          {actions.length > 0 ? <EllipsisDropdown options={actions} /> : null}
        </ItemColumn>
      </ItemRow>
    </>
  );
};

export default MemberListItem;
