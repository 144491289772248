/* eslint-disable react/no-danger */
import { useState } from 'react';
import cx from 'classnames';

import { Color } from '@/components/DesignTokens';
import Tooltip from '@/components/Tooltip';
import { Typography } from '@/components/Typography';
import { Card } from '@/ui/Card';

import { MetricCardProps } from './MetricCard.types';

const BACKGROUND_COLORS = {
  primary: '',
  secondary: 'bg-surface-100',
  success: 'bg-feedback-success-100',
};

const ICON_COLORS = {
  primary: '',
  secondary: '',
  success: 'feedback-success-700',
};

const TEXT_COLORS: Record<MetricCardProps['variant'], Color> = {
  primary: Color.SURFACE,
  secondary: Color.SURFACE,
  success: Color.SUCCESS,
};

const MetricCard = ({
  variant,
  label,
  LabelIcon,
  value,
  complements,
  tooltip,
  cta,
  initialVisibleComplements = 2,
  slot,
  className,
  isLoading,
  additionalComponent,
  bottomComponent,
}: MetricCardProps) => {
  const [expanded, setExpanded] = useState(false);

  const visibleComplements = (expanded ? complements : complements?.slice(0, initialVisibleComplements)) || [];

  return (
    <Card variant={variant} density="compact" backgroundColor={BACKGROUND_COLORS[variant]} className={className}>
      <div className={cx('h-full flex flex-col justify-between', cta ? 'space-y-1' : 'space-y-4')}>
        {cta && <div className="flex justify-end mb-6">{cta}</div>}
        <div className="space-y-2 py-2">
          <div className="flex items-center space-x-2">
            <Typography
              token="font-medium/text/base"
              className="truncate flex items-center gap-x-1"
              colorWeight="700"
              color={TEXT_COLORS[variant]}
            >
              {LabelIcon ? <LabelIcon className={cx('w-5 h-5', ICON_COLORS[variant])} /> : null}
              {label}
            </Typography>
            {tooltip && <Tooltip text={tooltip} id={String(Math.random())} />}
          </div>
          {isLoading ? (
            <div className="bg-gray-200 animate-pulse rounded w-fit">
              <p
                className="font-semibold text-gray-900 invisible text-3xl truncate "
                dangerouslySetInnerHTML={{ __html: '-----' }}
              />
            </div>
          ) : (
            <div>
              <Typography token="font-semibold/text/3xl" color={TEXT_COLORS[variant]}>
                <p className="truncate" dangerouslySetInnerHTML={{ __html: String(value) }} />
              </Typography>
            </div>
          )}

          {slot}
        </div>
        {additionalComponent}
        <div className="space-y-1">
          {visibleComplements.map((complement) => {
            return (
              <div className={cx('grid grid-cols-2 gap-x-1 pb-1')} key={complement.label}>
                <div className="flex flex-row space-x-2">
                  <p className="text-xs text-gray-700 font-medium ">{complement.label}</p>
                  {complement.tooltipText && <Tooltip text={complement.tooltipText} id={String(Math.random())} />}
                </div>
                <p className="text-xs text-right text-gray-900 font-semibold truncate">{complement.value}</p>
              </div>
            );
          })}
          {complements?.length! > initialVisibleComplements && (
            <button
              className="text-xs text-feedback-info-700 font-medium hover:underline focus:underline"
              onClick={() => setExpanded(!expanded)}
              type="button"
            >
              {expanded ? 'View less' : 'View more'}
            </button>
          )}
        </div>
        {bottomComponent}
      </div>
    </Card>
  );
};

export default MetricCard;
