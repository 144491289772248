import { Extension } from '@tiptap/core';
import { NodeSelection, TextSelection } from '@tiptap/pm/state';

import { getIgnoreTextInputOnNodes } from './plugins';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    customBehaviors: {
      /**
       * Select the parent text block when selection is a cursor
       */
      selectTextBlock: () => ReturnType;
    };
  }
}

export const CustomBehaviors = Extension.create({
  name: 'customBehaviors',

  addCommands() {
    return {
      selectTextBlock:
        () =>
        ({ tr, dispatch }) => {
          if (!dispatch || !(tr.selection instanceof TextSelection) || !tr.selection.$cursor) {
            return false;
          }

          const { $cursor } = tr.selection;

          if (!$cursor.parent.isTextblock) {
            return false;
          }

          tr.setSelection(NodeSelection.create(tr.doc, $cursor.before()));

          dispatch(tr);

          return true;
        },
    };
  },

  addProseMirrorPlugins() {
    return [getIgnoreTextInputOnNodes()];
  },
});
