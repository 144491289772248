export enum BillingActions {
  UPGRADE = 'upgrade',
  DOWNGRADE = 'downgrade',
  CHURN = 'churn',
  INFO = 'info',
  CANCEL_DOWNGRADE = 'cancel_downgrade',
}

export interface Plan {
  name: string;
  max_subscriptions: number;
}

export interface BillingDetails {
  organization_id: string;
  current_plan: Plan;
  upcoming_plan: Plan;
  subscription_price: string;
  upcoming_subscription_price: string;
  subscription_interval: string;
  upcoming_subscription_interval: string;
  organization_max_subscriptions: number;
  upcoming_max_subscriptions?: number;
  next_billing_date: string;
  upcoming_subscription_start_date: string;
  no_plan_change: boolean;
}
