import { PLAN } from '@/utils/plans';

export interface PlanPriceOption {
  id: string;
  max_subscriptions: number;
  interval: PlanPrice['interval'];
}

export interface PlanPrice {
  id: string;
  active_subscriptions: number;
  max_subscriptions: number;
  organization_max_subscriptions: number;
  interval: 'month' | 'year' | 'one_time';
  plan_name: PLAN;
  has_next_tier: boolean;
  has_previous_tier: boolean;
  unit_amount: number;
  legacy: boolean;
}

export const PlanPriceTierSizes = [1_000, 2_500, 5_000, 10_000, 25_000, 50_000, 75_000, 100_000];
