import { useCurrentPublication } from '@/hooks';

import SectionRenderer from '../../helpers/SectionRenderer';
import { RenderCondition } from '../../RenderCondition';
import { AttributeSettingProps } from '../../types';
import { AlignmentSettings } from '../AlignmentSettings';
import { BooleanSettings } from '../BooleanSettings';
import BorderSettings from '../BorderSettings';
import { ColorSettings } from '../ColorSettings';
import DividerSettings from '../DividerSettings';
import { GapSettings } from '../GapSettings';
import { GridColumnSetting } from '../GridColumnSetting';
import { IconPlacementSettings } from '../IconPlacementSettings';
import { IconSettings } from '../IconSettings';
import { LineClampSettings } from '../LineClampSettings';
import { PaddingSettings } from '../PaddingSettings';
import ShadowSettings from '../ShadowSettings';
import { SimpleBorderRadiusSettings } from '../SimpleBorderRadiusSettings';
import { SimpleFontSizeSettings } from '../SimpleFontSizeSettings';
import { TransformSettings } from '../TransformSettings';

import { PostsSelectionSettings } from './PostsSelectionSettings';
import { StructureSettings } from './StructureSettings';
import { TimestampSettings } from './TimestampSettings';

export const PostSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  const { data: currentPublication } = useCurrentPublication();

  const { activeNodeAttributes } = activeNodeResult;

  return (
    <RenderCondition editor={editor} allowedNodeTypes={['post']}>
      <SectionRenderer title="Posts" defaultIsOpen={false}>
        <PostsSelectionSettings editor={editor} activeNodeResult={activeNodeResult} />
      </SectionRenderer>

      <SectionRenderer title="Structure" defaultIsOpen={false}>
        <StructureSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          allowedProperties={['div', 'image', 'tags', 'titles', 'authors', 'timestamp', 'premium']}
        />
      </SectionRenderer>

      <SectionRenderer title="Layout" defaultIsOpen={false}>
        <GridColumnSetting editor={editor} activeNodeResult={activeNodeResult} title="Columns" />
        <GapSettings editor={editor} activeNodeResult={activeNodeResult} property="gap" title="Gap" />
        <DividerSettings editor={editor} activeNodeResult={activeNodeResult} />
      </SectionRenderer>

      <SectionRenderer title="Pagination" defaultIsOpen={false}>
        <BorderSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          properties={{
            color: 'paginateButtonBorderColor',
            style: 'paginateButtonBorderStyle',
            width: 'paginateButtonBorderWidth',
            radius: 'paginateButtonBorderRadius',
          }}
        />
        <PaddingSettings editor={editor} activeNodeResult={activeNodeResult} property="paginateButtonPadding" />
        <ShadowSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          title="Shadow"
          property="paginateButtonShadow"
        />
        <ColorSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          title="Text"
          property="paginateButtonTextColor"
        />
        <ColorSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          title="Background"
          property="paginateButtonBackgroundColor"
          hasGradientPicker
          gradientProperty="paginateButtonBackgroundColor"
        />
        <IconSettings editor={editor} activeNodeResult={activeNodeResult} property="paginateButtonIcon" />
        <IconPlacementSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          title="Icon Placement"
          property="paginateButtonIconPlacement"
        />
        <ColorSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          title="Icon Color"
          property="paginateButtonIconColor"
        />
      </SectionRenderer>

      <SectionRenderer title="Card" defaultIsOpen={false}>
        <AlignmentSettings editor={editor} activeNodeResult={activeNodeResult} />
        <ShadowSettings editor={editor} activeNodeResult={activeNodeResult} property="shadow" title="Shadow" />
        <BorderSettings editor={editor} activeNodeResult={activeNodeResult} />
        <ColorSettings title="Background" property="background" editor={editor} activeNodeResult={activeNodeResult} />
        <PaddingSettings editor={editor} activeNodeResult={activeNodeResult} property="cardPadding" />
      </SectionRenderer>

      <SectionRenderer title="Image" defaultIsOpen={false}>
        <BooleanSettings editor={editor} activeNodeResult={activeNodeResult} property="imageEnabled" title="Enabled" />
      </SectionRenderer>

      <SectionRenderer title="Titles" defaultIsOpen={false}>
        <ColorSettings title="Color" property="titleTextColor" editor={editor} activeNodeResult={activeNodeResult} />
        <LineClampSettings
          title="Lines"
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="titleLineClamp"
        />
        <SimpleFontSizeSettings
          title="Size"
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="titleFontSize"
        />
        <BooleanSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="subtitleShowing"
          title="Subtitle"
        />
        {activeNodeAttributes?.subtitleShowing && (
          <>
            <ColorSettings
              title="Color"
              property="subtitleTextColor"
              editor={editor}
              activeNodeResult={activeNodeResult}
            />
            <LineClampSettings
              title="Lines"
              editor={editor}
              activeNodeResult={activeNodeResult}
              property="subtitleLineClamp"
            />
            <SimpleFontSizeSettings
              title="Size"
              editor={editor}
              activeNodeResult={activeNodeResult}
              property="subtitleFontSize"
            />
          </>
        )}
      </SectionRenderer>

      <SectionRenderer title="Tags" defaultIsOpen={false}>
        <ColorSettings title="Color" property="tagTextColor" editor={editor} activeNodeResult={activeNodeResult} />
        <TransformSettings editor={editor} activeNodeResult={activeNodeResult} property="tagTransform" />
        <BooleanSettings editor={editor} activeNodeResult={activeNodeResult} property="tagBadgeEnabled" title="Badge" />
        {activeNodeAttributes?.tagBadgeEnabled && (
          <>
            <ColorSettings
              title="Background"
              property="tagBackgroundColor"
              editor={editor}
              activeNodeResult={activeNodeResult}
            />
            <SimpleBorderRadiusSettings
              editor={editor}
              activeNodeResult={activeNodeResult}
              property="tagBorderRadius"
            />
          </>
        )}
      </SectionRenderer>

      <SectionRenderer title="Authors" defaultIsOpen={false}>
        <BooleanSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="authorsEnabled"
          title="Enabled"
        />
        <BooleanSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="authorsImagesEnabled"
          title="Images"
        />
        <BooleanSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="authorsTextEnabled"
          title="Text"
        />
        <BooleanSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="authorsTruncateListEnabled"
          title="Truncate List"
        />
        {activeNodeAttributes?.authorsEnabled && (
          <>
            <ColorSettings
              title="Color"
              property="authorsTextColor"
              editor={editor}
              activeNodeResult={activeNodeResult}
            />
            <TransformSettings editor={editor} activeNodeResult={activeNodeResult} property="authorsTextTransform" />
          </>
        )}
      </SectionRenderer>

      <SectionRenderer title="Timestamp" defaultIsOpen={false}>
        <BooleanSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="timestampsEnabled"
          title="Enabled"
        />
        {activeNodeAttributes?.timestampsEnabled && (
          <>
            <TimestampSettings editor={editor} activeNodeResult={activeNodeResult} property="timestampsDisplay" />
            <ColorSettings
              title="Color"
              property="timestampsTextColor"
              editor={editor}
              activeNodeResult={activeNodeResult}
              initialColor={activeNodeAttributes?.timestampsTextColor}
            />
          </>
        )}
      </SectionRenderer>

      {currentPublication?.is_premium_enabled && (
        <SectionRenderer title="Premium" defaultIsOpen={false}>
          <BooleanSettings
            editor={editor}
            activeNodeResult={activeNodeResult}
            property="premiumIconEnabled"
            title="Icon"
          />
          <BooleanSettings
            editor={editor}
            activeNodeResult={activeNodeResult}
            property="premiumTextEnabled"
            title="Text"
          />
          <BooleanSettings
            editor={editor}
            activeNodeResult={activeNodeResult}
            property="premiumBadgeEnabled"
            title="Badge"
          />
          <ColorSettings
            title="Color"
            property="premiumTextColor"
            editor={editor}
            activeNodeResult={activeNodeResult}
            initialColor={activeNodeAttributes?.premiumTextColor}
          />
          {activeNodeAttributes?.premiumBadgeEnabled && (
            <ColorSettings
              title="Background"
              property="premiumBackgroundColor"
              editor={editor}
              activeNodeResult={activeNodeResult}
              initialColor={activeNodeAttributes?.premiumBackgroundColor}
            />
          )}
          {activeNodeAttributes?.premiumTextEnabled && (
            <TransformSettings editor={editor} activeNodeResult={activeNodeResult} property="premiumTextTransform" />
          )}
        </SectionRenderer>
      )}
    </RenderCondition>
  );
};
