import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { DEFAULT_BORDER_COLOR, DEFAULT_BORDER_RADIUS } from '../constants';
import { Section } from '../Section';

import { ContainerView } from './view/ContainerView';

export const Container = Node.create({
  name: 'container',

  group: 'block',

  content: 'block*',

  selectable: true,

  draggable: true,

  addAttributes() {
    return {
      textAlign: {
        default: 'center',
        parseHTML: (element) => element.getAttribute('data-text-align'),
        renderHTML: (attributes) => ({
          'data-text-align': attributes.textAlign,
        }),
      },
      backgroundColor: {
        default: '#FFFFFF00',
        parseHTML: (element) => element.getAttribute('data-background-color'),
        renderHTML: (attributes) => ({
          'data-background-color': attributes.backgroundColor,
        }),
      },
      shadow: {
        default: 'none',
        parseHTML: (element) => element.getAttribute('data-shadow'),
        renderHTML: (attributes) => ({ 'data-shadow': attributes.shadow }),
      },
      blur: {
        default: '0px',
        parseHTML: (element) => element.getAttribute('data-blur'),
        renderHTML: (attributes) => ({
          'data-blur': attributes.blur,
        }),
      },
      backgroundImage: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-background-image'),
        renderHTML: (attributes) => ({
          'data-background-image': attributes.backgroundImage,
        }),
      },
      backgroundDarken: {
        default: false,
        parseHTML: (element) => element.getAttribute('data-background-darken'),
        renderHTML: (attributes) => ({
          'data-background-darken': attributes.backgroundDarken,
        }),
      },
      horizontalAlign: {
        default: 'left',
        parseHTML: (element) => element.getAttribute('data-horizontal-align'),
        renderHTML: (attributes) => ({
          'data-horizontal-align': attributes.horizontalAlign,
        }),
      },
      verticalAlign: {
        default: 'align_center',
        parseHTML: (element) => element.getAttribute('data-vertical-align'),
        renderHTML: (attributes) => ({
          'data-vertical-align': attributes.verticalAlign,
        }),
      },
      stackOnMobile: {
        default: true,
        parseHTML: (element) => element.getAttribute('data-stack-on-mobile'),
        renderHTML: (attributes) => ({
          'data-stack-on-mobile': attributes.stackOnMobile,
        }),
      },
      width: {
        default: '100%',
        parseHTML: (element) => element.getAttribute('data-width'),
        renderHTML: (attributes) => ({
          'data-width': attributes.width,
          style: `width: ${attributes.width}`,
        }),
      },
      height: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-height'),
        renderHTML: (attributes) => ({
          'data-height': attributes.height,
          style: `height: ${attributes.height}`,
        }),
      },
      width_max: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-width-max'),
        renderHTML: (attributes) => ({
          'data-width-max': attributes.width_max,
          style: `max-width: ${attributes.width_max || '100%'}`,
        }),
      },
      padding: {
        default: '0px 0px 0px 0px',
        parseHTML: (element) => element.getAttribute('data-padding'),
        renderHTML: (attributes) => ({
          'data-padding': attributes.padding,
          style: `--padding: ${attributes.padding || '40px 0px 40px 0px'};--padding-mobile: ${
            attributes.padding
          };--padding-root: ${attributes.padding || '40px 30px 40px 30px'};--padding-root-mobile: ${
            attributes.padding || '5px 30px 5px 30px'
          }`,
        }),
      },
      margin: {
        default: '0px 0px 0px 0px',
        parseHTML: (element) => element.getAttribute('data-margin'),
        renderHTML: (attributes) => ({
          'data-margin': attributes.margin,
          style: `margin: ${attributes.margin}`,
        }),
      },
      gap: {
        parseHTML: (element) => element.getAttribute('data-gap'),
        renderHTML: (attributes) => ({
          'data-gap': attributes.gap,
          style: `--gap: ${attributes.gap || '30px'};--gap-mobile: ${attributes.gap || '16px'};--gap-mobile-vertical: ${
            attributes.gap || '10px'
          }`,
        }),
      },
      borderRadius: {
        default: DEFAULT_BORDER_RADIUS,
        renderHTML: (attributes) => ({ 'data-border-radius': attributes.borderRadius }),
        parseHTML: (element) => element.getAttribute('data-border-radius') || DEFAULT_BORDER_RADIUS,
      },
      borderWidth: {
        default: '1px',
        renderHTML: (attributes) => ({ 'data-border-width': attributes.borderWidth }),
        parseHTML: (element) => element.getAttribute('data-border-width') || '1px',
      },
      borderColor: {
        default: DEFAULT_BORDER_COLOR,
        renderHTML: (attributes) => ({ 'data-border-color': attributes.borderColor }),
        parseHTML: (element) => element.getAttribute('data-border-color') || DEFAULT_BORDER_COLOR,
      },
      borderStyle: {
        default: 'solid',
        renderHTML: (attributes) => ({ 'data-border-style': attributes.borderStyle }),
        parseHTML: (element) => element.getAttribute('data-border-style') || 'none',
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  addOptions() {
    return {
      HTMLAttributes: {
        'data-type': this.name,
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(ContainerView);
  },
});

export default Section;
