import { imageUrl1, imageUrl2, imageUrl3, imageUrl4, imageUrl5, imageUrl6 } from "./images";

const TESTIMONIAL_1 = {
  id: 'testimonial123',
  name: 'Oliver Buchannon',
  description: 'Tech Enthusiast',
  message: 'I look forward to every edition! The content is always insightful, and the layout makes it so easy to read.',
  picture_url: imageUrl1,
};

const TESTIMONIAL_2 = {
  id: 'testimonial456',
  name: 'Kyra Singh',
  description: 'Marketing Professional',
  message: 'The newsletters are a perfect mix of education and entertainment. They brighten up my inbox every week!',
  picture_url: imageUrl2,
};

const TESTIMONIAL_3 = {
  id: 'testimonial789',
  name: 'Natalia Mirescu',
  description: 'Product Manager',
  message: 'I appreciate how every email feels relevant to my interests. It’s like the team really understands what I want to read.',
  picture_url: imageUrl3,
};

const TESTIMONIAL_4 = {
  id: 'testimonial101',
  name: 'Liam McKenzie',
  description: 'Logistics Specialist',
  message: 'The writing style is refreshing, and the updates are always timely. I’ve even shared some newsletters with my team.',
  picture_url: imageUrl4,
};

const TESTIMONIAL_5 = {
  id: 'testimonial1234',
  name: 'Catalina Vidal',
  description: 'Creative Designer',
  message: 'I love the visuals and design of the newsletter. It’s one of the few emails I open immediately when it lands in my inbox!',
  picture_url: imageUrl5,
};

const TESTIMONIAL_6 = {
  id: 'testimonial12345',
  name: 'Alessandro Rossi',
  description: 'Tech Savvy Reader',
  message: 'The newsletters always have something new to offer. Whether it’s trends or tips, I never miss an issue!',
  picture_url: imageUrl6,
};

const TESTIMONIALS = [TESTIMONIAL_1, TESTIMONIAL_2, TESTIMONIAL_3, TESTIMONIAL_4, TESTIMONIAL_5, TESTIMONIAL_6];

export { TESTIMONIALS };