import { useQuery } from 'react-query';

import { Tier } from '@/interfaces/tier';
import api from '@/services/swarm';

const useTiers = (publicationId: string, {
  onlyActive = false,
  excludeDonation = false,
  requireRecurringPrice = false,
  includeActiveSubscriptionsCount = false,
}: {
  onlyActive?: boolean;
  requireRecurringPrice?: boolean;
  excludeDonation?: boolean;
  includeActiveSubscriptionsCount?: boolean;
} = {}) => {
  return useQuery<Tier[]>(
    ['publication_settings', 'premium', 'tiers', publicationId, onlyActive, requireRecurringPrice],
    () =>
      api
        .get(`/tiers`, {
          params: {
            publication_id: publicationId,
            only_active: onlyActive,
            exclude_donation: excludeDonation,
            require_recurring_price: requireRecurringPrice,
            include_active_subscriptions_count: includeActiveSubscriptionsCount,
          },
        })
        .then((res) => res.data),
    {
      enabled: !!publicationId,
    }
  );
};

export default useTiers;
