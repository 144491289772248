/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

import MarkDownHelperText from '@/pages/DesignLab/components/MardownHelperText';

import ErrorText from '../../components/Form/ErrorText';
import { Typography } from '../../components/Typography';
import HelperText from '../HelperText';

export interface Props extends React.HTMLProps<HTMLInputElement> {
  name: string;
  className?: string;
  error?: boolean;
  errorText?: string;
  helperText?: string | React.ReactNode;
  inputClassOverride?: string;
  inputClassName?: string;
  labelText?: string;
  leadingIcon?: React.ReactNode;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  leadingText?: string;
  placeholderText?: string;
  readOnly?: boolean;
  readOnlyText?: string;
  supportsMarkdown?: boolean;
  trailingButton?: React.ReactNode;
  trailingIcon?: React.ReactNode;
  trailingText?: string;
  type?: string;
  value?: string;
  variant?: 'primary' | 'secondary';
}

const CLASS_MAP = Object.freeze({
  primary:
    'appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:shadow-[0px_0px_0px_4px_#E5E7EB,0px_1px_2px_0px_rgba(0,0,0,0.05)] focus:border-surface-300 focus:ring-0 text-sm text-gray-900 placeholder-gray-400',
  secondary:
    'appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:shadow-[0px_0px_0px_4px_#E5E7EB,0px_1px_2px_0px_rgba(0,0,0,0.05)] focus:border-surface-300 focus:ring-0 text-sm text-gray-900 placeholder-gray-400',
});

const TextInput = React.forwardRef((props: Props, ref: React.ForwardedRef<HTMLInputElement>) => {
  const {
    name,
    className,
    disabled = false,
    error,
    errorText,
    helperText,
    inputClassOverride,
    inputClassName,
    labelText,
    leadingIcon,
    leadingText,
    placeholderText,
    readOnly = false,
    readOnlyText,
    required = false,
    supportsMarkdown = false,
    trailingButton,
    trailingIcon,
    trailingText,
    type = 'text',
    value,
    variant = 'primary',
    ...rest
  } = props;

  const hasError = !!(errorText || error);

  return (
    <div className={cx('w-full', className)}>
      {labelText && (
        <label htmlFor={name} className="flex items-center gap-1 text-sm font-medium text-gray-900">
          {labelText}
          {required ? ' *' : ''}
          {supportsMarkdown && (
            <Typography token="font-light/text/xs" colorWeight="400" as="span">
              <MarkDownHelperText />
            </Typography>
          )}
        </label>
      )}
      <div className={cx(labelText ? 'mt-1' : '', readOnlyText && 'flex flex-row w-full')}>
        <div className="flex items-center w-full relative">
          {(leadingText || readOnlyText) && (
            <span
              className={cx(
                'inline-flex h-auto items-center px-3 py-2 border border-surface-200 border-r-0 rounded-l-md shadow-sm text-gray-600 text-sm whitespace-nowrap relative',
                { ' bg-surface-100': disabled, 'bg-surface-50': !disabled }
              )}
            >
              {leadingText || readOnlyText}
            </span>
          )}
          {leadingIcon && (
            <span className="absolute left-0 inline-flex w-auto h-auto items-center px-3 py-2 text-sm whitespace-nowrap text-surface-500 z-40">
              {leadingIcon}
            </span>
          )}
          <input
            ref={ref}
            name={name}
            type={type}
            value={value}
            placeholder={placeholderText}
            required={required}
            disabled={disabled}
            readOnly={readOnly}
            className={cx(
              'flex-grow relative',
              !hasError && !disabled && 'border-surface-200',
              inputClassOverride || CLASS_MAP[variant],
              inputClassName,
              hasError &&
                'focus:border-surface-300 !border-feedback-danger-600 !focus:border-feedback-danger-600 focus:shadow-[0px_0px_0px_4px_#FECACA,_0px_1px_2px_0px_rgba(0,0,0,0.05)] pr-10 z-30',
              disabled && 'bg-surface-100 border-surface-200 text-gray-400 cursor-not-allowed',
              (leadingText || readOnlyText) && 'rounded-l-none z-30',
              (trailingText || trailingButton) && 'rounded-r-none z-30',
              leadingIcon && 'pl-10 z-30',
              trailingIcon && 'pr-10 z-30'
            )}
            style={{
              height: 'inherit',
            }}
            {...rest}
          />
          {trailingIcon && (
            <span className="absolute right-0 inline-flex w-auto h-auto items-center px-3 py-2 text-sm whitespace-nowrap text-surface-500 z-40">
              {trailingIcon}
            </span>
          )}
          {error && !trailingText && !trailingButton && (
            <div className={cx('absolute right-0 mr-3 text-feedback-danger-600 z-50', trailingText && 'mr-20')}>
              <ExclamationCircleIcon width={20} height="auto" />
            </div>
          )}
          {trailingText && (
            <span
              className={cx(
                'inline-flex h-auto items-center px-3 py-2 border border-l-0 rounded-r-md shadow-sm text-gray-600 text-sm whitespace-nowrap relative border-surface-200',
                !error && 'border-gray-300',
                error && 'border-feedback-danger-600',
                { 'bg-surface-100': disabled, 'bg-surface-50': !disabled }
              )}
            >
              {error && (
                <div className={cx('absolute left-0 -ml-8 text-feedback-danger-600 z-50', trailingText && 'mr-20')}>
                  <ExclamationCircleIcon width={20} height="auto" />
                </div>
              )}
              {trailingText}
            </span>
          )}
          {trailingButton && (
            <span className="inline-flex items-center text-sm whitespace-nowrap relative shadow-sm">
              {error && (
                <div className={cx('absolute left-0 -ml-8 text-feedback-danger-600 z-50', trailingText && 'mr-20')}>
                  <ExclamationCircleIcon width={20} height="auto" />
                </div>
              )}
              {trailingButton}
            </span>
          )}
        </div>
        {helperText && (
          <HelperText className={cx('mt-2', hasError && '!text-feedback-danger-600')}>{helperText}</HelperText>
        )}
        {errorText && <ErrorText className="mt-2">{errorText}</ErrorText>}
      </div>
    </div>
  );
});

export default TextInput;
