import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import cx from 'classnames';
import qs from 'query-string';
import styled from 'styled-components';

import { Divider } from '@/components/Divider';
import { Input } from '@/components/Form';
import SniperLink from '@/components/SniperLink';
import { Typography } from '@/components/Typography';
import { useAuth } from '@/context/auth-context';
import { useOAuth } from '@/context/oauth-context';
import { ApiError } from '@/interfaces/api';
import { Button } from '@/ui/Button';
import analytics from '@/utils/analytics';

import { Layout, LogoWrapper } from './_components/Layout';

export const LoginButtonStyled = styled(Button)`
  background-color: #209bf1;
  box-shadow: 6px 6px 0px rgba(32, 155, 241, 0.25);
  font-size: 22px;
  line-height: 41px;
  font-weight: 600;
  text-align: center;
`;

const SUPPORT_LINK_MESSAGES: string[] = ['This account is scheduled for deletion.'];

interface ErrorMessage {
  message: string;
  includeSupportLink: boolean;
}

interface ApiErrorResponse {
  status: number;
  data: {
    errors: ApiError[];
  };
}

const getErrorMessage = (
  errorData: ApiError[],
  status: number,
  needsOtp: boolean,
  needsDeviceConfirmationCode: boolean
): ErrorMessage => {
  if (status === 500 || errorData.length === 0) {
    return { message: 'Unexpected Error', includeSupportLink: false };
  }

  if (needsOtp || needsDeviceConfirmationCode) {
    return { message: 'Code is invalid', includeSupportLink: false };
  }

  const error = errorData[0];

  if (error.field === 'password') {
    return { message: 'Email or password is incorrect', includeSupportLink: false };
  }

  if (error.field === 'base') {
    const includeSupportLink = SUPPORT_LINK_MESSAGES.includes(error.message);
    return { message: error.message, includeSupportLink };
  }

  const fieldName = error.field.charAt(0).toUpperCase() + error.field.slice(1);
  return { message: `${fieldName} ${error.message}`, includeSupportLink: false };
};

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [deviceConfirmationCode, setDeviceConfirmationCode] = useState('');
  const [title, setTitle] = useState('Log In to beehiiv');
  const [subtitle, setSubtitle] = useState('');
  const [error, setError] = useState<JSX.Element | null>(null);
  const isDisabled = () => !email?.match(/^.+@.+$/) || !password;

  const { login, needsOtp, needsDeviceConfirmationCode, loginWithToken } = useAuth();
  const { loginWithGoogleCredential } = useOAuth();

  useEffect(() => {
    if (needsOtp) {
      setTitle('Enter Two-Factor Code');
      setSubtitle('Please enter the 6 digit code from your Two-Factor Authentication app');
    }

    if (needsDeviceConfirmationCode) {
      setTitle('Enter the Device Confirmation Code');
      setSubtitle(
        'Please enter the 6-7 character code in the the Device Confirmation email that was sent to your email address.'
      );
    }
  }, [needsOtp, needsDeviceConfirmationCode]);

  const navigate = useNavigate();

  const getErrorMessageElement = (errorResponse: ApiErrorResponse): JSX.Element => {
    const embeddedSupportLinkText = 'contact support';
    const errorMessage = getErrorMessage(
      errorResponse?.data?.errors,
      errorResponse.status,
      needsOtp,
      needsDeviceConfirmationCode
    );

    if (errorMessage.includeSupportLink) {
      return (
        <span>
          {errorMessage.message}
          {' Please '}
          <button
            type="button"
            onClick={window.adaEmbed.toggle}
            className="text-gray-600 font-medium underline hover:text-gray-500 italic"
          >
            {embeddedSupportLinkText}
          </button>{' '}
          for more assistance.
        </span>
      );
    }

    return <span>{errorMessage.message}</span>;
  };

  const handleLogin = () => {
    if (email && password) {
      login.mutate(
        { email, password, otp, deviceConfirmationCode },
        {
          onSuccess: (data: any) => {
            if (data?.code === 'OTP_REQUIRED') {
              return;
            }
            if (data?.code === 'DEVICE_CONFIRMATION_REQUIRED') {
              return;
            }

            const queryParams = new URLSearchParams(window.location.search);
            const redirectUrl = typeof queryParams.get('redirect') === 'string' ? queryParams.get('redirect') : '/';

            let method = 'password';
            let type = 'returning';

            if (data?.code) {
              method = 'otp';
            }

            if (localStorage.getItem('newUser')) {
              type = 'new';
              localStorage.removeItem('newUser');
            }

            analytics.track('Signed In', {
              method,
              type,
            });

            if (redirectUrl && typeof redirectUrl === 'string' && redirectUrl !== '/') {
              const redirectUrlWithSearchParams = new URL(redirectUrl, window.location.origin);
              const currentLocationSearchParams = new URLSearchParams(window.location.search);
              const spid = currentLocationSearchParams.get('spid');
              const pcpid = currentLocationSearchParams.get('pcpid');

              if (typeof spid === 'string') {
                redirectUrlWithSearchParams.searchParams.set('spid', spid);
              }
              if (typeof pcpid === 'string') {
                redirectUrlWithSearchParams.searchParams.set('pcpid', pcpid);
              }

              const fullPath = redirectUrlWithSearchParams.pathname + redirectUrlWithSearchParams.search;
              navigate(fullPath);
            } else {
              navigate('/');
            }
          },
          onError: (err: any) => {
            setError(getErrorMessageElement(err.response));
          },
        }
      );
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    handleLogin();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'email') {
      setEmail(e.target.value);
    } else if (e.target.name === 'password') {
      setPassword(e.target.value);
    } else if (e.target.name === 'otp') {
      setOtp(e.target.value);
      setError(null);
    } else if (e.target.name === 'device_confirmation_code') {
      setDeviceConfirmationCode(e.target.value);
    }
  };

  useEffect(() => {
    if (otp.length === 6) {
      handleLogin();
    }
  }, [otp]);

  return (
    <>
      <Helmet>
        <title>Login - beehiiv</title>
      </Helmet>

      <Layout>
        <LogoWrapper title={title} subtitle={subtitle}>
          <form onSubmit={handleSubmit} className="w-full">
            {!needsOtp && !needsDeviceConfirmationCode && (
              <div>
                <Input
                  required
                  className="w-full"
                  variant="primary"
                  labelText="Email"
                  name="email"
                  placeholderText="name@email.com"
                  autoComplete="email"
                  value={email}
                  onChange={handleChange}
                />
              </div>
            )}
            {!needsOtp && !needsDeviceConfirmationCode && (
              <div className="pt-6">
                <Input
                  required
                  className="w-full"
                  variant="primary"
                  labelText="Password"
                  type="password"
                  placeholderText="********"
                  name="password"
                  autoComplete="current-passowrd"
                  value={password}
                  onChange={handleChange}
                />
              </div>
            )}
            {needsOtp && (
              <div>
                <Input
                  required
                  className="w-full"
                  variant="primary"
                  placeholder="000 000"
                  inputClassName="text-center"
                  maxLength={6}
                  name="otp"
                  value={otp}
                  onChange={handleChange}
                />
              </div>
            )}
            {needsDeviceConfirmationCode && (
              <div className="flex flex-col gap-6">
                <SniperLink recipient={email} sender="support@beehiiv.com" />
                <Input
                  required
                  className="w-full"
                  variant="primary"
                  labelText="Device Confirmation Code"
                  inputClassName="text-center"
                  maxLength={7}
                  name="device_confirmation_code"
                  value={deviceConfirmationCode}
                  onChange={handleChange}
                />
              </div>
            )}
            {!needsOtp && (
              <div className="pt-6">
                <Button type="submit" disabled={isDisabled()} loading={login.isLoading} className="w-full">
                  {login.isLoading ? 'Signing In...' : 'Sign in'}
                </Button>
              </div>
            )}
            {login.isError && (
              <p
                className={cx(
                  'text-red-500 text-xs italic mt-2',
                  needsOtp || needsDeviceConfirmationCode ? 'text-center' : 'text-left'
                )}
              >
                {error}
              </p>
            )}
            {window.env.REACT_APP_ALLOW_GOOGLE_LOGIN && !needsOtp && !needsDeviceConfirmationCode && (
              <>
                <div className="pt-4">
                  <Divider>
                    <Typography token="font-normal/text/base">Or continue with</Typography>
                  </Divider>
                </div>
                <div className="pt-4 flex flex-row justify-center">
                  <GoogleLogin
                    size="large"
                    onSuccess={async (credentialResponse) => {
                      loginWithGoogleCredential.mutate(
                        { idToken: credentialResponse.credential },
                        {
                          onSuccess: (data: any) => {
                            const queryParams = qs.parse(window.location.search);
                            const { redirect } = queryParams;
                            let type = 'returning';

                            if (localStorage.getItem('newUser')) {
                              type = 'new';
                              localStorage.removeItem('newUser');
                            }

                            analytics.track('Signed In', {
                              method: 'sso',
                              type,
                            });

                            if (data?.requires_phone_verification) {
                              localStorage.setItem('signupToken', data.token);
                              navigate('/signup/phone_collection', {
                                state: { redirect },
                              });
                              return;
                            }

                            loginWithToken({ token: data.token, userId: data.user.id, email: data.user.email });

                            if (redirect && typeof redirect === 'string' && redirect !== '/') {
                              navigate(redirect);
                            } else {
                              navigate('/');
                            }
                          },
                          onError: (err: any) => {
                            if (err.response?.status === 404) {
                              window.location.href = 'https://www.beehiiv.com/pricing';
                              return;
                            }
                            setError(getErrorMessageElement(err.response));
                          },
                        }
                      );
                    }}
                    onError={() => {
                      console.log('Login Failed');
                    }}
                    useOneTap
                  />
                </div>
              </>
            )}
            {!needsOtp && !needsDeviceConfirmationCode && (
              <div className="text-center pt-4">
                <div className="text-sm">
                  <Link
                    className="block mb-2 text-action-secondary-600 font-medium underline"
                    to={`/request_password_reset${email ? `?email=${email}` : ''}`}
                  >
                    Forgot your password?
                  </Link>
                  <span className="text-sm text-left">
                    Don’t have a beehiiv account?{' '}
                    <a
                      className="text-action-secondary-600 font-medium underline"
                      href="https://www.beehiiv.com/pricing"
                    >
                      Create one
                    </a>
                  </span>
                </div>
              </div>
            )}
          </form>
        </LogoWrapper>
      </Layout>
    </>
  );
}
