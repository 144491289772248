import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import api from '@/services/swarm';

interface ApiResponse {}

export default function useNotificationsMarkAllAsRead() {
  const [publicationId] = useCurrentPublicationState(false);

  const markAllAsRead = (): Promise<ApiResponse> => {
    return api
      .post(`/notifications/mark_all_as_read`, {
        publication_id: publicationId,
      })
      .then((res) => res.data);
  };

  const queryClient = useQueryClient();

  return useMutation(markAllAsRead, {
    onSuccess: () => {
      toast.success('All notifications marked as read');
      queryClient.invalidateQueries([publicationId, 'notifications']);
    },
  });
}
