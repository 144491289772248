import { ArrowUpRightIcon } from '@heroicons/react/20/solid';

import LoadingBox from '@/components/LoadingBox';
import { Typography } from '@/components/Typography';
import { useStripeBillingSession } from '@/hooks/useBilling';
import { BillingActions } from '@/interfaces/billing';
import BodyContainer from '@/routes/settings/_components/BodyContainer';
import CardHeader from '@/routes/settings/_components/CardHeader';
import { Button } from '@/ui/Button';
import { Card } from '@/ui/Card';
import { capitalize } from '@/utils';

import { useBillingSummary } from '../../_hooks';

const BillingDetails: React.FC<{ organizationId: string; publicationId: string }> = ({
  organizationId,
  publicationId,
}) => {
  const { data, isLoading, isError } = useBillingSummary({ organizationId, publicationId });

  const { billing_details: billingDetails } = data || {};
  const { line1, line2, state, city, country, postal_code: postalCode } = billingDetails?.address || {};

  const stripeInfoSession = useStripeBillingSession({ organizationId, publicationId, action: BillingActions.INFO });

  return (
    <Card>
      <BodyContainer>
        <CardHeader
          title="Billing Details"
          cta={
            <Button
              type="button"
              size="xs"
              variant="primary-inverse"
              iconRight
              Icon={ArrowUpRightIcon}
              loading={stripeInfoSession.isLoading}
              onClick={() => stripeInfoSession.mutate({})}
            >
              Update Billing Info
            </Button>
          }
        />
        <LoadingBox isLoading={isLoading} isError={isError} backgroundClassName="bg-transparent" height={300}>
          {billingDetails && (
            <div className="space-y-4">
              <div className="space-y-2">
                <Typography as="p" token="font-medium/text/base">
                  Billing Information
                </Typography>
                <div className="bg-surface-50 border border-surface-200 rounded px-3 py-2">
                  <Typography as="p" token="font-normal/text/base" colorWeight="400">
                    Name: {billingDetails.name || '-'}
                    <br />
                    <br />
                    Email: {billingDetails.email || '-'}
                    <br />
                    <br />
                    Address:
                    <br />
                    {line1 && (
                      <>
                        {line1}
                        <br />
                      </>
                    )}
                    {line2 && (
                      <>
                        {line2}
                        <br />
                      </>
                    )}
                    {city && state && postalCode && (
                      <>
                        {city}, {state} {postalCode}
                        <br />
                      </>
                    )}
                    {country && (
                      <>
                        {country}
                        <br />
                      </>
                    )}
                    <br />
                    Phone: {billingDetails.phone || '-'}
                    <br />
                    <br />
                    Tax IDs: {billingDetails.tax_ids?.join(',') || '-'}
                  </Typography>
                </div>
              </div>
              {billingDetails.brand && billingDetails.last4 && (
                <div className="space-y-2">
                  <Typography as="p" token="font-medium/text/base">
                    Payment Method
                  </Typography>
                  <div className="bg-surface-50 border border-surface-200 rounded px-3 py-2">
                    <Typography as="p" token="font-normal/text/base" colorWeight="400">
                      Credit Card: {capitalize(billingDetails.brand || '')} ending in {billingDetails.last4}
                      <br />
                      Expires {billingDetails.exp_month}/{billingDetails.exp_year}
                    </Typography>
                  </div>
                </div>
              )}
            </div>
          )}
        </LoadingBox>
      </BodyContainer>
    </Card>
  );
};

export default BillingDetails;
