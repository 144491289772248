import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import api from '@/services/swarm';

interface Props {
  publicationId: string;
  organizationId: string;
  newPlanPriceId?: string;
  onSuccess?: () => void;
}

const useCreatePlanPriceChange = ({ organizationId, publicationId, onSuccess }: Props) => {
  return useMutation<any, unknown, { newPlanPriceId: string }>(
    ({ newPlanPriceId }) =>
      api
        .post(`/organizations/${organizationId}/plan_price_change`, {
          publication_id: publicationId,
          new_plan_price_id: newPlanPriceId,
        })
        .then((res) => res.data),
    {
      onSuccess: () => {
        onSuccess?.();
      },
      onError: (err: any) => {
        toast.error(err?.response?.data?.error || 'Something went wrong, please try again.');
      },
    }
  );
};

export default useCreatePlanPriceChange;
