import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Resizable } from 'react-resizable';
import { ImageElement } from '@shared/dream-components';
import { NodeViewProps } from '@tiptap/core';
import { NodeViewContent } from '@tiptap/react';

import { getDataAttributes } from '../../utils';

import { ImageUpload } from './ImageUpload';

export const ImageBlockView: FC<NodeViewProps> = (nodeViewProps) => {
  const { HTMLAttributes, node, selected, editor, getPos } = nodeViewProps;
  const imageRef = useRef<HTMLDivElement | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [image, setImage] = useState<string | null>(null);

  const { id, src } = node.attrs;

  useEffect(() => {
    if (src) {
      // append the base URL to the src
      if (window?.env?.REACT_APP_HEROKU_ENV === 'development') {
        setImage(`${window?.env?.REACT_APP_API_BASE_URL}${src}`);
      } else {
        setImage(src);
      }
    } else {
      setImage(null);
    }
  }, [src]);

  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (timeoutRef.current) return;
    const setInitialHeightAndWidth = () => {
      if (imageRef.current) {
        const rect = imageRef.current.getBoundingClientRect();

        if (rect.height > 0 && rect.width > 0) {
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }
          setHeight(rect.height);
          setWidth(rect.width);
        } else {
          timeoutRef.current = setTimeout(setInitialHeightAndWidth, 100);
        }
      } else {
        timeoutRef.current = setTimeout(setInitialHeightAndWidth, 100);
      }
    };

    timeoutRef.current = setTimeout(setInitialHeightAndWidth, 100);
  }, []);

  const handleResizeStop = useCallback(
    (e: any, data: any) => {
      if (!selected) return;

      setHeight(data.size.height);
      setWidth(data.size.width);

      // set width & height attribute
      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(getPos(), 'height', data.size.height);
        tr.setNodeAttribute(getPos(), 'width', data.size.width);
        return true;
      });
    },
    [editor, getPos, selected]
  );

  const handleResize = useCallback(
    (e: any, data: any) => {
      if (!selected) return;

      setHeight(data.size.height);
      setWidth(data.size.width);

      imageRef.current!.style.width = `${data.size.width}px`;
      imageRef.current!.style.height = `${data.size.height}px`;
    },
    [selected]
  );

  if (!image) {
    return <ImageUpload {...nodeViewProps} />;
  }

  return (
    <ImageElement
      ref={imageRef}
      element={{
        type: 'image',
        id,
        attrs: {
          id,
          ...node.attrs,
          src: image || undefined,
        },
        content: [{ id: 'text', text: '' }],
      }}
      attributes={{
        'data-node-view-wrapper': true,
        ...getDataAttributes(HTMLAttributes),
      }}
    >
      {selected && (
        <>
          {/* pill shaped resize handle left and right */}
          {height && width && selected && editor.isActive('image') && (
            <Resizable
              draggableOpts={{ grid: [10] }}
              lockAspectRatio
              height={height}
              width={width}
              axis="x"
              onResizeStop={handleResizeStop}
              onResize={handleResize}
              resizeHandles={['w', 'e']}
            >
              <div className="absolute top-0 left-0 h-full w-full" />
            </Resizable>
          )}
          {/* Show the image dimensions when the image is selected */}
          {height && width && selected && editor.isActive('image') && (
            <div className="absolute bottom-2 left-1/2 -translate-x-1/2 text-xs text-gray-500 border border-border rounded-full px-2 py-1 bg-gray-300">
              {Math.round(height)}px x {Math.round(width)}px
            </div>
          )}
        </>
      )}
      <NodeViewContent />
    </ImageElement>
  );
};
