import { Image } from '../../../extensions';
import SectionRenderer from '../../helpers/SectionRenderer';
import { RenderCondition } from '../../RenderCondition';
import { AttributeSettingProps } from '../../types';
import BorderSettings from '../BorderSettings';
import { SimpleTextSettings } from '../SimpleTextSettings';

import { ImageSrcSettings } from './ImageSrcSettings';

export const ImageSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  return (
    <RenderCondition editor={editor} allowedNodeTypes={[Image.name]}>
      <SectionRenderer title="Media">
        <ImageSrcSettings editor={editor} activeNodeResult={activeNodeResult} />
        <SimpleTextSettings editor={editor} activeNodeResult={activeNodeResult} title="Alt Text" property="alt" />
        <BorderSettings editor={editor} activeNodeResult={activeNodeResult} />
      </SectionRenderer>
    </RenderCondition>
  );
};
