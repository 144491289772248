import { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowPathIcon, ChartBarIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';
import moment from 'moment-mini';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';

import Form from '@/components/_domain/PollForm';
import ActionModal from '@/components/ActionModal';
import Badge from '@/components/Badge';
import PageHeading from '@/components/Layout/PageLayout/PageHeading';
import LoadingBox from '@/components/LoadingBox';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import OptionsDropdown from '@/components/OptionsDropdown';
import PostSelect from '@/components/PostSelect';
import { EmptyCard } from '@/components/ResourceList';
import SlideOver from '@/components/SlideOver';
import TableHeaders from '@/components/TableHeaders';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { usePolls, useRequireSetting } from '@/hooks';
import useTimePeriodSelect from '@/hooks/useTimePeriodSelect';
import { useTutorial } from '@/hooks/useTutorials';
import { Order, Sort } from '@/interfaces/general';
import { Poll, PollFormQueryParams, PollStatuses } from '@/interfaces/poll';
import { Setting } from '@/interfaces/setting';
import { TutorialType } from '@/interfaces/tutorial';
import PollSearch from '@/models/pollSearch';
import api from '@/services/swarm';
import { Button } from '@/ui/Button';
import SearchInput from '@/ui/SearchInput';
import { TimePeriod } from '@/utils';

const TABLE_HEADERS: Map<string, string> = new Map([
  ['poll.name', 'Poll Name'],
  ['status', 'Status'],
  ['type', 'Type'],
  ['appearances', 'Appearances'],
  ['response_count', 'Responses'],
  ['most_recent_response', 'Latest Response'],
  ['created_at', 'Created'],
  ['poll_options', ''],
]);

const SORTABLE_HEADERS = [
  'poll.name',
  'status',
  'type',
  'appearances',
  'response_count',
  'most_recent_response',
  'created_at',
];

const BADGE_COLORS: any = {
  draft: undefined,
  published: 'success',
};

const COLUMN_SIDE_PADDING = 3;
const TEXT_COLUMN_CLASSES = `px-${COLUMN_SIDE_PADDING} py-4 whitespace-nowrap text-sm text-gray-500`;

const Polls: React.FunctionComponent = () => {
  const tutorial = useTutorial(TutorialType.POLLS);

  const [isOpen, setIsOpen] = useState(false);
  const [shouldResetSearch, setShouldResetSearch] = useState(false);
  const navigate = useNavigate();
  const [publicationId] = useCurrentPublicationState();
  const [selectedPoll, setSelectedPoll] = useState<Poll | undefined>();
  const [deletePollId, setDeletePollId] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);

  const [searchParams, setSearchParams] = useQueryParams<PollFormQueryParams>({
    search: withDefault(StringParam, ''),
    order: withDefault(StringParam, 'created_at'),
    dir: withDefault(StringParam, Order.ASC),
    filteredPost: withDefault(StringParam, 'all'),
    filterByDateCreated: withDefault(StringParam, TimePeriod.ALL_TIME),
    filterByDatePublished: withDefault(StringParam, TimePeriod.ALL_TIME),
  });

  // After triggering search reset, switch boolean back to false
  useEffect(() => {
    if (shouldResetSearch) {
      setShouldResetSearch(false);
    }
  }, [shouldResetSearch, setShouldResetSearch]);

  const query = new PollSearch(searchParams);
  const pollsQuery = usePolls(query);

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, isError } = pollsQuery;
  const polls = data?.pages.flatMap((page) => page.polls) || [];
  const totalPollsCount = data?.pages[0]?.pagination?.total;
  const showingPollsCount = polls?.length || 0;

  const handleSearch = (search: string) => {
    setSearchParams({ ...searchParams, search });
  };

  // Handle post filtering
  const handleFilteredPostChange = (val: string) => {
    const filteredPost = val.toLowerCase();

    setSearchParams({ ...searchParams, filteredPost });
  };

  const handleDateCreatedChange = (filterByDateCreated: TimePeriod) => {
    setSearchParams({ ...searchParams, filterByDateCreated });
  };

  const handleDatePublishedChange = (filterByDatePublished: TimePeriod) => {
    setSearchParams({ ...searchParams, filterByDatePublished });
  };

  // Reset search when no results
  const handleResetSearch = () => {
    setSearchParams({
      ...searchParams,
      search: undefined,
      filteredPost: 'all',
      filterByDateCreated: TimePeriod.ALL_TIME,
      filterByDatePublished: TimePeriod.ALL_TIME,
    });

    setShouldResetSearch(true);
  };

  const handleSortChange = (newSort: Sort) => {
    setSearchParams({ ...searchParams, ...newSort });
  };

  useRequireSetting({ setting: Setting.POLLS, message: 'Upgrade to use polls.', redirectTo: '/polls/overview' });

  const onDeletePollRequested = (pollId: string) => {
    setDeletePollId(pollId);
  };

  const onDeletePollModalClose = () => {
    setDeletePollId('');
  };

  const onDeletePoll = (pollId: string) => {
    const params = {
      publication_id: publicationId,
    };

    setIsDeleting(true);
    api
      .delete(`/polls/${pollId}`, { params })
      .then(() => {
        setDeletePollId('');
        pollsQuery.refetch();
        toast.success('Poll deleted successfully');
      })
      .catch((errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  const pollOptions = useCallback(
    (poll: Poll) => [
      [
        {
          label: 'View',
          onClick: () => navigate(`/polls/${poll.id}`),
        },
        {
          label: 'Edit',
          onClick: () => {
            setSelectedPoll(poll);
            setIsOpen(true);
          },
        },
        {
          label: 'Duplicate',
          onClick: () => {
            api
              .post(`/polls/${poll.id}/duplicate`, {
                publication_id: publicationId,
              })
              .then(() => {
                toast.success('Poll duplicated');
                pollsQuery.refetch();
              })
              .catch((err) => {
                toast.error(err?.response?.data?.error || 'Something went wrong');
              });
          },
        },
      ],
      [
        {
          label: 'Delete',
          color: 'red',
          disabled: poll.is_locked,
          onClick: () => {
            onDeletePollRequested(poll.id);
          },
        },
      ],
    ],
    [navigate, pollsQuery, publicationId]
  );

  const { search, filteredPost, filterByDateCreated, filterByDatePublished, order, dir } = searchParams;

  const { TimePeriodSelect: DateCreated } = useTimePeriodSelect({
    defaultValue: filterByDateCreated as TimePeriod,
    size: 'md',
    label: 'Date Created',
    labelClassName: '-mt-[18px]',
  });
  const { TimePeriodSelect: DatePublished } = useTimePeriodSelect({
    defaultValue: filterByDatePublished as TimePeriod,
    size: 'md',
    label: 'Date Published',
    labelClassName: '-mt-[18px]',
  });

  const isNoResults = !isLoading && polls.length === 0;

  const searchIsSet =
    search !== '' ||
    filteredPost !== 'all' ||
    filterByDateCreated !== TimePeriod.ALL_TIME ||
    filterByDatePublished !== TimePeriod.ALL_TIME;

  const noResultsDescription = searchIsSet
    ? `No results found for search`
    : `Once you create your first poll, it will show up here.`;

  const noResultsActionLabel = searchIsSet ? 'Reset Search' : 'New Poll';
  const noResultsActionClick = searchIsSet ? handleResetSearch : () => setIsOpen(true);
  const noResultsActionIcon = searchIsSet ? ArrowPathIcon : ChartBarIcon;

  const handleClose = () => {
    setIsOpen(false);
    setSelectedPoll(undefined);
  };

  const slideOverBodyId = 'manage-poll';

  return (
    <>
      <ActionModal
        isOpen={deletePollId !== ''}
        onClose={onDeletePollModalClose}
        onProceed={onDeletePoll}
        resourceId={deletePollId}
        isWorking={isDeleting}
        headerText="Delete Poll"
        actionText="Delete"
      >
        Are you sure you want to delete this poll?
      </ActionModal>

      <PageHeading
        title="Polls"
        description="Create engaging polls to learn more about your audience"
        tutorial={tutorial}
      />

      <div className="mb-8 pb-8 pt-4 border-b border-gray-200">
        <div className="flex flex-col md:flex-row justify-between">
          <div className="pt-4 md:pt-0 relative flex space-x-1 items-end">
            <div className="w-full basis-96">
              <SearchInput
                defaultValue={search || ''}
                shouldDebounce={false}
                shouldReset={shouldResetSearch}
                onClearSearch={handleResetSearch}
                onSearch={handleSearch}
                placeholder="Search Polls, Posts, and Emails"
                searchInputLineHeight={14}
              />
            </div>
            <PostSelect
              className="w-1/4"
              labelText=""
              postId={filteredPost || ''}
              onSelectPost={(postId: string) => handleFilteredPostChange(postId)}
              onClearPost={() => handleFilteredPostChange('all')}
            />
            <DateCreated handleChange={handleDateCreatedChange} />
            <DatePublished handleChange={handleDatePublishedChange} />
            <Button variant="primary" onClick={() => setIsOpen(true)}>
              <div className="flex">
                <ChartBarIcon className="h-5 w-5 mr-2" />
                New poll
              </div>
            </Button>
            {isLoading && (
              <div className="hidden md:block">
                <LoadingSpinner />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="mb-3">
        <span className="text-xs font-semibold text-gray-600">{`Showing ${showingPollsCount} of ${totalPollsCount} results`}</span>
      </div>
      <LoadingBox isLoading={isLoading} isError={isError}>
        <>
          {isNoResults ? (
            <EmptyCard
              title="No Polls Found"
              description={noResultsDescription}
              primaryIcon={ChartBarIcon}
              primaryActionLabel={noResultsActionLabel}
              onPrimaryActionClick={noResultsActionClick}
              primaryActionIcon={noResultsActionIcon}
            />
          ) : (
            <div className="-my-2 sm:-mx-6 lg:-mx-8">
              <div className="align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="border rounded-md border-gray-200 relative">
                  <table className="min-w-full divide-y divide-gray-200 border-t rounded-md">
                    <TableHeaders
                      headers={TABLE_HEADERS}
                      sort={{ order, dir: dir as Order }}
                      sortableHeaders={SORTABLE_HEADERS}
                      handleSortChange={handleSortChange}
                      headerSidePadding={COLUMN_SIDE_PADDING}
                    />

                    <tbody className="bg-white divide-y divide-gray-200">
                      {polls?.map((poll: Poll) => {
                        return (
                          <tr key={poll.id}>
                            <td className={TEXT_COLUMN_CLASSES}>
                              <div className="flex flex-col w-full">
                                <div className="font-medium">
                                  <Link className="text-primary-600 hover:text-primary-900" to={`/polls/${poll.id}`}>
                                    {poll.name}
                                  </Link>
                                </div>
                                {poll.question && (
                                  <p className="text-xs text-gray-500 py-1 truncate w-52" title={poll.question}>
                                    {poll.question}
                                  </p>
                                )}
                              </div>
                            </td>
                            <td className="text-sm text-gray-900">
                              <Badge type={BADGE_COLORS[poll.status]} className="capitalize">
                                {PollStatuses[poll.status]}
                              </Badge>
                            </td>
                            <td className={cx(TEXT_COLUMN_CLASSES, 'capitalize')}>{poll.poll_type}</td>
                            <td className={cx(TEXT_COLUMN_CLASSES, 'pl-8')}>{poll.appearances.toLocaleString()}</td>
                            <td className={cx(TEXT_COLUMN_CLASSES, 'pl-8')}>{poll.completions.toLocaleString()}</td>
                            <td className={TEXT_COLUMN_CLASSES}>
                              {poll.most_recent_response ? moment(poll.most_recent_response).fromNow() : 'No responses'}
                            </td>
                            <td className={TEXT_COLUMN_CLASSES}>{moment(poll.created_at).fromNow()}</td>
                            <td className={TEXT_COLUMN_CLASSES}>
                              <OptionsDropdown options={pollOptions(poll)} />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
          <div className="text-center mt-6">
            {hasNextPage && (
              <div>
                <Button
                  variant="primary-inverse"
                  onClick={() => fetchNextPage()}
                  disabled={!hasNextPage || isFetchingNextPage}
                >
                  {isFetchingNextPage ? 'Loading more...' : 'Load more'}
                </Button>
              </div>
            )}
          </div>
        </>
      </LoadingBox>
      <SlideOver bodyId={slideOverBodyId} headerText="New Poll" isOpen={isOpen} onClose={handleClose}>
        <Form
          onCancel={handleClose}
          pollId={selectedPoll?.id}
          onSuccess={(pollId: string) => navigate(`/polls/${pollId}`)}
          publicationId={publicationId}
          slideOverBodyId={slideOverBodyId}
        />
      </SlideOver>
    </>
  );
};

export default Polls;
