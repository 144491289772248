import Button from '@/components/TiptapEditor/components/ui/Button';
import Icon from '@/components/TiptapEditor/components/ui/Icon';

interface Props {
  onDeleteClick(): void;
}

const SubtitleTooltipMenu = ({ onDeleteClick }: Props) => {
  const deleteSubtitle = () => {
    onDeleteClick();
  };

  return (
    <div className="flex h-[3rem] bg-gray-800 rounded-lg p-2">
      <Button $variant="quaternary" $leftSlot={<Icon name="Trash" />} onClick={deleteSubtitle} />
    </div>
  );
};

export default SubtitleTooltipMenu;
