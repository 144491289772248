import { Editor } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';

export const ParentSelectorPluginKey = new PluginKey('parentSelectorPlugin');

export const getParentSelectorPlugin = (editor: Editor) => {
  return new Plugin({
    key: ParentSelectorPluginKey,

    props: {
      handleDOMEvents: {
        keydown(_view, event) {
          if (event.key !== 'Escape') {
            return false;
          }

          event.preventDefault();

          editor.commands.selectParentNode();

          return true;
        },
      },
    },
  });
};
