import { CursorClick } from '@phosphor-icons/react';

import { Text } from '@/routes/website/_components/UI/Text';

import { useNavbarContext } from '../NavbarContext';

import { NavDropdownColumnMainSettings } from './components/NavDropdownColumnMainSettings';
import { NavDropdownItemMainSettings } from './components/NavDropdownItemMainSettings';
import { NavDropdownItemStylingSettings } from './components/NavDropdownItemStylingSettings';
import { NavDropdownLayoutSettings } from './components/NavDropdownLayoutSettings';
import { NavDropdownMainSettings } from './components/NavDropdownMainSettings';
import { NavItemContentSettings } from './components/NavItemContentSettings';
import { NavItemLinkSettings } from './components/NavItemLinkSettings';
import { NavItemLogoSettings } from './components/NavItemLogoSettings';
import { NavItemMobileSettings } from './components/NavItemMobileSettings';
import { NavItemStylingSettings } from './components/NavItemStylingSettings';
import { NavMenuLayoutSettings } from './components/NavMenuLayoutSettings';
import { NavMenuMobileSettings } from './components/NavMenuMobileSettings';
import { NavMenuStyleSettings } from './components/NavMenuStyleSettings';

export const AttributesPanel = () => {
  const { selectedContent } = useNavbarContext();
  const preventPropagation = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  if (!selectedContent)
    return (
      <div
        className="flex flex-col px-3 items-center justify-center gap-4 py-8"
        onClick={preventPropagation}
        role="none"
      >
        <div className="flex items-center justify-center h-9 w-9 bg-wb-primary rounded-lg border border-solid border-wb-primary shadow-wb-sm">
          <CursorClick size={16} weight="bold" className="text-wb-secondary" />
        </div>
        <Text size="xs" variant="secondary">
          Select a block to edit
        </Text>
      </div>
    );

  return (
    <div className="flex flex-col p-3" onClick={preventPropagation} role="none">
      <NavMenuLayoutSettings />
      <NavMenuStyleSettings />
      <NavMenuMobileSettings />

      <NavItemContentSettings />
      <NavItemLinkSettings />
      <NavItemLogoSettings />
      <NavItemStylingSettings />
      <NavItemMobileSettings />

      <NavDropdownMainSettings />
      <NavDropdownLayoutSettings />

      <NavDropdownColumnMainSettings />

      <NavDropdownItemMainSettings />
      <NavDropdownItemStylingSettings />
    </div>
  );
};
