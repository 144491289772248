import React, { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';

import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';

import Editor from '../../../../components/_domain/EmailMessage/Editor';
import LoadingBox from '../../../../components/LoadingBox';
import { useEmailMessage } from '../../../../hooks';

const BlastEdit: React.FunctionComponent = () => {
  const navigate = useNavigate();

  const { segmentId, emailMessageId } = useParams() as unknown as { segmentId: string; emailMessageId: string };
  const currentPublicationId = useCurrentPublicationId();
  const {
    data: emailMessage,
    isLoading: messageLoading,
    isError: messageError,
  } = useEmailMessage(currentPublicationId, {
    emailMessageId,
  });

  useEffect(() => {
    if (emailMessage?.last_sent_at) {
      navigate(`/segments/${segmentId}/blasts/${emailMessageId}`);
      toast.error('You cannot edit a blast that is scheduled or sent');
    }
  }, [emailMessage, emailMessageId, navigate, segmentId]);

  return (
    <LoadingBox isLoading={messageLoading} isError={messageError}>
      {emailMessage && (
        <Editor
          emailMessage={emailMessage}
          onExit={() => navigate(`/segments/${segmentId}/blasts/${emailMessageId}`)}
          showResourceSelect
          allowPolls={false}
        />
      )}
    </LoadingBox>
  );
};

export default BlastEdit;
