import React from 'react';

import Text from '@/components/Text';
import { PlanPrice } from '@/interfaces/plan_price';
import { Button } from '@/ui/Button';
import { PLAN } from '@/utils/plans';

interface Props {
  planPrice: PlanPrice;
  onSelect: (option: string) => void;
}

const ChooseDowngradeOption: React.FC<Props> = ({ planPrice, onSelect }) => {
  const currentPlan = planPrice.plan_name;
  const isAnnualBilling = planPrice.interval === 'year';

  return (
    <div className="p-8 max-w-lg">
      <div className="text-center">
        <Text size="lg" type="bold" className="text-gray-800">
          Downgrade your plan
        </Text>

        <Text size="md" className="text-gray-600 my-4">
          {isAnnualBilling
            ? 'Choose one of the following options to downgrade your plan.'
            : 'Click the following button to downgrade your plan.'}
        </Text>

        <div className="space-x-2 flex justify-center">
          {isAnnualBilling && (
            <Button className="h-10" variant="primary" onClick={() => onSelect('downgrade_to_monthly')}>
              Change to monthly billing
            </Button>
          )}

          {currentPlan === PLAN.MAX && (
            <div>
              <Button
                className="h-10"
                variant={isAnnualBilling ? 'primary-inverse' : 'primary'}
                onClick={() => onSelect('downgrade_to_scale')}
              >
                Downgrade to Scale plan
              </Button>
            </div>
          )}
        </div>

        <div className="relative my-6">
          <div className="absolute inset-0 flex items-center" aria-hidden="true">
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <span className="bg-white px-2 text-sm text-gray-500">Alternative options</span>
          </div>
        </div>

        <Button variant="danger" size="sm" onClick={() => onSelect('downgrade_to_free')}>
          Downgrade to free
        </Button>
      </div>
    </div>
  );
};

export default ChooseDowngradeOption;
