import { useCallback, useMemo } from 'react';
import { PostGroupType } from '@shared/dream-components';
import { NodeViewProps } from '@tiptap/core';

import useOptions from '@/hooks/useOptions';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';

import InputWrapper from '../../../../UI/InputWrapper';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
} from '../../../../UI/Select';

type Props = Partial<NodeViewProps> & {
  setIsCategorySelected: (value: boolean) => void;
  setPostType: (value: 'free_selection' | 'latest' | 'featured' | any) => void;
};

const PostTypeSelect = ({ setIsCategorySelected, setPostType, node, editor, getPos }: Props) => {
  const publicationId = useCurrentPublicationId();
  const tags = useOptions(publicationId, 'content_tags');
  const currentPostGroupType = node?.attrs.postGroupType;

  const tagOptions = useMemo(() => {
    return tags?.data?.options?.map((tag: any) => ({ label: tag?.display, value: tag?.display }));
  }, [tags]);

  const handleUpdatePostGroupType = useCallback(
    (value: PostGroupType) => {
      editor?.commands.command(({ tr }) => {
        tr.setNodeAttribute(getPos?.() ?? 0, 'postGroupType', value);
        return true;
      });
    },
    [editor, getPos]
  );

  return (
    <InputWrapper name="post_type" labelText="Post Type" className="w-full">
      <Select
        defaultValue={currentPostGroupType}
        onValueChange={(value) => {
          // Let the parent know if a catgery has been selected to determine if we should insert the appropriate tags
          const isCategory = tagOptions?.find((option: any) => option.value === value);

          if (isCategory) {
            setIsCategorySelected(true);
          } else {
            setIsCategorySelected(false);
          }

          setPostType(value);
          handleUpdatePostGroupType(value as PostGroupType);
        }}
      >
        <SelectTrigger className="w-full" id="by_status">
          <SelectValue placeholder="Select a category" />
        </SelectTrigger>
        <SelectContent className="max-h-[280px] overflow-y-auto no-scrollbar">
          <SelectGroup>
            <SelectLabel>Basic</SelectLabel>
            <SelectItem value="free_selection">Free Selection</SelectItem>
            <SelectItem value="latest">Latest</SelectItem>
            <SelectItem value="archive">Archive</SelectItem>
          </SelectGroup>
          <SelectSeparator />
          <SelectGroup>
            <SelectLabel>Categories</SelectLabel>
            {tagOptions?.map((option: any) => (
              <SelectItem key={option.value} value={option.value} onClick={() => console.log('hi')}>
                {option.label}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </InputWrapper>
  );
};

export default PostTypeSelect;
