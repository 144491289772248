import { CheckCircleIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

import Badge from '@/components/Badge';
import { RadioOption } from '@/components/Form/RadioSelect';
import { Typography, TypographyStack } from '@/components/Typography';

import FormBlock from './FormBlock';

interface Props {
  checked: boolean;
  option: RadioOption;
}

const CustomSetupMethodNode = ({ checked, option }: Props) => {
  return (
    <div className="cursor-pointer">
      <FormBlock className={checked ? 'bg-action-secondary-600' : 'bg-white'}>
        {option.customProps?.isRecommended ? (
          <Badge type="info_blue" className="w-fit">
            Recommended
          </Badge>
        ) : null}
        <div className="flex flex-row gap-x-2 items-center justify-between">
          <TypographyStack gap="2">
            <Typography token="font-normal/text/base" color={checked ? 'white' : 'primary'}>
              {option.name}
            </Typography>
            <Typography token="font-normal/text/sm" color={checked ? 'white' : 'primary'}>
              {option.description}
            </Typography>
          </TypographyStack>
          {checked ? (
            <div className="grow-0 shrink-0">
              <CheckCircleIcon className={classNames('h-5 w-5', checked && 'text-white')} />
            </div>
          ) : null}
        </div>
        {checked ? option.children : null}
      </FormBlock>
    </div>
  );
};

export default CustomSetupMethodNode;
