import { useCallback, useMemo, useState } from 'react';
import Editor from '@monaco-editor/react';
import { BracketsCurly, CaretRight } from '@phosphor-icons/react';
import { PostElement, TPostAttributes } from '@shared/dream-components';
import Ajv from 'ajv';

import { Button } from '../../../../UI/Button';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '../../../../UI/Dialog';
import { Text } from '../../../../UI/Text';
import { AttributeSettingProps } from '../../types';

type Props = AttributeSettingProps & {
  allowedProperties: string[];
};

const getCardStructure = (initialStructure: any, incomingStructure: string): any => {
  if (!incomingStructure) return initialStructure;

  try {
    const parsedStructure = JSON.parse(incomingStructure);
    return parsedStructure as any;
  } catch (error) {
    console.error('Error parsing JSON string:', error);
    return {};
  }
};

const ajv = new Ajv();

export const StructureSettings = ({ editor, activeNodeResult, allowedProperties }: Props) => {
  const { activeNodePos, activeNode } = activeNodeResult;
  const [isOpen, setIsOpen] = useState(false);

  const [structure, setStructure] = useState(`${JSON.stringify(activeNode?.attrs?.cardStructure)}`);
  const [errors, setErrors] = useState<string[]>();
  const hasErrors = errors && errors.length > 0;
  const [editorInstance, setEditorInstance] = useState<any>();

  const onClose = () => {
    setIsOpen(false);
  };

  const allowedSchema = useMemo(() => {
    return {
      type: 'object',
      properties: {
        node: {
          type: 'string',
          enum: allowedProperties,
        },
        className: { type: 'string', default: '' },
        children: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              node: {
                type: 'string',
                enum: allowedProperties,
              },
              className: { type: 'string', default: '' },
              children: { type: 'array', items: { $ref: '#' } }, // Recursive reference
            },
            required: ['node', 'children'],
            additionalProperties: false,
          },
        },
      },
      required: ['node', 'children'],
      additionalProperties: false,
    };
  }, [allowedProperties]);

  const validateJSON = (jsonString: string) => {
    try {
      const parsed = JSON.parse(jsonString);
      const valid = ajv.validate(allowedSchema, parsed);

      if (!valid) {
        const ajvErrors = ajv.errors?.map((error) => `${error.message}`);
        setErrors(ajvErrors || []);
        return false;
      }
      setErrors([]);
      return true;
    } catch (e) {
      setErrors(['Invalid JSON format']);
      return false;
    }
  };

  const handleFormat = () => {
    editorInstance?.getAction('editor.action.formatDocument').run();
  };

  const handleReset = () => {
    editorInstance?.getModel()?.setValue(JSON.stringify(activeNode?.attrs?.cardStructure));
    setStructure(JSON.stringify(activeNode?.attrs?.cardStructure));
    handleFormat();
  };

  const handleUpdate = useCallback(() => {
    if (!activeNodeResult || hasErrors) return;

    try {
      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(
          activeNodePos,
          'cardStructure',
          getCardStructure(activeNode?.attrs?.cardStructure, structure)
        );
        return true;
      });
      onClose();
    } catch (error) {
      console.error('Error parsing JSON string:', error);
    }
  }, [editor, activeNodePos, structure, activeNodeResult, activeNode?.attrs?.cardStructure, hasErrors]);

  return (
    <>
      <div className="flex items-center justify-stretch gap-2">
        <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
          Select
        </Text>

        <button
          type="button"
          className="grow bg-wb-secondary rounded-lg shadow-sm cursor-pointer"
          onClick={() => setIsOpen(true)}
        >
          <div className="w-full justify-between flex items-center gap-2 p-2 ">
            <div className="flex items-center gap-1">
              <BracketsCurly className="text-wb-secondary" weight="bold" />
              <Text
                size="2xs"
                weight="medium"
                className="whitespace-nowrap overflow-hidden overflow-ellipsis pr-2 max-w-[80px]"
              >
                Structure
              </Text>
            </div>

            <CaretRight className="text-wb-secondary" weight="bold" />
          </div>
        </button>
      </div>

      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="w-[100vw] max-w-none h-[100vh] flex flex-col overflow-hidden">
          <DialogHeader>
            <DialogTitle>
              <div className="flex items-center gap-2">
                <Text size="xl" weight="semibold" variant="primary" as="h4">
                  Structure
                </Text>
              </div>
            </DialogTitle>
          </DialogHeader>
          <div className="flex gap-8">
            <div className="flex flex-col gap-2 w-1/2">
              <div className="rounded-lg overflow-hidden w-full">
                <Editor
                  height="90vh"
                  width="100%"
                  defaultLanguage="json"
                  defaultValue={structure}
                  theme="vs-dark"
                  options={{
                    formatOnType: true,
                    formatOnPaste: true,
                    tabSize: 2,
                  }}
                  onChange={(value) => {
                    if (value && validateJSON(value)) {
                      setStructure(`${value}`);
                    }
                  }}
                  onMount={(e: any) => {
                    setEditorInstance(e);
                    e.getAction('editor.action.formatDocument').run();
                  }}
                  onValidate={(markers) => {
                    if (markers.length > 0) {
                      setErrors(markers.map((marker) => marker.message));
                    } else {
                      setErrors([]);
                    }
                  }}
                />
              </div>

              <div>
                {errors && errors.length > 0 ? (
                  <div className="text-red-500">
                    <Text size="sm" weight="semibold">
                      Validation Errors:
                    </Text>
                    <div className="flex flex-col gap-1">
                      {errors.map((error) => (
                        <Text size="xs" weight="medium" className="text-red-500" key={error}>
                          {error}
                        </Text>
                      ))}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>

            <div className="transform p-4 rounded-lg w-1/2 h-full flex flex-col items-center">
              <PostElement
                element={{
                  type: 'post',
                  attrs: {
                    ...(activeNode?.attrs as TPostAttributes),
                    cardStructure: getCardStructure(activeNode?.attrs?.cardStructure, structure) as any,
                    columns: '1',
                    data: {
                      // We only need one post to display the structure
                      posts: activeNode?.attrs?.data?.posts.slice(0, 1),
                    },
                  },
                }}
              >
                {null}
              </PostElement>
            </div>
          </div>

          <DialogFooter className="flex justify-between items-center absolute bottom-0 left-0 right-0 p-4 bg-white border-t border-wb-primary">
            <div className="flex gap-2 justify-between w-full">
              <Button variant="outlined" onClick={handleReset} isDisabled={hasErrors}>
                Reset
              </Button>
              <div className="flex gap-2">
                <Button variant="outlined" onClick={handleFormat} isDisabled={hasErrors}>
                  Format
                </Button>
                <Button variant="primary" onClick={handleUpdate} isDisabled={hasErrors}>
                  Apply
                </Button>
              </div>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
