import { FC, useEffect, useState } from 'react';
import { PostElement, TPostAttributes } from '@shared/dream-components';
import { NodeViewProps, useReactNodeView } from '@tiptap/react';

import NotConnected from '../../components/NotConnected';
import PostsSelectionModal from '../modal/PostsSelectionModal';

export const PostView: FC<NodeViewProps> = (nodeViewProps) => {
  const { HTMLAttributes, node, editor, getPos } = nodeViewProps;
  const { onDragStart } = useReactNodeView();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { insertedFromSidebar, hasFakeData } = node.attrs;

  useEffect(() => {
    if (insertedFromSidebar && !isModalOpen) {
      setIsModalOpen(true);
    }
  }, [insertedFromSidebar, isModalOpen]);

  const handleCloseModal = () => {
    editor.commands.command(({ tr }) => {
      tr.setNodeAttribute(getPos(), 'insertedFromSidebar', false);
      return true;
    });
    setIsModalOpen(false);
  };

  const handleOpenModal = () => {
    editor.commands.command(({ tr }) => {
      tr.setNodeAttribute(getPos(), 'insertedFromSidebar', true);
      return true;
    });
    setIsModalOpen(false);
  };

  return (
    <PostElement
      element={{
        type: 'post',
        attrs: {
          ...(node.attrs as TPostAttributes),
        },
      }}
      attributes={{
        'data-node-view-wrapper': '',
        ...HTMLAttributes,
      }}
      onDragStart={onDragStart}
    >
      {hasFakeData && (
        <NotConnected
          text="Not connected to real posts"
          onClick={handleOpenModal}
          className="absolute top-2 left-1/2 transform -translate-x-1/2"
        />
      )}
      {insertedFromSidebar && (
        <PostsSelectionModal
          {...nodeViewProps}
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          setIsModalOpen={setIsModalOpen}
          node={node}
        />
      )}
    </PostElement>
  );
};
