import { FC, useCallback, useState } from 'react';
import toast from 'react-hot-toast';

// Hooks
import { useCurrentPublication } from '../../hooks';
import { Option } from '../../interfaces/general';
// Interfaces
import { Post } from '../../interfaces/options';
// Services
import api from '../../services/swarm';
// Components
import { TypeaheadSelect } from '../Form';

interface Props {
  labelText?: string;
  helperText?: string;
  className?: string;
  labelClassName?: string;
  postId?: string;
  onSelectFullPost?: (post: Post | undefined) => void;
  onSelectPost: (postId: string) => void;
  onClearPost: () => void;
  livePostsOnly?: boolean;
  dropdownClassnames?: string;
}

const PostSelect: FC<Props> = (props: Props) => {
  const {
    labelText = 'Post',
    helperText,
    className,
    labelClassName,
    postId,
    onSelectPost,
    onClearPost,
    onSelectFullPost,
    livePostsOnly = false,
    dropdownClassnames,
  } = props;
  const { data: currentPublication } = useCurrentPublication();
  const [renderedPosts, setRenderedPosts] = useState<Post[]>();

  const fetchPosts = useCallback(
    (inputValue?: string) => {
      const params = {
        publication_id: currentPublication?.id,
        q: inputValue,
        live_posts_only: livePostsOnly,
        all_results: true,
      };

      return api
        .get(`/options/posts/`, { params })
        .then((res) => {
          const posts = res.data?.options || [];
          setRenderedPosts(posts);

          const options: Option[] = posts.map((post: Post) => {
            const val: Option = {
              label: post.web_title,
              value: post.id,
            };
            return val;
          });
          return options;
        })
        .catch((errPayload) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        });
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPublication]
  );

  const loadInitialValue = useCallback(() => {
    if (!postId) {
      return undefined;
    }

    const params = {
      publication_id: currentPublication?.id,
      id: postId,
    };

    return api
      .get(`/options/posts/`, { params })
      .then((res) => {
        const posts = res.data?.options || [];
        setRenderedPosts(posts);
        const onlyPost: Post = posts.length > 0 ? posts[0] : null;
        const option = onlyPost && {
          label: onlyPost?.web_title,
          value: onlyPost?.id,
        };

        return option;
      })
      .catch((errPayload) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      });
  }, [currentPublication, postId]);

  const handleSelect = (name: string, value: string) => {
    onSelectPost(value);

    if (onSelectFullPost) {
      onSelectFullPost(renderedPosts?.find((post) => post.id === value));
    }
  };

  const handleClear = () => {
    onClearPost();
  };

  if (!currentPublication) {
    return null;
  }

  return (
    <TypeaheadSelect
      name="post"
      labelText={labelText || ''}
      helperText={helperText}
      placeholderText="Select a Post"
      value={postId || ''}
      onSelect={handleSelect}
      onClear={handleClear}
      loadOptions={fetchPosts}
      loadInitialValue={loadInitialValue}
      labelClassName={labelClassName}
      className={className}
      dropdownClassnames={dropdownClassnames}
      emptyLabel="No posts found"
    />
  );
};

export default PostSelect;
