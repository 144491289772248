import { useState } from 'react';
import { Checkbox } from '@radix-ui/react-checkbox';

import { useCreateSite } from '@/hooks/useSite';
import useSitePackages from '@/hooks/useSitePackages/useSitePackages';
import { cn } from '@/utils/cn';

import { Button } from '../_components/UI/Button';
import { Label } from '../_components/UI/Label';
import { Text } from '../_components/UI/Text';

const WebsiteOnboardingPage = () => {
  const { data: packages } = useSitePackages();
  const projects = packages?.pages.flatMap((pkg) => pkg.site_packages) || [];
  const [selectedProject, setSelectedProject] = useState<string | null>(null);
  const isDisabled = selectedProject === null;

  const createSite = useCreateSite({ sitePackageId: selectedProject || '' });

  const createSiteMutation = () => {
    createSite.mutate();
  };

  return (
    <div className="w-screen h-screen flex flex-col overflow-scroll no-scrollbar bg-white py-16 pb-32 gap-8">
      <div className="flex flex-col items-center justify-center">
        <Text size="2xl" className="font-bold !text-6xl">
          Select a template
        </Text>
      </div>

      <div className="grid grid-cols-3 gap-4 mx-auto">
        {projects?.map((project) => {
          const isSelected = selectedProject === project.id;

          return (
            <Label
              key={project.id}
              htmlFor={project.id}
              className={cn(
                'flex flex-col cursor-pointer hover:shadow-2xl transition-shadow duration-200 border shadow p-0 rounded-md overflow-hidden focus:outline-none relative justify-center items-center',
                isSelected ? 'border-wb-accent border-2' : 'border-wb-primary'
              )}
            >
              <img
                className="w-full h-full object-cover"
                src="https://ghost.org/images/marketplace/themes/maple_hubf6e9dba2902af2a76982bbdde6b95b2_119659_450x0_resize_q100_h2_box_3.webp"
                alt="Maple"
              />
              <Checkbox
                id={project.id}
                className={cn('absolute -top-2 -right-2', isSelected ? 'opacity-100' : 'opacity-0')}
                checked={Boolean(isSelected)}
                onCheckedChange={() => setSelectedProject(project.id)}
              />

              <Text
                weight="semibold"
                variant="primary"
                size="sm"
                as="span"
                className="absolute -bottom-6 left-1/2 transform -translate-x-1/2"
              >
                Template
              </Text>
            </Label>
          );
        })}
      </div>

      <div className="absolute bottom-0 left-0 w-full h-16 bg-white flex justify-center items-center border-t border-wb-primary">
        <Button
          variant="primary"
          onClick={createSiteMutation}
          className="w-full max-w-sm"
          isDisabled={isDisabled}
          isLoading={createSite.isLoading}
        >
          {createSite.isLoading ? 'Creating Site...' : 'Start Building'}
        </Button>
      </div>
    </div>
  );
};

export default WebsiteOnboardingPage;
