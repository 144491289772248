import React, { Fragment, MouseEvent, ReactNode, useCallback } from 'react';
import {
  GetNavbarElementProps,
  NavbarMenu,
  NavbarMenuBurger,
  NavbarMenuBurgerContent,
  NavbarMenuDropdown,
  NavbarMenuDropdownColumn,
  NavbarMenuDropdownItem,
  NavbarMenuItem,
  NavbarMenuList,
  NavbarMenuListGroup,
  NavbarSerializableNode,
  NavbarSerializerProps,
  reorganizeNavbarMobileChildren,
} from '@shared/dream-components';

import { AddNavbarItemButton } from './NavbarComponents/AddNavbarItemButton';
import { NavbarDropdownColumnEmptyState } from './NavbarComponents/NavbarDropdownColumnEmptyState';
import { useNavbarContext } from './NavbarContext';

const componentMap = {
  navbar_menu: NavbarMenu,
  navbar_menu_list: NavbarMenuList,
  navbar_menu_list_group: NavbarMenuListGroup,
  navbar_item: NavbarMenuItem,
  navbar_dropdown: NavbarMenuDropdown,
  navbar_dropdown_column: NavbarMenuDropdownColumn,
  navbar_dropdown_item: NavbarMenuDropdownItem,
  navbar_menu_burger: NavbarMenuBurger,
  navbar_menu_burger_content: NavbarMenuBurgerContent,
};
interface WrapperProps {
  element: NavbarSerializableNode;
  children: ReactNode;
  screen: 'desktop' | 'mobile' | undefined;
  allowClick?: boolean;
}

const Wrapper = ({ element, children, allowClick = false, screen }: WrapperProps) => {
  const { onSelectNode, onHoverNode } = useNavbarContext();

  const child = React.Children.only(children) as React.ReactElement;

  const handleMouseDown = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      if (allowClick) return;
      // Stop the event from bubbling up to parent elements
      event.stopPropagation();
      event.preventDefault();

      // Set the clicked element as the selected node
      const targetElement = event.currentTarget as HTMLElement;
      onSelectNode(targetElement, element.attrs?.id || 'empty', screen === 'mobile' ? false : undefined);
    },
    [onSelectNode, element, screen, allowClick]
  );

  const handleClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      // Stop click events like clicking links
      if (allowClick) return;
      event.stopPropagation();
      event.preventDefault();
    },
    [allowClick]
  );

  const handleMouseOver = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      if (allowClick) return;
      // Stop the event from bubbling up to parent elements
      event.stopPropagation();
      event.preventDefault();

      // Set the clicked element as the selected node
      // if (!element.attrs?.id) return;
      const targetElement = event.currentTarget as HTMLElement;
      onHoverNode(targetElement, element.attrs?.id || 'empty');
    },
    [onHoverNode, element, allowClick]
  );

  // Ensure there's only one child and clone it with the new props
  return React.cloneElement(child, {
    onClick: handleClick,
    onMouseDown: handleMouseDown,
    onMouseOver: handleMouseOver,
    'data-element-id': element.attrs?.id,
    controlbyselected: 'true',
  });
};

export function getNavbarElement({ node, children, key, portalRef, screen }: GetNavbarElementProps): JSX.Element {
  let Comp: any;

  switch (node.type) {
    case 'navbar_menu':
    case 'navbar_menu_list':
    case 'navbar_item':
    case 'navbar_dropdown_item':
      Comp = componentMap[node.type];
      return (
        <Wrapper key={key} element={node} screen={screen}>
          <Comp screen={screen} element={node} className={`dream-${node.type}`}>
            {children}
          </Comp>
        </Wrapper>
      );

    case 'navbar_menu_burger':
    case 'navbar_menu_burger_content':
      Comp = componentMap[node.type];
      return (
        <Comp screen={screen} element={node} className={`dream-${node.type}`}>
          {children}
        </Comp>
      );

    case 'navbar_dropdown_column':
      Comp = componentMap[node.type];
      return (
        <Wrapper key={key} element={node} screen={screen}>
          <Comp screen={screen} element={node} className={`dream-${node.type}`}>
            {node.content?.length ? children : <NavbarDropdownColumnEmptyState />}
          </Comp>
        </Wrapper>
      );

    case 'navbar_dropdown':
      Comp = componentMap[node.type];
      return (
        <Wrapper key={key} element={node} screen={screen}>
          <Comp screen={screen} element={node} className={`dream-${node.type}`} portalref={portalRef}>
            {children}
          </Comp>
        </Wrapper>
      );

    case 'navbar_menu_list_group':
      Comp = componentMap[node.type];
      return (
        <Comp screen={screen} element={node} className={`dream-${node.type}`} data-element-id={node.attrs?.id}>
          {node.attrs?.type === 'right' && screen !== 'mobile' && <AddNavbarItemButton node={node} />}
          {children}
          {node.attrs?.type === 'left' && screen !== 'mobile' && <AddNavbarItemButton node={node} />}
        </Comp>
      );
    default:
      return <Fragment key={key}>{children}</Fragment>;
  }
}

const serializeNavbarNode = (
  {
    node,
    createKey = (entry) => entry[0].attrs?.id || entry[1].join(','),
    children,
    portalRef,
    screen,
  }: NavbarSerializerProps,
  path = [0] // internal path tracking
): JSX.Element => {
  const key = createKey([node, path]);

  const nodeChildren =
    // @ts-ignore
    node.content?.map((n, i) =>
      serializeNavbarNode(
        {
          node: n,
          createKey,
          portalRef,
          screen,
        },
        [...path, i]
      )
    ) || [];

  return getNavbarElement({
    node,
    key,
    portalRef,
    screen,
    children: [...nodeChildren, children],
  });
};

export function NavbarSerializer(props: NavbarSerializerProps) {
  let { node } = props;
  const { screen } = props;

  if (screen === 'mobile') {
    node = reorganizeNavbarMobileChildren(node);
  }

  return serializeNavbarNode({ ...props, node });
}
