import React, { FC, useState } from 'react';

import { Button } from '@/ui/Button';
import { Dropdown } from '@/ui/Dropdown';

import { ApprovalRequest, ApprovalRequestType } from '../../interfaces/approval_request';
import { Checkbox, Input, Textarea } from '../Form';

interface Props {
  onSubmit: (data: ApprovalRequest) => Promise<void>;
}

const RaiseSubscriberImportLimitApprovalRequestForm: FC<Props> = ({ onSubmit }) => {
  const [saving, setSaving] = useState(false);
  const [numSubscribers, setNumSubscribers] = useState('');
  const [hasArchive, setHasArchive] = useState<boolean>(false);
  const [archiveUrl, setArchiveUrl] = useState('');
  const [emailSourceUrl, setEmailSourceUrl] = useState('');
  const [signupProcess, setSignupProcess] = useState('');
  const [sendingCadence, setSendingCadence] = useState('');
  const [otherCommunication, setOtherCommunication] = useState('');
  const [activeEmailsConfirmation, setActiveEmailsConfirmation] = useState(false);
  const [optedInConfirmation, setOptedInConfirmation] = useState(false);

  const sendingCadenceOptions = [
    { label: '< once per week', value: '0' },
    { label: 'Weekly', value: '1' },
    { label: '2 times per week', value: '2' },
    { label: '3 times per week', value: '3' },
    { label: '4 times per week', value: '4' },
    { label: '5 times per week', value: '5' },
    { label: '6 times per week', value: '6' },
    { label: '7 times per week', value: '7' },
  ];

  const formData = () => {
    return [
      {
        label: 'How many subscribers are you looking to import?',
        value: numSubscribers,
      },
      ...(archiveUrl
        ? [
            {
              label: 'Link to your current newsletter archive',
              value: archiveUrl,
            },
          ]
        : [
            {
              label: 'Link to the page where you collect emails',
              value: emailSourceUrl,
            },
          ]),
      {
        label: 'How did these people sign up to the newsletter?',
        value: signupProcess,
      },
      {
        label: 'Any other information about your existing communication with these emails',
        value: otherCommunication,
      },
      {
        label:
          "Can you confirm these emails are only the ACTIVE emails (i.e. you've removed inactive and unsubscribed emails)?",
        value: activeEmailsConfirmation,
      },
      {
        label:
          'Can you confirm these readers have knowingly opted in and you have permission to communicate to ALL of these emails?',
        value: optedInConfirmation,
      },
    ];
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSaving(true);

    onSubmit({
      request_type: 'raise_subscriber_import_limit' as ApprovalRequestType,
      form_data: formData(),
    }).finally(() => {
      setSaving(false);
    });
  };

  const submitDisabled = () =>
    !numSubscribers ||
    (hasArchive && !archiveUrl) ||
    (!hasArchive && !emailSourceUrl) ||
    !signupProcess ||
    !sendingCadence ||
    !otherCommunication ||
    !activeEmailsConfirmation ||
    !optedInConfirmation;

  return (
    <form className="space-y-4" onSubmit={handleSubmit}>
      <div>
        <Input
          name="numSubscribers"
          labelText="How many subscribers are you looking to import?"
          helperText="Enter a number"
          value={numSubscribers}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setNumSubscribers(e.target.value);
          }}
          type="number"
          required
        />
      </div>
      <div>
        <Checkbox
          name="hasArchive"
          labelText="Do you have an existing archive of content?"
          checked={hasArchive || false}
          onChange={(checked) => setHasArchive(checked)}
        />
      </div>
      <div>
        {hasArchive === true && (
          <Input
            name="archiveUrl"
            labelText="Link to your current newsletter archive"
            type="text"
            value={archiveUrl}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setArchiveUrl(e.target.value)}
            required={hasArchive === true}
          />
        )}
        {hasArchive === false && (
          <Input
            name="emailSourceUrl"
            labelText="Link to the page where you collect emails"
            type="text"
            value={emailSourceUrl}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmailSourceUrl(e.target.value)}
            required={hasArchive === false}
          />
        )}
      </div>
      <div>
        <Textarea
          name="body"
          rows={6}
          labelText="How did these people sign up to the newsletter?"
          value={signupProcess || ''}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setSignupProcess(e.target.value)}
          required
        />
      </div>
      <div>
        <Dropdown
          name="sendingCadence"
          labelText="What is your cadence of sending?"
          value={sendingCadence}
          options={sendingCadenceOptions}
          onSelect={(_name: string, value: string) => {
            setSendingCadence(value);
          }}
        />
      </div>
      <div>
        <Textarea
          name="otherCommunication"
          rows={6}
          labelText="Any other information about your existing communication with these emails"
          value={otherCommunication || ''}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setOtherCommunication(e.target.value)}
          required
        />
      </div>
      <div>
        <Checkbox
          name="activeEmailsConfirmation"
          labelText="Can you confirm these emails are only the ACTIVE emails (i.e. you've removed inactive and unsubscribed emails)?"
          checked={activeEmailsConfirmation || false}
          onChange={(checked) => setActiveEmailsConfirmation(checked)}
        />
      </div>
      <div>
        <Checkbox
          name="optedInConfirmation"
          labelText="Can you confirm these readers have knowingly opted in and you have permission to communicate to ALL of these emails?"
          checked={optedInConfirmation || false}
          onChange={(checked) => setOptedInConfirmation(checked)}
        />
      </div>

      <div className="pt-8 flex w-full justify-end">
        <Button type="submit" disabled={submitDisabled()} loading={saving}>
          Request import limit increase
        </Button>
      </div>
    </form>
  );
};

export default RaiseSubscriberImportLimitApprovalRequestForm;
