import { ContentTree } from '../ContentTree';
import { ContentTreeProvider } from '../ContentTree/context';
import { LAYERS_PANEL_ID } from '../DreamEditor/constants';
import { NavSection } from '../SideNav/NavSection';
import { NavSectionTitle } from '../SideNav/NavSectionTitle';
import { SavePageIndicator } from '../SideNav/SaveIndicator';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../UI/Tabs';
import { Text } from '../UI/Text';

import AddableDefaultPages from './AddableDefaultPages';

interface Props {
  pagesRoutes: any;
  defaultRoutes: any;
  previewSiteVersion: any;
}

const SidePanelTabs = ({ pagesRoutes, defaultRoutes, previewSiteVersion }: Props) => {
  return (
    <Tabs defaultValue="layers" className="flex flex-col flex-1">
      <TabsList className="p-2" defaultValue="layers">
        <TabsTrigger value="layers">
          <Text weight="medium" size="2xs" className="leading-0">
            Layers
          </Text>
        </TabsTrigger>
        <TabsTrigger value="pages">
          <Text weight="medium" size="2xs" className="leading-0">
            Pages
          </Text>
        </TabsTrigger>
      </TabsList>
      <TabsContent value="layers" id={LAYERS_PANEL_ID} />
      <TabsContent value="pages" asChild>
        <>
          <div className="flex flex-col flex-1 gap-6">
            <NavSection>
              <NavSectionTitle title="Default Pages" />
              {defaultRoutes && (
                <ContentTreeProvider
                  dndDisabledErrorMessage={
                    previewSiteVersion?.id
                      ? "You can't rearrange pages in preview mode."
                      : "You can't re-arrange default pages"
                  }
                  isAllowDnD={false}
                  isShowOptions={!previewSiteVersion?.id}
                  isShowAddPage={!previewSiteVersion?.id}
                  pageLinkPrefix="/website_builder_v2/page"
                >
                  <ContentTree showHome={false} route={defaultRoutes} parentPath={[]} slug="" />
                </ContentTreeProvider>
              )}
              <AddableDefaultPages />
            </NavSection>
            <NavSection>
              <NavSectionTitle title="Pages" />
              {pagesRoutes && (
                <ContentTreeProvider
                  isAllowDnD={!previewSiteVersion?.id}
                  isShowOptions={!previewSiteVersion?.id}
                  isShowAddPage={!previewSiteVersion?.id}
                  pageLinkPrefix="/website_builder_v2/page"
                  dndDisabledErrorMessage={previewSiteVersion?.id ? "You can't rearrange pages in preview mode." : ''}
                >
                  <ContentTree route={pagesRoutes} parentPath={[]} slug="" />
                </ContentTreeProvider>
              )}
            </NavSection>
          </div>
          <NavSection>
            <SavePageIndicator />
          </NavSection>
        </>
      </TabsContent>
    </Tabs>
  );
};

export default SidePanelTabs;
