import React, { useLayoutEffect, useState } from 'react';
import { Editor, useEditorState } from '@tiptap/react';

import { useFrame } from '../../../hooks/useFrame';

export const HoverBox = ({ editor }: { editor: Editor }) => {
  const node = useEditorState({
    editor,
    selector: ({ editor: e }) => {
      // check current node selection
      const { selection } = e.state;
      const { pos } = editor.storage.hover;

      // Check if pos is within any selection range
      const isInSelection = selection.ranges.some((range) => pos >= range.$from.pos && pos <= range.$to.pos);

      if (isInSelection) {
        // do not show hover box if pos is within selection,
        // let decorator handle it
        return null;
      }

      return e.storage.hover.node;
    },
  });

  const [nodeStyle, setNodeStyle] = useState<React.CSSProperties>({});

  const { window } = useFrame();

  useLayoutEffect(() => {
    const createStyle = (element: Element | null, color: string): React.CSSProperties => {
      if (!element) return {};

      if (element.tagName === 'SECTION') return {};

      const rect = element.getBoundingClientRect();

      return {
        position: 'absolute',
        opacity: 1,
        left: rect.left + (window?.scrollX || 0),
        top: rect.top + (window?.scrollY || 0),
        width: rect.width,
        height: rect.height,
        border: `1px solid ${color}`,
        boxSizing: 'border-box',
        pointerEvents: 'none',
        transition: 'opacity 0.03s ease-out',
      };
    };

    setNodeStyle(createStyle(node, '#7C3AED'));
  }, [node, editor.view.dom, window]);

  return <div style={nodeStyle} />;
};
