import { FC, useState } from 'react';

import { Input, Switch } from '@/components/Form';
import LoadingBox from '@/components/LoadingBox';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import RichContent from '@/components/RichContent';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { Button } from '@/ui/Button';
import { Dropdown } from '@/ui/Dropdown';

import ConfigureContainer from '../../ConfigureContainer';
import { FormContextProvider, useFormContext } from '../../FormContext';
import PromoCodesSlideOver from '../../PromoCodesSlideOver';
import RewardSlideOver from '../../RewardSlideOver';

interface Props {
  milestoneId?: string;
}

const MilestoneForm: FC<Props> = ({ milestoneId }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const [isAddRewardsModalOpen, setIsAddRewardsModalOpen] = useState(false);
  const {
    setMilestone,
    milestone,
    fetchAndSetRewardOptions,
    rewardsWithTypes,
    emailContent,
    updateMilestone,
    createMilestone,
    setEmailContent,
    showManagePromoCodes,
    setShowManagePromoCodes,
    isLoadingMilestone,
    rewardOptions,
    isErrorMilestone,
    isSavingMilestone,
    onFetchPreview,
  } = useFormContext();

  const onRequestAddReward = () => {
    setIsAddRewardsModalOpen(true);
  };

  const onAddRewardModalClose = () => {
    setIsAddRewardsModalOpen(false);
  };

  const onAddRewardModalSuccess = (rewardId: string) => {
    // Close Modal
    setIsAddRewardsModalOpen(false);

    // Set the reward
    setMilestone({ ...milestone, reward_id: rewardId });

    // Fetch latest options
    fetchAndSetRewardOptions(currentPublicationId);
  };

  const isRewardTypePromoCode = () => {
    if (!milestone || !milestone?.reward_id || !rewardsWithTypes) {
      return false;
    }

    return rewardsWithTypes[milestone?.reward_id] === 'promo_code';
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (milestoneId) {
      updateMilestone({
        id: milestone.id,
        num_referrals: milestone.num_referrals,
        reward_id: milestone.reward_id,
        preview_text: milestone.preview_text,
        subject_line: milestone.subject_line,
        auto_fulfill: milestone.auto_fulfill,
        delay_achievement_email_until_fulfilled: milestone.delay_achievement_email_until_fulfilled,
        publication_id: currentPublicationId,
        tiptap_state: emailContent,
      });
      return;
    }

    createMilestone({
      num_referrals: milestone.num_referrals,
      reward_id: milestone.reward_id,
      preview_text: milestone.preview_text,
      subject_line: milestone.subject_line,
      auto_fulfill: milestone.auto_fulfill,
      delay_achievement_email_until_fulfilled: milestone.delay_achievement_email_until_fulfilled,
      publication_id: currentPublicationId,
      tiptap_state: emailContent,
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {
      target: { value, name },
    } = e;
    setMilestone({ ...milestone, [name]: value });
  };

  const handleSelect = (name: string, value: string) => {
    setMilestone({ ...milestone, [name]: value });
  };

  const handleContentChange = (value: any) => {
    setEmailContent(value);
  };

  const handleSwitch = (name: string, value: boolean) => {
    setMilestone({ ...milestone, [name]: value });
  };

  const onManagePromoCodesSelected = () => {
    setShowManagePromoCodes(true);
  };

  const onManagePromoCodesClosed = () => {
    setShowManagePromoCodes(false);
  };

  return (
    <ConfigureContainer selectedTab="milestones">
      <FormContextProvider>
        <RewardSlideOver
          isOpen={isAddRewardsModalOpen}
          onClose={onAddRewardModalClose}
          onSuccess={onAddRewardModalSuccess}
          publicationId={currentPublicationId}
        />
      </FormContextProvider>
      <PromoCodesSlideOver
        isOpen={showManagePromoCodes}
        onClose={onManagePromoCodesClosed}
        publicationId={currentPublicationId}
        rewardId={milestone && milestone?.reward_id ? milestone.reward_id : ''}
      />
      <div className="sm:rounded-md sm:overflow-hidden">
        <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
          <div className="flex items-center justify-between flex-wrap sm:flex-nowrap">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900 inline-flex">
                {milestoneId ? 'Edit Milestone' : 'Create a Milestone'}{' '}
                {isLoadingMilestone && <LoadingSpinner className="ml-2" />}
              </h3>
            </div>
          </div>
          <div>
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 space-y-8 divide-y divide-gray-200">
                  <div className="space-y-6">
                    <div>
                      <h3 className="text-lg leading-6 font-medium text-gray-900">Basic Setup</h3>
                      <p className="mt-1 text-sm text-gray-500">
                        How many referrals are needed and what reward should be given
                      </p>
                    </div>
                    <Input
                      name="num_referrals"
                      labelText="Number of referrals needed"
                      type="number"
                      value={milestone?.num_referrals ? String(milestone?.num_referrals) : ''}
                      onChange={handleChange}
                      required
                    />
                    <Dropdown
                      name="reward_id"
                      labelText="Reward"
                      value={milestone?.reward_id}
                      onSelect={handleSelect}
                      options={rewardOptions || []}
                      topRightLinkText="Add Reward"
                      onTopRightLinkClick={onRequestAddReward}
                      required
                    />
                  </div>
                  <div className="space-y-6">
                    <div className="mt-6">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">Advanced Settings</h3>
                      <p className="mt-1 text-sm text-gray-500">
                        Customize how fulfillment is performed and when emails are triggered
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <Switch
                        name="auto_fulfill"
                        labelText="Auto Fulfill"
                        helperText="We will automatically mark any achievements as fulfilled (useful when the email being sent contains the actual reward)"
                        checked={milestone?.auto_fulfill}
                        onChange={handleSwitch}
                      />
                      <Switch
                        name="delay_achievement_email_until_fulfilled"
                        labelText="Delay Email Until Fulfilled"
                        className="mt-4"
                        helperText="By default, the achievement email is sent as soon as someone achieves a milestone. This will delay it until it has been fulfilled. This is useful if you don't want to notify a user that they have achieved a milestone until you've had a chance to review their referrals."
                        checked={milestone?.delay_achievement_email_until_fulfilled}
                        onChange={handleSwitch}
                      />
                    </div>
                  </div>
                  {isRewardTypePromoCode() && (
                    <div className="space-y-6">
                      <div className="mt-6">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Promo Codes</h3>
                        <p className="mt-1 text-sm text-gray-500">
                          The reward assigned to this milestone uses Promo Codes. Promo Codes are pulled from your
                          available list and assigned to each achievement automatically. A use case would be if you
                          offer someone a free t-shirt and the promo code gives them the ability to order one from your
                          store with a 100% discount. Embed the promo code in the milestone email by using the tag{' '}
                          <span className="text-pink-400">&#123;&#123; reward_promo_code &#125;&#125;</span>.
                        </p>
                      </div>
                      <div className="flex">
                        <Button
                          type="button"
                          block={false}
                          variant="primary-inverse"
                          onClick={onManagePromoCodesSelected}
                        >
                          Manage Promo Codes
                        </Button>
                      </div>
                    </div>
                  )}
                  <div className="space-y-6">
                    <div className="mt-6">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">Milestone Email</h3>
                      <p className="mt-1 text-sm text-gray-500">
                        When a milestone is achieved, the subscriber will receive an email congratulating them and can
                        include any additional information about the milestone
                      </p>
                    </div>
                    <Input
                      name="subject_line"
                      labelText="Subject Line"
                      type="text"
                      value={milestone?.subject_line || ''}
                      onChange={handleChange}
                      required
                    />
                    <Input
                      name="preview_text"
                      labelText="Preview Text"
                      type="text"
                      value={milestone?.preview_text || ''}
                      onChange={handleChange}
                    />
                    <LoadingBox isLoading={isLoadingMilestone} isError={isErrorMilestone}>
                      <RichContent
                        isLoading={isLoadingMilestone}
                        name="content"
                        labelText="Content"
                        publicationId={currentPublicationId}
                        initialValue={emailContent || {}}
                        onChange={handleContentChange}
                        onFetchPreview={onFetchPreview}
                        fetchPreviewDisabled={!milestone?.reward_id}
                        required
                      />
                    </LoadingBox>
                  </div>
                </div>
                <div className="px-4 py-3 text-right sm:px-6">
                  <Button type="button" onClick={handleSubmit} loading={isSavingMilestone}>
                    {milestoneId ? 'Update' : 'Create'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ConfigureContainer>
  );
};

export default MilestoneForm;
