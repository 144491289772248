import { Dispatch, Fragment, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { AdjustmentsHorizontalIcon, ArchiveBoxIcon } from '@heroicons/react/24/outline';
import { SparklesIcon } from '@heroicons/react/24/solid';
import { cx } from 'class-variance-authority';

import Badge from '@/components/Badge';
import { DatePicker } from '@/components/Form';
import { Typography } from '@/components/Typography';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useContentTags, useSegments } from '@/hooks';
import { useAuthors } from '@/hooks/useAuthors';
import useGuestAuthors from '@/hooks/useGuestAuthors';
import { useTiers } from '@/hooks/useTiers';
import { Option } from '@/interfaces/general';
import { Button } from '@/ui/Button';
import { ButtonGroup } from '@/ui/Button/ButtonGroup/ButtonGroup';
import { Dropdown } from '@/ui/Dropdown';
import { MultiSelectDropdown } from '@/ui/MultiSelectDropdown';
import SearchInput from '@/ui/SearchInput';

type SearchProps = {
  filterValues: {
    search: string | null | undefined;
    statusFilters: string[] | null | undefined;
    platform: string | null | undefined;
    publishStartDate: Date | null | undefined;
    publishEndDate: Date | null | undefined;
    defaultAudiences: string[] | null | undefined;
    includeSegments: string[] | null | undefined;
    excludeSegments: string[] | null | undefined;
    contentTags: string[] | null | undefined;
    authors: string[] | null | undefined;
    sort: string;
  };
  filterActions: {
    setSearch: Dispatch<SetStateAction<string | null | undefined>>;
    setStatusFilters: Dispatch<SetStateAction<string[] | null | undefined>>;
    setPlatform: Dispatch<SetStateAction<string | null | undefined>>;
    setPublishStartDate: Dispatch<SetStateAction<Date | null | undefined>>;
    setPublishEndDate: Dispatch<SetStateAction<Date | null | undefined>>;
    setDefaultAudiences: Dispatch<SetStateAction<string[] | null | undefined>>;
    setIncludeSegments: Dispatch<SetStateAction<string[] | null | undefined>>;
    setExcludeSegments: Dispatch<SetStateAction<string[] | null | undefined>>;
    setContentTags: Dispatch<SetStateAction<string[] | null | undefined>>;
    setAuthors: Dispatch<SetStateAction<string[] | null | undefined>>;
    setSort: Dispatch<SetStateAction<'newest_first' | 'oldest_first' | 'most_relevant'>>;
  };
  shouldResetSearch: boolean;
  handleResetSearch: () => void;
  handleClearFilters: () => void;
};

const Filter = ({
  filterValues,
  filterActions,
  shouldResetSearch,
  handleResetSearch,
  handleClearFilters,
}: SearchProps) => {
  const {
    search,
    statusFilters,
    platform,
    publishStartDate,
    publishEndDate,
    defaultAudiences,
    includeSegments,
    excludeSegments,
    contentTags,
    authors,
    sort,
  } = filterValues;
  const {
    setSearch,
    setStatusFilters,
    setPlatform,
    setPublishStartDate,
    setPublishEndDate,
    setDefaultAudiences,
    setIncludeSegments,
    setExcludeSegments,
    setContentTags,
    setAuthors,
    setSort,
  } = filterActions;
  const platformOptions = [
    { label: 'Email and web', value: 'both' },
    { label: 'Email only', value: 'email' },
    { label: 'Web only', value: 'web' },
  ];

  const statusOptions = [
    {
      label: 'Published',
      labelElement: (
        <Badge size="sm" type="success">
          Published
        </Badge>
      ),
      value: 'published',
    },
    {
      label: 'Scheduled',
      labelElement: (
        <Badge size="sm" type="warning">
          Scheduled
        </Badge>
      ),
      value: 'scheduled',
    },
    {
      label: 'Draft',
      labelElement: (
        <Badge size="sm" type="information">
          Draft
        </Badge>
      ),
      value: 'draft',
    },
    {
      label: 'Featured',
      labelElement: (
        <Badge size="sm" type="info_blue" Icon={SparklesIcon}>
          Featured
        </Badge>
      ),
      value: 'featured',
    },
    {
      label: 'Archived',
      labelElement: (
        <Badge size="sm" type="information" Icon={ArchiveBoxIcon}>
          Archived
        </Badge>
      ),
      value: 'archived',
    },
  ];

  const [publicationId] = useCurrentPublicationState();
  const { data: tiersQuery, isLoading: loadingTiers } = useTiers(publicationId);
  const tiers = useMemo(() => tiersQuery || [], [tiersQuery]);
  const initialAudienceOptions: Option[] = [
    { label: 'All Free Subscribers', value: 'free' },
    { label: 'All Paid Subscribers', value: 'paid' },
    ...tiers.map((option) => ({
      label: `All ${option.name} subscribers`,
      value: option.id,
    })),
  ];

  const [audienceOptions, setAudienceOptions] = useState<Option[]>(initialAudienceOptions);

  useEffect(() => {
    if (loadingTiers) return;

    const options: Option[] = [
      { label: 'All Free Subscribers', value: 'free' },
      { label: 'All Paid Subscribers', value: 'paid' },
    ];

    if (tiers.length === 0) {
      setAudienceOptions(options);
      return;
    }

    setAudienceOptions([
      ...options,
      ...tiers.map((option) => ({
        label: `All ${option.name} subscribers`,
        value: option.id,
      })),
    ]);
  }, [tiersQuery, loadingTiers, tiers]);
  const segmentsQuery = useSegments({ shouldFetchAll: true, order: 'segment.name', dir: 'asc' });
  const { data: segmentsData } = segmentsQuery;
  const segmentOptions = useMemo<Option[]>(() => {
    const segments = segmentsData?.pages.flatMap((page) => page.segments) || [];
    if (!segments) return [];

    return segments.map((s: { id: string; name: string }) => ({
      label: s.name,
      value: s.id,
    }));
  }, [segmentsData]);

  const contentTagsQuery = useContentTags({ search: '', amountPerPage: 10000 });
  const { data: contentTagsData } = contentTagsQuery;
  const contentTagOptions = useMemo<Option[]>(() => {
    return (
      contentTagsData?.pages
        .flatMap((page) => page.contentTags)
        .map(
          (tag) =>
            ({
              label: tag.display,
              value: tag.id,
            } as Option)
        ) || []
    );
  }, [contentTagsData]);

  const authorsQuery = useAuthors();
  const { data: authorsData } = authorsQuery;
  const guestAuthorsQuery = useGuestAuthors();
  const { data: guestAuthorsData } = guestAuthorsQuery;
  const authorOptions = useMemo<Option[]>(() => {
    return [...(authorsData || []), ...(guestAuthorsData?.guest_authors || [])].map((author) => ({
      label: author.name,
      value: author.id,
    }));
  }, [authorsData, guestAuthorsData]);

  const defaultDate = (change = -1, date = new Date()) => {
    date.setMonth(date.getMonth() + change);

    return date;
  };

  const datePickerOptions = {
    clickOpens: true,
    enableTime: false,
    altFormat: 'm/j/Y',
  };

  const handleSortChange = (name: string, value: 'oldest_first' | 'newest_first' | 'most_relevant') => {
    setSort(value);
  };

  const filterValuesArray = [
    { value: statusFilters, check: Array.isArray(statusFilters) && statusFilters.length > 0 },
    { value: platform, check: !!platform },
    { value: publishStartDate || publishEndDate, check: !!(publishStartDate || publishEndDate) },
    { value: defaultAudiences, check: Array.isArray(defaultAudiences) && defaultAudiences.length > 0 },
    { value: includeSegments, check: Array.isArray(includeSegments) && includeSegments.length > 0 },
    { value: excludeSegments, check: Array.isArray(excludeSegments) && excludeSegments.length > 0 },
    { value: contentTags, check: Array.isArray(contentTags) && contentTags.length > 0 },
    { value: authors, check: Array.isArray(authors) && authors.length > 0 },
  ];

  const numberFiltersApplied = filterValuesArray.reduce((count, filter) => count + (filter.check ? 1 : 0), 0);

  const filtersPresent = numberFiltersApplied > 0;

  return (
    <div className="">
      <div className="flex flex-col gap-2">
        <div className="flex w-full space-y-2 md:space-y-0 gap-4 flex-col items-end md:flex-row md:items-center">
          <div className="w-full">
            <SearchInput
              defaultValue={search || ''}
              shouldDebounce
              shouldReset={shouldResetSearch}
              onClearSearch={handleResetSearch}
              onSearch={setSearch}
              placeholder="Search posts"
              block
            />
          </div>

          <div className="flex flex-row gap-4">
            <Menu as="div" className={cx('relative inline-block')}>
              {() => (
                <>
                  <ButtonGroup variant="primary-inverse">
                    <Menu.Button as={Button} shade="dark">
                      <div className="flex flex-row gap-2 items-center">
                        <AdjustmentsHorizontalIcon className={cx('w-4 h-4 text-grey-600')} />
                        <span>Filters</span>
                        {filtersPresent && <span className="border border-surface-20 h-4" />}
                        {filtersPresent && (
                          <Badge type="tertiary" size="sm">
                            {numberFiltersApplied}
                          </Badge>
                        )}
                      </div>
                    </Menu.Button>
                  </ButtonGroup>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="z-50 absolute min-w-80 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none right-0">
                      <div>
                        <div
                          className="h-110 overflow-scroll pb-16 flex flex-col divide-y"
                          style={{ scrollbarWidth: 'none' }}
                        >
                          <div className="p-4 flex flex-col gap-6 border-b-surface-200">
                            <Typography token="font-medium/text/base">Filters</Typography>
                            <MultiSelectDropdown
                              selectedTagType={statusFilters && statusFilters?.length > 1 ? 'info_blue' : 'flush'}
                              name="status"
                              placeholderText="Status"
                              className="w-fit"
                              maxVisibleSelectedOptions={1}
                              staticOptions={statusOptions}
                              optionsContainerClassNames={{ width: 'w-fit' }}
                              shouldBindOptionAsValue
                              values={statusOptions.filter((option) => statusFilters?.includes(option.value))}
                              onSelect={(name: string, value: string[] | Option[]) => {
                                const selectedStatusOptions = value
                                  .map((status) => {
                                    if (typeof status === 'string') {
                                      return statusOptions.find((option) => option.value === status);
                                    }
                                    return statusOptions.find((option) => option.value === status.value);
                                  })
                                  .filter(Boolean) as Option[];
                                setStatusFilters(selectedStatusOptions.map((status) => status.value));
                              }}
                            />
                            <MultiSelectDropdown
                              selectedTagType="info_blue"
                              name="platform"
                              placeholderText="Platform"
                              className="w-fit"
                              maxVisibleSelectedOptions={1}
                              shouldBindOptionAsValue
                              staticOptions={platformOptions}
                              values={platformOptions.filter((option) => option.value === platform)}
                              onSelect={(name: string, value: Option[]) => {
                                setPlatform(value.length > 1 ? value[1].value : value[0]?.value);
                              }}
                            />
                            <div className="flex flex-col gap-2">
                              <Typography token="font-medium/text/sm">Publish date</Typography>
                              <div className="flex flex-row gap-2 items-center">
                                <DatePicker
                                  inline
                                  className="w-full"
                                  placeholderText="Start date"
                                  value={publishStartDate ? [publishStartDate] : undefined}
                                  onChange={(date) => setPublishStartDate(date)}
                                  pickerOptions={datePickerOptions}
                                  displayTimezone={false}
                                  displayClearButton
                                />
                                <span>-</span>
                                <DatePicker
                                  inline
                                  className="w-full"
                                  placeholderText="End date"
                                  value={publishEndDate ? [publishEndDate] : undefined}
                                  onChange={(date) => setPublishEndDate(date)}
                                  minDate={publishStartDate ? new Date(publishStartDate) : defaultDate(0)}
                                  pickerOptions={datePickerOptions}
                                  displayTimezone={false}
                                  displayClearButton
                                />
                              </div>
                            </div>
                          </div>
                          <div className="p-4 flex flex-col gap-6 border-b-surface-200">
                            <Typography token="font-medium/text/base">Audience</Typography>
                            <MultiSelectDropdown
                              selectedTagType="info_blue"
                              name="default_audience"
                              placeholderText="Default audience"
                              className="w-fit"
                              maxVisibleSelectedOptions={1}
                              staticOptions={audienceOptions}
                              shouldBindOptionAsValue
                              values={
                                (audienceOptions.filter((option) => defaultAudiences?.includes(option.value)) ||
                                  []) as Option[]
                              }
                              onSelect={(name: string, value: string[] | Option[]) => {
                                const selectedAudienceOptions = value
                                  .map((status) => {
                                    if (typeof status === 'string') {
                                      return audienceOptions.find((option) => option.value === status);
                                    }
                                    return audienceOptions.find((option) => option.value === status.value);
                                  })
                                  .filter(Boolean) as Option[];
                                setDefaultAudiences(selectedAudienceOptions.map((audience) => audience.value));
                              }}
                            />
                            <MultiSelectDropdown
                              selectedTagType="info_blue"
                              name="included_segments"
                              placeholderText="Included Segments"
                              className="w-fit"
                              maxVisibleSelectedOptions={1}
                              staticOptions={segmentOptions}
                              search
                              emptyLabel="No segments found"
                              shouldBindOptionAsValue
                              values={segmentOptions.filter((option) => includeSegments?.includes(option.value))}
                              onSelect={(name: string, value: string[] | Option[]) => {
                                const selectedIncludeSegments = value
                                  .map((status) => {
                                    if (typeof status === 'string') {
                                      return segmentOptions.find((option) => option.value === status);
                                    }
                                    return segmentOptions.find((option) => option.value === status.value);
                                  })
                                  .filter(Boolean) as Option[];
                                setIncludeSegments(selectedIncludeSegments.map((segment) => segment.value));
                              }}
                            />
                            <MultiSelectDropdown
                              selectedTagType="info_blue"
                              name="excluded_segments"
                              search
                              emptyLabel="No segments found"
                              placeholderText="Excluded Segments"
                              className="w-fit"
                              maxVisibleSelectedOptions={1}
                              staticOptions={segmentOptions}
                              shouldBindOptionAsValue
                              values={segmentOptions.filter((option) => excludeSegments?.includes(option.value))}
                              onSelect={(name: string, value: string[] | Option[]) => {
                                const selectedExcludeSegments = value
                                  .map((status) => {
                                    if (typeof status === 'string') {
                                      return segmentOptions.find((option) => option.value === status);
                                    }
                                    return segmentOptions.find((option) => option.value === status.value);
                                  })
                                  .filter(Boolean) as Option[];
                                setExcludeSegments(selectedExcludeSegments.map((segment) => segment.value));
                              }}
                            />
                          </div>
                          <div className="p-4 flex flex-col gap-6 border-b-surface-200 py-4">
                            <Typography token="font-medium/text/base">Attributes</Typography>
                            <MultiSelectDropdown
                              selectedTagType="info_blue"
                              name="content_tags"
                              search
                              emptyLabel="No content tags found"
                              placeholderText="Content tags"
                              optionsContainerClassNames={{ width: 'w-fit' }}
                              className="w-fit"
                              maxVisibleSelectedOptions={1}
                              staticOptions={contentTagOptions}
                              shouldBindOptionAsValue
                              values={
                                (contentTagOptions.filter((option) => contentTags?.includes(option.value)) ||
                                  []) as Option[]
                              }
                              onSelect={(name: string, value: Option[]) => {
                                setContentTags(value.map((tag) => tag.value));
                              }}
                            />
                            <MultiSelectDropdown
                              selectedTagType="info_blue"
                              name="authors"
                              placeholderText="Authors"
                              className="w-fit"
                              maxVisibleSelectedOptions={1}
                              staticOptions={authorOptions}
                              search
                              emptyLabel="No authors found"
                              shouldBindOptionAsValue
                              values={authorOptions.filter((option) => authors?.includes(option.value))}
                              onSelect={(name: string, value: string[] | Option[]) => {
                                const selectedAuthorOptions = value
                                  .map((status) => {
                                    if (typeof status === 'string') {
                                      return authorOptions.find((option) => option.value === status);
                                    }
                                    return authorOptions.find((option) => option.value === status.value);
                                  })
                                  .filter(Boolean) as Option[];
                                setAuthors(selectedAuthorOptions.map((author) => author.value));
                              }}
                            />
                          </div>
                        </div>
                        <div className="w-full px-4 py-3 flex flex-row justify-end items-center absolute bottom-0 bg-surface-50">
                          <Button variant="primary-inverse" onClick={handleClearFilters}>
                            Clear all
                          </Button>
                        </div>
                      </div>
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
            <Dropdown
              name="sort"
              options={[
                { label: 'Newest first', value: 'newest_first' },
                { label: 'Oldest first', value: 'oldest_first' },
                {
                  label: 'Most Relevant',
                  value: 'most_relevant',
                  disabled: search === '' || search === null || search === undefined,
                },
              ]}
              value={[sort]}
              onSelect={handleSortChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filter;
