/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import { ArrowTopRightOnSquareIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import moment from 'moment-mini';

import LoadingBox from '@/components/LoadingBox';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { Typography, TypographyRow, TypographyStack } from '@/components/Typography';
import { useStripeBillingSession } from '@/hooks/useBilling';
import { BillingActions } from '@/interfaces/billing';
import { PlanPrice } from '@/interfaces/plan_price';
import { Button } from '@/ui/Button';
import { capitalize, currencyFormatter } from '@/utils';

import { usePlanPriceChange } from '../../../_hooks';

interface Props {
  organizationId: string;
  publicationId: string;
  currentPlanPrice: PlanPrice;
  newPlanPriceId: string;
}

const TierChangeInfo: React.FC<Props> = ({ organizationId, publicationId, currentPlanPrice, newPlanPriceId }) => {
  const [showDetails, setShowDetails] = useState(false);
  const { data, isLoading, isError } = usePlanPriceChange({ organizationId, newPlanPriceId });
  const stripeInfoSession = useStripeBillingSession({ organizationId, publicationId, action: BillingActions.INFO });

  const intervalChanging = currentPlanPrice.interval !== data?.new_interval;
  const subscriberLimitChanging = currentPlanPrice.max_subscriptions !== data?.new_max_subscriptions;

  return (
    <LoadingBox isLoading={isLoading} isError={isError} height={320} backgroundClassName="bg-transparent">
      {data && (
        <TypographyStack gap="2">
          <Typography as="div" className="pb-4">
            You are currently on the {capitalize(currentPlanPrice.plan_name)} plan @{' '}
            {currentPlanPrice.max_subscriptions.toLocaleString()} subscriptions, billed {currentPlanPrice.interval}ly.{' '}
            This change will update you to the{' '}
            <b>
              {capitalize(data?.new_plan_name)} plan @ {data?.new_max_subscriptions.toLocaleString()} subscriptions,
              billed {data?.new_interval}ly
            </b>{' '}
            {data.is_upgrade ? 'starting immediately' : 'starting on your next billing cycle'}.
          </Typography>

          <div className="bg-surface-50 border border-surface-200 rounded p-4 space-y-6">
            <div className="flex justify-between">
              <Typography as="div" token="font-normal/text/sm">
                What you&apos;ll pay {data.new_interval}ly starting
                <br />
                {data.next_billing_date ? moment(data.next_billing_date).format('LL') : 'on your next billing cycle'}
              </Typography>
              <Typography as="div" token="font-normal/text/sm">
                {currencyFormatter(data.new_unit_amount / 100, 'USD', 2, 2)}
              </Typography>
            </div>
            <hr />
            <div className="flex justify-between">
              <Typography as="div" token="font-normal/text/sm">
                Amount due today
                <br />
                Prorated for this billing cycle
              </Typography>
              <Typography as="div" token="font-bold/text/sm">
                {data.is_upgrade
                  ? currencyFormatter(data.amount_due / 100, 'USD', 2, 2)
                  : currencyFormatter(0, 'USD', 2, 2)}
              </Typography>
            </div>
            {data.is_upgrade && (
              <div className="flex justify-center">
                <Button
                  size="xxs"
                  variant="flush"
                  iconRight
                  Icon={showDetails ? ChevronUpIcon : ChevronDownIcon}
                  type="button"
                  onClick={() => setShowDetails(!showDetails)}
                >
                  Price Breakdown
                </Button>
              </div>
            )}
            {showDetails && (
              <>
                <div>
                  <div className="flex justify-between">
                    <Typography as="div" token="font-bold/text/xs" colorWeight="600">
                      Description
                    </Typography>
                    <Typography as="div" token="font-bold/text/xs" colorWeight="600">
                      Amount
                    </Typography>
                  </div>
                  <hr />
                </div>
                {data.lines.map((line) => (
                  <div className="flex justify-between">
                    <Typography as="div" token="font-normal/text/sm" colorWeight="600">
                      {line.description}
                    </Typography>
                    <Typography as="div" token="font-normal/text/sm" colorWeight="600">
                      {currencyFormatter(line.amount / 100, 'USD', 2, 2)}
                    </Typography>
                  </div>
                ))}
                <div className="ml-auto mr-0 w-fit space-y-6">
                  <hr />
                  <div className="flex justify-end space-x-2">
                    <Typography as="div" token="font-normal/text/sm" colorWeight="600">
                      Total:
                    </Typography>
                    <Typography as="div" token="font-normal/text/sm" colorWeight="600">
                      {currencyFormatter(data.amount_due / 100, 'USD', 2, 2)}
                    </Typography>
                  </div>
                </div>
              </>
            )}
          </div>

          {data.is_upgrade ? (
            <Typography token="font-normal/text/xs">
              Clicking Confirm will charge your card on file for the prorated amount of{' '}
              {currencyFormatter(data.amount_due / 100, 'USD', 2, 2)} and immediately{' '}
              {intervalChanging && `change your billing cadence to ${data?.new_interval}ly`}
              {intervalChanging && subscriberLimitChanging && ' and '}
              {subscriberLimitChanging &&
                `update your subscriber limit to ${data.new_max_subscriptions.toLocaleString()}`}
              .
            </Typography>
          ) : (
            <Typography token="font-normal/text/xs">
              Clicking Confirm will schedule{' '}
              {intervalChanging && `your billing cadence to change to ${data?.new_interval}ly`}
              {intervalChanging && subscriberLimitChanging && ' and ' }
              {subscriberLimitChanging &&
                `your subscriber limit to change to ${data.new_max_subscriptions.toLocaleString()}`}
              {' '}on {data.next_billing_date ? moment(data.next_billing_date).format('LL') : 'your next billing cycle'}.{' '}
              This <b>will not</b> charge your card until that point.
            </Typography>
          )}
          {stripeInfoSession.isLoading || stripeInfoSession.isSuccess ? (
            <LoadingSpinner size="sm" />
          ) : (
            <TypographyRow gap="1" className="items-center">
              <button type="button" className="underline" onClick={() => stripeInfoSession.mutate({})}>
                <Typography token="font-normal/text/xs">Manage your billing details in Stripe</Typography>
              </button>
              <ArrowTopRightOnSquareIcon className="h-3 w-3 mt-1" />
            </TypographyRow>
          )}
        </TypographyStack>
      )}
    </LoadingBox>
  );
};

export default TierChangeInfo;
