import React, { useState } from 'react';
import toast from 'react-hot-toast';

import SplitButton from '@/components/SplitButton';
import Tooltip from '@/components/Tooltip';
import { usePausePlan } from '@/hooks/usePausePlan';
import { PlanPrice } from '@/interfaces/plan_price';
import { capitalize } from '@/utils';
import { PLAN } from '@/utils/plans';

import { useCreatePlanPriceChange } from '../../_hooks';

import DowngradePlanModal from './DowngradePlanModal';
import TierChangeModal from './TierChangeModal';

interface Props {
  organizationId: string;
  publicationId: string;
  planPrice: PlanPrice;
  upcomingPlanPrice: PlanPrice;
}

const DowngradeSplitButton: React.FC<Props> = ({ organizationId, publicationId, planPrice, upcomingPlanPrice }) => {
  const { data: pausePlanData } = usePausePlan(organizationId);
  const isPaused = !!pausePlanData?.resumes_at;

  const [isDowngradeTierModalOpen, setIsDowngradeTierModalOpen] = useState(false);
  const [isDowngradePlanModalOpen, setIsDowngradePlanModalOpen] = useState(false);

  const isPendingDowngrade = planPrice?.id !== upcomingPlanPrice?.id;
  const allowPlanDowngrade = planPrice?.plan_name !== PLAN.LAUNCH;
  const allowTierDowngrade = planPrice?.plan_name !== PLAN.CUSTOM && planPrice?.has_previous_tier;

  const tierChangeMutation = useCreatePlanPriceChange({
    publicationId,
    organizationId,
    onSuccess: () => {
      toast.success('Plan tier changed!');
      setTimeout(() => window.location.reload(), 500);
    },
  });

  if (!allowPlanDowngrade && !allowTierDowngrade) {
    return null;
  }

  return (
    <>
      <TierChangeModal
        isOpen={isDowngradeTierModalOpen}
        isWorking={tierChangeMutation.isLoading}
        onClose={() => setIsDowngradeTierModalOpen(false)}
        onSubmit={(newPlanPriceId) => tierChangeMutation.mutate({ newPlanPriceId })}
        organizationId={organizationId}
        publicationId={publicationId}
        currentPlanPrice={planPrice}
        isAvailableOption={(option) => option.max_subscriptions < planPrice.max_subscriptions}
      />
      <DowngradePlanModal
        organizationId={organizationId}
        publicationId={publicationId}
        planPrice={planPrice}
        isOpen={isDowngradePlanModalOpen}
        onClose={() => setIsDowngradePlanModalOpen(false)}
      />
      <Tooltip
        id="organization-downgrade-button"
        showIcon={false}
        text={
          isPaused
            ? 'You must unpause your plan in order to make modifications'
            : 'You must cancel the pending downgrade to modify your plan.'
        }
        isEnabled={isPendingDowngrade || isPaused}
      >
        <SplitButton
          size="xs"
          variant="primary-inverse"
          disabled={isPendingDowngrade || isPaused}
          buttons={[
            {
              label: 'Downgrade Plan',
              helperText: 'Downgrade to monthly billing or to Launch and Scale plans',
              onClick: () => setIsDowngradePlanModalOpen(true),
              disabled: !allowPlanDowngrade,
              button: {
                text: 'Downgrade',
              },
            },
            {
              label: 'Lower Subscriber Limit',
              helperText: 'Move to a lower tier of your current plan to decrease your allowed subscribers',
              onClick: () => setIsDowngradeTierModalOpen(true),
              defaultSelected: !allowPlanDowngrade,
              disabled: !allowTierDowngrade,
              disabledTooltip: `You are currently on the lowest tier of the ${capitalize(planPrice.plan_name)} plan.`,
              button: {
                text: 'Downgrade',
              },
            },
          ]}
        />
      </Tooltip>
    </>
  );
};

export default DowngradeSplitButton;
