import { useState } from 'react';

import { Switch } from '@/components/Form';
import { useApproveAllSubscribers } from '@/hooks/usePublications';
import { Publication } from '@/interfaces/publication';
import RequireApprovalModal from '@/pages/Settings/Pages/Publication/General/RequireApprovalModal';
import analytics from '@/utils/analytics';

import BodyContainer from '../../_components/BodyContainer';
import CardHeader from '../../_components/CardHeader';
import FormRow from '../../_components/FormRow';
import { usePublicationSettings } from '../context';

interface Props {
  publication: Publication;
}

const ContentAccessForm = ({ publication }: Props) => {
  const { settings, handleChangeInAttribute, savingAttribute } = usePublicationSettings();
  const [isRequireApprovalModalOpen, setIsRequireApprovalModalOpen] = useState(false);

  const [isPrivate, setIsPrivate] = useState(publication.private);
  const [shouldRequireSubscriberApproval, setShouldRequireSubscriberApproval] = useState(
    publication.require_subscriber_approval || false
  );
  const [isWhiteLabeled, setIsWhiteLabeled] = useState(!!publication.white_labeled);

  const approveAllMutation = useApproveAllSubscribers(publication?.id || '');

  const handleSubscriberApprovalModal = (type: string) => () => {
    switch (type) {
      case 'close':
        setIsRequireApprovalModalOpen(false);
        break;
      case 'proceed':
        setShouldRequireSubscriberApproval(false);
        approveAllMutation
          .mutateAsync()
          .then(() => {
            handleChangeInAttribute('require_subscriber_approval')(false);
            setIsRequireApprovalModalOpen(false);
          })
          .catch(() => {
            // Revert value in local state
            setShouldRequireSubscriberApproval(true);
          });

        break;
      default:
    }
  };

  const handleSubscriberApprovalChange = async (val: boolean) => {
    if (shouldRequireSubscriberApproval) {
      setIsRequireApprovalModalOpen(true);
      return;
    }

    analytics.track('Require Subscriber Approval Toggled on');

    try {
      setShouldRequireSubscriberApproval(val);
      await handleChangeInAttribute('require_subscriber_approval')(val);
    } catch (error: any) {
      setShouldRequireSubscriberApproval(!val);
    }
  };

  const canSetPrivatePub = settings?.private_publications;

  return (
    <BodyContainer>
      <RequireApprovalModal
        publication={publication}
        isOpen={isRequireApprovalModalOpen}
        handleOnClose={handleSubscriberApprovalModal('close')}
        handleOnProceed={handleSubscriberApprovalModal('proceed')}
      />
      <CardHeader
        title="Content Access"
        description="Control who views your published posts and how your site and emails look"
      />
      {canSetPrivatePub ? (
        <>
          <FormRow
            label="Private Publication"
            helperText="Enable this to restrict access to your site and content. Only logged-in subscribers can view your posts. Non-subscribers will see gated content."
          >
            <Switch
              variant="primary"
              checked={isPrivate}
              name="private"
              onChange={async (_name: string, value: boolean) => {
                try {
                  setIsPrivate(value);
                  await handleChangeInAttribute(_name)(value);
                } catch (error: any) {
                  setIsPrivate(!value);
                }
              }}
              disabled={savingAttribute === 'private'}
            />
          </FormRow>

          <FormRow
            label="Require Subscriber Approval"
            helperText="Enable to let visitors request access. Review and approve/deny subscription requests in the subscribers page. Control who can view your content."
          >
            <Switch
              variant="primary"
              checked={shouldRequireSubscriberApproval}
              name="require_subscriber_approval"
              onChange={async (_name: string, value: boolean) => handleSubscriberApprovalChange(value)}
              disabled={savingAttribute === 'require_subscriber_approval'}
            />
          </FormRow>
        </>
      ) : null}
      <FormRow
        label="Private Branding"
        helperText="Toggle on to remove beehiiv branding from your emails and website. Fully customize the look and feel to match your brand."
      >
        <Switch
          variant="primary"
          checked={isWhiteLabeled}
          name="white_labeled_at"
          disabledText="Only Enterprise and Max customers can remove beehiiv branding from email and web."
          disabled={!settings?.white_labeling || savingAttribute === 'white_labeled_at'}
          onChange={async (_name: string, value: boolean) => {
            const newValue = value ? new Date().toISOString() : null;
            try {
              setIsWhiteLabeled(!!newValue);
              await handleChangeInAttribute(_name)(newValue);
            } catch (error: any) {
              setIsWhiteLabeled(newValue === null);
            }
          }}
        />
      </FormRow>
    </BodyContainer>
  );
};

export default ContentAccessForm;
