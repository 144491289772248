import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { PencilSquareIcon } from '@heroicons/react/24/solid';

import ActionModal from '@/components/ActionModal';
import Badge from '@/components/Badge';
import { Color } from '@/components/DesignTokens';
import EllipsisDropdown from '@/components/EllipsisDropdown';
import { Input } from '@/components/Form';
import { Typography } from '@/components/Typography';
import { useUpdateSubdomain } from '@/hooks/useSubdomain';
import { Button } from '@/ui/Button';
import appendSettingsPublicationId from '@/utils/appendSettingsPublicationId';

import { WebBadge } from './StatusBadge';

interface Props {
  subdomain: string;
  onSharedInfra: boolean;
  smartWarmingActive: boolean;
  disabled: boolean;
  publicationId: string;
}

const WebShared: React.FC<Props> = ({ subdomain, onSharedInfra, smartWarmingActive, publicationId, disabled }) => {
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newSubdomain, setNewSubdomain] = useState<string>(subdomain);

  const { mutateAsync: updateSubdomain, isLoading } = useUpdateSubdomain();

  const onSave = async () => {
    try {
      await updateSubdomain({ subdomain: newSubdomain });

      toast.success('Saved!');
      setIsModalOpen(false);
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  useEffect(() => {
    setNewSubdomain(subdomain);
  }, [subdomain]);

  return (
    <>
      <ActionModal
        resourceId={subdomain}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        isWorking={isLoading}
        onProceed={onSave}
        headerText="Update Web URL"
      >
        <div className="my-4">
          <div>
            <Input
              name="subdomain"
              labelText="New web URL"
              trailingText=".beehiiv.com"
              value={newSubdomain}
              className="flex-grow"
              helperText="This is your publication's live URL, accessible for anyone to visit."
              onChange={(e) => setNewSubdomain(e.target.value)}
            />
          </div>

          <Typography token="font-normal/text/xs" color={Color.DANGER} as="div" className="mt-4">
            <b>NOTE:</b> {onSharedInfra && 'Changing this will also update your email username. '}
            {smartWarmingActive && 'Changing this will also update your Secondary Email Username. '}
            All of your existing posts will be updated to the new URL. This means that URLs to previously published
            posts, including backlinks, will be outdated and no longer work.
          </Typography>
        </div>
      </ActionModal>

      <div className="p-4 bg-gray-50 border border-surface-200 rounded-lg">
        <div className="flex flex-row items-start justify-between mb-4">
          <div className="flex items-start space-x-2">
            <Badge size="sm">Web</Badge>
            <WebBadge />
          </div>
          {!disabled && (
            <EllipsisDropdown
              options={[
                {
                  label: 'Configure Custom Domain',
                  onClick: () =>
                    navigate(appendSettingsPublicationId('/settings/publication/domain/web/new', publicationId)),
                },
              ]}
            />
          )}
        </div>

        <div className="flex items-end space-x-4">
          <Input
            name="subdomain"
            labelText="Web URL"
            trailingText=".beehiiv.com"
            value={subdomain}
            className="flex-grow"
            readOnly
          />
          <Button
            variant="primary-inverse"
            onClick={() => setIsModalOpen(true)}
            Icon={PencilSquareIcon}
            className="h-[38] flex-shrink"
            disabled={disabled}
          >
            Edit
          </Button>
        </div>
      </div>
    </>
  );
};

export default WebShared;
