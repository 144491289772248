import { ArrowLeftIcon } from '@heroicons/react/24/solid';

import { RadioSelect, Textarea } from '@/components/Form';
import { DowngradeReason } from '@/interfaces/downgrade';
import { IUser } from '@/interfaces/user';
import CardHeader from '@/routes/settings/_components/CardHeader';
import { Button } from '@/ui/Button';

type Reason = {
  name: string;
  value: string;
};

interface Props {
  user?: IUser;
  reasons: Reason[];
  reason: DowngradeReason | undefined;
  onReasonChange: (value: DowngradeReason) => void;
  feedback: string | undefined;
  onFeedbackChange: (value: string) => void;
  onPreviousStep: () => void;
  onNextStep: () => void;
  onCancel: () => void;
  isLoading: boolean;
}

const Feedback = (props: Props) => {
  const {
    user,
    reasons,
    reason,
    onReasonChange,
    feedback,
    onFeedbackChange,
    onPreviousStep,
    onNextStep,
    onCancel,
    isLoading,
  } = props;

  if (!user) return null;

  return (
    <div className="flex flex-col gap-y-6 justify-between min-h-[80vh]">
      <div className="flex flex-col gap-10 flex-grow">
        <CardHeader
          title="What made you decide to cancel?"
          description="Every bit of feedback helps us improve our product."
        />

        <div className="lg:flex items-start justify-between space-y-8 lg:space-y-0">
          <div className="w-full">
            <form className="space-y-4">
              <RadioSelect
                labelText="Which of these describes your experience?"
                value={reason}
                onSelect={(val) => onReasonChange(val as DowngradeReason)}
                options={reasons}
                hasBorders={false}
                required
              />

              <Textarea
                className="w-full"
                name="feedback"
                labelText="Is there anything else you’d like to add?"
                placeholderText="Please tell us more so we can improve our offering"
                value={feedback || ''}
                showOptional
                onChange={(e) => onFeedbackChange(e.target.value)}
              />
            </form>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-y-2 gap-x-2 justify-end sm:flex-row sm:justify-between">
        <Button variant="flush" Icon={ArrowLeftIcon} onClick={onPreviousStep}>
          Back
        </Button>

        <div className="flex flex-col gap-y-2 sm:flex-row sm:gap-x-2">
          <Button variant="primary-inverse" onClick={onCancel}>
            Nevermind, keep my plan
          </Button>

          <Button variant="primary" loading={isLoading} onClick={onNextStep}>
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Feedback;
