import React, { useEffect, useMemo, useState } from 'react';

import { SimpleSelect } from '@/components/Form';
import LoadingBox from '@/components/LoadingBox';
import { Typography } from '@/components/Typography';
import { PlanPrice, PlanPriceOption } from '@/interfaces/plan_price';
import { capitalize } from '@/utils';

import usePlanPriceOptions from '../../../_hooks/usePlanPriceOptions';

interface Props {
  organizationId: string;
  currentPlanPrice: PlanPrice;
  interval: PlanPrice['interval'];
  planName: PlanPrice['plan_name'];
  onSelect: (val: string) => void;
  value?: string;
  isAvailableOption?: (option: PlanPriceOption) => boolean;
}

const TierSelect: React.FC<Props> = ({
  organizationId,
  currentPlanPrice,
  value,
  interval,
  planName,
  onSelect,
  isAvailableOption = () => true,
}) => {
  const [showAdditionalContext, setShowAdditionalContext] = useState(false);

  const {
    data: planPriceOptions,
    isLoading,
    isError,
  } = usePlanPriceOptions({
    organizationId,
    interval,
    planName,
  });

  const availableOptions = useMemo(
    () => planPriceOptions?.filter(isAvailableOption),
    [isAvailableOption, planPriceOptions]
  );

  // If there is a tier available that matches their current subscriber limit, select that one as the default.
  useEffect(() => {
    if (availableOptions && !value) {
      const matchedOption = availableOptions?.find(
        (option) => option.max_subscriptions === currentPlanPrice.max_subscriptions
      );

      if (matchedOption) {
        onSelect(matchedOption.id);
        setShowAdditionalContext(true);
      }
    }
  }, [availableOptions, currentPlanPrice.max_subscriptions, onSelect, value]);

  return (
    <LoadingBox isLoading={isLoading} isError={isError} backgroundClassName="bg-transparent" height={120}>
      {availableOptions && (
        <>
          <Typography as="div">
            The current tier of your <b>{capitalize(currentPlanPrice.plan_name)}</b> plan allows for up to{' '}
            {currentPlanPrice.max_subscriptions.toLocaleString()} subscriptions.{' '}
            {showAdditionalContext &&
              'If you would like to change your allowed number of subscriptions, select an option below.'}
          </Typography>
          <SimpleSelect
            className="my-6"
            name="desired_plan_price"
            value={value}
            onSelect={(_, val) => onSelect(val)}
            options={availableOptions.map((option) => ({
              label: `Up to ${option.max_subscriptions.toLocaleString()} Subscriptions`,
              value: option.id,
            }))}
          />
        </>
      )}
    </LoadingBox>
  );
};

export default TierSelect;
