import { useCallback } from 'react';

import { Slider } from '../../../UI/Slider';
import { Text } from '../../../UI/Text';
import { AttributeSettingProps } from '../types';

type SliderSettingsProps = AttributeSettingProps & {
  property: string;
  title: string;
  min?: number;
  max?: number;
  unit?: string;
};

const parseWidth = (width?: string | number, unit?: string) => {
  if (!width) return 0;
  if (typeof width === 'string') {
    // remove % and parse to number
    return parseInt(width.replace(unit ?? '%', ''), 10);
  }
  return width;
};

export const SliderSettings = ({ editor, activeNodeResult, property, title, min, max, unit }: SliderSettingsProps) => {
  const { activeNodeType, activeNodePos, activeNodeAttributes } = activeNodeResult;

  const handleSliderChange = useCallback(
    (value: number) => {
      if (activeNodePos === undefined || !activeNodeType) return;

      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, property, `${value}${unit ?? '%'}`);
        return true;
      });
    },
    [activeNodePos, activeNodeType, property, editor, unit]
  );

  return (
    <div className="flex items-center justify-stretch gap-2">
      <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
        {title}
      </Text>
      <div className="grow flex items-center h-[32px] gap-2">
        <div className="relative h-full">
          <input
            className="bg-wb-secondary rounded-lg border-none p-2 text-xs w-full"
            type="number"
            min={0}
            value={parseWidth(activeNodeAttributes?.[property], unit)}
            onChange={(e) => handleSliderChange(Number(e.target.value))}
          />
          {unit && (
            <Text size="2xs" weight="medium" className="absolute top-1/2 right-3 -translate-y-1/2" variant="secondary">
              {unit}
            </Text>
          )}
        </div>
        <Slider
          defaultValue={[parseWidth(activeNodeAttributes?.[property], unit)]}
          onValueChange={(value) => {
            handleSliderChange(value[0]);
          }}
          min={min}
          max={max}
        />
      </div>
    </div>
  );
};
