import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { SiteTemplate } from '@/interfaces/site_template';

import api from '../../services/swarm';

export default function useSiteTemplate({ siteTemplateId }: { siteTemplateId: string }) {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchSiteTemplate = () =>
    api
      .get(`/site_templates/${siteTemplateId}`, {
        params: {
          publication_id: currentPublicationId,
        },
      })
      .then((res) => res.data);

  return useQuery<SiteTemplate>({
    queryKey: ['publications', currentPublicationId, 'site_templates', siteTemplateId],
    queryFn: fetchSiteTemplate,
  });
}
