import SectionRenderer from '../../helpers/SectionRenderer';
import { RenderCondition } from '../../RenderCondition';
import { AttributeSettingProps } from '../../types';
import BorderSettings from '../BorderSettings';
import { ColorSettings } from '../ColorSettings';
import { IconPlacementSettings } from '../IconPlacementSettings';
import { IconSettings } from '../IconSettings';
import { PaddingSettings } from '../PaddingSettings';
import ShadowSettings from '../ShadowSettings';

import { ButtonStyleSettings } from './ButtonStyleSettings';

const ButtonSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  return (
    <RenderCondition editor={editor} allowedNodeTypes={['button']}>
      <SectionRenderer title="Style">
        <ButtonStyleSettings editor={editor} activeNodeResult={activeNodeResult} />
        <BorderSettings editor={editor} activeNodeResult={activeNodeResult} />
        <PaddingSettings editor={editor} activeNodeResult={activeNodeResult} property="padding" />
        <ShadowSettings editor={editor} activeNodeResult={activeNodeResult} title="Shadow" property="shadow" />
        <ColorSettings editor={editor} activeNodeResult={activeNodeResult} title="Text" property="customTextColor" />
        <ColorSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          title="Background"
          property="customBackgroundColor"
        />
        <IconSettings editor={editor} activeNodeResult={activeNodeResult} />
        <IconPlacementSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          title="Icon Placement"
          property="iconPlacement"
        />
        <ColorSettings editor={editor} activeNodeResult={activeNodeResult} title="Icon Color" property="iconColor" />
      </SectionRenderer>
    </RenderCondition>
  );
};

export { ButtonSettings };
