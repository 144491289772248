import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { CaretDown } from '@phosphor-icons/react';

import { useWebsiteContext } from '@/context/website-context';
import { useCurrentPublication } from '@/hooks';
import usePublishSite from '@/hooks/useSite/usePublishSite';
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '@/routes/website/_components/UI/DropdownMenu';

import SiteToggle from '../Main/SiteToggle';
import { Button } from '../UI/Button';
import { Text } from '../UI/Text';

import { PublishSettingsStep } from './PublishSettingsStep';
import { SearchPagesStep } from './SearchPagesStep';
import { Section } from './Section';
import { SelectPagesStep } from './SelectPagesStep';

export const PublishDropDown = () => {
  const [step, setStep] = useState<'publish_settings' | 'select_pages' | 'search_pages'>('publish_settings');
  const { site, pages } = useWebsiteContext();
  const { data: currentPublication } = useCurrentPublication();

  const [isSettingsSelected, setIsSettingsSelected] = useState(true);

  const [selectedPages, setSelectedPages] = useState<Record<string, boolean>>({});
  const [isPublished, setIsPublished] = useState(false);

  const isAllPagesSelected = pages && selectedPages && pages?.every((page) => selectedPages[page.id]);

  const { mutate: publishSiteMutate, isLoading } = usePublishSite({
    id: site?.id || '',
    onSuccess: () => {
      toast.success('Site published successfully');
      setIsPublished(true);
      setTimeout(() => {
        setIsPublished(false);
      }, 3000);
    },
  });

  const onPublish = () => {
    const publishPageIDs = Object.keys(selectedPages).filter((pageId) => selectedPages[pageId]);

    publishSiteMutate({
      publishSettings: isSettingsSelected,
      publishPageIDs,
    });
  };

  const onSelectAllPages = () => {
    if (!pages) return;
    if (isAllPagesSelected) {
      setSelectedPages({});
    } else {
      pages.forEach((page) => {
        setSelectedPages((prev) => ({ ...prev, [page.id]: true }));
      });
    }
  };

  useEffect(() => {
    // Select all pages by default when the component mounts or when pages change
    if (pages) {
      pages.forEach((page) => {
        setSelectedPages((prev) => ({ ...prev, [page.id]: true }));
      });
    }
  }, [pages]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button className="p-2.5" RightIcon={CaretDown}>
          <Text size="xs" weight="medium" variant="on-accent">
            Publish
          </Text>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent sideOffset={2} align="end" className="flex flex-col gap-0 p-0 w-[312px]">
        <SiteToggle
          title={currentPublication?.name || ''}
          usesDreamBuilderSite={currentPublication?.uses_dream_builder_site || false}
        />
        {step === 'publish_settings' && (
          <>
            <PublishSettingsStep
              goToSelectPagesStep={() => setStep('select_pages')}
              isSettingsSelected={isSettingsSelected}
              setIsSettingsSelected={setIsSettingsSelected}
              selectedPages={selectedPages}
              onSelectAllPages={onSelectAllPages}
            />
            <Section>
              <Button isDisabled={isLoading} isLoading={isLoading} onClick={isPublished ? () => {} : onPublish}>
                <Text size="xs" weight="medium" variant="on-accent">
                  {isPublished ? 'Published' : 'Publish to domain'}
                </Text>
              </Button>
            </Section>
          </>
        )}
        {step === 'select_pages' && (
          <SelectPagesStep
            onBack={() => setStep('publish_settings')}
            selectedPages={selectedPages}
            onSelectPage={(id, checked) => setSelectedPages((prev) => ({ ...prev, [id]: checked }))}
            onSelectAllPages={onSelectAllPages}
            goToSearchPagesStep={() => setStep('search_pages')}
          />
        )}
        {step === 'search_pages' && (
          <SearchPagesStep
            onBack={() => setStep('select_pages')}
            selectedPages={selectedPages}
            onSelectPage={(id, checked) => {
              setSelectedPages((prev) => ({ ...prev, [id]: checked }));
            }}
            onSelectAllPages={onSelectAllPages}
          />
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
