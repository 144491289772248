import { useCallback } from 'react';
import { Empty, X } from '@phosphor-icons/react';
import * as Icon from '@phosphor-icons/react';
import type { IconTypes } from '@shared/dream-components';

import { Button } from '../../../UI/Button';
import { SimpleInput, SimpleInputWrapper } from '../../../UI/Input';
import { Popover, PopoverContent, PopoverTrigger } from '../../../UI/Popover';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../../UI/Tabs';
import { Text } from '../../../UI/Text';
import { AttributeSettingProps } from '../types';

import { IconSelector } from './ui/IconSelector';

type IconSettingsProps = AttributeSettingProps & {
  property?: string;
};

const DEFAULT_PROPERTY = 'icon';

export const IconSettings = ({ editor, activeNodeResult, property = DEFAULT_PROPERTY }: IconSettingsProps) => {
  const { activeNodeAttributes, activeNodePos } = activeNodeResult;

  const { icon } = activeNodeAttributes;
  const isNone = !icon;

  const IconComponent = !isNone ? Icon[icon.iconName as IconTypes] : null;

  const handleReset = useCallback(() => {
    editor.commands.command(({ tr }) => {
      tr.setNodeAttribute(activeNodePos, property, null);
      return true;
    });
  }, [editor, activeNodePos, property]);

  const handleIconChange = useCallback(
    (value: { type: string; iconType: string; iconName: string }) => {
      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, property, { ...icon, ...(value || {}) });
        return true;
      });
    },
    [editor, activeNodePos, icon, property]
  );

  return (
    <Popover>
      <PopoverTrigger asChild>
        <div className="flex items-center justify-stretch gap-2 select-none">
          <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
            Icon
          </Text>

          <div className="grow bg-wb-secondary rounded-lg shadow-sm">
            <div className="w-full justify-between flex items-center gap-2 p-2 cursor-pointer">
              <div className="flex items-center gap-1">
                {isNone ? (
                  <Empty className="text-wb-secondary" weight="bold" />
                ) : (
                  IconComponent && <IconComponent className="w-4 h-4 rounded-md" />
                )}

                <Text size="2xs" weight="medium" className="capitalize">
                  {icon?.iconType || 'None'}
                </Text>
              </div>
              {!isNone && (
                <Button
                  variant="ghost"
                  Icon={X}
                  iconClassName="text-wb-secondary w-3 h-3"
                  onClick={handleReset}
                  className="p-0"
                />
              )}
            </div>
          </div>
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-[255px] p-0" align="center" side="left" sideOffset={20}>
        <div className="h-[500px] p-3 flex flex-col gap-4 min-h-0">
          <Text size="sm" weight="semibold">
            Icon
          </Text>
          <Tabs defaultValue="icon" className="grow flex flex-col max-h-full min-h-0">
            <TabsList>
              <TabsTrigger value="icon">Icons</TabsTrigger>
              <TabsTrigger value="emoji">Emojis</TabsTrigger>
            </TabsList>
            <TabsContent value="icon" asChild>
              <IconSelector onChange={handleIconChange} />
            </TabsContent>
            <TabsContent value="emoji">
              <div className="max-h-[500px] overflow-y-auto p-3 flex flex-col gap-4">
                <SimpleInputWrapper>
                  <SimpleInput placeholder="Search emojis" />
                </SimpleInputWrapper>
              </div>
            </TabsContent>
          </Tabs>
        </div>
      </PopoverContent>
    </Popover>
  );
};
