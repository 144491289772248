import { useQuery } from 'react-query';

import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { PlanPriceOption } from '@/interfaces/plan_price';
import api from '@/services/swarm';
import { PLAN } from '@/utils/plans';

interface Props {
  organizationId: string;
  planName?: PLAN;
  interval?: PlanPriceOption['interval'];
}

const usePlanPriceOptions = ({ organizationId, interval, planName }: Props) => {
  const publicationId = useCurrentPublicationId();

  return useQuery<PlanPriceOption[]>(
    ['organization', organizationId, 'plan_price_options', planName, interval],
    () =>
      api
        .get(`/organizations/${organizationId}/plan_price_options`, {
          params: {
            publication_id: publicationId,
            interval,
            plan_name: planName,
          },
        })
        .then((res) => res.data),
    {
      enabled: !!organizationId,
    }
  );
};

export default usePlanPriceOptions;
