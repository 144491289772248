import { ImageSelect, Input, Textarea } from '@/components/Form';
import { RewardType } from '@/interfaces/reward';
import { getPrefixedArticleHelperText } from '@/routes/referral_program/components/ReferralForm/getPrefixedArticleHelperText';
import { Dropdown } from '@/ui/Dropdown';

import { useFormContext } from '../FormContext';

const RewardForm = () => {
  const { reward, setReward, rewardTypeOptions, rewardImageValue } = useFormContext();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {
      target: { value, name },
    } = e;

    setReward({ ...reward, [name]: value });
  };

  const handleFileChange = (file: File) => {
    setReward({ ...reward, image: file });
  };

  const handleFileClear = () => {
    setReward({ ...reward, image: null });
  };

  const handleChangeInRewardType = (_: string, value: RewardType) => {
    setReward({ ...reward, reward_type: value });
  };

  return (
    <>
      <Input name="name" labelText="Name" type="text" value={reward?.name || ''} onChange={handleChange} required />
      <Input
        name="prefixed_article"
        labelText="Prefixed Article"
        helperText={getPrefixedArticleHelperText(reward?.name, reward?.prefixed_article)}
        type="text"
        value={reward?.prefixed_article || ''}
        onChange={handleChange}
      />
      <Textarea
        name="description"
        labelText="Description"
        value={reward?.description || ''}
        onChange={handleChange}
        required
      />
      <Input
        name="disclaimer"
        labelText="Disclaimer"
        type="text"
        value={reward?.disclaimer || ''}
        onChange={handleChange}
      />
      <Dropdown
        name="reward_type"
        labelText="Reward Type"
        value={reward?.reward_type}
        onSelect={handleChangeInRewardType}
        options={rewardTypeOptions}
        helperText="If you'd like to upload a list of promo codes that can automatically be sent in the milestone emails, select Promo Code type."
        required
      />
      <div className="w-40">
        <ImageSelect
          name="image"
          labelText="Image"
          helperText="We recommend a square or landscape image for your reward since it will be used in email and web"
          onFileSelect={handleFileChange}
          onFileClear={handleFileClear}
          file={rewardImageValue}
        />
      </div>
    </>
  );
};

export default RewardForm;
