import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { PencilSquareIcon } from '@heroicons/react/24/solid';

import ActionModal from '@/components/ActionModal';
import Badge from '@/components/Badge';
import { Color } from '@/components/DesignTokens';
import EllipsisDropdown from '@/components/EllipsisDropdown';
import { Input } from '@/components/Form';
import { Typography } from '@/components/Typography';
import { useUpdateSubdomain } from '@/hooks/useSubdomain';
import { Button } from '@/ui/Button';
import appendSettingsPublicationId from '@/utils/appendSettingsPublicationId';

import { EmailBadge } from './StatusBadge';

interface Props {
  username: string;
  onSharedInfra: boolean;
  publicationId: string;
  disabled: boolean;
}

const BeehiivEmailUsername: React.FC<Props> = ({ username, onSharedInfra, publicationId, disabled }) => {
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newUsername, setNewUsername] = useState<string>(username);

  const { mutateAsync: updateSubdomain, isLoading } = useUpdateSubdomain();

  const onSave = async () => {
    try {
      await updateSubdomain({ subdomain: newUsername });

      toast.success('Saved!');
      setIsModalOpen(false);
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  useEffect(() => {
    setNewUsername(username);
  }, [username]);

  return (
    <>
      <ActionModal
        resourceId={username}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        isWorking={isLoading}
        onProceed={onSave}
        headerText="Update Email Username"
      >
        <div className="my-4">
          <div>
            <Input
              name="subdomain"
              labelText="New Email Username"
              trailingText="@mail.beehiiv.com"
              value={newUsername}
              className="flex-grow"
              onChange={(e) => setNewUsername(e.target.value)}
            />
          </div>

          {onSharedInfra && (
            <Typography token="font-normal/text/xs" color={Color.DANGER} as="div" className="mt-4">
              <b>NOTE:</b> Changing this will also update your web URL, including links to existing posts and backlinks.
            </Typography>
          )}
        </div>
      </ActionModal>

      <div className="p-4 bg-gray-50 border border-surface-200 rounded-lg">
        <div className="flex flex-row items-start justify-between mb-4">
          <div className="flex items-start mb-4 space-x-2">
            <Badge size="sm">Email</Badge>
            <EmailBadge />
          </div>
          {!disabled && (
            <EllipsisDropdown
              options={[
                {
                  label: 'Configure Custom Domain',
                  onClick: () =>
                    navigate(appendSettingsPublicationId('/settings/publication/domain/email/new', publicationId)),
                },
              ]}
            />
          )}
        </div>

        <div className="flex items-end space-x-4">
          <Input
            name="username"
            labelText="Email Username"
            trailingText="@mail.beehiiv.com"
            value={username}
            className="flex-grow"
            readOnly
          />
          <Button
            variant="primary-inverse"
            onClick={() => setIsModalOpen(true)}
            Icon={PencilSquareIcon}
            className="h-[38] flex-shrink"
            disabled={disabled}
          >
            Edit
          </Button>
        </div>
      </div>
    </>
  );
};

export default BeehiivEmailUsername;
