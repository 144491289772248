import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

import FAQQuestionsForm from '@/routes/website_builder/_components/Form/PageSpecific/FAQs/FAQQuestionsForm';
import { Dropdown } from '@/ui/Dropdown';

import { Input, Switch } from '../../../../components/Form';
import ColorField from '../../../../components/Form/ColorField';
import { useUpdateUpgradePage } from '../../../../hooks/useUpgradePages';
import { FONT_SIZE_OPTIONS } from '../../../../interfaces/font';
import { UpgradePage } from '../../../../interfaces/upgrade_page';
import ThemeEditorDropdown from '../../../ThemeEditor/components/ThemeEditorDropdown';
import ThemeFormDrawer from '../../../ThemeEditor/components/ThemeFormDrawer';
import { Divider, FormContainer, FormInput, FormSection } from '../../../ThemeEditor/helpers';
import { usePageContext } from '../../components/PageContext';

const FAQSection = () => {
  const queryClient = useQueryClient();
  const {
    pageData: upgradePage,
    colorPalette,
    setIsSaving,
    handleSaved: onSave,
    fontOptions,
    currentPublication,
  } = usePageContext<UpgradePage>();

  const [sectionEnabled, setSectionEnabled] = useState(upgradePage?.faq_section?.enabled || false);
  const [bgColor, setBgColor] = useState(upgradePage?.faq_section?.bg_color || '#000000');
  const [overlineColor, setOverlineColor] = useState(upgradePage?.faq_section?.overline_color || '#000000');
  const [overline, setOverline] = useState(upgradePage?.faq_section?.overline || '');
  const [overlineFontFamily, setOverlineFontFamily] = useState(upgradePage?.faq_section?.overline_font_family || '');
  const [overlineFontSize, setOverlineFontSize] = useState(upgradePage?.faq_section?.overline_font_size || 'md');
  const [headlineColor, setHeadlineColor] = useState(upgradePage?.faq_section?.headline_color || '#000000');
  const [headline, setHeadline] = useState(upgradePage?.faq_section?.headline || '');
  const [headlineFontFamily, setHeadlineFontFamily] = useState(upgradePage?.faq_section?.headline_font_family || '');
  const [headlineFontSize, setHeadlineFontSize] = useState(upgradePage?.main_section?.headline_font_size || '5xl');
  const [faqQuestionColor, setFaqQuestionColor] = useState(upgradePage?.faq_section?.faq_question_color || '#000000');
  const [faqAnswerColor, setFaqAnswerColor] = useState(upgradePage?.faq_section?.faq_answer_color || '#000000');
  const [faqBorderColor, setFaqBorderColor] = useState(upgradePage?.faq_section?.faq_border_color || '#000000');
  const [faqButtonColor, setFaqButtonColor] = useState(upgradePage?.faq_section?.faq_button_color || '#000000');
  const [faqFontFamily, setFaqFontFamily] = useState(upgradePage?.faq_section?.faq_font_family || '');
  const [textAlign, setTextAlign] = useState(upgradePage?.faq_section?.text_align || 'left');

  useEffect(() => {
    setSectionEnabled(upgradePage?.faq_section?.enabled || false);
    setBgColor(upgradePage?.faq_section?.bg_color || '#000000');
    setOverlineColor(upgradePage?.faq_section?.overline_color || '#000000');
    setOverline(upgradePage?.faq_section?.overline || '');
    setOverlineFontFamily(upgradePage?.faq_section?.overline_font_family || '');
    setOverlineFontSize(upgradePage?.faq_section?.overline_font_size || 'md');
    setHeadlineColor(upgradePage?.faq_section?.headline_color || '#000000');
    setHeadline(upgradePage?.faq_section?.headline || '');
    setHeadlineFontFamily(upgradePage?.faq_section?.headline_font_family || '');
    setHeadlineFontSize(upgradePage?.faq_section?.headline_font_size || '5xl');
    setTextAlign(upgradePage?.faq_section?.text_align || 'left');
    setFaqQuestionColor(upgradePage?.faq_section?.faq_question_color || '#000000');
    setFaqAnswerColor(upgradePage?.faq_section?.faq_answer_color || '#000000');
    setFaqBorderColor(upgradePage?.faq_section?.faq_border_color || '#000000');
    setFaqButtonColor(upgradePage?.faq_section?.faq_button_color || '#000000');
    setFaqFontFamily(upgradePage?.faq_section?.faq_font_family || '');
  }, [upgradePage]);

  const updateUpgradePage = useUpdateUpgradePage({
    pageId: upgradePage?.id || '',
    onSuccess: () => {
      queryClient.invalidateQueries(['upgrade_page', currentPublication?.id, 'draft']);
      onSave();
    },
  });

  const handleSaveFAQSection = (payload: any) => {
    setIsSaving(true);
    updateUpgradePage.mutate({ upgrade_page: { faq_section: JSON.stringify(payload) } });
  };

  return (
    <ThemeFormDrawer active={false} title="FAQ Section" onClickHandler={() => {}}>
      <FormContainer>
        <FormSection title="General">
          <FormInput title="Show Section">
            <Switch
              variant="primary"
              name="enabled"
              checked={sectionEnabled}
              onChange={(_name: string, updatedValue: boolean) => {
                setSectionEnabled(updatedValue);
                handleSaveFAQSection({
                  ...upgradePage?.faq_section,
                  enabled: updatedValue,
                });
              }}
            />
          </FormInput>
          <FormInput title="Background Color">
            <ColorField
              placement="bottom"
              color={bgColor}
              colorPalette={colorPalette}
              onColorChange={(color: any) => setBgColor(color?.hexString)}
              onBlur={() => {
                handleSaveFAQSection({
                  ...upgradePage?.faq_section,
                  bg_color: bgColor,
                });
              }}
            />
          </FormInput>
          <FormInput title="Text alignment">
            <Dropdown
              name="text_align"
              value={textAlign}
              onSelect={(_name: string, value: string) => {
                setTextAlign(value);
                handleSaveFAQSection({
                  ...upgradePage?.faq_section,
                  text_align: value,
                });
              }}
              options={[
                {
                  label: 'Left',
                  value: 'left',
                },
                {
                  label: 'Center',
                  value: 'center',
                },
                {
                  label: 'Right',
                  value: 'right',
                },
              ]}
            />
          </FormInput>
        </FormSection>
        <Divider />
        <FormSection title="Overline">
          <FormInput title="Color">
            <ColorField
              placement="bottom"
              color={overlineColor}
              colorPalette={colorPalette}
              onColorChange={(color: any) => setOverlineColor(color?.hexString)}
              onBlur={() => {
                handleSaveFAQSection({
                  ...upgradePage?.faq_section,
                  overline_color: overlineColor,
                });
              }}
            />
          </FormInput>
          <FormInput title="Text">
            <Input
              name="overline"
              value={overline}
              maxLength={150}
              onChange={(e) => setOverline(e.target.value)}
              onBlur={() => {
                handleSaveFAQSection({
                  ...upgradePage?.faq_section,
                  overline,
                });
              }}
            />
          </FormInput>
          <FormInput title="Font family">
            <ThemeEditorDropdown
              currentValue={overlineFontFamily}
              onUpdate={(val: string) =>
                handleSaveFAQSection({
                  ...upgradePage?.faq_section,
                  overline_font_family: val,
                })
              }
              options={fontOptions}
            />
          </FormInput>
          <FormInput title="Font size">
            <ThemeEditorDropdown
              currentValue={overlineFontSize}
              onUpdate={(val: string) =>
                handleSaveFAQSection({
                  ...upgradePage?.faq_section,
                  overline_font_size: val,
                })
              }
              options={FONT_SIZE_OPTIONS}
            />
          </FormInput>
        </FormSection>
        <Divider />
        <FormSection title="Headline">
          <FormInput title="Color">
            <ColorField
              placement="bottom"
              color={headlineColor}
              colorPalette={colorPalette}
              onColorChange={(color: any) => setHeadlineColor(color?.hexString)}
              onBlur={() => {
                handleSaveFAQSection({
                  ...upgradePage?.faq_section,
                  headline_color: headlineColor,
                });
              }}
            />
          </FormInput>
          <FormInput title="Text">
            <Input
              name="headline"
              value={headline}
              maxLength={150}
              onChange={(e) => setHeadline(e.target.value)}
              onBlur={() => {
                handleSaveFAQSection({
                  ...upgradePage?.faq_section,
                  headline,
                });
              }}
            />
          </FormInput>
          <FormInput title="Font family">
            <ThemeEditorDropdown
              currentValue={headlineFontFamily}
              onUpdate={(val: string) =>
                handleSaveFAQSection({
                  ...upgradePage?.faq_section,
                  headline_font_family: val,
                })
              }
              options={fontOptions}
            />
          </FormInput>
          <FormInput title="Font size">
            <ThemeEditorDropdown
              currentValue={headlineFontSize}
              onUpdate={(val: string) =>
                handleSaveFAQSection({
                  ...upgradePage?.faq_section,
                  headline_font_size: val,
                })
              }
              options={FONT_SIZE_OPTIONS}
            />
          </FormInput>
        </FormSection>
        <Divider />
        <FormSection title="FAQs">
          <FormInput title="Question color">
            <ColorField
              placement="bottom"
              color={faqQuestionColor}
              colorPalette={colorPalette}
              onColorChange={(color: any) => setFaqQuestionColor(color?.hexString)}
              onBlur={() => {
                handleSaveFAQSection({
                  ...upgradePage?.faq_section,
                  faq_question_color: faqQuestionColor,
                });
              }}
            />
          </FormInput>
          <FormInput
            title="Answer color"
            tooltipText="We open the first question by default when previewing so you can see your updates. It will not be opened by default on the live site."
          >
            <ColorField
              placement="bottom"
              color={faqAnswerColor}
              colorPalette={colorPalette}
              onColorChange={(color: any) => setFaqAnswerColor(color?.hexString)}
              onBlur={() => {
                handleSaveFAQSection({
                  ...upgradePage?.faq_section,
                  faq_answer_color: faqAnswerColor,
                });
              }}
            />
          </FormInput>
          <FormInput title="Border color" helperText="Applied to the dividing line between questions">
            <ColorField
              placement="bottom"
              color={faqBorderColor}
              colorPalette={colorPalette}
              onColorChange={(color: any) => setFaqBorderColor(color?.hexString)}
              onBlur={() => {
                handleSaveFAQSection({
                  ...upgradePage?.faq_section,
                  faq_border_color: faqBorderColor,
                });
              }}
            />
          </FormInput>
          <FormInput title="Button color" helperText="Applied to the +/- toggle">
            <ColorField
              placement="bottom"
              color={faqButtonColor}
              colorPalette={colorPalette}
              onColorChange={(color: any) => setFaqButtonColor(color?.hexString)}
              onBlur={() => {
                handleSaveFAQSection({
                  ...upgradePage?.faq_section,
                  faq_button_color: faqButtonColor,
                });
              }}
            />
          </FormInput>
          <FormInput title="Font family">
            <ThemeEditorDropdown
              currentValue={faqFontFamily}
              onUpdate={(val: string) =>
                handleSaveFAQSection({
                  ...upgradePage?.faq_section,
                  faq_font_family: val,
                })
              }
              options={fontOptions}
            />
          </FormInput>
          <FormInput title="FAQs">
            <FAQQuestionsForm
              page={upgradePage}
              handleSave={handleSaveFAQSection}
              isProcessing={updateUpgradePage.isLoading}
            />
          </FormInput>
        </FormSection>
      </FormContainer>
    </ThemeFormDrawer>
  );
};

export default FAQSection;
