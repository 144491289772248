import { Editor } from '@tiptap/core';

import { useActiveNode } from '../extensions/ActiveNode/hooks/useActiveNode';

import { AccordionContentSettings, AccordionSettings, AccordionTitleSettings } from './components/AccordionSettings';
import { AuthorsWidgetSettings } from './components/AuthorsWidgetSettings';
import { BlockSettings } from './components/BlockSettings';
import { ButtonSettings } from './components/ButtonSettings';
import { ColumnSettings } from './components/ColumnSettings';
import { ColumnsSettings } from './components/ColumnsSettings';
import { ContainerSettings } from './components/ContainerSettings';
import { FooterSettings } from './components/FooterSettings';
import { ImageSettings } from './components/ImageSettings';
import { NavbarSettings } from './components/NavbarSettings';
import { PostSettings } from './components/PostsSettings';
import { PricingSettings } from './components/PricingSettings';
import { SectionSettings } from './components/SectionSettings';
import { SignupWidgetSettings } from './components/SignupWidgetSettings';
import { SocialsSettings } from './components/SocialsSettings';
import { SurveyWidgetSettings } from './components/SurveyWidgetSettings';
import { TestimonialsSettings } from './components/TestimonialsSettings';
import { TypographySettings } from './components/TypographySettings';
import { useAttributesPanelNavbarFooterContext } from './navbarFooterContextProvider';
// import { Debug } from './components/Debug';

export const AttributesPanel = ({ editor }: { editor: Editor }) => {
  const activeNodeResult = useActiveNode(editor);
  const context = useAttributesPanelNavbarFooterContext();
  const { isNavbarSelected, isFooterSelected } = context || {};

  const preventPropagation = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <div className="flex flex-col p-3" onClick={preventPropagation} role="none">
      {!isNavbarSelected && !isFooterSelected && (
        <>
          <BlockSettings editor={editor} activeNodeResult={activeNodeResult} />

          <TypographySettings editor={editor} activeNodeResult={activeNodeResult} />
          <SocialsSettings editor={editor} activeNodeResult={activeNodeResult} />
          <TestimonialsSettings editor={editor} activeNodeResult={activeNodeResult} />
          <SurveyWidgetSettings editor={editor} activeNodeResult={activeNodeResult} />
          <PricingSettings editor={editor} activeNodeResult={activeNodeResult} />
          <AuthorsWidgetSettings editor={editor} activeNodeResult={activeNodeResult} />
          <PostSettings editor={editor} activeNodeResult={activeNodeResult} />
          <SignupWidgetSettings editor={editor} activeNodeResult={activeNodeResult} />
          <SectionSettings editor={editor} activeNodeResult={activeNodeResult} />
          <ContainerSettings editor={editor} activeNodeResult={activeNodeResult} />
          <ColumnsSettings editor={editor} activeNodeResult={activeNodeResult} />
          <ColumnSettings editor={editor} activeNodeResult={activeNodeResult} />
          <ImageSettings editor={editor} activeNodeResult={activeNodeResult} />
          <ButtonSettings editor={editor} activeNodeResult={activeNodeResult} />
          <AccordionSettings editor={editor} activeNodeResult={activeNodeResult} />
          <AccordionTitleSettings editor={editor} activeNodeResult={activeNodeResult} />
          <AccordionContentSettings editor={editor} activeNodeResult={activeNodeResult} />
        </>
      )}

      <NavbarSettings />
      <FooterSettings />
      {/* <Debug editor={editor} /> */}
    </div>
  );
};
