import { useCallback, useMemo, useState } from 'react';
import { ArrowLeft, X } from '@phosphor-icons/react';
import { Testimonial } from '@shared/dream-components';
import { NodeViewProps } from '@tiptap/core';

import useTestimonials from '@/hooks/useTestimonials/useTestimonials';

import { cn } from '../../../../../_utils/cn';
import { Button } from '../../../../UI/Button';
import { Checkbox } from '../../../../UI/Checkbox';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '../../../../UI/Dialog';
import { Label } from '../../../../UI/Label';
import { Text } from '../../../../UI/Text';

const TestimonialsSelectionModal = ({
  editor,
  node,
  isOpen,
  onClose,
  setIsModalOpen,
  getPos,
}: NodeViewProps & {
  isOpen: boolean;
  onClose: () => void;
  setIsModalOpen: (isOpen: boolean) => void;
}) => {
  const [selectedTestimonials, setSelectedTestimonials] = useState<Testimonial[] | any[]>([]);
  const [isLayoutOpen, setIsLayoutOpen] = useState(false);

  const isSubmitDisabled = selectedTestimonials.length === 0;
  const selectionCount = selectedTestimonials.length;
  const selectionLabel = selectionCount <= 1 ? 'testimonial' : 'testimonials';

  const { data, isFetching, hasNextPage, fetchNextPage } = useTestimonials({ search: '' });
  const testimonials = data?.pages.flatMap((page) => page.testimonials) || [];
  const showingCount = testimonials.length;
  const totalCount = data?.pages[0]?.pagination?.total || 0;

  const selectedTestimonialsIds = useMemo(() => selectedTestimonials.map((post) => post.id), [selectedTestimonials]);

  const handleSubmit = useCallback(() => {
    const updatedAttributes = {
      ...node?.attrs,
      insertedFromSidebar: false,
      data: selectedTestimonials,
    };

    editor?.commands.command(({ tr }) => {
      tr.setNodeAttribute(getPos(), 'insertedFromSidebar', false);
      tr.setNodeAttribute(getPos(), 'hasFakeData', false);
      tr.setNodeAttribute(getPos(), 'data', updatedAttributes.data);
      return true;
    });

    setIsModalOpen(false);
  }, [selectedTestimonials, editor, node, getPos, setIsModalOpen]);

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="w-[60vw] max-w-none h-[90vh] flex flex-col overflow-hidden">
        <DialogHeader>
          <DialogTitle>
            <div className="flex items-center gap-2">
              {isLayoutOpen && <Button variant="ghost" onClick={() => setIsLayoutOpen(false)} LeftIcon={ArrowLeft} />}
              <Text size="xl" weight="semibold" variant="primary" as="h4">
                Select testimonials
              </Text>
            </div>
          </DialogTitle>
        </DialogHeader>

        <div className="flex flex-col gap-2 w-full">
          <div className="flex gap-2">
            {selectedTestimonials.map((testimonial) => (
              <button
                type="button"
                key={testimonial.id}
                className="flex items-center justify-center px-2 py-1 bg-wb-accent-soft text-wb-accent rounded-md text-[10px] gap-1"
                onClick={() => setSelectedTestimonials(selectedTestimonials.filter((p) => p.id !== testimonial.id))}
              >
                <Text
                  weight="regular"
                  variant="accent"
                  size="3xs"
                  as="span"
                  className="line-clamp-1 max-w-[150px] truncate"
                >
                  {testimonial.name}
                </Text>
                <X className="w-3 h-3" />
              </button>
            ))}
          </div>
        </div>

        <div className="flex flex-col gap-4 w-full overflow-y-auto no-scrollbar">
          <div className="flex flex-col gap-4">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 pb-32">
              {testimonials.map((testimonial) => {
                const isSelected = selectedTestimonialsIds.includes(testimonial.id);

                return (
                  <Label
                    key={testimonial.id}
                    htmlFor={testimonial.id}
                    className={cn(
                      'flex flex-col cursor-pointer hover:shadow-md border shadow p-0 rounded-md focus:outline-none relative overflow-hidden',
                      isSelected ? 'border-wb-accent border-2' : 'border-wb-primary'
                    )}
                  >
                    <div className="w-full h-36 overflow-hidden flex items-center justify-center">
                      <Text
                        weight="regular"
                        variant="secondary"
                        size="2xs"
                        as="span"
                        className="line-clamp-3 max-w-[200px]"
                      >
                        {`"${testimonial.message}"`}
                      </Text>
                    </div>
                    <div className="flex justify-between items-center gap-2 p-3">
                      <div className="flex gap-2 items-center">
                        <div className="w-8 h-8 rounded-full overflow-hidden">
                          <img
                            src={testimonial.picture_url}
                            alt={testimonial.name}
                            className="w-full h-full object-cover"
                          />
                        </div>
                        <div className="flex flex-col">
                          <Text weight="semibold" variant="primary" size="xs" as="span" className="line-clamp-1">
                            {testimonial.name}
                          </Text>
                          <Text weight="regular" variant="secondary" size="2xs" as="span" className="line-clamp-1">
                            {testimonial.description}
                          </Text>
                        </div>
                      </div>
                      <Checkbox
                        id={testimonial.id}
                        className={cn(isSelected ? 'opacity-100' : 'opacity-0')}
                        checked={Boolean(isSelected)}
                        onCheckedChange={() => {
                          if (isSelected) {
                            setSelectedTestimonials(selectedTestimonials.filter((p) => p.id !== testimonial.id));
                          } else {
                            setSelectedTestimonials([...selectedTestimonials, testimonial]);
                          }
                        }}
                      />
                    </div>
                  </Label>
                );
              })}
            </div>
          </div>
        </div>

        <DialogFooter className="flex justify-between items-center absolute bottom-0 left-0 right-0 p-4 bg-white border-t border-wb-primary">
          <div className="flex justify-between items-center w-full">
            <div className="flex gap-2 items-center">
              <Text weight="regular" variant="secondary" size="2xs" as="span" className="whitespace-nowrap">
                Showing {showingCount} of {totalCount} results
              </Text>
              {hasNextPage && (
                <Button variant="outlined" onClick={() => fetchNextPage()} className="whitespace-nowrap" size="sm">
                  {isFetching ? 'Loading...' : 'Load more'}
                </Button>
              )}
            </div>
            <div className="flex gap-2 w-full justify-end">
              <Button variant="outlined" onClick={onClose}>
                Cancel
              </Button>
              <Button variant="primary" onClick={handleSubmit} isDisabled={isSubmitDisabled}>
                Select {selectionLabel}
              </Button>
            </div>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default TestimonialsSelectionModal;
