import { useQueryClient } from 'react-query';
import { Plus } from '@phosphor-icons/react';

import useCreateSitePackage from '@/hooks/useSitePackages/useCreateSitePackage';

import { Button } from '../../_components/UI/Button';
import { Text } from '../../_components/UI/Text';

export const TopBar = () => {
  const queryClient = useQueryClient();

  const createSitePackage = useCreateSitePackage({
    onSuccess: () => {
      queryClient.invalidateQueries('site_packages');
    },
  });

  const handleCreateProject = () => {
    const formData = new FormData();
    formData.append('site_package[name]', 'New Project');

    createSitePackage.mutate(formData);
  };

  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-col space-y-2 max-w-[620px]">
        <div className="flex-1">
          <Text size="xl" weight="semibold" variant="primary" as="h4">
            Projects
          </Text>
        </div>
      </div>

      <Button LeftIcon={Plus} className="gap-2" onClick={handleCreateProject}>
        New Project
      </Button>
    </div>
  );
};
