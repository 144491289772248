import { cn } from '../../../../utils/cn';
import { VERSION_HISTORY_PANEL_ID } from '../DreamEditor/constants';
import { CloseButton } from '../UI/Button';

interface Props {
  isVersionHistoryPanelOpen: boolean;
  setIsVersionHistoryPanelOpen: (value: boolean) => void;
}

const VersionHistoryPortal = ({ isVersionHistoryPanelOpen, setIsVersionHistoryPanelOpen }: Props) => {
  return (
    <div
      id={VERSION_HISTORY_PANEL_ID}
      className={cn(
        'absolute top-0 right-0 bottom-0 w-[250px] transition-transform duration-250 bg-wb-primary border-l border-solid overflow-y-auto p-3',
        isVersionHistoryPanelOpen ? 'translate-x-0' : `translate-x-[500px]`
      )}
    >
      <CloseButton onClose={() => setIsVersionHistoryPanelOpen(false)} className="-translate-x-1/2 translate-y-1/2" />
    </div>
  );
};

export default VersionHistoryPortal;
