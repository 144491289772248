import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { draggable, dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { attachInstruction, extractInstruction, Instruction } from '@atlaskit/pragmatic-drag-and-drop-hitbox/tree-item';
import { CaretDown, CaretRight, Icon, Image, Stack, TextH, TextT } from '@phosphor-icons/react';
import { Editor } from '@tiptap/core';
import { Fragment, Node, Slice } from '@tiptap/pm/model';
import { useEditorState } from '@tiptap/react';

import { cn } from '@/utils/cn';

import { Text } from '../../UI/Text';
import { Section } from '../extensions';
import { useActiveNode } from '../extensions/ActiveNode/hooks/useActiveNode';

type TreeContextType = {
  editor: Editor;
};

const TreeContext = createContext<TreeContextType>({
  editor: {} as unknown as Editor,
});

type TNodeItemProps = {
  node: Node;
  nodePos: number;
  defaultIsOpen?: boolean;
};

const Icons: Record<string, Icon> = {
  paragraph: TextT,
  heading: TextH,
  columns: Stack,
  imageBlock: Image,
};

const formatActiveNodeType = (type?: string): string => {
  if (!type) return 'unknown';

  // split camel case and capitalize each word
  const formattedType = type
    .split(/(?=[A-Z])/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
  return formattedType;
};

const TNodeItem = ({ node, nodePos, defaultIsOpen = false }: TNodeItemProps) => {
  const { editor } = useContext(TreeContext);
  const {
    activeNodePos,
    activeNodeAttributes: { id },
  } = useActiveNode(editor);

  const childNodes = useMemo(() => {
    const newChildNodes: TNodeItemProps[] = [];

    node.descendants((n, pos) => {
      if (!n.type.isBlock) return false;

      newChildNodes.push({
        node: n,
        nodePos: pos + nodePos + 1,
      });

      return false; // do not dive into the children of these nodes
    });

    return newChildNodes;
  }, [node, nodePos]);

  const handleClick = useCallback(() => {
    if (!editor) return;

    editor.chain().focus().setNodeSelection(nodePos).scrollIntoView().run();
  }, [editor, nodePos]);

  const [isOpen, setIsOpen] = useState(defaultIsOpen);
  const [isDirtyOpen, setIsDirtyOpen] = useState(defaultIsOpen);

  useEffect(() => {
    // user already touched this, do not mess with it.
    if (isDirtyOpen || nodePos === 0 || !node) return;
    setIsOpen(activeNodePos > nodePos && activeNodePos <= nodePos + node.nodeSize);
    setIsDirtyOpen(activeNodePos > nodePos && activeNodePos <= nodePos + node.nodeSize);
  }, [nodePos, activeNodePos, node, isDirtyOpen]);

  const isNodeActive = id === node.attrs.id;

  const { text } = node;

  const dragItem = useRef<HTMLDivElement>(null);

  const [isOver, setIsOver] = useState(false);
  const [instruction, setInstruction] = useState<Instruction | null>(null);

  useEffect(() => {
    if (!dragItem.current) return () => {};

    const slice = new Slice(Fragment.from(node), 0, 0);

    const data = {
      node,
      nodePos,
    };

    return combine(
      draggable({
        element: dragItem.current,
        getInitialData() {
          editor.view.dragging = {
            slice,
            move: true,
          };
          return data;
        },
      }),
      dropTargetForElements({
        element: dragItem.current,
        getData: ({ input, element, source }) => {
          const block: ('reorder-above' | 'reorder-below' | 'make-child')[] = [];
          const sourceNode = source.data.node as Node;
          if (node.type.name === Section.name && sourceNode && sourceNode.type.name !== Section.name) {
            block.push('reorder-above');
            block.push('reorder-below');
          }

          const contentFragment = Fragment.from(sourceNode);
          if (!node.type.validContent(contentFragment)) {
            block.push('make-child');
          }

          // this will 'attach' the instruction to your `data` object
          return attachInstruction(data, {
            input,
            element,
            currentLevel: 2,
            indentPerLevel: 20,
            mode: 'standard',
            block,
          });
        },
        onDragEnter() {
          setIsOver(true);
        },
        onDragLeave() {
          setIsOver(false);
        },
        onDrag({ self, source }) {
          const sourceNode = source.data.node as Node;
          const selfNode = self.data.node as Node;
          if (selfNode.attrs.id === sourceNode.attrs.id) return;

          setInstruction(extractInstruction(self.data));
        },
        onDrop({ self, source }) {
          // handle dropping
          const inst: Instruction | null = extractInstruction(self.data);

          // the general idea is to insert source node to self node.
          // then remove the source node through the mapping after insert transaction.
          const { tr } = editor.state;

          if (inst?.type === 'reorder-above') {
            const newPos = self.data.nodePos as number;
            tr.insert(newPos, source.data.node as Node);
          } else if (inst?.type === 'reorder-below') {
            const dropNodePos = self.data.nodePos as number;
            const dropNode = self.data.node as Node;
            const newPos = dropNodePos + dropNode.nodeSize;
            tr.insert(newPos, source.data.node as Node);
          } else if (inst?.type === 'make-child') {
            const dropNodePos = (self.data.nodePos as number) + (self.data.node as Node).nodeSize - 1;
            tr.insert(dropNodePos, source.data.node as Node);
          }

          if (tr.docChanged) {
            const delPos = tr.mapping.map(source.data.nodePos as number);
            const sourceNode = source.data.node as Node;
            tr.delete(delPos, delPos + (sourceNode?.nodeSize || 0));

            editor.view.dispatch(tr);
          }

          setIsOver(false);
        },
      })
    );
  }, [editor, nodePos, node]);

  const IconComp = Icons[node.type.name] || Stack;

  return (
    <div className="select-none">
      <div
        className={cn('w-full bg-wb-accent h-[1px]', { 'opacity-0': !isOver || instruction?.type !== 'reorder-above' })}
      />
      <div
        ref={dragItem}
        className={cn('flex items-center gap-1 cursor-pointer border rounded-md p-1.5', {
          'bg-wb-button-accent-soft border-wb-accent-soft': isNodeActive,
          'hover:bg-wb-secondary border-transparent': !isNodeActive,
          'border-wb-accent': isOver && instruction?.type === 'make-child',
        })}
        onClick={handleClick}
        role="none"
      >
        {childNodes.length > 0 && (
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setIsOpen((o) => !o);
              setIsDirtyOpen((o) => !o);
            }}
            className="text-wb-primary-soft"
          >
            {isOpen ? <CaretDown /> : <CaretRight />}
          </button>
        )}
        {<IconComp className="text-wb-accent" weight="bold" /> || <Stack className="text-wb-accent" weight="bold" />}
        <Text size="xs" className="text-inherit">
          {formatActiveNodeType(node.type.name)}
        </Text>{' '}
        {childNodes.length === 0 && text && <Text>{`(${text})`}</Text>}
      </div>
      <div style={{ height: isOpen ? undefined : '0px', overflow: 'hidden' }}>
        {childNodes.map(({ node: childNode, nodePos: np }) => (
          <div key={childNode.attrs.id} style={{ marginLeft: '20px' }}>
            <TNodeItem node={childNode} nodePos={np} />
          </div>
        ))}
      </div>
      <div
        className={cn('w-full bg-wb-accent h-[1px]', { 'opacity-0': !isOver || instruction?.type !== 'reorder-below' })}
      />
    </div>
  );
};

export const LayersPanel = ({ editor }: { editor: Editor }) => {
  const contextValue = useMemo(() => ({ editor }), [editor]);

  const directRootChildren = useEditorState({
    editor,
    selector: ({ editor: e }) => {
      const childNodes: TNodeItemProps[] = [];

      e.state.doc.descendants((node, pos) => {
        if (!node.type.isBlock) return false;

        childNodes.push({
          node,
          nodePos: pos,
        });

        return false; // do not dive into the children of these nodes
      });

      return childNodes;
    },
  });

  return (
    <TreeContext.Provider value={contextValue}>
      <div className="flex flex-col gap-2">
        <Text size="xs" variant="secondary" weight="semibold" className="p-2">
          Layers
        </Text>
        <div>
          {directRootChildren.map(({ node: chNode, nodePos: np }) => (
            <TNodeItem key={`${np}-${chNode.attrs.id}`} node={chNode} nodePos={np} />
          ))}
        </div>
      </div>
    </TreeContext.Provider>
  );
};
