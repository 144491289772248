import { useMemo } from 'react';
import { CaretRight, Layout } from '@phosphor-icons/react';

import { Text } from '../../../../UI/Text';
import InsertTemplateModal from '../../../Templates/InsertTemplateModal';
import { AttributeSettingProps } from '../../types';

export const TemplateSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  const { templateType } = useMemo(() => {
    if (!activeNodeResult?.activeNodeType) return { node: null, templateType: 'block' };

    let type = 'block';
    if (activeNodeResult.activeNodeType === 'doc') {
      type = 'page';
    } else if (activeNodeResult.activeNodeType === 'section') {
      type = 'section';
    } else {
      type = 'block';
    }

    return {
      templateType: type,
    };
  }, [activeNodeResult?.activeNodeType]);

  return (
    <div className="flex items-center justify-stretch gap-2">
      <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
        Templates
      </Text>

      <InsertTemplateModal
        type={templateType as 'block' | 'section' | 'page'}
        activeNodeResult={activeNodeResult}
        actionButton={
          <div className="grow bg-wb-secondary rounded-lg shadow-sm cursor-pointer">
            <div className="w-full justify-between flex items-center gap-2 p-2 ">
              <div className="flex items-center gap-1">
                <Layout className="text-wb-secondary" weight="bold" />
                <Text size="2xs" weight="medium">
                  Template
                </Text>
              </div>
              <CaretRight className="text-wb-secondary" weight="bold" />
            </div>
          </div>
        }
        editor={editor}
        insertPos={0}
      />
    </div>
  );
};
