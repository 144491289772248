import { Extension } from '@tiptap/core';
import { Node as PMNode } from '@tiptap/pm/model';
import { NodeSelection } from '@tiptap/pm/state';

import { WebTheme } from '@/interfaces/web_theme';

import { THEME_ATTRS } from '../../AttributesPanel/components/BlockSettings/ActionsSettings/consts';

export enum ApplyThemeScope {
  DOC = 'doc',
  SECTION = 'section',
}

export const THEME_SCOPES = [ApplyThemeScope.DOC, ApplyThemeScope.SECTION];

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    theme: {
      applyTheme: ({
        theme,
        mapping,
        scope,
      }: {
        theme: WebTheme;
        mapping: Record<string, Partial<Record<(typeof THEME_ATTRS)[number], string[]>>>;
        scope?: ApplyThemeScope;
      }) => ReturnType;
    };
  }
}

export const ThemeCommands = Extension.create({
  name: 'themeCommands',

  addCommands() {
    return {
      applyTheme:
        ({ theme, mapping, scope = 'section' }) =>
        ({ state, dispatch }) => {
          const { selection, tr } = state;

          const { $from } = selection;

          const isSectionScope = scope === 'section';
          const isSectionSelection = selection instanceof NodeSelection && selection.node.type.name === 'section';

          let scopeTargetNode: PMNode = state.doc;

          if (isSectionScope && isSectionSelection) {
            scopeTargetNode = selection.node;
          } else {
            scopeTargetNode = isSectionScope ? $from.node(1) : state.doc;
          }

          if (!THEME_SCOPES.includes(scopeTargetNode.type.name as ApplyThemeScope)) {
            return false;
          }

          const sectionStart = isSectionScope ? $from.start(1) : 0;

          // Update section attrs
          if (isSectionScope && isSectionSelection) {
            const sectionMapping = mapping[scopeTargetNode.type.name];

            const sectionAttrs = Object.keys(sectionMapping) as Array<keyof typeof sectionMapping>;

            const updatedSectionAttrs: Record<string, string> = {};

            sectionAttrs.forEach((attrKey) => {
              if (!Object.hasOwn(theme, attrKey)) {
                return;
              }

              const themeValue = theme[attrKey];

              const attrsToUpdate = sectionMapping[attrKey] as string[];

              attrsToUpdate.forEach((attr) => {
                updatedSectionAttrs[attr] = themeValue;
              });
            });

            tr.setNodeMarkup(sectionStart, null, updatedSectionAttrs);
          }

          // Update Doc/Section Node Children attrs based on scope
          scopeTargetNode.descendants((node, pos) => {
            const adjustedPos = sectionStart + pos;

            const nodeThemeMetaData = mapping[node.type.name];

            if (!nodeThemeMetaData) {
              return;
            }

            const themeAttrs = Object.keys(nodeThemeMetaData) as Array<keyof typeof nodeThemeMetaData>;

            const updatedAttrs: Record<string, string> = {};

            themeAttrs.forEach((nodeThemeMetaDataKey) => {
              if (!Object.hasOwn(theme, nodeThemeMetaDataKey)) {
                return;
              }

              const themeValue = theme[nodeThemeMetaDataKey];

              const attrsToUpdate = nodeThemeMetaData[nodeThemeMetaDataKey] as string[];

              attrsToUpdate.forEach((attr) => {
                updatedAttrs[attr] = themeValue;
              });
            });

            if (node.type.name === 'text') {
              const textStyleMark = node.marks.find((mark) => mark.type.name === 'textStyle');

              const updatedTextStyleMark = state.schema.mark('textStyle', {
                ...(textStyleMark?.attrs || {}),
                ...updatedAttrs,
              });

              tr.addMark(adjustedPos, adjustedPos + node.nodeSize, updatedTextStyleMark);
            } else {
              const newAttrs = {
                ...node.attrs,
                ...updatedAttrs,
              };

              tr.setNodeMarkup(adjustedPos, null, newAttrs);
            }
          });

          if (tr.docChanged && dispatch) {
            dispatch(tr);
            return true;
          }

          return false;
        },
    };
  },
});
