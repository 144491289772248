import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import {
  DEFAULT_BORDER_COLOR,
  DEFAULT_BORDER_RADIUS,
  DEFAULT_PRIMARY_COLOR,
  DEFAULT_TEXT_ON_PRIMARY_COLOR,
} from '../constants';

import { ButtonView } from './views/ButtonView';

export const Button = Node.create({
  name: 'button',

  group: 'block',

  content: 'inline*',

  draggable: true,

  addKeyboardShortcuts() {
    return {
      // On Enter at the end of line, create new paragraph and focus
      Enter: ({ editor }) => {
        const {
          state: {
            selection: { $from, empty },
          },
        } = editor;

        if (!empty || $from.parent.type !== this.type) {
          return false;
        }

        const isAtEnd = $from.parentOffset === $from.parent.nodeSize - 2;

        if (!isAtEnd) {
          return false;
        }

        const pos = editor.state.selection.$from.end() + (empty ? 1 : 0);

        return editor.chain().focus(pos).insertContentAt(pos, { type: 'paragraph' }).run();
      },
    };
  },

  addAttributes() {
    return {
      margin: {
        default: '0px',
        parseHTML: (element) => element.getAttribute('data-margin'),
        renderHTML: (attributes) => ({
          'data-margin': attributes.margin,
        }),
      },
      padding: {
        default: '12px 16px 12px 16px', // Equivalent to px-4 py-2
        parseHTML: (element) => element.getAttribute('data-padding'),
        renderHTML: (attributes) => ({
          'data-padding': attributes.padding,
        }),
      },
      shadow: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-shadow'),
        renderHTML: (attributes) => ({
          'data-shadow': attributes.shadow,
        }),
      },
      borderRadius: {
        default: DEFAULT_BORDER_RADIUS,
        renderHTML: (attributes) => ({ 'data-border-radius': attributes.borderRadius }),
        parseHTML: (element) => element.getAttribute('data-border-radius') || DEFAULT_BORDER_RADIUS,
      },
      borderWidth: {
        default: '1px',
        renderHTML: (attributes) => ({ 'data-border-width': attributes.borderWidth }),
        parseHTML: (element) => element.getAttribute('data-border-width') || '1px',
      },
      borderColor: {
        default: DEFAULT_BORDER_COLOR,
        renderHTML: (attributes) => ({ 'data-border-color': attributes.borderColor }),
        parseHTML: (element) => element.getAttribute('data-border-color') || DEFAULT_BORDER_COLOR,
      },
      borderStyle: {
        default: 'solid',
        renderHTML: (attributes) => ({ 'data-border-style': attributes.borderStyle }),
        parseHTML: (element) => element.getAttribute('data-border-style') || 'none',
      },
      size: {
        default: 'normal',
        parseHTML: (element) => element.getAttribute('data-size'),
        renderHTML: (attributes) => ({
          'data-size': attributes.size,
        }),
      },
      alignment: {
        default: 'left',
        parseHTML: (element) => element.getAttribute('data-alignment'),
        renderHTML: (attributes) => ({
          'data-alignment': attributes.alignment,
        }),
      },
      href: {
        default: null,
        parseHTML: (element) => element.getAttribute('href'),
        renderHTML: (attributes) => ({
          href: attributes.href,
        }),
      },
      target: {
        default: null,
        parseHTML: (element) => element.getAttribute('target'),
        renderHTML: (attributes) => ({
          target: attributes.target,
        }),
      },
      isCustom: {
        default: undefined,
      },
      customBackgroundColor: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-custom-background-color'),
        renderHTML: (attributes) => ({
          'data-custom-background-color': attributes.customColor,
        }),
      },
      customTextColor: {
        default: DEFAULT_TEXT_ON_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-custom-text-color'),
        renderHTML: (attributes) => ({
          'data-custom-text-color': attributes.customColor,
        }),
      },
      icon: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-icon'),
        renderHTML: (attributes) => ({
          'data-icon': attributes.icon,
        }),
      },
      iconColor: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-icon-color'),
        renderHTML: (attributes) => ({
          'data-icon-color': attributes.iconColor,
        }),
      },
      iconPlacement: {
        default: 'left',
        parseHTML: (element) => element.getAttribute('data-icon-placement'),
        renderHTML: (attributes) => ({
          'data-icon-placement': attributes.iconPlacement,
        }),
      },
    };
  },

  parseHTML() {
    return [{ tag: `button[data-type="${this.name}"]` }];
  },

  addOptions() {
    return {
      HTMLAttributes: {
        'data-type': this.name,
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ['button', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(ButtonView);
  },
});

export default Button;
