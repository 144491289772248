import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ArrowLeft, ClockCounterClockwise, Plus } from '@phosphor-icons/react';

import { useWebsiteContext, useWebsitePageRouteGetter, WebsiteProvider } from '@/context/website-context';
import { cn } from '@/utils/cn';

import {
  ATTRIBUTES_PANEL_ID,
  LAYERS_PANEL_ID,
  SIDE_INSERT_PANEL_ID,
  VERSION_HISTORY_PANEL_ID,
} from '../_components/DreamEditor/constants';
import { Layout } from '../_components/Layout';
import { ActionMenu } from '../_components/Layout/ActionMenu';
import { NavSection } from '../_components/SideNav/NavSection';
import { Button, CloseButton } from '../_components/UI/Button';
import VersionHistoryNotice from '../_components/VersionHistoryNotice';

import { FooterEditor, FooterEditorProvider } from './_components/FooterEditor';

const FooterEditorPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const previousPageID = searchParams.get('previousPageID');
  const [isInsertPanelOpen, setIsInsertPanelOpen] = useState(false);
  const { previewSiteVersion, setPreviewSiteVersion } = useWebsiteContext();
  const [isVersionHistoryPanelOpen, setIsVersionHistoryPanelOpen] = useState(false);
  const { pageId } = useParams();
  const pageRouteGetter = useWebsitePageRouteGetter();
  const pageRoute = pageId ? pageRouteGetter?.getPageRouteFromID(pageId) : undefined;
  const homePageId = pageRouteGetter?.getHomePageID();

  const editorContainerRef = useRef<HTMLDivElement>(null);
  const [editorRect, setEditorRect] = useState<Partial<DOMRect>>({ height: 0, width: 0 });
  useEffect(() => {
    if (!isVersionHistoryPanelOpen) {
      setPreviewSiteVersion(undefined);
    }
  }, [isVersionHistoryPanelOpen, setPreviewSiteVersion]);

  useEffect(() => {
    if (pageId && homePageId && !pageRoute) {
      navigate(`/website_builder_v2/page/${homePageId}`);
    }
  }, [pageId, navigate, homePageId, pageRoute]);

  useEffect(() => {
    const editorContainer = editorContainerRef.current;
    const resizeObserver = new ResizeObserver((entries) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const entry of entries) {
        if (entry.contentRect) {
          setEditorRect(entry.contentRect);
        }
      }
    });

    if (editorContainer) {
      // setup listener for element size change
      resizeObserver.observe(editorContainer);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <Layout
      logoDropdownProps={{
        actionText: 'Go To Dashboard',
        backPath: '/',
      }}
      mainClassName="bg-wb-secondary pb-0 pt-0"
      isSidebarResizable
      leftChildren={
        <ActionMenu
          Icon={Plus}
          text="Insert"
          isActive={isInsertPanelOpen}
          onClick={() => {
            setIsInsertPanelOpen((prev) => !prev);
          }}
        />
      }
      rightChildren={
        <Button
          variant="secondary"
          Icon={ClockCounterClockwise}
          iconWeight="bold"
          onClick={() => setIsVersionHistoryPanelOpen((prev) => !prev)}
        />
      }
      sidenavChildren={
        <>
          <NavSection>
            <Link to={`/website_builder_v2/page/${previousPageID || homePageId}`} className="w-full cursor-pointer">
              <Button variant="secondary" size="sm" LeftIcon={ArrowLeft} className="w-full py-3">
                Back to page
              </Button>
            </Link>
          </NavSection>
          <NavSection>
            <div id={LAYERS_PANEL_ID} />
          </NavSection>
          <div
            id={SIDE_INSERT_PANEL_ID}
            className={cn(
              'absolute top-0 left-0 bottom-0 min-w-[200px] max-w-[250px] transition-transform duration-250 bg-wb-primary border-r border-solid overflow-y-auto',
              isInsertPanelOpen ? 'translate-x-0' : `-translate-x-[500px]`
            )}
          />
        </>
      }
      rightSideChildren={
        <>
          <div
            className="w-[250px] min-w-[250px] h-full bg-wb-primary border-l border-solid border-wb-primary overflow-y-scroll"
            id={ATTRIBUTES_PANEL_ID}
          />
          <div
            id={VERSION_HISTORY_PANEL_ID}
            className={cn(
              'absolute top-0 right-0 bottom-0 w-[250px] transition-transform duration-250 bg-wb-primary border-l border-solid overflow-y-auto p-3',
              isVersionHistoryPanelOpen ? 'translate-x-0' : `translate-x-[500px]`
            )}
          >
            <CloseButton
              onClose={() => setIsVersionHistoryPanelOpen(false)}
              className="-translate-x-1/2 translate-y-1/2"
            />
          </div>
        </>
      }
      titleType="page_name"
    >
      <div ref={editorContainerRef} className="h-full bg-transparent w-full relative">
        <FooterEditor editorRect={editorRect} />
      </div>
      {previewSiteVersion?.id && <VersionHistoryNotice />}
    </Layout>
  );
};

export default () => (
  <WebsiteProvider>
    <FooterEditorProvider>
      <FooterEditorPage />
    </FooterEditorProvider>
  </WebsiteProvider>
);
