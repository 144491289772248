import { Fragment, useMemo, useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import {
  ArrowSmallLeftIcon,
  Bars3Icon,
  BarsArrowDownIcon,
  BuildingLibraryIcon,
  CheckBadgeIcon,
  CheckIcon,
  ExclamationCircleIcon,
  EyeIcon,
  FlagIcon,
  LinkIcon,
  NoSymbolIcon,
  PhoneIcon,
  PhotoIcon,
  TagIcon,
  UserGroupIcon,
  UserMinusIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';

import Badge from '@/components/Badge';
import { Pagination } from '@/interfaces/general';
import useSpamContentVectors from '@/pages/SystemAdmin/SpamContent/_hooks/useSpamContentVectors';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export default function SystemAdminLayout() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const { data } = useSpamContentVectors({ status: 'unconfirmed', perPage: 1 });
  const { total: totalUnconfirmedSpam } = useMemo(() => data?.pages[0].pagination || ({} as Pagination), [data]);

  const { pathname } = useLocation();

  const navigation = [
    { name: 'Users', href: '/system_admin/users', icon: UserGroupIcon },
    {
      name: 'Workspaces',
      href: '/system_admin/organizations',
      icon: BuildingLibraryIcon,
    },
    { name: 'Allowed Emails', href: '/system_admin/allowed_emails', icon: CheckIcon },
    { name: 'Blocked Emails', href: '/system_admin/blocked_emails', icon: ExclamationCircleIcon },
    { name: 'Suppression List', href: '/system_admin/suppressed_emails', icon: BarsArrowDownIcon },
    { name: 'Blocked Domains', href: '/system_admin/blocked_domains', icon: NoSymbolIcon },
    { name: 'Blocked Links', href: '/system_admin/blocked_links', icon: LinkIcon },
    { name: 'Blocked Images', href: '/system_admin/blocked_images', icon: PhotoIcon },
    { name: 'Blocked Phone Numbers', href: '/system_admin/blocked_phone_numbers', icon: PhoneIcon },
    { name: 'Legit Workspaces', href: '/system_admin/legit_organizations', icon: CheckBadgeIcon },
    { name: 'Flagged Workspaces', href: '/system_admin/organization_flags', icon: FlagIcon },
    { name: 'Spam Content', href: '/system_admin/spam_content', icon: TagIcon, badge_content: totalUnconfirmedSpam },
    { name: 'Unconfirmed Users', href: '/system_admin/temp_users', icon: UserMinusIcon },
    { name: 'Account Review', href: '/system_admin/account_reviews', icon: EyeIcon },
  ].map((item) => {
    const current = pathname === item.href;

    return { ...item, current };
  });

  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 flex z-40 md:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full bg-white">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                <div className="flex-shrink-0 flex items-center px-4">
                  <Link to="/">
                    <img className="h-8 w-auto" src="https://media.beehiiv.net/assets/logo.png" alt="beehiiv" />
                  </Link>
                </div>
                <nav className="mt-5 px-2 space-y-1">
                  {navigation.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className={classNames(
                        item.current
                          ? 'bg-primary-50 text-primary-700'
                          : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                        'group flex items-center px-2 py-2 text-base font-medium rounded-md duration-200'
                      )}
                    >
                      <item.icon
                        className={classNames(
                          item.current ? 'text-primary-500' : 'text-gray-400 group-hover:text-gray-500',
                          'mr-4 flex-shrink-0 h-6 w-6 duration-200'
                        )}
                        aria-hidden="true"
                      />
                      {item.name}

                      <Badge className="ml-auto">{item.badge_content}</Badge>
                    </Link>
                  ))}
                </nav>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14">{/* Force sidebar to shrink to fit close icon */}</div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-white">
          <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
            <div className="flex items-center flex-shrink-0 px-4">
              <Link to="/">
                <img className="h-8 w-auto" src="https://media.beehiiv.net/assets/logo.png" alt="beehiiv" />
              </Link>
            </div>
            <nav className="mt-5 flex-1 px-2 space-y-1">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={classNames(
                    item.current
                      ? 'bg-primary-50 text-primary-700'
                      : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                    'group flex items-center px-2 py-2 text-sm font-medium rounded-md duration-200'
                  )}
                >
                  <item.icon
                    className={classNames(
                      item.current ? 'text-primary-500' : 'text-gray-400 group-hover:text-gray-500',
                      'mr-3 flex-shrink-0 h-6 w-6 duration-200'
                    )}
                    aria-hidden="true"
                  />
                  {item.name}

                  <Badge className="ml-auto">{item.badge_content}</Badge>
                </Link>
              ))}
            </nav>
          </div>
          <div className="flex-shrink-0 flex bg-gray-50 p-4">
            <a href="/" className="flex-shrink-0 w-full group block">
              <div className="flex items-center">
                <div className="ml-3 flex items-center">
                  <ArrowSmallLeftIcon className="w-5 h-5 mr-1 text-gray-400 group-hover:text-gray-700" />
                  <p className="text-sm text-gray-400 group-hover:text-gray-700">Exit</p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="md:pl-64 flex flex-col flex-1">
        <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-gray-100">
          <button
            type="button"
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <main className="flex-1">
          <Outlet />
        </main>
      </div>
    </div>
  );
}
