import { useCallback } from 'react';

import { SimpleInputWrapper } from '../../../UI/Input';
import { Text } from '../../../UI/Text';
import { ToggleGroup, ToggleGroupItem } from '../../../UI/ToggleGroup';
import { AttributeSettingProps } from '../types';

type BooleanSettingsProps = AttributeSettingProps<boolean> & {
  property?: string;
  title?: string;
  defaultValue?: boolean;
};

export const BooleanSettings = ({
  editor,
  activeNodeResult,
  property = 'enabled',
  title = 'Enabled',
  defaultValue = false,
  onChange,
}: BooleanSettingsProps) => {
  const { activeNodeType, activeNodePos, activeNodeAttributes } = activeNodeResult;

  const handleBooleanChange = useCallback(
    (value: boolean) => {
      if (activeNodePos === undefined || !activeNodeType) return;

      if (onChange) {
        onChange(value);
        return;
      }

      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, property, value);
        return true;
      });
    },
    [activeNodePos, activeNodeType, property, editor, onChange]
  );

  return (
    <div className="flex items-center justify-stretch gap-2">
      <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
        {title}
      </Text>
      <div className="grow flex items-center">
        <SimpleInputWrapper className="h-[38px] px-1">
          <ToggleGroup
            className="py-[1px] w-full"
            type="single"
            defaultValue={defaultValue || activeNodeAttributes?.[property] ? 'true' : 'false'}
            onValueChange={(value) => handleBooleanChange(value === 'true')}
          >
            <ToggleGroupItem asChild value="true">
              <Text variant="secondary" size="2xs" weight="medium" className="grow data-[state=on]:text-wb-accent">
                YES
              </Text>
            </ToggleGroupItem>
            <ToggleGroupItem asChild value="false">
              <Text variant="secondary" size="2xs" weight="medium" className="grow data-[state=on]:text-wb-accent">
                NO
              </Text>
            </ToggleGroupItem>
          </ToggleGroup>
        </SimpleInputWrapper>
      </div>
    </div>
  );
};
