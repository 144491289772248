import type React from 'react';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { CurrencyDollarIcon, ShareIcon, UserCircleIcon } from '@heroicons/react/20/solid';

import ActionModal from '@/components/ActionModal';
import Checkbox from '@/components/Form/Checkbox';
import { Typography } from '@/components/Typography';
import { useCurrentUser } from '@/context/current-user-context';
import useCreateAffiliate from '@/hooks/useCreateAffiliate';
import { Dropdown } from '@/ui/Dropdown';

interface InfoComponentProps {
  title: string;
  Icon: React.ElementType;
  description?: string;
  OptionComponent?: JSX.Element;
}

const options = [
  {
    label: 'Agency/Consultant',
    value: 'agency',
  },
  {
    label: 'Review/Comparison Site or Blog',
    value: 'review or comparison site',
  },
  {
    label: 'Software/Tool',
    value: 'software tool',
  },
  {
    label: 'Course Creator',
    value: 'course creator',
  },
  {
    label: 'Social Media Influencer',
    value: 'social media influencer',
  },
  {
    label: 'Affiliate Marketer',
    value: 'affiliate marketer',
  },
  {
    label: 'I just love beehiiv',
    value: 'beehiiv love',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

const InfoComponent = ({ title, description, OptionComponent, Icon }: InfoComponentProps) => {
  return (
    <div className="flex flex-row space-x-2 w-full">
      <div className="mb-auto">
        <div className="p-2 rounded-full bg-[#FDF2F8]">
          <Icon className="h-5 w-5 text-[#DB2777]" />
        </div>
      </div>
      <div className="flex flex-col space-y-2 w-full">
        <Typography token="font-medium/text/base" className="text-gray-900">
          {title}
        </Typography>
        {description && (
          <Typography token="font-normal/text/sm" className="text-gray-700">
            {description}
          </Typography>
        )}
        <div className="w-full">{OptionComponent || null}</div>
      </div>
    </div>
  );
};

const PartnerProgramAnnouncementModal = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [hasRun, setHasRun] = useState<boolean>(false);
  const [selectedPartnerType, setSelectedPartnerType] = useState<string>('');
  const { currentUser } = useCurrentUser();
  const createAffiliateMutation = useCreateAffiliate();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser && !hasRun) {
      setOpen(!currentUser?.partner_terms_accepted);
      setHasRun(true);
    }
  }, [currentUser, hasRun]);

  const onConfirm = () => {
    if (!selectedPartnerType) {
      toast.error('Select the option that best describes you');
      return;
    }
    if (!isChecked) {
      toast.error('Accept the TOS');
      return;
    }
    createAffiliateMutation.mutate(
      { partnerType: selectedPartnerType },
      {
        onSuccess: () => {
          setOpen(false);
          window.location.reload();
        },
      }
    );
  };

  return (
    <ActionModal
      isOpen={open}
      onClose={() => navigate('/')}
      isWorking={false}
      resourceId="partner-program"
      actionText="Join Partner Program"
      onProceed={onConfirm}
      modalSize="md"
      padding="p-0"
    >
      <img
        alt="beehiiv partner program"
        className="object-cover rounded-t-lg w-full h-32"
        src="https://beehiiv-images-production.s3.amazonaws.com/static_assets/partner+program_modal_illustration.png"
      />
      <div className="p-6 w-full flex flex-col gap-6 max-h-[70vh] sm:max-h-[484px] overflow-scroll">
        <div className="flex flex-col space-y-2">
          <Typography token="font-semibold/text/2xl">Welcome to the beehiiv Partner Program</Typography>
          <Typography token="font-normal/text/sm">
            Join thousands earning monthly passive income as beehiiv partners.
          </Typography>
        </div>
        <InfoComponent
          title="What best describes you?"
          Icon={UserCircleIcon}
          OptionComponent={
            <Dropdown
              name="name"
              className="w-full"
              value={selectedPartnerType}
              onSelect={(_name, selectedValue) => setSelectedPartnerType(selectedValue)}
              options={options}
            />
          }
        />
        <InfoComponent
          title="Share"
          description="Instantly access your unique partner link to start sharing today"
          Icon={ShareIcon}
        />
        <InfoComponent
          title="Earn"
          description="50% Commissions for 12 months + become eligible for our quarterly beach club giveaway and other prizes. "
          Icon={CurrencyDollarIcon}
        />
        <hr className="border-violet-100" />
        <div className="flex flex-row space-x-2 items-center">
          <Checkbox
            name="partner-tos"
            checked={isChecked}
            onChange={(checked) => setIsChecked(checked)}
            color="secondary"
          />
          <Typography token="font-normal/text/sm" className="text-gray-700 items-center">
            I accept and agree to our partner{' '}
            <a
              href="https://www.beehiiv.partners/c/tos"
              target="_blank"
              className="underline"
              rel="noopener noreferrer"
            >
              TOS
            </a>
          </Typography>
        </div>
        <div />
      </div>
    </ActionModal>
  );
};

export default PartnerProgramAnnouncementModal;
