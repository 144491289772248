import { PlusCircle } from '@phosphor-icons/react';
import { NavbarSerializableNode } from '@shared/dream-components';
import { v4 as uuidv4 } from 'uuid';

import { useNavbarContext } from '../NavbarContext';

export const AddNavbarItemButton = ({ node }: { node: NavbarSerializableNode }) => {
  const { content, onUpdateNodeContent } = useNavbarContext();

  if (!content) return null;
  if (!node || node.type !== 'navbar_menu_list_group') return null;

  const onAddNavbarItem = () => {
    if (!node.attrs) return;
    const nodeContent = node.content || [];
    const newItem = {
      type: 'navbar_item',
      attrs: {
        id: uuidv4(),
        type: 'link',
        label: 'Link',
      },
    };
    if (node.attrs?.type === 'left') {
      onUpdateNodeContent(node.attrs.id, [...nodeContent, newItem] as NavbarSerializableNode[]);
    } else {
      onUpdateNodeContent(node.attrs.id, [newItem, ...nodeContent] as NavbarSerializableNode[]);
    }
  };

  return (
    <button
      className="navbar-add-item-button text-wb-tertiary hover:text-wb-secondary bg-none outline-none border-none p-0 m-0 cursor-pointer"
      onClick={onAddNavbarItem}
      type="button"
    >
      <PlusCircle size={24} weight="fill" className=" transition-colors duration-200" />
    </button>
  );
};
