import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import api from '@/services/swarm';

interface Props {
  onSuccess?: () => void;
}

export default function useCreateSitePackage({ onSuccess }: Props) {
  return useMutation((formData: FormData) => api.post(`/site_packages`, formData), {
    onSuccess: () => {
      onSuccess?.();
      toast.success('Created!');
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.error || 'Something went wrong, please try again.');
    },
  });
}
