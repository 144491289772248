import React, { ReactNode, useEffect, useRef, useState } from 'react';
import Frame, { useFrame } from 'react-frame-component';
import { useParams } from 'react-router-dom';
import { getUsedFontsFromFamilies } from '@shared/dream-components';
import isEqual from 'lodash.isequal';
import { StyleSheetManager } from 'styled-components';

import { PublicationProvider } from '@/components/TiptapEditor/lib/context/PublicationContext';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useDreamEditorContext } from '@/context/dream-editor-context';
import { useSettings } from '@/context/settings-context';
import useSiteTemplate from '@/hooks/useSiteTemplates/useSiteTemplate';
import { DreamEditor } from '@/routes/website/_components/DreamEditor';
import { EditorHeader } from '@/routes/website/_components/EditorHeader/EditorHeader';

interface IframeWrapperProps {
  children: ReactNode;
  width: number;
  height: number;
  iframeProps?: React.IframeHTMLAttributes<HTMLIFrameElement>;
}

const StyleManager = ({ children }: { children: React.ReactNode }) => {
  const { document } = useFrame();
  return (
    <StyleSheetManager target={document?.head}>
      <>{children}</>
    </StyleSheetManager>
  );
};

const IFRAME_CONTENT_WIDTH = 1024; // px

const IframeWrapper = React.forwardRef<HTMLIFrameElement, IframeWrapperProps>(
  ({ children, width, height, iframeProps }, ref) => {
    const [initialContent, setInitialContent] = useState('');
    const { editor } = useDreamEditorContext();

    const [fontHrefs, setFontHrefs] = useState<Set<string>>(new Set());
    const [fetchedEditorFonts, setfetchedEditorFonts] = useState<Set<string>>(new Set());
    useEffect(() => {
      fetch('/canvas/index._html')
        .then((d) => d.text())
        .then(setInitialContent);
    }, []);

    const inPageFonts = editor?.storage?.fontFamily?.usedFonts;

    const inPageFontsKeys = inPageFonts ? Object.keys(inPageFonts) : undefined;

    useEffect(() => {
      if (!inPageFontsKeys) return;

      const getInPageFontLinks = async (families: string[] | undefined) => {
        if (!families) return;
        const renderableFonts = await getUsedFontsFromFamilies(families);
        setfetchedEditorFonts(new Set(families));
        setFontHrefs(new Set(renderableFonts));
      };

      if (!isEqual(Array.from(fetchedEditorFonts), inPageFontsKeys)) {
        getInPageFontLinks(inPageFontsKeys);
      }
    }, [inPageFontsKeys, fetchedEditorFonts]);

    const links = Array.from(new Set(fontHrefs)).map((href) => ({ rel: 'stylesheet', href }));

    if (!initialContent) return <div>loading..</div>;
    const scale = width / IFRAME_CONTENT_WIDTH;

    return (
      <Frame
        ref={ref}
        initialContent={initialContent}
        {...iframeProps}
        head={
          <>
            <style>
              {`
              .ProseMirror {
                outline: none !important;
              }

              .ProseMirror:focus {
                outline: none !important;
                box-shadow: none !important;
              }

              .ProseMirror {
                word-wrap: break-word;
                white-space: pre-wrap;
                white-space: break-spaces;
                -webkit-font-variant-ligatures: none;
                font-variant-ligatures: none;
                font-feature-settings: "liga" 0; /* the above doesn't seem to work in Edge */
              }

              .ProseMirror [contenteditable="false"] {
                white-space: normal;
              }

              .ProseMirror [contenteditable="false"] [contenteditable="true"] {
                white-space: pre-wrap;
              }

              .ProseMirror pre {
                white-space: pre-wrap;
              }

              img.ProseMirror-separator {
                display: inline !important;
                border: none !important;
                margin: 0 !important;
                width: 0 !important;
                height: 0 !important;
              }

              .ProseMirror-gapcursor {
                display: none;
                pointer-events: none;
                position: absolute;
                margin: 0;
              }

              .ProseMirror-gapcursor:after {
                content: "";
                display: block;
                position: absolute;
                top: -2px;
                width: 20px;
                border-top: 1px solid black;
                animation: ProseMirror-cursor-blink 1.1s steps(2, start) infinite;
              }

              @keyframes ProseMirror-cursor-blink {
                to {
                  visibility: hidden;
                }
              }

              .ProseMirror-hideselection *::selection {
                background: transparent;
              }

              .ProseMirror-hideselection *::-moz-selection {
                background: transparent;
              }

              .ProseMirror-hideselection * {
                caret-color: transparent;
              }

              .ProseMirror-focused .ProseMirror-gapcursor {
                display: block;
              }

              .tippy-box[data-animation=fade][data-state=hidden] {
                opacity: 0
              }
            `}
            </style>
            {links.map((link) => (
              <link key={link.href} rel={link.rel} href={link.href} />
            ))}
          </>
        }
        title="Page Editor Content"
        mountTarget="body"
        style={{
          transform: `scale(${scale})`,
          transformOrigin: 'top left',
          width: IFRAME_CONTENT_WIDTH,
          height: height / scale,
          border: 'none',
          outline: 'none',
          ...iframeProps?.style,
        }}
      >
        <StyleManager>{children}</StyleManager>
      </Frame>
    );
  }
);

export const TemplatesEditor = ({ editorRect }: { editorRect: Partial<DOMRect> }) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const { templateId } = useParams();
  const { data: template } = useSiteTemplate({ siteTemplateId: templateId as string });

  const { settings } = useSettings();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  return (
    <>
      <EditorHeader type="template" name={template?.name} />
      <IframeWrapper width={editorRect.width || 0} height={editorRect.height || 0} ref={iframeRef}>
        <div className="relative overflow-y-scroll min-h-[100px] h-fit">
          <PublicationProvider id={currentPublicationId}>
            {settings && (
              <div style={{ backgroundColor: 'white' }}>
                <DreamEditor
                  publicationId={currentPublicationId}
                  settings={settings}
                  className="typedream content template"
                  isFullPageHeight={false}
                  type="template"
                />
              </div>
            )}
          </PublicationProvider>
        </div>
      </IframeWrapper>
    </>
  );
};
