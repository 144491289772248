import { ProgressBar } from '@tremor/react';

import LoadingBox from '@/components/LoadingBox';
import { Typography } from '@/components/Typography';
import { useTrialStatus } from '@/hooks/usePublicationDashboard';
import BodyContainer from '@/routes/settings/_components/BodyContainer';
import CardHeader from '@/routes/settings/_components/CardHeader';
import { Button } from '@/ui/Button';
import { Card } from '@/ui/Card';

import { usePlanPrice } from '../../_hooks';

import AutoUpgradeToggle from './AutoUpgradeToggle';
import DowngradeSplitButton from './DowngradeSplitButton';
import UpgradeCta from './UpgradeCta';
import UpgradeSplitButton from './UpgradeSplitButton';

const UsageInformation: React.FC<{ organizationId: string; publicationId: string }> = ({
  organizationId,
  publicationId,
}) => {
  const { data: planPrice, isLoading, isError } = usePlanPrice({ organizationId, publicationId });
  const { data: upcomingPlanPrice } = usePlanPrice({ organizationId, publicationId, upcoming: true });

  const trialStatusQuery = useTrialStatus(publicationId);
  const { data: trialStatus } = trialStatusQuery || {};
  const trialEligible = trialStatus?.trial_eligible;

  const { active_subscriptions: activeSubscriptions = 1, organization_max_subscriptions: maxSubscriptions = 1 } =
    planPrice || {};

  const usagePercentage = Math.min(100, (activeSubscriptions / maxSubscriptions) * 100);

  return (
    <Card>
      <BodyContainer>
        <CardHeader
          title="Usage Information"
          cta={
            <div className="flex space-x-2">
              {trialEligible && (
                <Button
                  type="button"
                  size="xs"
                  variant="primary-inverse"
                  onClick={() => {
                    document.getElementById('start-trial')?.scrollIntoView({ behavior: 'smooth', block: 'start' });
                  }}
                >
                  Start a Trial
                </Button>
              )}
              {planPrice && upcomingPlanPrice && (
                <>
                  <DowngradeSplitButton
                    organizationId={organizationId}
                    publicationId={publicationId}
                    planPrice={planPrice}
                    upcomingPlanPrice={upcomingPlanPrice}
                  />
                  <UpgradeSplitButton
                    activeSubscriptions={activeSubscriptions}
                    organizationId={organizationId}
                    publicationId={publicationId}
                    planPrice={planPrice}
                    upcomingPlanPrice={upcomingPlanPrice}
                  />
                </>
              )}
            </div>
          }
        />

        <LoadingBox isLoading={isLoading} isError={isError} backgroundClassName="bg-transparent" height={150}>
          {planPrice && (
            <div className="space-y-6">
              <UpgradeCta planPrice={planPrice} />
              <div className="space-y-4">
                <div className="bg-surface-50 border border-surface-200 rounded p-6 space-y-4">
                  <Typography as="p" token="font-semibold/text/base">
                    Subscriber Count
                  </Typography>
                  <ProgressBar percentageValue={usagePercentage} color="violet" />
                  <Typography as="p" token="font-normal/text/sm">
                    {activeSubscriptions.toLocaleString()} out of {maxSubscriptions.toLocaleString()} subscribers
                  </Typography>
                </div>
              </div>
            </div>
          )}
        </LoadingBox>

        <AutoUpgradeToggle
          organizationId={organizationId}
          publicationId={publicationId}
          currentPlanPrice={planPrice}
          upcomingPlanPrice={upcomingPlanPrice}
        />
      </BodyContainer>
    </Card>
  );
};

export default UsageInformation;
