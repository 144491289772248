import { getColumnRenderHTML } from '@shared/dream-components';
import { mergeAttributes, Node } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';

import { getDataAttributes } from '../utils';

export type ColumnStorage = {
  columnNode: Element | null;
  hoverSide: 'left' | 'right' | null;
};

export const Column = Node.create<any, ColumnStorage>({
  name: 'column',

  content: 'block+',

  selectable: true,

  draggable: true,

  addAttributes() {
    return {
      position: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-position'),
        renderHTML: (attributes) => ({ 'data-position': attributes.position }),
      },
      width: {
        default: '50%',
        parseHTML: (element) => element.getAttribute('data-width'),
        renderHTML: (attributes) => ({
          'data-has-width': attributes.width ? true : undefined,
          style: attributes.width ? `width: ${attributes.width}%` : undefined,
        }),
      },
      borderRadius: {
        default: '0px 0px 0px 0px',
        parseHTML: (element) => element.getAttribute('data-border-radius'),
        renderHTML: (attributes) => ({
          'data-border-radius': attributes.borderRadius,
        }),
      },
      borderWidth: {
        default: '0px',
        parseHTML: (element) => element.getAttribute('data-border-width'),
        renderHTML: (attributes) => ({
          'data-border-width': attributes.borderWidth,
        }),
      },
      borderColor: {
        default: '#000000FF',
        parseHTML: (element) => element.getAttribute('data-border-color'),
        renderHTML: (attributes) => ({
          'data-border-color': attributes.borderColor,
        }),
      },
      borderStyle: {
        default: 'none',
        parseHTML: (element) => element.getAttribute('data-border-style'),
        renderHTML: (attributes) => ({
          'data-border-style': attributes.borderStyle,
        }),
      },
    };
  },

  addStorage() {
    return {
      columnNode: null,
      hoverSide: null,
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML({ HTMLAttributes, node }) {
    // TODO: fix typing error
    return getColumnRenderHTML({
      node: node as any,
      HTMLAttributes: mergeAttributes(getDataAttributes(HTMLAttributes), { 'data-type': this.name }),
    });
  },

  addProseMirrorPlugins() {
    const { editor } = this;
    return [
      new Plugin({
        key: new PluginKey('column-width-handle'),
        props: {
          handleDOMEvents: {
            mousemove: (view, event) => {
              if (!this.storage.columnNode) return;

              const columnRect = this.storage.columnNode.getBoundingClientRect();
              const mouseX = event.clientX;
              const hoverThreshold = 10; // pixels from the edge to consider as hovering over the side

              let hoverSide: 'left' | 'right' | null = null;
              if (mouseX <= columnRect.left + hoverThreshold) {
                hoverSide = 'left';
              } else if (mouseX >= columnRect.right - hoverThreshold) {
                hoverSide = 'right';
              }

              this.storage.hoverSide = hoverSide;
            },
            mouseover: (view, event) => {
              try {
                if (!editor) return;
                const target = event.target as HTMLElement;

                const nodeDom = target.closest('[data-type="column"]');
                if (nodeDom) {
                  this.storage.columnNode = nodeDom;
                }
              } catch (error) {
                console.error(error);
              }
            },
          },
        },
      }),
    ];
  },
});

export default Column;
