import { ImageSelect, Input, Switch, Textarea } from '@/components/Form';
import { Separator } from '@/components/UI/Dropdown';
import { useNavGroups } from '@/hooks/useNavGroups';
import { NavGroup } from '@/interfaces/nav_group';
import { useCustomPageContext } from '@/routes/website_builder/_components/Context/CustomPageContext';
import { Dropdown } from '@/ui/Dropdown';
import { capitalize } from '@/utils';

/**
 * Note:
 *
 * When this form is run we send to the server values for creating a nav link and the values for
 * creating a custom page. The nav link is created first and then the custom page is created.
 * Cta text is an example of a value that is used for the nav link. See the NavLink Model for more context.
 */

const CustomPageSettings = () => {
  const navGroupsQuery = useNavGroups();

  const {
    currentPublication,
    setChangesMade,
    setThumbnail,
    pagePayload,
    setPagePayload,
    thumbnail,
    handleSetPagePayload,
  } = useCustomPageContext();

  const navGroupOptions =
    navGroupsQuery?.data
      ?.filter((navGroup: NavGroup) => !navGroup.is_category_group)
      .map((navGroup: NavGroup) => ({
        value: navGroup.id,
        label: `Navbar: ${capitalize(navGroup.title)}`,
      })) || [];

  navGroupOptions.push({
    value: 'live',
    label: 'Standalone Page',
  });

  navGroupOptions.unshift({
    value: 'draft',
    label: 'Draft',
  });

  const handleImageChange = (file: File) => {
    setChangesMade(true);
    setThumbnail(file);
  };

  const handleImageClear = () => {
    setChangesMade(true);
    setThumbnail(null);
  };

  return (
    <div>
      <div className="space-y-3 pb-8">
        <ImageSelect
          name="thumbnail"
          dimensionSuggestion="1200px x 630px recommended"
          onFileSelect={handleImageChange}
          onFileClear={handleImageClear}
          file={thumbnail}
        />
        <Dropdown
          name="nav_group_id"
          labelText="Add to Website"
          value={pagePayload.nav_group_id || pagePayload.status || ''}
          onSelect={(_name: string, value: string) => {
            if (value === 'live') {
              handleSetPagePayload({ ...pagePayload, status: 'live', nav_group_id: '' });
            } else if (value === 'draft') {
              handleSetPagePayload({ ...pagePayload, status: 'draft', nav_group_id: '' });
            } else {
              handleSetPagePayload({ ...pagePayload, status: 'live', nav_group_id: value });
            }
          }}
          helperText="Choose a destination for this page. You can either add it to a tab in the navbar so it is easily accesible from the homepage, or you can add it as a standalone webpage, which will not appear in your navigation."
          options={navGroupOptions}
        />
        <Separator />
        <Input
          name="cta_text"
          value={pagePayload.cta_text || ''}
          labelText="Title"
          placeholder="Team Page"
          helperText="This is the text that users will see in the navigation menu. Feel free to use the same value for cta text and SEO title."
          onChange={(e) => handleSetPagePayload({ ...pagePayload, cta_text: e.target.value })}
          required
        />
        <Input
          name="slug"
          value={pagePayload.slug || ''}
          labelText="Slug"
          placeholder="about-us"
          helperText={`The page url will be ${currentPublication?.url}c/${pagePayload.slug}`}
          onChange={(e) => {
            const newValue = e.target.value
              .toLocaleLowerCase()
              .replace(/[^a-z0-9-]/g, '')
              .split(' ')
              .join('-');

            handleSetPagePayload({ ...pagePayload, slug: newValue });
          }}
          required
        />
        <Input
          name="meta_title"
          value={pagePayload.meta_title || ''}
          labelText="SEO Title"
          helperText="This title will be used in search engines and social media."
          placeholder='e.g. "About Us"'
          onChange={(e) => setPagePayload({ ...pagePayload, meta_title: e.target.value })}
        />
        <Textarea
          name="meta_description"
          placeholderText="This page will tell you a little bit about us."
          value={pagePayload.meta_description || ''}
          helperText="This description will be used in search engines and social media."
          labelText="SEO Description"
          onChange={(e) => setPagePayload({ ...pagePayload, meta_description: e.target.value })}
        />
        {currentPublication?.is_premium_enabled && (
          <div className="pt-4">
            <Switch
              name="premium"
              labelText="Paid Subscribers?"
              checked={pagePayload.premium || false}
              helperText="This page will only be accessible to paid subscribers."
              onChange={(_name: string, value: boolean) => setPagePayload({ ...pagePayload, premium: value })}
              variant="primary"
            />
          </div>
        )}
        <div className="pt-4">
          <Switch
            name="no_index_enabled"
            labelText="No Index Enabled?"
            checked={pagePayload.noindexing_enabled || false}
            helperText="This will prevent search engines from indexing this page."
            onChange={(_name: string, value: boolean) => setPagePayload({ ...pagePayload, noindexing_enabled: value })}
            variant="primary"
          />
        </div>
      </div>
    </div>
  );
};

export default CustomPageSettings;
