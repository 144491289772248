import { ArrowPathIcon, ArrowUturnRightIcon, EnvelopeIcon, GlobeAltIcon, SunIcon } from '@heroicons/react/24/solid';

import Badge from '@/components/Badge';
import { CustomDomain, CustomDomainTypes } from '@/interfaces/custom_domain';

interface Props {
  domain: CustomDomain;
  type: CustomDomainTypes;
}

export const EmailBadge = () => (
  <Badge size="sm" type="success" Icon={EnvelopeIcon}>
    Live
  </Badge>
);

export const WebBadge = () => (
  <Badge size="sm" type="success" Icon={GlobeAltIcon}>
    Live
  </Badge>
);

export const RedirectBadge = () => (
  <Badge size="sm" type="info_blue" Icon={ArrowUturnRightIcon}>
    Redirect
  </Badge>
);

export const SmartWarmingBadge = () => (
  <Badge size="sm" type="warning" Icon={SunIcon}>
    Smart Warming In Progress
  </Badge>
);

export const VerifyingBadge = ({ entri = false }: { entri: boolean }) => (
  <Badge size="sm" type="warning" Icon={ArrowPathIcon}>
    {entri ? 'Entri Verification in Progress' : 'Verifying'}
  </Badge>
);

const StatusBadge = ({ domain, type }: Props) => {
  const isVerifying =
    domain.entri_verification_pending ||
    (type === CustomDomainTypes.WEB && domain.web_verification_pending) ||
    (type === CustomDomainTypes.EMAIL && domain.email_verification_pending);

  if (isVerifying) {
    return <VerifyingBadge entri={domain.entri} />;
  }

  if (type === CustomDomainTypes.EMAIL) {
    return <EmailBadge />;
  }

  if (type === CustomDomainTypes.REDIRECT) {
    return <RedirectBadge />;
  }

  return <WebBadge />;
};

export default StatusBadge;
