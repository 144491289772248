import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import api from '../../services/swarm';
import useCurrentPublicationId from '../usePublications/useCurrentPublicationId';

export type SubdomainPayload = {
  subdomain?: string;
  email_username?: string;
  custom_email_domain_id?: string;
};

const useUpdateSubdomain = () => {
  const queryClient = useQueryClient();
  const currentPublicationId = useCurrentPublicationId();

  return useMutation(
    (resource_locator: SubdomainPayload) =>
      api.patch(`/publications/${currentPublicationId}/subdomain`, {
        publication_id: currentPublicationId,
        resource_locator,
      }),
    {
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      },
      onSuccess: () => queryClient.invalidateQueries(['publications', currentPublicationId]),
    }
  );
};

export default useUpdateSubdomain;
