export type PermissionLevel = 'read' | 'create' | 'update' | 'delete';
export type PermissionResource =
  | 'api_key'
  | 'automation'
  | 'billing'
  | 'content_import'
  | 'data_export'
  | 'domain_setting'
  | 'external_embed'
  | 'organization'
  | 'posts/schedule'
  | 'posts/test_sends/custom_recipients'
  | 'publication'
  | 'subscriber'
  | 'views/automations/overview'
  | 'views/dashboard/audience'
  | 'views/dashboard/engagement'
  | 'views/dashboard/monetization'
  | 'views/dashboard/onboarding'
  | 'views/dashboard/subscription_chart'
  | 'views/nav/analyze/reports'
  | 'views/nav/audience/automations'
  | 'views/nav/audience/polls'
  | 'views/nav/audience/segments'
  | 'views/nav/audience/subscribe_forms'
  | 'views/nav/audience/subscribers'
  | 'views/nav/audience/surveys'
  | 'views/nav/design/newsletter_builder'
  | 'views/nav/design/website_builder'
  | 'views/nav/grow/boosts'
  | 'views/nav/grow/magic_links'
  | 'views/nav/grow/recommendations'
  | 'views/nav/grow/referral_program'
  | 'views/nav/monetize/ads'
  | 'views/nav/monetize/boosts'
  | 'views/nav/monetize/subscriptions'
  | 'views/nav/settings'
  | 'views/nav/share_and_earn'
  | 'views/nav/wallet'
  | 'views/posts/ab_test'
  | 'views/posts/archive'
  | 'views/posts/bulk_actions'
  | 'views/posts/content_tags'
  | 'views/posts/feature'
  | 'views/posts/interactions'
  | 'views/posts/overview'
  | 'views/posts/performance'
  | 'views/posts/premium_data'
  | 'views/posts/publish'
  | 'views/settings/billing'
  | 'views/settings/company'
  | 'views/settings/integrations'
  | 'views/settings/notifications'
  | 'views/settings/payment_accounts'
  | 'views/settings/publication'
  | 'views/settings/v2/publication/content_import'
  | 'views/settings/v2/publication/domain'
  | 'views/settings/v2/publication/emails'
  | 'views/settings/v2/publication/export_data'
  | 'views/settings/v2/publication/general'
  | 'views/settings/v2/publication/payment_accounts'
  | 'views/settings/v2/publication/rss'
  | 'views/settings/v2/publication/subscribers_import'
  | 'views/settings/v2/publication/webhooks'
  | 'views/settings/web_builder'
  | 'views/tiptap/advertisement'
  | 'web_template'
  | 'web_theme';

export type Permissions = {
  [key: string]: any;
};

export const NO_PERMISSION_MESSAGE =
  "Your current role can't access this. Contact an admin to change your permissions.";

// This list is incomplete and should be updated as needed.
// It should be the namespaced class name of the model being checked.
export type PermissionResourceType = 'Automation' | 'Post' | 'PostTemplate';
