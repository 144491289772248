import { useState } from 'react';
import toast from 'react-hot-toast';
import { ClipboardDocumentCheckIcon, EyeSlashIcon, NewspaperIcon, PencilSquareIcon } from '@heroicons/react/20/solid';

import ActionModal from '@/components/ActionModal';
import { Textarea } from '@/components/Form';
import { Typography } from '@/components/Typography';
import { useSettings } from '@/context/settings-context';
import { useCurrentPublication, useUpdateWebTemplate, useWebTemplate } from '@/hooks';
import { useUpdatePublication } from '@/hooks/usePublications';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { Button } from '@/ui/Button';

import {
  SettingsContainer,
  SettingsGroup,
  SettingsSubContainer,
  SettingsSubheading,
} from '../../../../ui/Settings/SettingsContainer';
import SwitchDescriptionInput from '../../../../ui/Settings/SwitchDescriptionInput';

const WebBuilderSecurity = () => {
  const { data: currentPublication } = useCurrentPublication();

  const { data: webTemplate }: any = useWebTemplate();
  const webTemplateMutation = useUpdateWebTemplate();

  const currentPublicationId = useCurrentPublicationId();
  const publicationMutation = useUpdatePublication(currentPublicationId);

  const { settings } = useSettings();

  const [customTosOpen, setCustomTosOpen] = useState<boolean>(false);
  const [customTosText, setCustomTosText] = useState<string>(currentPublication?.terms_of_service || '');

  const onTextSave = async () => {
    try {
      publicationMutation.mutateAsync({ terms_of_service: customTosText });
      setCustomTosOpen(false);
      toast.success('Saved!');
    } catch (e) {
      toast.error('Error updating custom ToS');
    }
  };

  const gdprSettings = [
    {
      name: 'cookie_banner',
      title: 'Cookie Banner',
      description:
        'Enable this to show a cookie banner when someone first visits your publication site to allow them to opt out of certain cookies.',
      icon: ClipboardDocumentCheckIcon,
      onSave: (val: boolean) => publicationMutation.mutateAsync({ enable_cookie_banner: val }),
      value: currentPublication?.enable_cookie_banner || false,
      toastSuccessMessage: 'Saved!',
    },
    {
      name: 'tos_and_privacy_policy',
      title: 'Signup ToS & Privacy Policy',
      description:
        'Enable this to display your terms of service & privacy policy upon signup. Subscribers must check this box to signup. ',
      icon: NewspaperIcon,
      onSave: (val: boolean) => publicationMutation.mutateAsync({ enable_signup_confirmation: val }),
      value: currentPublication?.enable_signup_confirmation || false,
      toastSuccessMessage: 'Saved!',
    },
  ];

  return (
    <SettingsContainer>
      <SettingsGroup
        title="GDPR, CCPA, & Compliance"
        description="Both of these must be toggled on for your publication to be GDPR & CCPA compliant. "
      >
        <div className="flex flex-col space-y-6">
          <Typography className="text-sm text-gray-600 font-regular">
            Deletions and exports are handled at the platform level, make sure users email:{' '}
            <a
              href="mailto:privacy@beehiiv.com"
              target="_blank"
              rel="noreferrer"
              className="font-semibold text-gray-900 hover:opacity-70"
            >
              privacy@beehiiv.com
            </a>
            .
          </Typography>

          {gdprSettings.map((setting) => {
            return (
              <SwitchDescriptionInput
                key={setting.name}
                name={setting.name}
                title={setting.title}
                Icon={setting.icon}
                value={setting.value}
                onSave={setting.onSave}
                description={setting.description}
                toastSuccessMessage={setting.toastSuccessMessage}
              />
            );
          })}
          <SettingsSubContainer>
            <SettingsSubheading
              title="Subscriber Signup Terms of Service"
              description="Add and customize your publication‘s signup terms of service for your readers."
            />
            <Button
              Icon={PencilSquareIcon}
              type="button"
              variant="primary-inverse"
              className="flex flex-row space-x-2 w-fit"
              onClick={() => setCustomTosOpen(true)}
            >
              Edit Signup ToS
            </Button>
          </SettingsSubContainer>
        </div>
      </SettingsGroup>
      {settings?.subscription_password && (
        <SettingsGroup title="Log In" description="Change the subscriber login settings">
          <div className="flex flex-col space-y-1">
            <SwitchDescriptionInput
              name="subscriber_passwords"
              title="Subscriber Password Log In"
              Icon={EyeSlashIcon}
              description="Enable to allow subscribers to create and log in with passwords on your publication site."
              value={webTemplate?.enable_subscription_passwords || false}
              onSave={(val: boolean) => webTemplateMutation.mutateAsync({ enable_subscription_passwords: val })}
            />
          </div>
        </SettingsGroup>
      )}
      <ActionModal
        isOpen={customTosOpen}
        onClose={() => setCustomTosOpen(false)}
        isWorking={publicationMutation.isLoading}
        onProceed={onTextSave}
        headerText="Subscriber Signup Terms of Service"
        resourceId={customTosText}
      >
        <Typography token="font-light/text/sm" className="text-900">
          Add and customize below your publication&apos;s signup terms of service for your readers.
        </Typography>
        <div className="flex flex-col p-2 space-y-2">
          <Typography token="font-medium/text/sm" className="text-900">
            Terms of Service
          </Typography>
          <Textarea
            name="custom_tos_text"
            placeholderText='These Terms of Service ("Terms") govern your access to and use of [Service Name] and any related services provided by [Company Name]...'
            value={customTosText}
            onChange={(e) => setCustomTosText(e.target.value)}
          />
          <Typography token="font-light/text/xs" className="text-900">
            Please note, this is accessible via the ToS page on your publication site if the cookie banner is enabled.{' '}
          </Typography>
        </div>
      </ActionModal>
    </SettingsContainer>
  );
};

export default WebBuilderSecurity;
