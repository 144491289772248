import SectionRenderer from '../../helpers/SectionRenderer';
import { AttributeSettingProps } from '../../types';

import { ActionSettings } from './ActionsSettings';
import { BlockTypeSettings } from './BlockTypeSettings';
import { TemplateSettings } from './TemplateSettings';

export function BlockSettings({ editor, activeNodeResult }: AttributeSettingProps) {
  return (
    <SectionRenderer title="Block" collapsible={false}>
      <ActionSettings editor={editor} activeNodeResult={activeNodeResult} />
      <BlockTypeSettings editor={editor} />
      <TemplateSettings editor={editor} activeNodeResult={activeNodeResult} />
    </SectionRenderer>
  );
}
