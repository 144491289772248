import { FC, useCallback } from 'react';
import toast from 'react-hot-toast';

import { Option } from '../../interfaces/general';
import { Poll } from '../../interfaces/options';
import api from '../../services/swarm';
import { TypeaheadSelect } from '../Form';

interface Props {
  labelText?: string;
  helperText?: string;
  className?: string;
  labelClassName?: string;
  pollId?: string;
  onSelectPoll: (pollId: string) => void;
  onClearPoll: () => void;
  publicationId: string;
  allowAnyPoll?: boolean;
  allowAllPolls?: boolean;
}

const PollSelect: FC<Props> = (props: Props) => {
  const {
    labelText = 'Poll',
    helperText,
    className,
    labelClassName,
    pollId,
    onSelectPoll,
    onClearPoll,
    publicationId,
    allowAnyPoll,
    allowAllPolls,
  } = props;

  const fetchPolls = useCallback(
    (inputValue?: string) => {
      const params = {
        publication_id: publicationId,
        q: inputValue,
      };

      return api
        .get(`/options/polls/`, { params })
        .then((res) => {
          const polls = res.data?.options || [];

          let options: Option[] = polls.map((poll: Poll) => {
            const val: Option = {
              label: poll.name,
              value: poll.id,
            };
            return val;
          });

          if (allowAnyPoll && !params.q) {
            options = [{ label: 'Any poll', value: 'any' }, ...options];
          }

          if (allowAllPolls && !params.q) {
            options = [{ label: 'All polls', value: 'all' }, ...options];
          }

          return options;
        })
        .catch((errPayload) => {
          const error = errPayload?.response?.data?.error || 'Something went wrong';
          toast.error(error);
        });
    },
    [publicationId, allowAnyPoll, allowAllPolls]
  );

  const loadInitialValue = useCallback(() => {
    if (!pollId) {
      return undefined;
    }

    const params = {
      publication_id: publicationId,
      id: pollId,
    };

    return api
      .get(`/options/polls/`, { params })
      .then((res) => {
        const polls = res.data?.options || [];
        const onlyPoll: Poll = polls.length > 0 ? polls[0] : null;
        const option = onlyPoll && {
          label: onlyPoll?.name,
          value: onlyPoll?.id,
        };

        return option;
      })
      .catch((errPayload) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      });
  }, [publicationId, pollId]);

  const handleSelect = (name: string, value: string) => {
    onSelectPoll(value);
  };

  const handleClear = () => {
    onClearPoll();
  };

  return (
    <TypeaheadSelect
      name="poll"
      labelText={labelText || ''}
      helperText={helperText}
      placeholderText="Select a Poll"
      value={pollId || ''}
      onSelect={handleSelect}
      onClear={handleClear}
      loadOptions={fetchPolls}
      loadInitialValue={loadInitialValue}
      labelClassName={labelClassName}
      className={className}
    />
  );
};

export default PollSelect;
