import { ArrowPathIcon, PencilIcon } from '@heroicons/react/24/solid';

import { Button } from '@/ui/Button';
import { Card } from '@/ui/Card';

interface NoPostsProps {
  noResultsFound: boolean;
  resetFilters: (e: any) => void;
  createDraftMutation: any;
}

const NoPosts = ({ noResultsFound, resetFilters, createDraftMutation }: NoPostsProps) => {
  return (
    <Card density="wide">
      <div className="flex flex-col gap-6 items-center">
        {!noResultsFound && 'Once you create your first draft, it will show up here'}
        {noResultsFound && 'No results found'}
        {!noResultsFound && (
          <Button
            title="Start writing"
            variant="primary"
            size="xs"
            Icon={PencilIcon}
            type="button"
            onClick={() => createDraftMutation.mutateAsync({})}
          >
            Start writing
          </Button>
        )}
        {noResultsFound && (
          <Button
            title="Reset filter"
            variant="primary-inverse"
            Icon={ArrowPathIcon}
            size="xs"
            type="button"
            onClick={resetFilters}
          >
            Reset filter
          </Button>
        )}
      </div>
    </Card>
  );
};

export default NoPosts;
