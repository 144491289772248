import React from 'react';
import moment from 'moment-mini';

import Badge, { BadgeProps } from '@/components/Badge';
import LoadingBox from '@/components/LoadingBox';
import { EmptyCard, ItemColumn, ItemHeader, ItemHeaders, ItemRow, Items, ItemsBody } from '@/components/ResourceList';
import { AlignType } from '@/components/ResourceList/types';
import { Typography } from '@/components/Typography';
import { useImports } from '@/hooks';
import { Publication } from '@/interfaces/publication';
import { LoadMoreButton } from '@/ui/LoadMoreButton';
import { ResourceListSearchText } from '@/ui/ResourceListSearchText';

import BodyContainer from '../../_components/BodyContainer';
import CardHeader from '../../_components/CardHeader';

interface Props {
  publication: Publication;
}

const HistoricalImportsCard = ({ publication }: Props) => {
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, isError } = useImports(publication.id);
  const historicalImports = data?.pages.flatMap((page) => page.imports) || [];
  const totalHistoricalImportCount = data?.pages[0]?.pagination?.total;
  const showingHistoricalImportsCount = historicalImports?.length || 0;

  const getBadgeType = (importAndValidationStatus: string): BadgeProps['type'] => {
    if (importAndValidationStatus === 'failed') {
      return 'error' as BadgeProps['type'];
    }
    if (importAndValidationStatus === 'completed') {
      return 'success' as BadgeProps['type'];
    }
    return 'info' as BadgeProps['type'];
  };

  const noResults = !isLoading && !isError && totalHistoricalImportCount === 0;

  return (
    <LoadingBox isLoading={isLoading} isError={isError}>
      <BodyContainer>
        <CardHeader title="Historical Imports" />
        <div className="flex flex-col gap-y-2">
          <ResourceListSearchText
            showingCount={showingHistoricalImportsCount}
            totalCount={totalHistoricalImportCount || 0}
          />
          {noResults ? (
            <EmptyCard title="No historical subscribers imports available" />
          ) : (
            <Items>
              <ItemHeaders>
                <ItemHeader align={AlignType.LEFT}>Date Imported</ItemHeader>
                <ItemHeader align={AlignType.LEFT}>Import Status</ItemHeader>
                <ItemHeader align={AlignType.LEFT}>Accepted</ItemHeader>
              </ItemHeaders>
              <ItemsBody>
                {historicalImports.map((historicalImport) => (
                  <ItemRow key={historicalImport.id}>
                    <ItemColumn align={AlignType.LEFT}>
                      <Typography size="sm" colorWeight="700">
                        {moment(historicalImport.created_at).format('lll')}
                      </Typography>
                    </ItemColumn>
                    <ItemColumn align={AlignType.LEFT}>
                      <div className="flex flex-col gap-y-2">
                        <Badge type={getBadgeType(historicalImport.import_and_validation_status)} className="w-fit">
                          {historicalImport.import_and_validation_status}
                        </Badge>
                        {historicalImport.failure_reason && (
                          <Typography size="sm" colorWeight="700">
                            {historicalImport.failure_reason}
                          </Typography>
                        )}
                      </div>
                    </ItemColumn>
                    <ItemColumn align={AlignType.LEFT}>
                      <Typography size="sm" colorWeight="700">
                        {historicalImport.imported_subscription_count}
                      </Typography>
                    </ItemColumn>
                  </ItemRow>
                ))}
              </ItemsBody>
            </Items>
          )}
        </div>
        {hasNextPage && (
          <div className="flex justify-start w-fit">
            <LoadMoreButton fetchNextPage={fetchNextPage} isFetchingNextPage={isFetchingNextPage} />
          </div>
        )}
      </BodyContainer>
    </LoadingBox>
  );
};

export default HistoricalImportsCard;
